function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { Step as MuiStep } from '@material-ui/core';
import StepProgressContext from './internal/StepProgressContext';
import { STEP_PROGRESS_STEP_CLASSNAME } from './internal/constants';
import cn from 'classnames';

import { borderRadiusMedium, spacing40 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            borderColor: theme.palette.ctaColor.base
        },
        expansion: {
            backgroundColor: '#FFFFFF',
            boxShadow: '\n            0 19px 20px 0 rgba(0, 0, 0, 0.03),\n            0 3px 8px 0 rgba(0, 0, 0, 0.06)\n        ',
            padding: spacing40,
            borderRadius: borderRadiusMedium
        }
    };
};

/**
 * A single step in a stepper
 */
var Step = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    var _React$useContext = React.useContext(StepProgressContext),
        expansion = _React$useContext.expansion;

    return (
        /* These marker class names will be used by its parent component StepProgress */
        React.createElement(MuiStep, Object.assign({}, rest, {
            className: cn(STEP_PROGRESS_STEP_CLASSNAME, _defineProperty({}, classes.expansion, expansion)),
            classes: classOverrides,
            ref: ref
        }))
    );
});

Step.muiName = 'Step';
Step.displayName = 'Step';

Step.propTypes = {
    /**
     * Sets the step as active. Is passed to child components.
     */
    active: PropTypes.bool,
    /**
     * Should be `Step` sub-components such as `StepLabel`, `StepContent`.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepButton` is a child of `Step`. Is passed to child components.
     */
    disabled: PropTypes.bool
};

// NOTE: we are very intentionally NOT providing defaults for these props; if any
// value is assigned to the active, completed, or disabled props, those values will
// supercede the directives of the parent StepProgress; ie, if the stepper tries to
// set a Step to active, any defaults we declare here will override those settings

export default withStyles(styles)(Step);