"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* CTA taupe base color */
var colorCtaTaupeBase = exports.colorCtaTaupeBase = "#72665B";
/* CTA blue tint color */
var colorCtaBlueTint = exports.colorCtaBlueTint = "#F2F8FC";
/* CTA iris active color */
var colorCtaIrisActive = exports.colorCtaIrisActive = "#3A3B87";
/* CTA green hover color */
var colorCtaGreenHover = exports.colorCtaGreenHover = "#046D44";
/* CTA plum active color */
var colorCtaPlumActive = exports.colorCtaPlumActive = "#502F5E";
/* CTA green base color */
var colorCtaGreenBase = exports.colorCtaGreenBase = "#00834F";
/* Brand Neutral 250 color */
var colorBrandNeutral250 = exports.colorBrandNeutral250 = "#E9E9E9";
/* CTA taupe tint color */
var colorCtaTaupeTint = exports.colorCtaTaupeTint = "#F8F7F7";
/* CTA taupe hover color */
var colorCtaTaupeHover = exports.colorCtaTaupeHover = "#5F564E";
/* CTA blue active color */
var colorCtaBlueActive = exports.colorCtaBlueActive = "#0A4982";
/* Brand Neutral 100 color */
var colorBrandNeutral100 = exports.colorBrandNeutral100 = "#FFFFFF";
/* Brand Neutral 200 color */
var colorBrandNeutral200 = exports.colorBrandNeutral200 = "#F8F8F8";
/* Brand CTA hover color */
var colorBrandCtaHover = exports.colorBrandCtaHover = "#065AA5";
/* Brand Neutral 300 color */
var colorBrandNeutral300 = exports.colorBrandNeutral300 = "#D9D9D9";
/* Brand secondary base color */
var colorBrandSecondary = exports.colorBrandSecondary = "#51ABFF";
/* CTA iris base color */
var colorCtaIrisBase = exports.colorCtaIrisBase = "#5353D1";
/* Brand Neutral 400 color */
var colorBrandNeutral400 = exports.colorBrandNeutral400 = "#B2B3B7";
/* CTA green tint color */
var colorCtaGreenTint = exports.colorCtaGreenTint = "#F2F9F6";
/* CTA plum base color */
var colorCtaPlumBase = exports.colorCtaPlumBase = "#783F8C";
/* Brand Neutral 500 color */
var colorBrandNeutral500 = exports.colorBrandNeutral500 = "#5B5E65";
/* Brand CTA active color */
var colorBrandCtaActive = exports.colorBrandCtaActive = "#0A4982";
/* Brand Neutral 600 color */
var colorBrandNeutral600 = exports.colorBrandNeutral600 = "#151618";
/* CTA blue hover color */
var colorCtaBlueHover = exports.colorCtaBlueHover = "#065AA5";
/* CTA taupe active color */
var colorCtaTaupeActive = exports.colorCtaTaupeActive = "#4D4640";
/* CTA blue base color */
var colorCtaBlueBase = exports.colorCtaBlueBase = "#026BC8";
/* CTA iris tint color */
var colorCtaIrisTint = exports.colorCtaIrisTint = "#F6F6FD";
/* CTA plum hover color */
var colorCtaPlumHover = exports.colorCtaPlumHover = "#643775";
/* CTA plum tint color */
var colorCtaPlumTint = exports.colorCtaPlumTint = "#F8F5F9";
/* CTA green active color */
var colorCtaGreenActive = exports.colorCtaGreenActive = "#085739";
/* CTA iris hover color */
var colorCtaIrisHover = exports.colorCtaIrisHover = "#4747AC";
/* Brand CTA base color */
var colorBrandCtaBase = exports.colorBrandCtaBase = "#026BC8";
/* Brand primary base color */
var colorBrandPrimary = exports.colorBrandPrimary = "#5353D1";
var fountain400 = exports.fountain400 = "#90C9FF";
var iris300 = exports.iris300 = "#B0B0EA";
var fountain500 = exports.fountain500 = "#70BAFF";
var iris400 = exports.iris400 = "#9191E2";
var kiwi100 = exports.kiwi100 = "#F4FAEF";
var iris500 = exports.iris500 = "#7272D9";
var kiwi200 = exports.kiwi200 = "#E1F2D3";
var fountain600 = exports.fountain600 = "#51ABFF";
var kiwi300 = exports.kiwi300 = "#CEEAB7";
var colorBackgroundHeaderBarItemSelected = exports.colorBackgroundHeaderBarItemSelected = "#21226E";
var iris600 = exports.iris600 = "#5353D1";
var kiwi400 = exports.kiwi400 = "#BBE29B";
var tangerine100 = exports.tangerine100 = "#FFF4EB";
var kiwi500 = exports.kiwi500 = "#A8D97E";
var tangerine200 = exports.tangerine200 = "#FFDFC8";
/* Fill color to be used behind white warning icon. */
var colorFillAlertWarning = exports.colorFillAlertWarning = "#EFC728";
var kiwi600 = exports.kiwi600 = "#95D162";
var tangerine300 = exports.tangerine300 = "#FFCAA4";
var tangerine400 = exports.tangerine400 = "#FFB581";
/* Global divider color. */
var colorBackgroundDivider = exports.colorBackgroundDivider = "#B2B3B7";
var tangerine500 = exports.tangerine500 = "#FFA15D";
var tangerine600 = exports.tangerine600 = "#FF8C3A";
var colorFillLogoAlternativeText = exports.colorFillLogoAlternativeText = "#454647";
/* Fill color to be used behind white success icon. */
var colorFillAlertSuccess = exports.colorFillAlertSuccess = "#00AF69";
var meadow100 = exports.meadow100 = "#E8F9F5";
var saffron100 = exports.saffron100 = "#FDF9EA";
var saffron200 = exports.saffron200 = "#FBEFC3";
var meadow200 = exports.meadow200 = "#BFEDE2";
/* Default background color that should be applied to all pages other than login and dashboard pages. */
var colorBackgroundDefault = exports.colorBackgroundDefault = "#F8F8F8";
var colorFillLogoPreferred = exports.colorFillLogoPreferred = "#FFFFFF";
var saffron300 = exports.saffron300 = "#F8E59C";
var meadow300 = exports.meadow300 = "#96E2CF";
/* Background color for warning alerts and notifications. */
var colorBackgroundAlertWarning = exports.colorBackgroundAlertWarning = "#F5F2E7";
var saffron400 = exports.saffron400 = "#F5DB75";
/* Fill color to be used behind white neutral/information icon. */
var colorFillAlertNeutral = exports.colorFillAlertNeutral = "#51ABFF";
var meadow400 = exports.meadow400 = "#6CD6BC";
/* Fill color to be used behind white error icon. */
var colorFillAlertError = exports.colorFillAlertError = "#D42828";
var saffron500 = exports.saffron500 = "#F2D14F";
var meadow500 = exports.meadow500 = "#43CBA9";
var saffron600 = exports.saffron600 = "#EFC728";
var meadow600 = exports.meadow600 = "#1ABF96";
var colorBackgroundHeaderBarItemHover = exports.colorBackgroundHeaderBarItemHover = "#2E2E93";
var purple100 = exports.purple100 = "#F8EEF6";
var purple200 = exports.purple200 = "#ECCCE6";
/* Background color for default alerts and notifications. */
var colorBackgroundAlertDefault = exports.colorBackgroundAlertDefault = "#F8F8F8";
var purple300 = exports.purple300 = "#DFAAD6";
/* Background color for success alerts and notifications. */
var colorBackgroundAlertSuccess = exports.colorBackgroundAlertSuccess = "#EAF7F2";
var purple400 = exports.purple400 = "#D389C6";
var purple500 = exports.purple500 = "#C667B6";
var purple600 = exports.purple600 = "#BA46A6";
var colorFillLogoAlternativePowerButton = exports.colorFillLogoAlternativePowerButton = "#5353D1";
/* Background color for neutral alerts and notifications. */
var colorBackgroundAlertNeutral = exports.colorBackgroundAlertNeutral = "#EFF7FF";
var fountain100 = exports.fountain100 = "#EFF7FF";
/* Background color for error alerts and notifications. */
var colorBackgroundAlertError = exports.colorBackgroundAlertError = "#FFEEED";
var fountain200 = exports.fountain200 = "#CEE7FF";
var iris100 = exports.iris100 = "#EEEEFA";
var fountain300 = exports.fountain300 = "#AFD8FF";
var iris200 = exports.iris200 = "#CFCFF2";
/* Border width tokens should only be used within the border property. */
var borderWidthThin = exports.borderWidthThin = "0.063rem";
/* Border width tokens should only be used within the border property. */
var borderWidthThick = exports.borderWidthThick = "0.125rem";
/* Border width tokens should only be used within the border property. */
var borderWidthThicker = exports.borderWidthThicker = "0.1875rem";
/* Border width tokens should only be used within the border property. */
var borderWidthThickest = exports.borderWidthThickest = "0.25rem";
/* Global focus color for better accessibility. */
var colorBorderGlobalFocus = exports.colorBorderGlobalFocus = "#51ABFF";
/* Global error state border color. */
var colorGlobalBorderError = exports.colorGlobalBorderError = "#D42828";
/* Global default state border color. */
var colorGlobalBorderDefault = exports.colorGlobalBorderDefault = "#B2B3B7";
/* Global disabled state border color. */
var colorGlobalBorderDisabled = exports.colorGlobalBorderDisabled = "#B2B3B7";
/* Border color for success alerts and notifications. */
var colorBorderAlertSuccess = exports.colorBorderAlertSuccess = "#00AF69";
/* Border color for success alerts and notifications. */
var colorBorderAlertWarning = exports.colorBorderAlertWarning = "#EFC728";
/* Border color for success alerts and notifications. */
var colorBorderAlertNeutral = exports.colorBorderAlertNeutral = "#51ABFF";
/* Border color for success alerts and notifications. */
var colorBorderAlertError = exports.colorBorderAlertError = "#D42828";
/* 0 second */
var durationNone = exports.durationNone = "0s";
/* .1 second */
var durationFast = exports.durationFast = ".1s";
/* .15 seconds */
var durationFastAlt = exports.durationFastAlt = ".15s";
/* .3 seconds */
var durationMedium = exports.durationMedium = ".3s";
/* .5 seconds */
var durationSlow = exports.durationSlow = ".5s";
var fontFamilyDefault = exports.fontFamilyDefault = "'Noto Sans', Helvetica, Arial, sans-serif";
var fontFamilyHeader = exports.fontFamilyHeader = "'Roboto', Helvetica, Arial, sans-serif";
var fontFamilyCondensed = exports.fontFamilyCondensed = "'Roboto Condensed', 'Impact', 'Franklin Gothic Bold', sans-serif";
var fontFamilyMonospace = exports.fontFamilyMonospace = "'Courier New', Courier, monospace";
/* Large body text. */
var fontSizeLarge = exports.fontSizeLarge = "1rem";
/* h5 small font size. */
var fontSizeHeader5Small = exports.fontSizeHeader5Small = "0.75rem";
/* h4 small font size. */
var fontSizeHeader4Small = exports.fontSizeHeader4Small = "0.875rem";
/* h3 small font size. */
var fontSizeHeader3Small = exports.fontSizeHeader3Small = "1rem";
/* Default body text. */
var fontSizeDefault = exports.fontSizeDefault = "0.875rem";
/* h2 small font size. */
var fontSizeHeader2Small = exports.fontSizeHeader2Small = "1.375rem";
/* h1 small font size. */
var fontSizeHeader1Small = exports.fontSizeHeader1Small = "1.75rem";
/* h1 font size. */
var fontSizeHeader1 = exports.fontSizeHeader1 = "2rem";
/* h2 font size. */
var fontSizeHeader2 = exports.fontSizeHeader2 = "1.5rem";
/* h3 font size. */
var fontSizeHeader3 = exports.fontSizeHeader3 = "1.125rem";
/* h4 font size. */
var fontSizeHeader4 = exports.fontSizeHeader4 = "1rem";
/* Small body text. */
var fontSizeSmall = exports.fontSizeSmall = "0.75rem";
/* h5 font size. */
var fontSizeHeader5 = exports.fontSizeHeader5 = "0.875rem";
/* h6 font size. */
var fontSizeHeader6 = exports.fontSizeHeader6 = "0.75rem";
/* Light font weight. */
var fontWeightLight = exports.fontWeightLight = 300;
/* Normal or regular font weight. */
var fontWeightNormal = exports.fontWeightNormal = 400;
/* Use fontWeightBold instead. */
var fontWeightStrong = exports.fontWeightStrong = 600;
/* Bold font weight. */
var fontWeightBold = exports.fontWeightBold = 700;
/* Minimum accessible line height value. */
var lineHeightDefault = exports.lineHeightDefault = 1.5;
var lineHeightParagraphLarge = exports.lineHeightParagraphLarge = "1.375";
var lineHeightParagraphMedium = exports.lineHeightParagraphMedium = "1.43";
var lineHeightHeader1 = exports.lineHeightHeader1 = "1.17";
var lineHeightHeader2 = exports.lineHeightHeader2 = "1.23";
var lineHeightHeader3 = exports.lineHeightHeader3 = "1.33";
var lineHeightHeader4 = exports.lineHeightHeader4 = "1.33";
var lineHeightParagraphSmall = exports.lineHeightParagraphSmall = "1.5";
var lineHeightReset = exports.lineHeightReset = "1";
var lineHeightHeader2Small = exports.lineHeightHeader2Small = "1.25";
/* Screens smaller than 480px. */
var mqXSmall = exports.mqXSmall = "only screen and (min-width: 0px) and (max-width: 479px)";
/* Screens smaller than 768px. */
var mqSmallDown = exports.mqSmallDown = "only screen and (min-width: 0px) and (max-width: 767px)";
/* Screens 480px or larger. */
var mqSmall = exports.mqSmall = "only screen and (min-width: 480px)";
/* Screens smaller than 992px. */
var mqMediumDown = exports.mqMediumDown = "only screen and (min-width: 0px) and (max-width: 991px)";
/* Screens 768px or larger. */
var mqMedium = exports.mqMedium = "only screen and (min-width: 768px)";
/* Screens 992px or larger. */
var mqLarge = exports.mqLarge = "only screen and (min-width: 992px)";
/* 20% of transparency of an element */
var opacity2 = exports.opacity2 = "0.2";
/* 30% of transparency of an element */
var opacity3 = exports.opacity3 = "0.3";
/* 40% of transparency of an element */
var opacity4 = exports.opacity4 = "0.4";
/* 70% of transparency of an element */
var opacity7 = exports.opacity7 = "0.7";
/* 80% of transparency of an element */
var opacity8 = exports.opacity8 = "0.8";
/* Generic reset border radius for global use. */
var borderRadiusReset = exports.borderRadiusReset = "0";
/* Circle border radius for global use. */
var borderRadiusCircle = exports.borderRadiusCircle = "50%";
/* Generic extra small border radius value. */
var borderRadiusXSmall = exports.borderRadiusXSmall = "0.125rem";
/* Generic small border radius value. */
var borderRadiusSmall = exports.borderRadiusSmall = "0.25rem";
/* Generic medium border radius value. */
var borderRadiusMedium = exports.borderRadiusMedium = "0.375rem";
/* Generic large border radius value. */
var borderRadiusLarge = exports.borderRadiusLarge = "1rem";
/* Generic extra large border radius value. */
var borderRadiusXLarge = exports.borderRadiusXLarge = "6.25rem";
var boxShadowReset = exports.boxShadowReset = "none";
/* Focus state border, inset. */
var boxShadowFocusInset = exports.boxShadowFocusInset = "inset 0 0 0 0.125rem #51ABFF";
/* Focus state border, default outset. */
var boxShadowFocus = exports.boxShadowFocus = "0 0 0 0.125rem #51ABFF";
/* Error state border, outset. */
var boxShadowError = exports.boxShadowError = "0 0 0 0.063rem #D42828";
var sizingXLarge = exports.sizingXLarge = "2.5rem";
/* Height used to span the entire height of its children. */
var heightFluid = exports.heightFluid = "100%";
/* Width used to span the entirety of its parent container. */
var widthFluid = exports.widthFluid = "100%";
var sizingSmall = exports.sizingSmall = "1rem";
var sizingXxSmall = exports.sizingXxSmall = "0.25rem";
/* Height of small input fields. */
var heightInputFieldsSmall = exports.heightInputFieldsSmall = "2.375rem";
var sizingXSmall = exports.sizingXSmall = "0.5rem";
var sizingMedium = exports.sizingMedium = "1.5rem";
var sizingXxLarge1 = exports.sizingXxLarge1 = "4rem";
/* Height of input fields. */
var heightInputFields = exports.heightInputFields = "3.375rem";
var sizingLarge = exports.sizingLarge = "2rem";
/* Width of input fields that are not full width. */
var widthInputFields = exports.widthInputFields = "20.9375rem";
var sizingXxLarge = exports.sizingXxLarge = "3rem";
/* Height of the HeaderBar component. */
var heightHeaderBar = exports.heightHeaderBar = "4rem";
/* Use '12px or 0.75rem' */
var spacingXSmall2 = exports.spacingXSmall2 = "0.75rem";
/*  */
var spacing30 = exports.spacing30 = "0.5rem";
/*  */
var spacing20 = exports.spacing20 = "0.25rem";
/*  */
var spacing10 = exports.spacing10 = "0.125rem";
/* Use 'spacing-20' */
var spacingXxSmall = exports.spacingXxSmall = "0.25rem";
/* Use 'spacing-40' */
var spacingSmall = exports.spacingSmall = "1rem";
/* Use 'spacing-90' */
var spacingXxxLarge = exports.spacingXxxLarge = "4.5rem";
/* Use 'spacing-70' */
var spacingXLarge = exports.spacingXLarge = "2.5rem";
/* Use 'spacing-30' */
var spacingXSmall = exports.spacingXSmall = "0.5rem";
/* Use 'spacing-60' */
var spacingLarge = exports.spacingLarge = "2rem";
/* Use 'spacing-50' */
var spacingMedium = exports.spacingMedium = "1.5rem";
/* Use 'spacing-80' */
var spacingXxLarge = exports.spacingXxLarge = "3rem";
/* Use '64px or 4rem' */
var spacingXxLarge1 = exports.spacingXxLarge1 = "4rem";
/*  */
var spacing90 = exports.spacing90 = "4.5rem";
/*  */
var spacing80 = exports.spacing80 = "3rem";
/*  */
var spacing70 = exports.spacing70 = "2.5rem";
/* Padding around TextField, DatePicker, and TimePicker component inputs. */
var paddingTextField = exports.paddingTextField = "1.375rem 1rem .625rem 1rem";
/*  */
var spacing60 = exports.spacing60 = "2rem";
/*  */
var spacing50 = exports.spacing50 = "1.5rem";
/* Use '10px or 0.625rem' */
var spacingXSmall1 = exports.spacingXSmall1 = "0.625rem";
/*  */
var spacing40 = exports.spacing40 = "1rem";
/*  */
var spacingInsetShort20 = exports.spacingInsetShort20 = "0.5rem 1rem";
/*  */
var spacingInsetTall60 = exports.spacingInsetTall60 = "4.5rem 3rem";
/*  */
var spacingInsetShort10 = exports.spacingInsetShort10 = "0.25rem 0.5rem";
/*  */
var spacingInsetTall50 = exports.spacingInsetTall50 = "3rem 2rem";
/*  */
var spacingInsetTall40 = exports.spacingInsetTall40 = "2.25rem 1.5rem";
/*  */
var spacingInsetTall30 = exports.spacingInsetTall30 = "1.5rem 1rem";
/*  */
var spacingInsetTall20 = exports.spacingInsetTall20 = "0.75rem 0.5rem";
/*  */
var spacingInsetTall10 = exports.spacingInsetTall10 = "0.375rem 0.25rem";
/*  */
var spacingInset70 = exports.spacingInset70 = "4rem";
/*  */
var spacingInset60 = exports.spacingInset60 = "3rem";
/*  */
var spacingInset50 = exports.spacingInset50 = "2rem";
/*  */
var spacingInset40 = exports.spacingInset40 = "1.5rem";
/*  */
var spacingInset30 = exports.spacingInset30 = "1rem";
/*  */
var spacingInset20 = exports.spacingInset20 = "0.5rem";
/*  */
var spacingInset10 = exports.spacingInset10 = "0.25rem";
/*  */
var spacingInsetShort60 = exports.spacingInsetShort60 = "2rem 3rem";
/*  */
var spacingInsetShort50 = exports.spacingInsetShort50 = "1.25rem 2.5rem";
/*  */
var spacingInsetShort40 = exports.spacingInsetShort40 = "1rem 2rem";
/*  */
var spacingInsetShort30 = exports.spacingInsetShort30 = "0.75rem 1.5rem";
/*  */
var layout10 = exports.layout10 = "1rem";
/*  */
var layout20 = exports.layout20 = "1.5rem";
/*  */
var layout30 = exports.layout30 = "2rem";
/*  */
var layout40 = exports.layout40 = "3rem";
/*  */
var layout50 = exports.layout50 = "4rem";
/*  */
var layout60 = exports.layout60 = "6rem";
/*  */
var layout70 = exports.layout70 = "7.5rem";
var colorTextSecondary = exports.colorTextSecondary = "#5B5E65";
var colorTextDisabled = exports.colorTextDisabled = "#5B5E65";
/* Text color for warning and draft notifications. */
var colorTextAlertWarning = exports.colorTextAlertWarning = "#8A6A00";
var colorTextPrimary = exports.colorTextPrimary = "#151618";
/* Text color for default notifications. */
var colorTextAlertDefault = exports.colorTextAlertDefault = "#5B5E65";
var colorTextNeutral250 = exports.colorTextNeutral250 = "#E9E9E9";
/* Text color for success notifications. */
var colorTextAlertSuccess = exports.colorTextAlertSuccess = "#00804D";
var colorTextError = exports.colorTextError = "#D42828";
var colorTextNeutral100 = exports.colorTextNeutral100 = "#FFFFFF";
/* Text color for hint, neutral and pending notifications. */
var colorTextAlertNeutral = exports.colorTextAlertNeutral = "#2874BB";
var colorTextNeutral200 = exports.colorTextNeutral200 = "#F8F8F8";
/* Text color for error and failure notifications. */
var colorTextAlertError = exports.colorTextAlertError = "#D42828";
var colorTextNeutral300 = exports.colorTextNeutral300 = "#D9D9D9";
var colorTextNeutral400 = exports.colorTextNeutral400 = "#B2B3B7";
var colorTextNeutral500 = exports.colorTextNeutral500 = "#5B5E65";
var colorTextNeutral600 = exports.colorTextNeutral600 = "#151618";
var zIndexDropdown = exports.zIndexDropdown = 1450;
var zIndexMegaMenu = exports.zIndexMegaMenu = 1500;
var zIndexDefault = exports.zIndexDefault = 1;
var zIndexDrawer = exports.zIndexDrawer = 1200;
var zIndexAppBar = exports.zIndexAppBar = 1100;
var zIndexTooltip = exports.zIndexTooltip = 1500;
var zIndexInlineAlert = exports.zIndexInlineAlert = 1425;
var zIndexActionMenu = exports.zIndexActionMenu = 1450;
var zIndexHeaderBar = exports.zIndexHeaderBar = 1500;
var zIndexBackdrop = exports.zIndexBackdrop = 1500;
var zIndexSmall = exports.zIndexSmall = 2;
var zIndexDatePicker = exports.zIndexDatePicker = 1050;
var zIndexModal = exports.zIndexModal = 1500;
var zIndexPageAlert = exports.zIndexPageAlert = 1475;
var zIndexMobileStepper = exports.zIndexMobileStepper = 1000;
var zIndexSnackbar = exports.zIndexSnackbar = 1600;
var zIndexTextFieldLabel = exports.zIndexTextFieldLabel = 1;
var zIndexHeaderBarDropdown = exports.zIndexHeaderBarDropdown = 1450;