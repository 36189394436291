var _toolbar;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import colorPalette from '../common/palette';
import { colorBrandCtaActive, colorBrandCtaBase, colorBrandCtaHover, colorBrandNeutral100, colorBrandNeutral200, colorBrandNeutral250, colorBrandNeutral300, colorBrandNeutral400, colorBrandNeutral500, colorBrandNeutral600, fontSizeHeader1, fontSizeHeader1Small, fontSizeHeader2, fontSizeHeader2Small, fontSizeHeader3, fontSizeHeader3Small, fontSizeHeader4, fontSizeHeader4Small, fontSizeHeader5, fontSizeHeader5Small, fontSizeHeader6, fontSizeLarge, fontSizeSmall, fontSizeDefault, fontFamilyDefault, fontFamilyHeader, fontFamilyCondensed, fontWeightLight, fontWeightNormal, fontWeightBold, lineHeightDefault, sizingXxLarge1, zIndexTextFieldLabel, zIndexDrawer, zIndexDropdown, zIndexHeaderBarDropdown, zIndexMegaMenu, zIndexInlineAlert, zIndexPageAlert, zIndexActionMenu, zIndexHeaderBar, zIndexModal, zIndexBackdrop } from '@hedtech/react-design-system/core/styles/tokens';

// Breakpoints must be defined in ascending order of viewport size. These breakpoints are used to calculate the size of the viewport in useWidth() hook.
// If values are "NOT" in ascending order then useWidth() hook will fail to return correct value.
var breakpoints = createBreakpoints({
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1920
    }
});

var lightThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    breakpoints: breakpoints,
    mixins: {
        toolbar: (_toolbar = {
            minHeight: sizingXxLarge1
        }, _defineProperty(_toolbar, breakpoints.up('xs') + ' and (orientation: landscape)', {
            minHeight: sizingXxLarge1
        }), _defineProperty(_toolbar, breakpoints.up('sm'), {
            minHeight: sizingXxLarge1
        }), _toolbar)
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: colorBrandNeutral500,
                '&$disabled:hover': {
                    cursor: 'not-allowed'
                },
                '&$error': {
                    color: colorBrandNeutral500
                },
                '&$focused': {
                    color: colorBrandNeutral500
                }
            },
            asterisk: {
                '&$error': {
                    color: colorBrandNeutral500
                }
            }
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 0,
                    width: '100%'
                }
            }
        },
        // Hover and selected states are same across
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: colorBrandNeutral100
                },
                '&$selected': {
                    color: colorBrandNeutral100
                }
            }
        }
    },
    palette: Object.assign({
        action: {
            active: colorBrandCtaActive, // cta blue 800
            hover: colorBrandCtaHover, // cta blue 700
            base: colorBrandCtaBase, // cta blue 600
            disabled: colorBrandNeutral400
        },
        grey: {
            100: colorBrandNeutral100,
            200: colorBrandNeutral200,
            250: colorBrandNeutral250,
            300: colorBrandNeutral300,
            400: colorBrandNeutral400,
            500: colorBrandNeutral500,
            600: colorBrandNeutral600
        }
    }, colorPalette),

    spacing: 4,

    typography: {
        fontFamily: fontFamilyDefault,
        fontSize: 14, // fontSizeDefault, MUI converts to rem for us
        fontWeightLight: fontWeightLight,
        fontWeightRegular: fontWeightNormal,
        fontWeightBold: fontWeightBold,

        h1: _defineProperty({
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader1,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.03125rem'
        }, breakpoints.down('sm'), {
            fontSize: fontSizeHeader1Small
        }),
        h2: _defineProperty({
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader2,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.03125rem'
        }, breakpoints.down('sm'), {
            fontSize: fontSizeHeader2Small
        }),
        h3: _defineProperty({
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader3,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            letterSpacing: '0.03125rem',
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: fontSizeHeader3Small
        }),
        h4: _defineProperty({
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeHeader4,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: fontSizeHeader4Small
        }),
        h5: _defineProperty({
            fontFamily: fontFamilyDefault,
            fontWeight: fontWeightBold,
            fontSize: fontSizeHeader5,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: fontSizeHeader5Small
        }),
        h6: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeHeader6,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        },
        subtitle1: { // set to h5 styles in case anyone uses this
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeHeader5,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        },
        subtitle2: { // set to h6 styles in case anyone uses this
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeHeader6,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body1: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeLarge,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body2: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeDefault,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body3: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeSmall,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: 'initial',
            textTransform: 'initial'
        },
        button: {
            fontFamily: fontFamilyCondensed,
            fontSize: fontSizeLarge,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        },
        caption: {
            fontFamily: fontFamilyCondensed,
            fontSize: fontSizeDefault,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        },
        overline: { // set to caption styles in case anyone uses this
            fontFamily: fontFamilyCondensed,
            fontSize: fontSizeDefault,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        }
    },
    zIndex: {
        textFieldLabel: zIndexTextFieldLabel,
        dropdown: zIndexDropdown,
        headerBarDropdown: zIndexHeaderBarDropdown,
        actionMenu: zIndexActionMenu,
        megaMenu: zIndexMegaMenu,
        inlineAlert: zIndexInlineAlert,
        pageAlert: zIndexPageAlert,
        headerBar: zIndexHeaderBar,
        modal: zIndexModal,
        drawer: zIndexDrawer,
        backdrop: zIndexBackdrop
    }
};

var lightTheme = createMuiTheme(lightThemeOptions);

export { lightTheme as default, lightThemeOptions };