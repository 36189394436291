var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { default as MuiDrawer } from '@material-ui/core/Drawer';
import { useSpacing } from './../globalSpacing';
import { withStyles } from '../styles';

import { spacing40, spacing50, spacing60 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            width: '17rem' }, theme.breakpoints.up('sm'), {
            width: '25.9375rem' // 415px
        }),
        fullScreenDrawer: {
            width: '100%',
            height: '100%'
        },
        fullScreenPaper: {
            height: 'inherit'
        },
        paper: {
            width: 'inherit',
            gridAutoRows: 'minmax(min-content, max-content)'
        }
    };
};

/**
 * A component that can be used for navigation or to provide supplemental information.
 * @done true
 * @updated false
 * @versionAdded v3.14.0
 * @examples
 *  TemporaryDrawer
 *  ResponsiveDrawer
 *  FullScreenDrawer
 *  DrawerWithCustomSpacing
 *  DrawerWithCustomSpacingSpacingOptions
 */
var Drawer = function Drawer(props) {
    var _classNames;

    var classes = props.classes,
        spacingOptionsLocal = props.spacingOptions,
        anchor = props.anchor,
        fullScreen = props.fullScreen,
        rest = _objectWithoutProperties(props, ['classes', 'spacingOptions', 'anchor', 'fullScreen']);

    var _useSpacing = useSpacing(spacingOptionsLocal),
        _useSpacing2 = _slicedToArray(_useSpacing, 3),
        spacingStyles = _useSpacing2[0],
        setSpacingOptions = _useSpacing2[2];

    var fullScreenDrawer = classes.fullScreenDrawer,
        fullScreenPaper = classes.fullScreenPaper,
        restClasses = _objectWithoutProperties(classes, ['fullScreenDrawer', 'fullScreenPaper']);

    var classOverrides = Object.assign({}, restClasses, {
        root: classNames((_classNames = {}, _defineProperty(_classNames, classes.root, anchor === 'left' || anchor === 'right'), _defineProperty(_classNames, fullScreenDrawer, fullScreen), _classNames)),
        paper: classNames(classes.paper, spacingStyles, _defineProperty({}, fullScreenPaper, fullScreen))
    });

    useEffect(function () {
        setSpacingOptions(spacingOptionsLocal);
    }, [spacingOptionsLocal]); // eslint-disable-line

    return React.createElement(MuiDrawer, Object.assign({
        classes: classOverrides,
        anchor: anchor
    }, rest));
};

Drawer.muiName = 'Drawer';

Drawer.propTypes = {
    /**
     * Side from which the drawer will appear.
     */
    anchor: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    /**
     * The contents of the drawer.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component. See Classes API below for more details.
     */
    classes: PropTypes.object,
    /**
     * The elevation of the drawer.
     */
    elevation: PropTypes.number,
    /**
     * Props applied to the [`Modal`](https://material-ui.com/api/modal/) element.
     *
     * Only available when the variant is "temporary"
     */
    ModalProps: PropTypes.object,
    /**
     * Callback fired when the component requests to be closed.
     * Signature: `function(event: object) => void`
     * event: The event source of the callback.
     */
    onClose: PropTypes.func,
    /**
     * If true, the drawer is open.
     */
    open: PropTypes.bool,
    /**
     * Props applied to the [`Paper`](#/components/Paper) element.
     */
    PaperProps: PropTypes.object,
    /**
     * Props applied to the [`Slide`](https://material-ui.com/api/slide/) element.
     */
    SlideProps: PropTypes.object,
    /**
     * The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
     *
     * Object format: { appear?: number, enter?: number, exit?: number }
     *
     * Default value: `{enter: duration.enteringScreen, exit: duration.leavingScreen}`
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    /**
     * The variant to use.
     */
    variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
    /**
     * If `true`, the Drawer will be full-screen
     */
    fullScreen: PropTypes.bool,
    /**
     * Provides several attributes to control `Drawer` spacing.
     *
     * Available options are:
     *
     * * `spacing` (string): **'standard'** | 'none' | \<object\>
     *      * Controls the padding of the `Drawer` and grid gap between child elements
     *      * `standard`: The default spacing
     *      * `none`: Removes all spacing styles, allowing for complete style customization through the `classes` prop
     *      * `object`: Allows for more fine-grained spacing customization. See [container customization documentation](#/utilities/spacing#using-customizations-for-fine-grained-container-spacing-control)
     *        for more details.
     * * `outerSpacing` (bool): **true** | false
     *      * If set to `false`, removes the drawer's outer padding (but retains the space between its subcomponents)
     */
    spacingOptions: PropTypes.shape({
        spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        outerSpacing: PropTypes.bool
    })
};

// If default props are needed, they must be set here
Drawer.defaultProps = {
    anchor: 'left',
    elevation: 16,
    ModalProps: {},
    open: false,
    PaperProps: {},
    SlideProps: {},
    variant: 'temporary',
    fullScreen: false,
    spacingOptions: {
        spacing: {
            xs: {
                padding: spacing40,
                gutter: spacing40
            },
            lg: {
                padding: spacing60,
                gutter: spacing50
            }
        }
    }
};

export default withStyles(styles)(Drawer);