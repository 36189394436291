import AppDispatcher from '../AppDispatcher';
import { LayoutStatus } from '../../types/LayoutStatus';
import Requests from '../../requests/LayoutRequests';
const LayoutActions = {
    getCountCart() {
        AppDispatcher.dispatchAsync(Requests.getCountCart(), {
            failure: LayoutStatus.ErrorGetCountCart,
            request: LayoutStatus.FetchGetCountCart,
            success: LayoutStatus.SuccessGetCountCart
        }, {});
    },
    hidePageLoader() {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessHidePageLoader });
    },
    resetTimeout() {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessResetTimeout, payload: null });
    },
    setAlert(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetAlert, payload: { value } });
    },
    setCountCart(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetCountCart, payload: { value } });
    },
    setLayoutReady(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetLayoutReady, payload: { value } });
    },
    setLoading(value) {
        if (value) {
            console.warn('setLoading function was deprecated, use the showPageLoader function.');
            AppDispatcher.dispatch({ type: LayoutStatus.SuccessShowPageLoader });
        }
        else {
            console.warn('setLoading function was deprecated, use the hidePageLoader function.');
            AppDispatcher.dispatch({ type: LayoutStatus.SuccessHidePageLoader });
        }
    },
    setLogData(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetLogData, payload: { value } });
    },
    setRedirectCode(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetRedirectCode, payload: { value } });
    },
    setReloadTheme(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessSetReloadTheme, payload: { value } });
    },
    setTimeout(sessionTimeout, tokenTimeout, sessionTimeoutType, tokenTimeoutType) {
        AppDispatcher.dispatch({
            type: LayoutStatus.SuccessSetTimeout,
            payload: { sessionTimeout, tokenTimeout, sessionTimeoutType, tokenTimeoutType }
        });
    },
    showPageLoader() {
        AppDispatcher.dispatch({ type: LayoutStatus.SuccessShowPageLoader });
    }
};
export default LayoutActions;
