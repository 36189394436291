function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiDialogActions } from '@material-ui/core/DialogActions';
import { spacingXSmall } from '../styles/tokens';

var styles = function styles(theme) {
    var _button;

    return {
        root: _defineProperty({
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
            padding: 0
        }, theme.breakpoints.up('sm'), {
            justifyContent: 'flex-end',
            flexDirection: 'row'
        }),
        spacing: {
            '& > button': (_button = {
                margin: spacingXSmall + ' 0',
                width: '100%'
            }, _defineProperty(_button, theme.breakpoints.up('sm'), {
                margin: '0 ' + spacingXSmall,
                width: 'auto'
            }), _defineProperty(_button, theme.breakpoints.down('xs'), {
                '&:not(:first-child)': {
                    marginLeft: 0 // since buttons are stacked in sm viewports, no need for margin
                }
            }), _button)
        }

    };
};

/**
 * DialogActions are controls that live within a Dialog.
 */
var DialogActions = React.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root,
        spacing: classesProp.spacing
    };

    return React.createElement(
        MuiDialogActions,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogActions.muiName = 'DialogActions';
DialogActions.displayName = 'DialogActions';

DialogActions.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the dialog actions do not have additional margin.
     */
    disableSpacing: PropTypes.bool
};

DialogActions.defaultProps = {
    disableSpacing: false
};

export default withStyles(styles, { index: 0 })(DialogActions);