function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTableHead } from '@material-ui/core/TableHead';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {
        lineHeight: 1.5,
        fontWeight: 400
    }
};

/**
 * Table header
 */
var TableHead = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root
    };

    return React.createElement(MuiTableHead, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableHead.muiName = 'TableHead';
TableHead.displayName = 'TableHead';

TableHead.propTypes = {
    /**
     * The content of the table head, normally `TableRow`
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])
};

// If default props are needed, they must be set here
TableHead.defaultProps = {
    component: 'thead'
};

export default withStyles(styles)(TableHead);