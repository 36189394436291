'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.darkThemeOptions = exports.default = undefined;

var _styles = require('@material-ui/core/styles');

// This is a copy of lightTheme -- needs to be changed
var darkThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    palette: {
        primary: {
            light: '#8a80ff',
            main: '#5353d1',
            dark: '#012a9f',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#5b97fc',
            main: '#026ab8',
            dark: '#004097',
            contrastText: '#ffffff'
        }
    },
    typography: {
        fontFamily: "'Noto Sans', Helvetica, Arial, sans-serif"
    }
};

var darkTheme = (0, _styles.createMuiTheme)(darkThemeOptions);

exports.default = darkTheme;
exports.darkThemeOptions = darkThemeOptions;