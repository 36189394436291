function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanelDetails } from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelContext from './internal/ExpansionPanelContext';
import { spacingVariant } from './../globalSpacing';

import { spacing20, spacing30, spacing40, spacing50 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            padding: spacing20 + ' ' + spacing40 + ' ' + spacing40
        }, theme.breakpoints.up('lg'), {
            padding: spacing30 + ' ' + spacing50 + ' ' + spacing50
        }),
        none: {
            padding: 0
        }
    };
};

/**
 * A component that....
 */
var ExpansionPanelDetails = React.forwardRef(function (props, ref) {
    var _useContext = useContext(ExpansionPanelContext),
        spacing = _useContext.spacingOptions.spacing;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classNames(classes.root, _defineProperty({}, classes.none, spacing === spacingVariant.NONE))
    };

    return React.createElement(MuiExpansionPanelDetails, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

ExpansionPanelDetails.muiName = 'ExpansionPanelDetails';
ExpansionPanelDetails.displayName = 'ExpansionPanelDetails';

ExpansionPanelDetails.propTypes = {
    /**
     * The content of the expansion panel details.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string
};

// If default props are needed, they must be set here
// ExpansionPanelDetails.defaultProps = { };

export default withStyles(styles)(ExpansionPanelDetails);