function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { getSpacingStyles, spacingVariant, spacingType } from './../../globalSpacing';
import isEmpty from 'lodash/isEmpty';

export default function (context, classes) {
    var _ref;

    var padding = context.padding,
        spacingOptions = context.spacingOptions,
        responsive = context.responsive;


    return isEmpty(spacingOptions) ? (_ref = {}, _defineProperty(_ref, classes.standard, padding === spacingVariant.STANDARD), _defineProperty(_ref, classes.none, padding === spacingVariant.NONE), _defineProperty(_ref, classes.responsive, responsive && padding !== spacingVariant.NONE), _ref) : getSpacingStyles({}, spacingType.COMPONENT_RESET);
};