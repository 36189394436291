import React from 'react';
import { ResultType } from '../../../types/ResultType';
import LogData from '../../../helpers/LogData';
import LayoutActions from '../../../flux/actions/LayoutActions';
class DSErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.showError = (message) => {
            LayoutActions.setAlert({ message: message, messageType: ResultType.error });
        };
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            componentError: false
        };
    }
    static getDerivedStateFromError() {
        return {
            componentError: true
        };
    }
    logError(logData) {
        LayoutActions.setLogData(logData);
    }
    redirectError(code) {
        LayoutActions.setRedirectCode(code);
    }
    componentDidCatch(error, info) {
        this.logError(LogData.fromComponentException(this.componentDidCatch.name, error, info));
        this.showError();
        this.redirectError(500);
    }
    render() {
        const { children } = this.props;
        return (React.createElement(React.Fragment, null, children));
    }
}
export default DSErrorBoundary;
