function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiListItemText } from '@material-ui/core/ListItemText';

var styles = function styles(theme) {
    return {
        root: {
            margin: 0
        },
        multiline: {
            margin: 0 // Reset MUI margin
        },
        // Note that these styles propagate out to components that use List, e.g. Dropdown
        primary: { // Primary typography components
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.body2.lineHeight
        },
        secondary: { // Secondary typography components
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.caption.lineHeight
        }
    };
};

/**
 * A component that applies correct text styling to ListItems.
 */
var ListItemText = function ListItemText(props) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = Object.assign({
        root: classes.root,
        primary: classes.primary,
        secondary: classes.secondary
    }, classes);

    return React.createElement(MuiListItemText, Object.assign({}, rest, {
        classes: classOverrides
    }));
};

ListItemText.muiName = 'ListItemText';

ListItemText.propTypes = {
    /**
     * Alias for the `primary` property.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * If `true`, the children won't be wrapped by a Typography component.
     * This can be useful to render an alternative Typography variant by wrapping
     * the `children` (or `primary`) text, and optional `secondary` text
     * with the Typography component.
     */
    disableTypography: PropTypes.bool,
    /**
     * If `true`, the children will be indented.
     * This should be used if there is no left avatar or left icon.
     */
    inset: PropTypes.bool,
    /**
     * The main content element.
     */
    primary: PropTypes.node,
    /**
     * These props will be forwarded to the primary typography component
     * (as long as disableTypography is not `true`).
     */
    primaryTypographyProps: PropTypes.object,
    /**
     * The secondary content element.
     */
    secondary: PropTypes.node,
    /**
     * These props will be forwarded to the secondary typography component
     * (as long as disableTypography is not `true`).
     */
    secondaryTypographyProps: PropTypes.object
};

ListItemText.defaultProps = {
    disableTypography: false,
    inset: false
};

export default withStyles(styles)(ListItemText);