import { EventEmitter } from 'events';
import { CartItemType } from '../../types/Student/ICartItem';
import { LayoutEventStatus } from '../../types/LayoutEventStatus';
import { LayoutStatus } from '../../types/LayoutStatus';
import { TimeoutType } from '../../types/TimeoutType';
import AppDispatcher from '../AppDispatcher';
class LayoutStoreEmitter extends EventEmitter {
    constructor() {
        super();
        this.abortController = new AbortController();
        this.alert = undefined;
        this.authMode = undefined;
        this.countCart = 0;
        this.cultures = undefined;
        this.hasProfile = false;
        this.isAuthenticated = false;
        this.layoutReady = false;
        this.loading = false;
        this.logData = undefined;
        this.menuOptions = undefined;
        this.permissions = undefined;
        this.redirectCode = 0;
        this.resources = {};
        this.theme = undefined;
        this.sessionTimeout = -1;
        this.sessionTimeoutType = TimeoutType.Refresh;
        this.tokenTimeout = -1;
        this.tokenTimeoutType = TimeoutType.Absolute;
        this.dateTimeout = new Date();
        this.error = false;
        this.errorMessage = undefined;
    }
    getSignal() {
        return this.abortController.signal;
    }
    abort() {
        try {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
        catch (_a) {
            console.warn('The request was not aborted');
        }
    }
    addAlertListener(callback) {
        this.addListener(LayoutEventStatus.ChangeAlert, callback);
    }
    removeAlertListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeAlert, callback);
    }
    emitAlert() {
        this.emit(LayoutEventStatus.ChangeAlert);
    }
    getAlert() {
        return this.alert;
    }
    setAlert(alert) {
        this.alert = alert;
    }
    getAuthMode() {
        return this.authMode;
    }
    setAuthMode(value) {
        this.authMode = value;
    }
    addCountCartListener(callback) {
        this.addListener(LayoutEventStatus.ChangeCountCart, callback);
    }
    removeCountCartListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeCountCart, callback);
    }
    emitCountCart() {
        this.emit(LayoutEventStatus.ChangeCountCart);
    }
    getCountCart() {
        return this.countCart;
    }
    getCartItems() {
        return this.cartItems;
    }
    setCountCart(value, cartItems) {
        this.cartItems = cartItems;
        this.countCart = value;
    }
    getCultures() {
        if (!this.cultures) {
            const culturesStorage = window.sessionStorage.getItem('pc_cultures');
            if (culturesStorage) {
                this.cultures = JSON.parse(culturesStorage);
            }
        }
        if (this.cultures) {
            return this.cultures;
        }
        else {
            return this.getDefaultCutlure();
        }
    }
    getDefaultCutlure() {
        return {
            amDesignator: 'AM',
            currencyCulture: 'en-us',
            currencySymbol: '$',
            dateTimeCulture: 'en-us',
            firstDayOfWeek: 0,
            isRightToLeft: false,
            numberCulture: 'en-us',
            pmDesignator: 'PM',
            shortDatePattern: 'M/d/yyyy',
            shortTimePattern: 'hh:mm A',
            uiCulture: 'en-us'
        };
    }
    setCultures(value) {
        this.cultures = value;
        if (this.cultures) {
            window.sessionStorage.setItem('pc_cultures', JSON.stringify(this.cultures));
        }
        else {
            window.sessionStorage.removeItem('pc_cultures');
        }
    }
    getHasProfile() {
        return this.hasProfile;
    }
    setHasProfile(value) {
        this.hasProfile = value;
    }
    getIsAuthenticated() {
        const isAuthenticatedStorage = window.sessionStorage.getItem('pc_auth');
        if (isAuthenticatedStorage) {
            this.isAuthenticated = isAuthenticatedStorage === '1' ? true : false;
        }
        return this.isAuthenticated;
    }
    setIsAuthenticated(value) {
        this.isAuthenticated = value;
        window.sessionStorage.setItem('pc_auth', this.isAuthenticated ? '1' : '0');
    }
    addLogDataListener(callback) {
        this.addListener(LayoutEventStatus.ChangeLogData, callback);
    }
    removeLogDataListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeLogData, callback);
    }
    emitLogData() {
        this.emit(LayoutEventStatus.ChangeLogData);
    }
    getLogData() {
        return this.logData;
    }
    setLogData(logData) {
        this.logData = logData;
    }
    addLayoutReadyListener(callback) {
        this.addListener(LayoutEventStatus.ChangeLayoutReady, callback);
    }
    removeLayoutReadyListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeLayoutReady, callback);
    }
    emitLayoutReady() {
        this.emit(LayoutEventStatus.ChangeLayoutReady);
    }
    getLayoutReady() {
        return this.layoutReady;
    }
    setLayoutReady(value) {
        this.layoutReady = value;
    }
    addLoadingListener(callback) {
        this.addListener(LayoutEventStatus.ChangeLoading, callback);
    }
    removeLoadingListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeLoading, callback);
    }
    emitLoading() {
        this.emit(LayoutEventStatus.ChangeLoading);
    }
    getLoading() {
        return this.loading;
    }
    setLoading(loading) {
        this.loading = loading;
    }
    getMenuOptions() {
        if (!this.menuOptions) {
            const menuStorage = window.sessionStorage.getItem('pc_menu');
            if (menuStorage) {
                this.menuOptions = JSON.parse(menuStorage);
            }
        }
        return this.menuOptions;
    }
    setMenuOptions(value) {
        this.menuOptions = value;
        if (this.menuOptions) {
            window.sessionStorage.setItem('pc_menu', JSON.stringify(this.menuOptions));
        }
        else {
            window.sessionStorage.removeItem('pc_menu');
        }
    }
    getPermissions() {
        return this.permissions;
    }
    setPermissions(value) {
        this.permissions = value;
    }
    addRedirectCodeListener(callback) {
        this.addListener(LayoutEventStatus.ChangeRedirectCode, callback);
    }
    removeRedirectCodeListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeRedirectCode, callback);
    }
    emitRedirectCode() {
        this.emit(LayoutEventStatus.ChangeRedirectCode);
    }
    getRedirectCode() {
        return this.redirectCode;
    }
    setRedirectCode(redirectCode) {
        this.redirectCode = redirectCode;
    }
    getResources() {
        return this.resources.View;
    }
    setResources(value) {
        this.resources.View = value;
    }
    getResourcesLayout() {
        if (!this.resources.Layout) {
            const layoutResourcesStorage = window.sessionStorage.getItem('pc_res_lay');
            if (layoutResourcesStorage) {
                this.resources.Layout = JSON.parse(layoutResourcesStorage);
            }
        }
        return this.resources.Layout;
    }
    setResourcesLayout(value) {
        this.resources.Layout = value;
        if (this.resources.Layout) {
            window.sessionStorage.setItem('pc_res_lay', JSON.stringify(this.resources.Layout));
        }
        else {
            window.sessionStorage.removeItem('pc_res_lay');
        }
    }
    getResourcesByKey(key) {
        return this.resources[key];
    }
    setResourcesByKey(key, value) {
        this.resources[key] = value;
    }
    addReloadThemeListener(callback) {
        this.addListener(LayoutEventStatus.ChangeReloadTheme, callback);
    }
    removeReloadThemeListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeReloadTheme, callback);
    }
    emitReloadTheme() {
        this.emit(LayoutEventStatus.ChangeReloadTheme);
    }
    getTheme() {
        if (!this.theme) {
            const themeStorage = window.sessionStorage.getItem('pc_theme');
            if (themeStorage) {
                this.theme = JSON.parse(themeStorage);
            }
        }
        return this.theme;
    }
    getDefaultTheme() {
        return {
            actionColor: '',
            backgroundUrl: '',
            faviconUrl: '',
            logoAlternateText: '',
            logoUrl: '',
            primaryColor: '',
            secondaryColor: ''
        };
    }
    setTheme(value) {
        this.theme = value;
        if (this.theme) {
            window.sessionStorage.setItem('pc_theme', JSON.stringify(this.theme));
        }
        else {
            window.sessionStorage.removeItem('pc_theme');
        }
    }
    addTimeoutListener(callback) {
        this.addListener(LayoutEventStatus.ChangeTimeout, callback);
    }
    removeTimeoutListener(callback) {
        this.removeListener(LayoutEventStatus.ChangeTimeout, callback);
    }
    emitTimeout() {
        this.emit(LayoutEventStatus.ChangeTimeout);
    }
    getTimeout() {
        let minTimeout = -1;
        if (this.sessionTimeout !== -1 && this.tokenTimeout !== -1) {
            minTimeout = this.sessionTimeout <= this.tokenTimeout ? this.sessionTimeout : this.tokenTimeout;
        }
        else if (this.sessionTimeout !== -1) {
            minTimeout = this.sessionTimeout;
        }
        else if (this.tokenTimeout !== -1) {
            minTimeout = this.tokenTimeout;
        }
        this.dateTimeout = new Date();
        return minTimeout;
    }
    resetTimeout() {
        if (this.tokenTimeout !== -1 && this.tokenTimeoutType === TimeoutType.Absolute) {
            const newDate = new Date();
            const pastTime = (newDate.getTime() - this.dateTimeout.getTime());
            const remainingTime = this.tokenTimeout - (pastTime / 1000);
            this.dateTimeout = newDate;
            this.tokenTimeout = remainingTime > 0 ? remainingTime : 0;
        }
    }
    setTimeout(sessionTimeout, tokenTimeout, sessionTimeoutType, tokenTimeoutType) {
        this.sessionTimeout = sessionTimeout;
        this.sessionTimeoutType = sessionTimeoutType ? sessionTimeoutType : this.sessionTimeoutType;
        this.tokenTimeout = tokenTimeout;
        this.tokenTimeoutType = tokenTimeoutType ? tokenTimeoutType : this.tokenTimeoutType;
    }
    addErrorListener(callback) {
        this.addListener(LayoutEventStatus.Error, callback);
    }
    removeErrorListener(callback) {
        this.removeListener(LayoutEventStatus.Error, callback);
    }
    emitError() {
        this.emit(LayoutEventStatus.Error);
    }
    getError() {
        return this.error;
    }
    getErrorMessage() {
        return this.errorMessage;
    }
    setError(error, errorMessage) {
        this.error = error;
        this.errorMessage = errorMessage;
    }
}
const LayoutStore = new LayoutStoreEmitter();
AppDispatcher.register((callback) => {
    switch (callback.type) {
        case LayoutStatus.SuccessGetCountCart:
            if (callback.payload.response) {
                const result = JSON.parse(callback.payload.response);
                if (result.status) {
                    let cartItemList = result.data;
                    const menuOptions = LayoutStore.getMenuOptions();
                    if (cartItemList && cartItemList.length > 0 && menuOptions) {
                        let registrationUrl;
                        let conEdRegistrationUrl;
                        menuOptions.forEach(optionMenu => {
                            if (optionMenu.options) {
                                optionMenu.options.forEach(option => {
                                    if (option.id === 'CoursesId') {
                                        registrationUrl = option.url;
                                    }
                                    if (option.id === 'ConEdCoursesId') {
                                        conEdRegistrationUrl = option.url;
                                    }
                                });
                            }
                        });
                        if (!registrationUrl) {
                            cartItemList = cartItemList.filter(cartItem => cartItem.type !== CartItemType.Traditional);
                        }
                        if (!conEdRegistrationUrl) {
                            cartItemList = cartItemList.filter(cartItem => cartItem.type !== CartItemType.ContinuingEducation);
                        }
                    }
                    else {
                        cartItemList = [];
                    }
                    let overallCountCart = 0;
                    if (cartItemList.length > 0) {
                        cartItemList.forEach(cartItem => {
                            overallCountCart += cartItem.count;
                        });
                    }
                    LayoutStore.setCountCart(overallCountCart, cartItemList);
                    LayoutStore.emitCountCart();
                }
            }
            break;
        case LayoutStatus.SuccessSetAlert:
            LayoutStore.setAlert(callback.payload.value);
            LayoutStore.emitAlert();
            break;
        case LayoutStatus.SuccessSetCountCart:
            LayoutStore.emitCountCart();
            break;
        case LayoutStatus.SuccessSetLayoutReady:
            LayoutStore.setLayoutReady(callback.payload.value);
            LayoutStore.emitLayoutReady();
            break;
        case LayoutStatus.SuccessHidePageLoader:
            LayoutStore.setLoading(false);
            LayoutStore.emitLoading();
            break;
        case LayoutStatus.SuccessShowPageLoader:
            LayoutStore.setLoading(true);
            LayoutStore.emitLoading();
            break;
        case LayoutStatus.SuccessSetLogData:
            LayoutStore.setLogData(callback.payload.value);
            LayoutStore.emitLogData();
            break;
        case LayoutStatus.SuccessSetRedirectCode:
            LayoutStore.setRedirectCode(callback.payload.value);
            LayoutStore.emitRedirectCode();
            break;
        case LayoutStatus.SuccessSetReloadTheme:
            LayoutStore.setTheme(callback.payload.value);
            LayoutStore.emitReloadTheme();
            break;
        case LayoutStatus.SuccessResetTimeout:
            LayoutStore.resetTimeout();
            LayoutStore.emitTimeout();
            break;
        case LayoutStatus.SuccessSetTimeout:
            LayoutStore.setTimeout(callback.payload.sessionTimeout, callback.payload.tokenTimeout, callback.payload.sessionTimeoutType, callback.payload.tokenTimeoutType);
            LayoutStore.emitTimeout();
            break;
        case LayoutStatus.ErrorGetCountCart:
            if (callback.payload.response) {
                LayoutStore.setError(true, callback.payload.response);
            }
            break;
        default:
            break;
    }
});
export default LayoutStore;
