"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var isRTL = function isRTL(props) {
  return props.dir === "rtl";
};
var reverseTransform = "scale(-1, 1)";

var SvgArrowEndLeft = function SvgArrowEndLeft(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return _react2.default.createElement(
    "svg",
    Object.assign({
      viewBox: "0 0 448 512",
      width: "1em",
      height: "1em",
      transform: isRTL(props) ? reverseTransform : null,
      ref: svgRef
    }, props),
    _react2.default.createElement("path", { d: "M76 480h24c6.6 0 12-5.4 12-12V285l219.5 187.6c20.6 17.2 52.5 2.8 52.5-24.6V64c0-27.4-31.9-41.8-52.5-24.6L112 228.1V44c0-6.6-5.4-12-12-12H76c-6.6 0-12 5.4-12 12v424c0 6.6 5.4 12 12 12zM336 98.5v315.1L149.3 256.5 336 98.5z" })
  );
};

var ForwardRef = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(SvgArrowEndLeft, Object.assign({ svgRef: ref }, props));
});
exports.default = ForwardRef;