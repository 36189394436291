var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import SearchStatus from './internal/SearchStatus';
import List from '../List/List';
import TextField from '../TextField/TextField';
import FormHelperText from '../FormControl/FormHelperText';
import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../IconButton/IconButton';
import { withEDSContext } from '../EDSContext/EDSContext';
import SelectionMenu from '../SelectionMenu/SelectionMenu';
import SelectionMenuItem from '../SelectionMenu/SelectionMenuItem';
import useWidth from '../useWidth';
import { SEARCH_WIDTH_WITH_CATEGORY_MENU } from './internal/constants';

import { borderRadiusMedium, borderWidthThick, borderWidthThin, boxShadowError, colorBorderAlertError, colorTextNeutral100, heightInputFields, sizingSmall, sizingXLarge, spacingLarge, spacingSmall, spacingXSmall, spacingXxSmall, widthFluid, widthInputFields } from '../styles/tokens';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextField } from '@material-ui/core/TextField';
import SearchIcon from '@eui/ds-icons/lib/Search';
import CloseIcon from '@eui/ds-icons/lib/Close';

export var VARIANTS = {
    STANDALONE: 'standalone',
    STANDARD: 'standard'
};

export var COLORS = {
    LIGHT: 'light',
    DARK: 'dark'
};

var useStyles = makeStyles(function (theme) {
    var _searchInput;

    return {
        listRoot: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            height: 'auto',
            '&$listWidth': {
                width: '' + widthInputFields
            },
            '&$listRootFullWidth': {
                width: '100%'
            },
            borderRadius: borderRadiusMedium,
            boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)',
            marginTop: spacingXxSmall,
            maxHeight: '20rem',
            overflow: 'auto',
            position: 'absolute',
            zIndex: theme.zIndex.dropdown,
            '&$resultListWidthWithCategoryMenu': {
                width: SEARCH_WIDTH_WITH_CATEGORY_MENU
            }
        },
        resultListWidthWithCategoryMenu: {},
        listWidth: {},
        inputBackground: {
            // with label
            position: 'absolute',
            top: '0',
            left: '0',
            borderColor: 'transparent',
            boxShadow: 'none',
            color: theme.palette.grey[500],
            borderRadius: borderRadiusMedium,
            height: heightInputFields,
            width: widthInputFields,
            outline: '0',
            paddingTop: '0.8125rem', // 13px
            paddingLeft: '0.9375rem', // 15px
            fontSize: theme.typography.body1.fontSize,
            fontFamily: theme.typography.fontFamily,
            backgroundColor: theme.palette.grey[100],
            '&$error': {
                // standard with label
                paddingTop: '0.875rem', // 14px
                paddingLeft: spacingSmall // 16px
            },
            '&$standaloneInput': {
                backgroundColor: 'transparent',
                paddingLeft: '2.375rem', // 38px
                top: '-' + spacingXSmall,
                width: 0,
                '&$error$inputBackgroundWithoutLabel': {
                    paddingTop: 1
                }
            },
            '&$standaloneInputExpanded': {
                paddingLeft: '1.84375rem', // 29.5px
                paddingTop: '0.125rem' // 2px
            },
            '&$standaloneInputOpen': {
                width: widthInputFields
            },
            '&$standaloneInputOpenFluid': {
                width: widthFluid,
                paddingLeft: '2.375rem !important' // 38px
            },
            '&$error$inputBackgroundWithoutLabel': {
                paddingTop: spacingXxSmall // 4px
            }
        },
        inputBackgroundCategoryMenu: {
            // with label
            position: 'absolute',
            borderColor: 'transparent',
            boxShadow: 'none',
            opacity: 1,
            border: 0,
            color: theme.palette.grey[500],
            borderRadius: '0 ' + borderRadiusMedium + ' ' + borderRadiusMedium + ' 0',
            height: '100%',
            width: 'auto',
            outline: '0',
            margin: 0,
            paddingTop: '0.875rem', // 14px
            paddingLeft: '1.0625rem', // 17px
            fontSize: theme.typography.body1.fontSize,
            fontFamily: theme.typography.fontFamily,
            '&$inputBackgroundWithoutLabel': {
                paddingTop: '0.0625rem', // 1px
                paddingLeft: '1.0625rem' // 17px
            }
        },

        standaloneInput: {},
        standaloneInputExpanded: {},
        standaloneInputOpen: {
            width: widthInputFields
        },
        standaloneInputOpenFluid: {
            width: '100%'
        },

        searchInputWrapper: {
            position: 'relative'
        },

        searchInputWrapperCategoryMenu: {
            position: 'relative',
            width: SEARCH_WIDTH_WITH_CATEGORY_MENU,
            display: 'flex',
            height: heightInputFields,
            '&.eds-full-width': {
                width: '100%'
            }
        },

        // ensures that the typeahead text is visible through the main textfield
        searchBackground: {
            '& label + div': {
                background: 'transparent'
            },
            '& > div': {
                background: 'transparent'
            },
            '& div input': {
                textOverflow: 'ellipsis'
            },
            '& > input + div': {
                textOverflow: 'ellipsis',
                backgroundColor: colorTextNeutral100
            }
        },
        inputBackgroundWithoutLabel: {
            paddingTop: '0.125rem', // 2px
            paddingLeft: '0.9375rem' // 15px
        },
        iOSAlignmentWithLabel: {
            paddingLeft: spacingSmall, // 16px
            paddingTop: spacingSmall, // 16px
            '&$error': {
                paddingLeft: '1.0625rem', // 17px
                paddingTop: '1.125rem' // 18px
            }
        },
        iOSAlignmentWithoutLabel: {
            paddingLeft: '.99rem',
            paddingTop: '.3rem',
            '&$error': {
                paddingLeft: '1.0625rem', // 17px
                paddingTop: spacingXSmall // 8px
            },
            '&$standaloneInput': {
                paddingTop: '0.375rem', // 6px
                paddingLeft: '2.4375rem' // 39px
            },
            '&$standaloneInputExpanded': {
                paddingLeft: '2.4375rem' // 39px
            },
            '&$standaloneInputOpen': {
                paddingLeft: '2.4375rem' // 39px
            },
            '&$standaloneInputOpenFluid': {
                paddingLeft: '2.4375rem !important', // 39px
                paddingTop: '0.3125rem' // 5px
            }
        },
        searchContainer: {
            minWidth: '2.625rem', // IconButton width plus focus box shadow width
            position: 'relative',
            '&.eds-inline-search': {
                width: SEARCH_WIDTH_WITH_CATEGORY_MENU
            }
        },
        searchContainerFullWidth: {
            position: 'relative',
            width: '100%'
        },
        searchContainerFullWidthClosed: {
            width: '2.625rem' // IconButton width plus focus box shadow width
        },

        searchContainerFullWidthClosedHelperText: {
            width: 'auto'
        },

        inputBackgroundFullWidth: {
            width: '100%',
            '&$standaloneInputExpanded': {
                paddingLeft: '2.1875rem' // 35px
            }
        },
        listRootFullWidth: {},

        inputError: {},

        // standalone search
        standaloneFormControl: {
            width: 0,
            borderBottom: 'solid ' + borderWidthThick + ' transparent',
            transition: theme.transitions.create(['border', 'width'], {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut
            })
        },
        standaloneFormControlOpen: {
            borderBottom: 'solid ' + borderWidthThick + ' ' + theme.palette.grey[500],
            width: widthInputFields,
            '&$disabled': {
                borderColor: theme.palette.disabled.background
            },
            '&$inputError': {
                borderColor: colorBorderAlertError
            },
            '&$standaloneLight': {
                borderBottom: 'solid ' + borderWidthThick + ' ' + theme.palette.grey[100],
                '&$disabled': {
                    borderColor: theme.palette.disabled.background
                }
            }
        },
        standaloneFormControlOpenFullWidth: {
            width: widthFluid
        },
        searchInput: (_searchInput = {
            width: 0,
            opacity: 0,
            WebkitTransition: theme.transitions.create(['border', 'width', 'opacity'], {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut
            }),
            transition: theme.transitions.create(['border', 'width', 'opacity'], {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut
            })
        }, _defineProperty(_searchInput, '&[type="search"]::-webkit-search-decoration,\n        &[type="search"]::-webkit-search-cancel-button,\n        &[type="search"]::-webkit-search-results-button,\n        &[type="search"]::-webkit-search-results-decoration', {
            display: 'none'
        }), _defineProperty(_searchInput, '&[type="search"]', {
            '-webkit-appearance': 'none'
        }), _defineProperty(_searchInput, '&[type=search]::-ms-clear', {
            display: 'none',
            width: 0,
            height: 0
        }), _searchInput),
        standaloneLight: {},
        standaloneInputLight: {
            color: theme.palette.grey[100]
        },
        standaloneSearchIconExpanded: {
            width: sizingXLarge,
            height: sizingXLarge,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        searchIcon: {
            width: sizingSmall,
            height: sizingSmall
        },
        searchIconExpanded: {
            fill: theme.palette.grey[500],
            '&$light': {
                fill: theme.palette.grey[100]
            },
            '&$disabled': {
                color: theme.palette.disabled.background,
                fill: theme.palette.disabled.background
            }
        },
        light: {},
        disabled: {},
        searchInputOpen: {
            opacity: 1,
            width: widthFluid
        },

        categoryMenuRoot: {
            backgroundColor: theme.palette.grey[100],
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            borderRight: 'none',
            borderRadius: borderRadiusMedium + ' 0 0 ' + borderRadiusMedium,
            display: 'inline-block',
            maxWidth: '30%',
            '&.eds-category-search-inline': {
                maxWidth: '40%'
            },
            '& svg': {
                position: 'absolute',
                right: spacingSmall
            },
            '& $categoryListButton': {
                borderRadius: borderRadiusMedium + ' 0 0 ' + borderRadiusMedium,
                height: '100%',
                padding: '.6rem ' + spacingSmall,
                paddingRight: spacingLarge,
                '&:focus': {
                    'zIndex': 2 // ensures that the focus ring appears *over* the adjacent search field
                }
            },
            '&$error': {
                border: 'none'
            }
        },

        error: {},

        categoryListButton: {
            '&$error': {
                border: borderWidthThick + ' solid ' + colorBorderAlertError,
                borderRight: 'none',
                boxSizing: 'border-box',
                height: heightInputFields,
                '&:focus': {
                    boxShadow: boxShadowError,
                    borderRightColor: colorBorderAlertError
                }
            }
        },

        categoryMenuRootDisabled: {
            backgroundColor: theme.palette.grey[200],
            cursor: 'not-allowed'
        },

        // truncates category list menu text
        categoryListText: {
            '& > span': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },

        searchFieldRoot: {
            // border overrides when category sidecar is present
            '& $searchWithCategory': {
                borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[400],
                borderRadius: '0 ' + borderRadiusMedium + ' ' + borderRadiusMedium + ' 0',
                '& $error;': {
                    borderLeftColor: colorBorderAlertError
                }
            },
            // allows the textfield to span the full width of the search container, so that the typeahead
            // menu does as well
            '&$searchWithCategoryWidthOverride': {
                width: '100%'
            }
        },

        searchWithCategory: {
            height: heightInputFields,
            '&$error': {
                borderLeftColor: colorBorderAlertError
            }
        },
        searchWithCategoryWidthOverride: {},

        searchFieldContainer: {
            flexGrow: 1, // search field takes all available excess space
            width: '7rem',
            backgroundColor: theme.palette.grey[100],
            borderRadius: borderRadiusMedium
        },

        searchResultsList: {
            top: '3.375rem',
            position: 'absolute',
            width: '100%',
            '&$searchResultsListStandalone': {
                top: '2.5rem'
            }
        },
        searchResultsListStandalone: {}
    };
}, { index: 2 });

var DEFAULT_PLACEHOLDER = 'Search';

// figure out context
var isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);

/**
 * A search field that can appear as both a full-fledged textfield, and a collapsible search control. It supports
 * typehead searching in both variants.
 *
 * @done true
 * @updated false
 * @versionAdded v3.6.0
 * @examples
 *  SearchCountryNameMockExample
 *  SearchCountryNameAPIExample
 *  DefaultSearchStandalone
 *  OpenSearchExample
 *  SearchStandaloneTypeahead
 *  CategorySearchExample
 *  ErrorHelperTextExample
 */
var Search = React.forwardRef(function (props, ref) {
    var _classNames6, _classNames8, _classNames9, _classNames10, _classNames11;

    var childrenProp = props.children,
        value = props.value,
        onSearchInvokedProp = props.onSearchInvoked,
        expanded = props.expanded,
        color = props.color,
        fullWidth = props.fullWidth,
        variant = props.variant,
        label = props.label,
        ariaLabel = props['aria-label'],
        typeaheadOptions = props.typeaheadOptions,
        edsContext = props.edsContext,
        searchCategories = props.searchCategories,
        placeholderProp = props.placeholder,
        error = props.error,
        helperText = props.helperText,
        FormHelperTextProps = props.FormHelperTextProps,
        inputProps = props.inputProps;
    var loading = typeaheadOptions.loading,
        _typeaheadOptions$sea = typeaheadOptions.searchDelay,
        searchDelay = _typeaheadOptions$sea === undefined ? 500 : _typeaheadOptions$sea,
        onFilterProp = typeaheadOptions.onFilter,
        onItemSelectProp = typeaheadOptions.onItemSelect;


    var cleanedId = props.id || uuid();

    // show the category menu if (a) the caller passed in categories; (b) we're no in an xs viewport
    var showCategoryMenu = searchCategories && useWidth() !== 'xs';

    var typeahead = !_isEmpty(typeaheadOptions);
    var classes = useStyles();

    var _useState = useState(false),
        _useState2 = _slicedToArray(_useState, 2),
        resultListOpen = _useState2[0],
        setResultListOpen = _useState2[1]; // handles visibility of search results list


    var _useState3 = useState(false),
        _useState4 = _slicedToArray(_useState3, 2),
        standaloneInputOpen = _useState4[0],
        setStandaloneInputOpen = _useState4[1]; // handles standalone expanded state


    var placeholder = placeholderProp === DEFAULT_PLACEHOLDER ? edsContext.formatMessage('component.Search.placeholder') : placeholderProp;

    // the selected index of the category search

    var _useState5 = useState(-1),
        _useState6 = _slicedToArray(_useState5, 2),
        currentCategoryIndex = _useState6[0],
        setCurrentCategoryIndex = _useState6[1];

    // disable the standalone search close behavior on blur; used to keep the search open when the user clicks
    // on a menu item


    var suppressSearchClose = false;

    var _useState7 = useState(-1),
        _useState8 = _slicedToArray(_useState7, 2),
        activeItemIndex = _useState8[0],
        setActiveItemIndex = _useState8[1];

    var _useState9 = useState(''),
        _useState10 = _slicedToArray(_useState9, 2),
        tooltipText = _useState10[0],
        setTooltipText = _useState10[1];

    var inputRef = React.useRef(null);
    var searchIconButtonRef = React.createRef();
    var standaloneRef = React.createRef();

    var children = childrenProp;

    React.useImperativeHandle(ref, function () {
        return {
            focus: function focus() {
                inputRef.current.focus();
            }
        };
    });

    // warn if the caller is trying to use search categories with a standalone search
    React.useEffect(function () {
        if (searchCategories && variant === VARIANTS.STANDALONE) {
            console.warn('Search categories cannot be used with standalone variants of search');
        }
    }, [variant, searchCategories]);

    // Event triggers when user selects a list item.
    var onItemSelect = function onItemSelect(event, index, option) {
        setResultListOpen(false);
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
        setActiveItemIndex(-1);
        if (onItemSelectProp) {
            onItemSelectProp(event, index, option);
        }
    };

    // Event triggers when user enters data into textfield for search.
    var onChange = function onChange(event) {
        setActiveItemIndex(-1);
        if (tooltipText.length > 0) {
            setTooltipText('');
        }

        if (props.onChange) {
            props.onChange(event);
        }

        // if there's a delay before invoking the onFilter fuction, call the debounced version
        if (searchDelay > 0) {
            memoizedOnFilter(event);

            // no delay, call it directly
        } else {
            onFilter(event);
        }

        // Execute the persist method of event object to pass SyntheticEvent in callback methods.
        // https://reactjs.org/docs/events.html#event-pooling
        event.persist();
    };

    // opens up the typeahead list, then calls the passed-in onFilter function
    var onFilter = function onFilter(event) {
        if (event.target.value.length) {
            setResultListOpen(true);
        } else {
            setResultListOpen(false);
        }
        if (onFilterProp) {
            onFilterProp(event.target.value, _isNil(currentCategoryIndex) ? null : currentCategoryIndex, event);
        }
    };

    // the debounced version of onFilter
    //
    // it's memoized by useCallback, so that it's only defined once, no many how many times Search is rendered;
    // if any of its dependent values change, it'll be redefined, etc.
    var memoizedOnFilter = React.useCallback(_debounce(onFilter, searchDelay), [props.onFilter, currentCategoryIndex, searchDelay]);

    // Refer following link for more details about escaping regex characters
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    var escapeRegExp = function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    };

    // Method to check whether the first item in the result list starts with the search string.
    // This will help us to display ghost/suggestion text in the background.
    var getFirstItemFromResult = function getFirstItemFromResult(exactResult) {
        var firstItem = '';
        try {
            if (resultListOpen && !loading && children && children.length > 0 && value.length > 0) {
                firstItem = children[0].props.children;
                var firstItemRegex = new RegExp('^' + escapeRegExp(value), 'i');
                if (!children[0].props.disabled && firstItemRegex.test(firstItem)) {
                    // unless we've been asked for an exact result, retain the case of the
                    // typed-in portion of the match
                    if (!exactResult) {
                        firstItem = firstItem.replace(firstItemRegex, value);
                    }

                    // Hide suggested text if content is more than the TextField width.
                    if (inputRef.current && inputRef.current.clientWidth < inputRef.current.scrollWidth) {
                        firstItem = '';
                    }
                } else {
                    firstItem = '';
                }
            }
        } catch (err) {
            console.error('Error while fetching first item from the list', err);
        }
        return firstItem;
    };

    // Triggers when the search text needs to be cleared
    var onClear = function onClear(event) {
        setResultListOpen(false);
        if (props.onClear) {
            props.onClear(event);
        }

        // stop the event that led to the clear from propagating up
        // to the search field (we don't want this to invoke any search events)
        event.preventDefault();
        event.stopPropagation();

        // refocus search field
        inputRef.current.focus();
    };

    var onClickOutside = function onClickOutside(event) {
        if (variant === VARIANTS.STANDALONE) {
            setStandaloneInputOpen(false);
        }
        setResultListOpen(false);
    };

    var onSearchInvoked = function onSearchInvoked(searchValue, event) {
        if (onSearchInvokedProp) {
            onSearchInvokedProp(searchValue, _isNil(currentCategoryIndex) ? null : currentCategoryIndex, event);
        }
    };

    if (children && children.length) {
        children = React.Children.map(childrenProp, function (child, index) {
            return React.cloneElement(child, {
                onClick: function onClick(event) {
                    onItemSelect(event, index, child.props.option);
                },
                onMouseDown: function onMouseDown() {
                    // we don't want the loss of focus resulting from clicking this item to close
                    // the standalone search; use this flag to temporarily suppress the blur handler's
                    // desire to close it
                    suppressSearchClose = true;
                },
                onKeyDown: function onKeyDown(event) {
                    onKeyDownHandler(event);
                },
                isActive: activeItemIndex === index,
                searchString: value
            });
        });
    }

    // Down arrow: increment the index
    var handleDownArrow = function handleDownArrow(event) {
        if (activeItemIndex < children.length - 1) {
            setActiveItemIndex(activeItemIndex + 1);
        }
        // prevents page from excecuting excess scrolling in list, prevents arrow keys from leaving list
        event.preventDefault();
    };

    // Up arrow: Decrement the index.
    var handleUpArrow = function handleUpArrow(event) {
        if (activeItemIndex > 0) {
            setActiveItemIndex(activeItemIndex - 1);
        } else {
            setActiveItemIndex(-1);
            inputRef.current.focus();
        }
        event.preventDefault();
    };

    // Enter: if focus is on list item.
    var handleOnEnter = function handleOnEnter(event) {
        if (typeahead && activeItemIndex >= 0) {
            event.preventDefault();
            setActiveItemIndex(-1);
            onItemSelect(event, activeItemIndex, children[activeItemIndex].props.option);

            // get the text of the selected item
            onSearchInvoked(children[activeItemIndex].props.children, event);
        } else {
            // get the search text
            onSearchInvoked(event.target.value, event);
        }

        // Close result list
        setResultListOpen(false);
    };

    // Tab: Selects suggested ghost content.
    var handleTab = function handleTab(event) {
        // grab the completed value (exactly as it appears in the result list)
        var selectedValue = getFirstItemFromResult(true);

        if (selectedValue.length > 0) {
            event.preventDefault();
            setActiveItemIndex(-1);
            onItemSelect(event, 0, children[0].props.option);
            onSearchInvoked(selectedValue, event);
        } else {
            setResultListOpen(false);
        }
    };

    // Right arrow: Selects first item if it starts with search string
    var handleRightArrow = function handleRightArrow(event) {
        if (getFirstItemFromResult().length > 0) {
            event.preventDefault();
            setActiveItemIndex(-1);
            onItemSelect(event, 0, children[0].props.option);
        }
    };

    // Esc button: Closes search results list and standalone input
    var handleEscape = function handleEscape(event) {
        event.preventDefault();
        setResultListOpen(false);
        if (variant === VARIANTS.STANDALONE) {
            setStandaloneInputOpen(false);
            searchIconButtonRef.current.focus(); // focus icon button after input closes
        }
    };

    // Standalone blur: close standalone input
    var onStandaloneBlurHandler = function onStandaloneBlurHandler(event) {
        // determine if the focus has left the component entirely
        var departed = !standaloneRef.current.contains(event.relatedTarget);

        // if it has close the input; but first make sure the result list isn't open; we don't want blurs
        // that occur as a result of selecting an item from the list to close the search
        if (departed && !suppressSearchClose) {
            setStandaloneInputOpen(false);
            setResultListOpen(false);
            suppressSearchClose = false;
        }
    };

    var onKeyDownHandler = function onKeyDownHandler(event) {
        // Executes appropriate event handler if list is open.
        if (resultListOpen || standaloneInputOpen) {
            switch (event.keyCode) {
                case 9:
                    if (!event.shiftKey) {
                        handleTab(event);
                    }
                    break;
                case 13:
                    handleOnEnter(event);
                    break;
                case 27:
                    handleEscape(event);
                    break;
                case 38:
                    handleUpArrow(event);
                    break;
                case 39:
                    handleRightArrow(event);
                    break;
                case 40:
                    handleDownArrow(event);
                    break;
                default:
                    break;
            }
        }
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    };

    // Logic to update tooltip content.
    React.useEffect(function () {
        if (inputRef.current && inputRef.current.clientWidth < inputRef.current.scrollWidth) {
            setTooltipText(value);
        }
    }, [value]);

    // Get Icon color
    var getIconColor = function getIconColor() {
        return color === COLORS.LIGHT ? 'white' : 'gray';
    };

    // Get element to recreate search result text 'within' search input field
    var getFakeInput = function getFakeInput(hasCategoryMenu) {
        var _classNames;

        return React.createElement('input', {
            'aria-hidden': 'true',
            autoComplete: 'off',
            className: classNames((_classNames = {}, _defineProperty(_classNames, classes.inputBackground, !hasCategoryMenu), _defineProperty(_classNames, classes.inputBackgroundCategoryMenu, hasCategoryMenu), _defineProperty(_classNames, classes.inputBackgroundFullWidth, fullWidth && !hasCategoryMenu), _defineProperty(_classNames, classes.inputBackgroundWithoutLabel, !label && !isIOS), _defineProperty(_classNames, classes.iOSAlignmentWithLabel, label && isIOS), _defineProperty(_classNames, classes.iOSAlignmentWithoutLabel, !label && isIOS), _defineProperty(_classNames, classes.standaloneInput, variant === VARIANTS.STANDALONE), _defineProperty(_classNames, classes.standaloneInputExpanded, expanded), _defineProperty(_classNames, classes.standaloneInputOpen, standaloneInputOpen), _defineProperty(_classNames, classes.standaloneInputOpenFluid, standaloneInputOpen && fullWidth), _defineProperty(_classNames, classes.error, error), _classNames)),
            readOnly: true,
            spellCheck: 'false',
            value: getFirstItemFromResult(),
            tabIndex: '-1'

            // this is here to mollify overzealous accessibility checkers that demand all inputs have a label (even, apparently, hidden ones)
            , 'aria-label': 'hidden-search-input'

        });
    };

    // Shared utility function that gets result list for search query
    var getResultsList = function getResultsList() {
        var widthClass = classNames(_defineProperty({}, classes.resultListWidthWithCategoryMenu, !fullWidth && showCategoryMenu));

        return (
            // See if we can componentize this
            React.createElement(
                'div',
                { className: classNames(classes.searchResultsList, _defineProperty({}, classes.searchResultsListStandalone, variant === VARIANTS.STANDALONE)) },
                resultListOpen && loading && React.createElement(SearchStatus, { loading: true, showCategoryMenu: showCategoryMenu, fullWidth: fullWidth }),
                resultListOpen && (!children || children.length <= 0) && !loading && React.createElement(SearchStatus, { noData: true, showCategoryMenu: showCategoryMenu, fullWidth: fullWidth }),
                resultListOpen && children && children.length > 0 && !loading && React.createElement(
                    List,
                    {
                        className: classNames(classes.listWidth, _defineProperty({}, classes.listRootFullWidth, fullWidth)),
                        classes: {
                            root: classes.listRoot + ' ' + widthClass
                        }
                    },
                    children
                )
            )
        );
    };

    // Toggle visibility of the standalone input
    var toggleStandaloneInput = function toggleStandaloneInput() {

        setStandaloneInputOpen(function (prev) {
            // Set focus on input if previous state was closed
            if (!prev) {
                inputRef.current.focus();
            }
            return !prev;
        });
    };

    // Helper function to return start input adornment for the standalone search
    var getStartInputAdornment = function getStartInputAdornment() {
        var _classNames5;

        return expanded ? React.createElement(
            'span',
            { className: classes.standaloneSearchIconExpanded },
            React.createElement(SearchIcon, {
                className: classNames(classes.searchIcon, (_classNames5 = {}, _defineProperty(_classNames5, classes.searchIconExpanded, expanded), _defineProperty(_classNames5, classes.light, color === COLORS.LIGHT), _defineProperty(_classNames5, classes.disabled, props.disabled), _classNames5)),
                disabled: props.disabled,
                onBlur: onStandaloneBlurHandler
            })
        ) : React.createElement(
            Tooltip,
            {
                title: standaloneInputOpen ? edsContext.formatMessage('component.Search.close') : edsContext.formatMessage('component.Search.open'),
                placement: edsContext.dir === 'rtl' ? 'bottom-start' : 'bottom-end',
                disableFocusListener: true,
                disableTouchListener: true,
                disableHoverListener: props.disabled
            },
            React.createElement(
                IconButton,
                {
                    color: getIconColor(),
                    disabled: props.disabled,
                    onClick: toggleStandaloneInput,
                    onBlur: onStandaloneBlurHandler,
                    'aria-label': standaloneInputOpen ? edsContext.formatMessage('component.Search.close') : edsContext.formatMessage('component.Search.open'),
                    ref: searchIconButtonRef,
                    tabIndex: 0,
                    component: 'div',
                    role: 'search'
                },
                React.createElement(SearchIcon, { className: classes.searchIcon })
            )
        );
    };

    // Helper function to return end input adornment for the standalone search
    var getEndInputAdornment = function getEndInputAdornment() {
        if ((standaloneInputOpen || expanded) && value.length !== 0 && props.onClear && !props.disabled) {
            return React.createElement(
                Tooltip,
                {
                    title: edsContext.formatMessage('component.Search.clear')
                },
                React.createElement(
                    IconButton,
                    {
                        color: getIconColor(),
                        onClick: onClear,
                        onBlur: onStandaloneBlurHandler,
                        'aria-label': edsContext.formatMessage('component.Search.clear')
                    },
                    React.createElement(CloseIcon, { className: classes.searchIcon })
                )
            );
        }
    };

    var categorySearchList = void 0;

    // build the list of category search options, if any were requested
    if (searchCategories) {
        categorySearchList = [edsContext.formatMessage('component.Search.categorySearch.All')].concat(_toConsumableArray(searchCategories));
    }

    // create the category menu, if necessary

    // note: we create this here, outside the confines of the search control, because it always
    // has to be built -- even it doesn't appear (ie, for xs viewports). we can't *conditionally* create
    // the menu without running afoul of hook restrictions that require hook invocations to be
    // consistent across renders: if we only sometimes create the search category menu, React
    // will throw exceptions, becauses the hooks defined in SelectionMenu could change as the viewport
    // changes, leading to differences in hook invocation
    var categoryMenu = !searchCategories ? null : React.createElement(
        SelectionMenu,
        {
            disabled: props.disabled,
            classes: {
                root: classNames(classes.categoryMenuRoot, (_classNames6 = {}, _defineProperty(_classNames6, classes.categoryMenuRootDisabled, props.disabled), _defineProperty(_classNames6, 'eds-category-search-inline', !fullWidth && showCategoryMenu), _defineProperty(_classNames6, classes.error, error), _classNames6)),
                listItemButton: classNames(classes.categoryListButton, _defineProperty({}, classes.error, error)),
                listItemDisabled: classes.categoryListRootDisabled,
                listItemTextRoot: classes.categoryListText
            }
        },
        categorySearchList.map(function (category, index) {
            return React.createElement(
                SelectionMenuItem,
                {
                    key: index,
                    onClick: function onClick(event) {

                        // because we want to report a zero-index category value, relative to the category list
                        // passed in, subtract one
                        setCurrentCategoryIndex(index - 1);

                        // clear any extant search results
                        onClear(event);
                    }
                },
                category
            );
        })
    );

    var tooltipProps = {
        title: tooltipText,
        placement: edsContext.dir === 'rtl' ? 'bottom-start' : 'bottom-end',
        disableFocusListener: true,
        disableTouchListener: true,
        disableHoverListener: props.disabled
    };

    return variant === VARIANTS.STANDALONE ? React.createElement(
        'div',
        {
            className: classNames((_classNames8 = {}, _defineProperty(_classNames8, classes.searchContainer, !fullWidth), _defineProperty(_classNames8, classes.searchContainerFullWidth, fullWidth && (standaloneInputOpen || expanded)), _defineProperty(_classNames8, classes.searchContainerFullWidthClosed, fullWidth && !standaloneInputOpen & !expanded), _defineProperty(_classNames8, classes.searchContainerFullWidthClosedHelperText, fullWidth && !standaloneInputOpen & !expanded && helperText), _classNames8)),
            ref: standaloneRef
        },
        React.createElement(
            'div',
            { className: classes.searchInputWrapper },
            getFakeInput(),
            React.createElement(
                Tooltip,
                tooltipProps,
                React.createElement(MuiTextField, {
                    id: cleanedId,
                    className: props.className,
                    disabled: props.disabled,
                    fullWidth: fullWidth,
                    InputProps: {
                        classes: {
                            root: classNames(classes.standaloneFormControl, (_classNames9 = {}, _defineProperty(_classNames9, classes.standaloneFormControlOpen, standaloneInputOpen || expanded), _defineProperty(_classNames9, classes.standaloneFormControlOpenFullWidth, fullWidth && (standaloneInputOpen || expanded)), _defineProperty(_classNames9, classes.standaloneLight, color === COLORS.LIGHT), _defineProperty(_classNames9, classes.disabled, props.disabled), _classNames9)),
                            input: classNames(classes.searchInput, (_classNames10 = {}, _defineProperty(_classNames10, classes.searchInputOpen, standaloneInputOpen || expanded), _defineProperty(_classNames10, classes.standaloneInputLight, color === COLORS.LIGHT), _classNames10)),
                            error: classes.inputError
                        },
                        disableUnderline: true,
                        startAdornment: getStartInputAdornment(),
                        endAdornment: getEndInputAdornment()
                    },
                    inputProps: Object.assign({
                        tabIndex: standaloneInputOpen || expanded ? 0 : -1,
                        'aria-label': ariaLabel || edsContext.formatMessage('component.Search.placeholder')
                    }, inputProps),
                    inputRef: inputRef,
                    label: '' // don't want consumers to use labels with standalone
                    , name: props.name,
                    onBlur: onStandaloneBlurHandler,
                    onChange: onChange,
                    onKeyDown: onKeyDownHandler,
                    placeholder: placeholder,
                    type: 'search',
                    value: value,
                    error: error,
                    helperText: helperText,
                    FormHelperTextProps: FormHelperTextProps
                })
            )
        ),
        typeahead && getResultsList()
    ) : React.createElement(
        ClickAwayListener,
        { onClickAway: onClickOutside },
        React.createElement(
            'div',
            { className: classNames(classes.searchContainer, { 'eds-inline-search': !fullWidth }) },
            React.createElement(
                'div',
                { className: classNames((_classNames11 = {}, _defineProperty(_classNames11, classes.searchInputWrapper, !showCategoryMenu), _defineProperty(_classNames11, classes.searchInputWrapperCategoryMenu, showCategoryMenu), _defineProperty(_classNames11, 'eds-full-width', showCategoryMenu && fullWidth), _classNames11)) },
                showCategoryMenu && categoryMenu,
                React.createElement(
                    'div',
                    { className: showCategoryMenu ? classes.searchFieldContainer : undefined },
                    getFakeInput(showCategoryMenu),
                    React.createElement(
                        Tooltip,
                        tooltipProps,
                        React.createElement(TextField, {
                            autoComplete: 'off',
                            classes: showCategoryMenu ? {
                                root: classes.searchFieldRoot,
                                inputRoot: classNames(classes.searchWithCategory, _defineProperty({}, classes.error, error)),
                                // allows the textfield to span the full width of the search container, so that the typeahead
                                // menu does as well
                                constrainedWidth: classes.searchWithCategoryWidthOverride
                            } : undefined,
                            className: classNames(props.className, classes.searchBackground),
                            disabled: props.disabled,
                            fullWidth: fullWidth,
                            id: cleanedId,
                            inputRef: inputRef,
                            inputProps: Object.assign({
                                'aria-label': ariaLabel,
                                'aria-describedby': showCategoryMenu && cleanedId + '-helper-text'
                            }, inputProps),
                            label: label,
                            name: props.name,
                            onChange: onChange,
                            onClearClick: onClear,
                            onEnterPress: function onEnterPress(event) {
                                onSearchInvoked(event.target.value, event);
                            },
                            onKeyDown: onKeyDownHandler,
                            placeholder: placeholder,
                            type: 'search',
                            value: value,
                            error: error,
                            helperText: !showCategoryMenu ? helperText : undefined,
                            FormHelperTextProps: !showCategoryMenu ? FormHelperTextProps : undefined
                        })
                    )
                )
            ),
            showCategoryMenu && React.createElement(
                FormHelperText,
                Object.assign({
                    id: cleanedId + '-helper-text',
                    error: error
                }, FormHelperTextProps),
                helperText
            ),
            typeahead && getResultsList()
        )
    );
});

Search.displayName = 'Search';

Search.propTypes = {
    /**
     * Use this object to enable the typeahead feature of the `Search` component.
     *
     * The object accepts the following properties:
     *
     * * `loading` (boolean): If `true`, the component is in a loading state.
     * * `searchDelay` (number): Specifies the amount of time to wait (in milliseconds) before triggering a typeahead search (via the `onFilter` function).
     * * `onFilter` (func): Callback fired to prompt the retrieval of search results. It is triggered when the user enters text, after an optional interval specified by the `searchDelay` prop.
     * * `onItemSelect` (func) Callback fired when an item is selected from the typeahead search result list. Use this to set the `value` of the search.
     *
     * Signature of `onFilter`:
     *
     * ```
     * function(searchValue: string, categoryIndex: number, event: object )
     * ```
     * `@param {string} searchValue` The current search value <br/>
     * `@param {number} categoryIndex` Index of the selected category; -1 if no category selected <br/>
     * `@param {SyntheticEvent} event` The event source of the callback. <br/>
     *
     * Signature of `onItemSelect`:
     * ```
     * function(event: React.SyntheticEvent, index: number, option?: object) => void
     * ```
     * `@param {SyntheticEvent} event` The event source of the callback. <br/>
     * `@param {number} index`         Index of the selected item from the list. <br/>
     * `@param {option} option`        Prop value assigned while construction `SearchItem` array.
     */
    typeaheadOptions: PropTypes.shape({
        loading: PropTypes.bool,
        onFilter: PropTypes.func,
        onItemSelect: PropTypes.func,
        searchDelay: PropTypes.number
    }),
    /**
     * The color of the standalone `Search` to be rendered.
     *
     * **Only valid when `variant=VARIANT.STANDALONE`.**
     */
    color: PropTypes.oneOf([COLORS.LIGHT, COLORS.DARK]),
    /**
     * If `true`, the input will be always expanded.
     *
     * **Only valid when `variant=VARIANT.STANDALONE`.**
     */
    expanded: PropTypes.bool,
    /**
     * The variant of the input to be rendered.
     */
    variant: PropTypes.oneOf([VARIANTS.STANDALONE, VARIANTS.STANDARD]),
    /**
     * The elements to populate typeahead search results. These must be an array of `SearchItem` elements.
     *
     * **Only valid when typeahead is enabled.**
     */
    children: PropTypes.node,
    /**
     * If `true`, the input will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * @ignore
     * This function fetches resource string translations. It comes from the withEDSContext HOC.
     */
    edsContext: PropTypes.object,
    /**
     * If `true`, the input will render with an error state.
     */
    error: PropTypes.bool,
    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,
    /**
     * The helper text content.
     */
    helperText: PropTypes.string,
    /**
     * The id of the `input` element.
     * Use that property to make `label` accessible for screen readers.
     */
    id: PropTypes.string,
    /**
     * Properties applied to the native `input` element.
     */
    inputProps: PropTypes.object,
    /**
     * Props applied to the `FormHelperText` element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
     * The label content.
     *
     * **Only valid when `variant=VARIANT.STANDALONE`.**
     */
    label: PropTypes.node,
    /**
     * The aria-label used for accessibility of the input.
     */
    'aria-label': PropTypes.node,
    /**
     * The placeholder content. A placeholder should be provided when `variant=VARIANT.STANDALONE`.
     */
    placeholder: PropTypes.string,
    /**
     * Name attribute of the `input` element.
     */
    name: PropTypes.string,
    /**
     * Callback fired when the value is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Callback fired when the clear icon is clicked. If nothing is passed to this prop,
     * no clear icon will be displayed when text is entered into the input.
     */
    onClear: PropTypes.func,
    /**
     * Callback fired when a key is pressed.
     *
     * @param {object} event The event source of the callback.
     *
     * Use `event.key` to get the current search value.
     */
    onKeyDown: PropTypes.func,
    /**
     * Callback fired when a search is invoked. A search is invoked in a couple of ways:
     *
     * 1. Entering text and pressing the enter key
     * 2. Typeahead suggestions are accepted by pressing the tab key (typeahead only)
     * 3. An Item is selected from the typeahead result list (typeahead only)
     *
     * ```
     * function(searchValue: string, categoryIndex: number, event: React.SyntheticEvent) => void
     * ```
     *
     * `@param {string} searchValue`   The value to search on <br/>
     * `@param {number} categoryIndex` The index of the selected category; -1 if no category selected <br/>
     * `@param {SyntheticEvent} event` The event source of the callback <br/>
     *
     */
    onSearchInvoked: PropTypes.func,
    /**
     * A list of search categories, which can be used as an additional filter for searches.
     *
     * If a list is provided, a category sidecar will appear beside the search field. The first option
     * of the category list will be "All", following by the categories provided in this prop.
     *
     * When the user selects a category, it is provided to the caller via the following callbacks:
     *
     * * onSearchInvoked: Called when a search is performed
     * * typeaheadOptions.onFilter: (for typeahead searches) Called to request a list of matches for the typeahead list
     *
     * In both cases, the selected category index is provided as an argument, if present. The value is zero-indexed,
     * relative to the given list of categories. A value of -1 indicates that no category was selected.
     *
     * Selecting a category will clear any current selections (by invoking the *onClear* callback).
     *
     * **Only valid when `variant=VARIANT.STANDARD`.**
     */
    searchCategories: PropTypes.array,
    /**
     * The value of the `Input` element.
     */
    value: PropTypes.string.isRequired
};

Search.defaultProps = {
    color: COLORS.DARK,
    expanded: false,
    value: '',
    variant: VARIANTS.STANDARD,
    placeholder: DEFAULT_PLACEHOLDER,
    typeaheadOptions: {}
};

export default withEDSContext(Search);