import React from 'react';
import EdsBadge, { NotificationBadge as EdsNotificationBadge } from '@hedtech/react-design-system/core/es/Badge';
import EdsHeaderBar, { HeaderBarLogo as EdsHeaderBarLogo } from '@hedtech/react-design-system/core/es/HeaderBar';
import EdsLogo from '@hedtech/react-design-system/core/es/Logo';
import Icon from '../../core/Icon';
import Text from '../../core/Text';
import { withWidth } from '../../core/withWidth';
import Format from '../../../helpers/Format';
import Storage from '../../../storage';
import StorageKeys from '../../../storage/StorageKeys';
import Tokens from '../../core/styles/Tokens';
import { createStyles, withStyles } from '../../core/styles/withStyles';
import { CartItemType } from '../../../types/Student/ICartItem';
import { NotificationType } from '../../../types/INotification';
import LayoutStore from '../../../flux/stores/LayoutStore';
const styles = ((theme) => createStyles({
    badgeCart: {
        [theme.breakpoints.up('sm')]: {
            right: '-30.4px'
        },
        minWidth: '0rem',
        position: 'absolute',
        right: 0,
        top: 0
    },
    badgeCartContainer: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(${Tokens.widthFluid} - 32px)`
        },
        '& > span': {
            [theme.breakpoints.up('sm')]: {
                width: Tokens.widthFluid
            }
        }
    },
    badgeCartText: {
        [theme.breakpoints.only('xs')]: {
            width: '90vw'
        }
    },
    customLogo: {
        display: 'inline-block',
        fill: Tokens.colorFillLogoPreferred,
        height: Tokens.sizingXxLarge1,
        padding: Tokens.spacing40
    },
    customLogoContainer: {
        padding: '0rem'
    },
    edsLogo: {
        '& svg': {
            width: '5.625rem'
        },
        minWidth: '0rem!important',
        padding: '0rem!important'
    },
    iconListMenu: {
        marginRight: Tokens.spacing50
    }
}));
const defaultProps = {
    countCart: -1,
    homeUrl: '/',
    isAuthenticated: false,
    logOutUrl: '/Home/LogOut',
    profileUrl: '/Account/Profile',
    resources: undefined,
    valueSelected: ''
};
const NavBar = (props) => {
    const { cartItems, classes, countCart, displayName, homeUrl, isAuthenticated, layoutPermissions, logoAlternateText, logoUrl, logOutUrl, menuOptions, notifications, profileUrl, resources, showCart, showNotifications, valueSelected, width } = props;
    const getCartKey = (cartItem) => {
        let cartKey = '';
        if (resources) {
            switch (cartItem.type) {
                case CartItemType.Traditional:
                    cartKey = `btnCartItem_${cartItem.value}`;
                    break;
                case CartItemType.ContinuingEducation:
                    cartKey = 'btnConEdCartItem';
                    break;
            }
        }
        return cartKey;
    };
    const getCartLabel = (cartItem) => {
        let cartLabel = '';
        if (resources) {
            switch (cartItem.type) {
                case CartItemType.Traditional:
                    cartLabel = cartItem.description;
                    break;
                case CartItemType.ContinuingEducation:
                    cartLabel = resources.utilityNav.cartMessages.lblContinuingEducation;
                    break;
            }
        }
        return cartLabel;
    };
    const getCartSingleLabel = (cartItem) => {
        let cartLabel = '';
        if (resources) {
            switch (cartItem.type) {
                case CartItemType.Traditional:
                    cartLabel = `${resources.utilityNav.lblCart}: ${cartItem.description}`;
                    break;
                case CartItemType.ContinuingEducation:
                    cartLabel = `${resources.utilityNav.lblCart}: ${resources.utilityNav.cartMessages.lblContinuingEducation}`;
                    break;
            }
        }
        return cartLabel;
    };
    const lstMenuOptions = [];
    const lstUtilityOptions = [];
    const lstMegaMenuOptions = [];
    let registrationUrl;
    let conEdRegistrationUrl;
    let enrollmentUrl;
    if (menuOptions && menuOptions !== null) {
        let items;
        menuOptions.forEach(optionMenu => {
            if (optionMenu.isVisible) {
                if (optionMenu.options && optionMenu.options.length > 0) {
                    items = [];
                    optionMenu.options.forEach(optionSubMenu => {
                        if (optionSubMenu.id === 'CoursesId') {
                            registrationUrl = optionSubMenu.url;
                        }
                        if (optionSubMenu.id === 'ConEdCoursesId') {
                            conEdRegistrationUrl = optionSubMenu.url;
                        }
                        if (optionSubMenu.id === 'EnrollmentId') {
                            enrollmentUrl = optionSubMenu.url;
                        }
                        if (items && optionSubMenu.isVisible) {
                            items.push({
                                content: (React.createElement("span", { id: `${optionMenu.id}_${optionSubMenu.id}` }, optionSubMenu.text)),
                                isCustom: optionSubMenu.isCustom,
                                key: optionSubMenu.id,
                                value: optionSubMenu.url
                            });
                        }
                    });
                }
                else {
                    items = undefined;
                }
                lstMenuOptions.push({
                    active: optionMenu.id === valueSelected,
                    content: (React.createElement("span", { id: optionMenu.id }, optionMenu.text)),
                    isCustom: optionMenu.isCustom,
                    items: items,
                    key: optionMenu.id,
                    value: optionMenu.url
                });
            }
        });
    }
    if (resources && isAuthenticated) {
        let cartIcon;
        if (showCart &&
            (registrationUrl || conEdRegistrationUrl)
            && countCart !== undefined
            && countCart !== null
            && countCart >= 0) {
            if (countCart > 0) {
                cartIcon = (React.createElement(EdsNotificationBadge, { badgeContent: countCart },
                    React.createElement(Icon, { name: "cart" })));
            }
            else {
                cartIcon = (React.createElement(Icon, { name: "cart" }));
            }
        }
        if (cartIcon && cartItems) {
            if (cartItems && cartItems.length > 0) {
                if (cartItems.length === 1) {
                    lstUtilityOptions.push({
                        content: cartIcon,
                        items: undefined,
                        key: getCartKey(cartItems[0]),
                        label: getCartSingleLabel(cartItems[0])
                    });
                }
                else {
                    lstUtilityOptions.push({
                        content: cartIcon,
                        items: cartItems.map(cartItem => ({
                            content: (React.createElement("span", { className: classes.badgeCartContainer },
                                React.createElement(EdsBadge, { badgeContent: cartItem.count, classes: { badge: classes.badgeCart } },
                                    React.createElement("span", { className: classes.badgeCartText }, getCartLabel(cartItem))))),
                            key: getCartKey(cartItem)
                        })),
                        key: 'btnCartItemList',
                        label: resources.utilityNav.lblCart
                    });
                }
            }
            else {
                lstUtilityOptions.push({
                    content: cartIcon,
                    items: undefined,
                    key: 'btnCart',
                    label: resources.utilityNav.lblCart
                });
            }
        }
        if (showNotifications && notifications && notifications.length > 0) {
            lstUtilityOptions.push({
                content: (React.createElement(EdsNotificationBadge, { badgeContent: notifications.length > 0 ? notifications.length : undefined },
                    React.createElement(Icon, { name: "notification" }))),
                items: notifications.map((notification, iNotif) => {
                    let notificationText = '';
                    switch (notification.notificationType) {
                        case NotificationType.PermissionRequest:
                            notificationText = Format.toString(resources.utilityNav.notificationMessages.formatPermissionRequest, [notification.personFrom]);
                            break;
                    }
                    return {
                        content: (React.createElement(Text, null, notificationText)),
                        key: `btnNotification_${iNotif}_${notification.notificationType}_${notification.personIdFrom}`
                    };
                }),
                key: 'btnNotifications',
                label: resources.utilityNav.lblNotifications
            });
        }
        const accountMenu = [];
        let megaMenuAccount;
        if (width !== 'xs') {
            megaMenuAccount = {
                content: resources.utilityNav.lblProfile,
                items: [],
                key: 'account-menu'
            };
        }
        if (layoutPermissions && layoutPermissions.hasProfileClaim) {
            accountMenu.push({
                content: (React.createElement(React.Fragment, null,
                    React.createElement(Icon, { name: "user", className: classes.iconListMenu }),
                    React.createElement("span", { id: "lblUtilityProfile" }, resources.utilityNav.profileMenu.lblProfile))),
                key: 'btnProfile'
            });
            if (megaMenuAccount && megaMenuAccount.items) {
                megaMenuAccount.items.push({
                    content: (React.createElement("span", { id: "lblMegaMenuProfile" }, resources.utilityNav.profileMenu.lblProfile)),
                    key: 'btnMegaMenuProfile'
                });
            }
        }
        accountMenu.push({
            content: (React.createElement(React.Fragment, null,
                React.createElement(Icon, { name: "sign-out", className: classes.iconListMenu }),
                React.createElement("span", { id: "lblUtilityLogOut" }, resources.utilityNav.profileMenu.lblLogOut))),
            key: 'btnLogOut'
        });
        if (megaMenuAccount && megaMenuAccount.items) {
            megaMenuAccount.items.push({
                content: (React.createElement("span", { id: "lblMegaMenuLogOut" }, resources.utilityNav.profileMenu.lblLogOut)),
                key: 'btnMegaMenuLogOut'
            });
        }
        lstUtilityOptions.push({
            content: (React.createElement(Icon, { name: "user" })),
            contentLabel: displayName,
            items: accountMenu,
            key: 'profile-utility',
            label: resources.utilityNav.lblProfile
        });
        if (megaMenuAccount) {
            lstMegaMenuOptions.push(megaMenuAccount);
        }
    }
    const onItemSelected = (item) => {
        if (item.key) {
            const parts = item.key.split('_');
            switch (parts[0]) {
                case 'btnNotification':
                    switch (parts[2]) {
                        case '1':
                            if (enrollmentUrl) {
                                LayoutStore.abort();
                                window.location.assign(`${enrollmentUrl}`);
                            }
                            break;
                    }
                    break;
                case 'btnCart':
                    LayoutStore.abort();
                    if (registrationUrl) {
                        window.location.assign(registrationUrl);
                    }
                    else if (conEdRegistrationUrl) {
                        window.location.assign(`${conEdRegistrationUrl}/Cart`);
                    }
                    break;
                case 'btnCartItem':
                    LayoutStore.abort();
                    if (registrationUrl) {
                        window.location.assign(`${registrationUrl}/${parts[1]}`);
                    }
                    break;
                case 'btnConEdCartItem':
                    LayoutStore.abort();
                    if (conEdRegistrationUrl) {
                        window.location.assign(`${conEdRegistrationUrl}/Cart`);
                    }
                    break;
                case 'btnMegaMenuLogOut':
                case 'btnLogOut':
                    LayoutStore.abort();
                    LayoutStore.setIsAuthenticated(false);
                    LayoutStore.setMenuOptions(undefined);
                    LayoutStore.setResourcesLayout(undefined);
                    Storage.removeFromStorage(StorageKeys.urlAdvisees);
                    window.location.assign(logOutUrl);
                    break;
                case 'btnMegaMenuProfile':
                case 'btnProfile':
                    LayoutStore.abort();
                    window.location.assign(profileUrl);
                    break;
                default:
                    if (item.value) {
                        if (item.isCustom) {
                            window.open(item.value);
                        }
                        else {
                            LayoutStore.abort();
                            window.location.assign(item.value);
                        }
                    }
                    break;
            }
        }
    };
    const logo = logoUrl ? (React.createElement(EdsHeaderBarLogo, { classes: { root: classes.customLogoContainer } },
        React.createElement("a", { href: homeUrl, id: "logoNavBar" },
            React.createElement("img", { alt: logoAlternateText, className: classes.customLogo, src: logoUrl })))) : (React.createElement(EdsHeaderBarLogo, null,
        React.createElement("a", { href: homeUrl, id: "logoNavBar" },
            React.createElement(EdsLogo, { className: classes.edsLogo }))));
    return (React.createElement(EdsHeaderBar, { autoGenerateMenus: true, logo: logo, megaMenuBadge: Boolean((countCart && countCart > 0) || (notifications && notifications.length > 0)), megaMenuDefinition: lstMegaMenuOptions, menuDefinition: lstMenuOptions, menuItemsLabel: resources ? resources.utilityNav.lblMegaMenuTitle : '', position: "fixed", showSearch: false, utilityDefinition: lstUtilityOptions, onItemSelected: onItemSelected }));
};
NavBar.defaultProps = defaultProps;
export default withStyles(styles)(withWidth()(NavBar));
