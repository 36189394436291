var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import get from 'lodash/get';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

// MUI imports
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import Checkbox from '../Checkbox';
import { DropdownItem } from '../Dropdown';
import FormControl from '../FormControl';
import { FormHelperText } from '../FormControl';

import { withEDSContext } from '../EDSContext/EDSContext';
import IOSBodyScroll from './../IOSBodyScroll';

import withStyles from '../styles';
import { spacingXSmall1, spacingXSmall2, borderRadiusMedium, borderWidthThin, borderWidthThick, fontSizeLarge, fontSizeDefault, colorFillAlertError, boxShadowFocus, spacingXSmall, spacingSmall, spacingMedium, spacing30, widthInputFields } from '../styles/tokens';
import ChevronDown from '@eui/ds-icons/lib/ChevronDown';

// select all selection states
var ALL = 0;
var INDETERMINATE = 1;
var NONE = 2;

var styles = function styles(theme) {
    return {
        selectRoot: {
            // note -- putting this here instead of in the selectMenu class, so that it'll take precedence
            // over MUI's styles
            '&$select': {
                padding: spacingMedium + ' 3.5rem ' + spacing30 + ' ' + spacingSmall,
                minHeight: 'auto'
            }
        },
        select: {
            backgroundColor: theme.palette.grey['100'],
            '&$disabled': {
                backgroundColor: theme.palette.grey['200'],
                cursor: 'not-allowed'
            },
            '&:focus': {
                backgroundColor: theme.palette.grey['100'],
                borderRadius: borderRadiusMedium,
                boxShadow: boxShadowFocus
            }
        },
        selectIcon: {
            '&:hover': {
                '& $icon': {
                    fill: theme.palette.ctaColor.active
                }
            }
        },
        paper: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)'
        },
        selectMenu: {
            border: borderWidthThin + ' solid ' + theme.palette.grey['400'],
            borderRadius: borderRadiusMedium,
            lineHeight: '1.3rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        nativeSelect: {
            backgroundColor: theme.palette.grey['100'],
            border: borderWidthThin + ' solid ' + theme.palette.grey['400'],
            borderRadius: borderRadiusMedium,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 'calc(100% - 4.625rem)',
            height: 'auto',
            '&$disabled': {
                backgroundColor: theme.palette.grey['200'],
                cursor: 'not-allowed'
            },
            '&:focus': {
                backgroundColor: theme.palette.grey['100'],
                borderRadius: borderRadiusMedium,
                boxShadow: boxShadowFocus
            }
        },
        icon: {
            right: spacingSmall,
            top: '1.1875rem',
            fill: theme.palette.grey['500']
        },
        inputLabelRoot: {
            fontSize: fontSizeLarge,
            color: theme.palette.grey['500'],
            left: '1.0625rem',
            top: '-0.375rem',
            zIndex: theme.zIndex.textFieldLabel,
            pointerEvents: 'none'
        },
        inputLabelShrink: {
            top: spacingXSmall
        },
        disabled: {
            cursor: 'not-allowed'
        },
        zIndex: {
            zIndex: theme.zIndex.dropdown + 1
        },
        small: {
            fontSize: fontSizeDefault,
            '& $icon': {
                top: spacingXSmall2
            },
            '& $inputLabelRoot': {
                top: '-' + spacingXSmall2,
                fontSize: fontSizeDefault
            },
            '& $selectMenu': {
                padding: '\n                ' + spacingXSmall1 + '\n                3.5rem\n                ' + spacingXSmall1 + '\n                ' + spacingXSmall1 + '\n            ',
                lineHeight: 'unset'
            },
            '& $nativeSelect': {
                padding: spacingXSmall1 + ' 3.5rem ' + spacingXSmall1 + ' ' + spacingXSmall1,
                lineHeight: 'unset'
            }
        },
        medium: {
            // we have to put the padding here (rather than with the nativeSelect class) because of an apparent bug in MUI's
            // dropdown; it lists its select class twice when applying padding (.MuiSelect-select-220.MuiSelect-select-220),
            // which appears to give it more specificity than our padding override
            '& $nativeSelect': {
                padding: spacingMedium + ' 3.5rem ' + spacingXSmall + ' ' + spacingSmall
            }
        },
        inputLabelSmall: {
            top: '-' + spacingXSmall2,
            left: spacingXSmall2,
            fontSize: fontSizeDefault,
            '&[data-shrink=true]': {
                display: 'none'
            }
        },
        error: {
            border: borderWidthThick + ' solid ' + theme.palette.status.error.fill,
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThin + ' ' + colorFillAlertError,
                '-webkit-box-shadow': '0 0 0 ' + borderWidthThin + ' ' + colorFillAlertError,
                '-moz-box-shadow:': '0 0 0 ' + borderWidthThin + ' ' + colorFillAlertError
            }
        },
        ffErrorTextAdjuster: {
            '-moz-transform': 'translateY(-' + spacingSmall + ')'
        },
        formControlWidth: {
            maxWidth: widthInputFields
        }
    };
};

var menuListClassName = 'menu-list';

/**
 * Use `Dropdown` to make selections from a list.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  ControlledDropdownExample
 *  ErrorDropdownExample
 *  FullWidthDropdownExample
 *  IconDropdownExample
 *  LongTextDropdownExample
 *  MultiSelectDropdownExample
 *  SelectAllDropdownExample
 *  PerformanceTestDropdownExample
 *  NativeExample
 */

var Dropdown = function (_React$Component) {
    _inherits(Dropdown, _React$Component);

    function Dropdown(props) {
        _classCallCheck(this, Dropdown);

        // the internal value of the 'select all' item
        var _this = _possibleConstructorReturn(this, (Dropdown.__proto__ || Object.getPrototypeOf(Dropdown)).call(this, props));

        _this.onDropDownOpen = function (e) {
            var onOpen = _this.props.onOpen;


            IOSBodyScroll.lock('.' + menuListClassName);
            onOpen && onOpen(e);
        };

        _this.onDropDownClose = function (e) {
            var onClose = _this.props.onClose;


            IOSBodyScroll.unlock();
            onClose && onClose(e);
        };

        _this.selectAllValue = uuid();

        _this.state = {
            selectionState: NONE,
            allItems: {}
        };

        _this.onDropDownOpen = _this.onDropDownOpen.bind(_this);
        _this.onDropDownClose = _this.onDropDownClose.bind(_this);
        return _this;
    }

    /**
     * Called when the dropdown value changes
     *
     * @param {Event} event   The event that spurred this callback
     * @param {object} child  The react element that was selected
     */


    _createClass(Dropdown, [{
        key: 'handleChange',
        value: function handleChange(event, child) {
            var _this2 = this;

            // if the user click the 'select all' item in a multi-select dropdown
            if (this.props.multiple && event.target.value.indexOf(this.selectAllValue) > -1) {
                // if no items are selected, select everything; otherwise unselect everything
                var newState = this.state.selectionState === NONE ? ALL : NONE;

                // set the new select all state
                this.setState({ selectionState: newState }, function () {
                    // either select or unselect everything (overriding the value in the
                    // event with the results of doing so)
                    if (_this2.state.selectionState === ALL) {
                        event.target.value = Object.keys(_this2.state.allItems);
                    } else {
                        event.target.value = [];
                    }

                    // let the caller know
                    _this2.props.onChange && _this2.props.onChange(event, child);
                });
            } else {
                this.props.onChange && this.props.onChange(event, child);
            }
        }

        /**
         * Grab the label/value of each item
         */

    }, {
        key: 'objectifyItems',
        value: function objectifyItems() {
            // grab all the dropdown items

            var filteredDropdownItems = filter(flattenDeep(this.props.children), function (item) {
                return get(item, 'type.muiName') === 'DropdownItem';
            });

            return reduce(filteredDropdownItems, function (mapping, item) {
                mapping[item.props.value] = item.props.label;
                return mapping;
            }, {});
        }

        /**
         * Render a single value.
         *
         * @param {*} val The value to render
         * @return        The rendered value
         */

    }, {
        key: 'renderSingleValue',
        value: function renderSingleValue(val) {
            return this.state.allItems[val];
        }

        /**
         * Render multiple values.
         *
         * @param {Array.<*>} values The values to render
         * @return                   The rendered values
         */

    }, {
        key: 'renderMultipleValues',
        value: function renderMultipleValues(values) {
            var _this3 = this;

            return values.map(function (val) {
                return _this3.renderSingleValue(val);
            }).join(', ');
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            // retain a catalog of each dropdown item
            this.setState({
                allItems: this.objectifyItems()
            });
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            // if the list of items has changed, update our internal catalog
            var itemsHaveChanged = get(this.props, 'children.length', 0) !== get(prevProps, 'children.length', 0) || !isEqual(this.objectifyItems(), this.state.allItems);

            if (itemsHaveChanged) {
                this.setState({
                    allItems: this.objectifyItems()
                });
            }

            // if the select-all item is visible, update it based on current selections
            if (this.props.multiple && this.props.showSelectAll) {
                var selectionState = NONE;

                if (this.props.value.length === Object.keys(this.state.allItems).length) {
                    selectionState = ALL;
                } else if (this.props.value.length > 0) {
                    selectionState = INDETERMINATE;
                }

                // if selection state changed, update it
                if (this.state.selectionState !== selectionState) {
                    this.setState({
                        selectionState: selectionState
                    });
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classNames3, _classNames4;

            var _props = this.props,
                childrenProp = _props.children,
                classes = _props.classes,
                disabled = _props.disabled,
                error = _props.error,
                edsContext = _props.edsContext,
                helperText = _props.helperText,
                id = _props.id,
                inputProps = _props.inputProps,
                labelProp = _props.label,
                multiple = _props.multiple,
                required = _props.required,
                value = _props.value,
                size = _props.size,
                onChange = _props.onChange,
                showSelectAll = _props.showSelectAll,
                fullWidth = _props.fullWidth,
                FormControlProps = _props.FormControlProps,
                FormHelperTextProps = _props.FormHelperTextProps,
                rest = _objectWithoutProperties(_props, ['children', 'classes', 'disabled', 'error', 'edsContext', 'helperText', 'id', 'inputProps', 'label', 'multiple', 'required', 'value', 'size', 'onChange', 'showSelectAll', 'fullWidth', 'FormControlProps', 'FormHelperTextProps']);

            var cleanedId = id || uuid();
            var selectLabelId = cleanedId + '_label';
            var selectValueId = cleanedId + '_value';

            var newInputProps = Object.assign({}, inputProps, {
                id: cleanedId
            });

            var children = childrenProp;
            var label = labelProp;

            // we do this to handle a bug within MUI - if the initial value is nil, then the label does not correctly
            // shrink out of the way when an item is selected. so we set the initial value to empty string instead, which
            // appears to solve the issue
            var localValue = isNil(value) ? '' : value;

            if (multiple) {
                children = React.Children.map(childrenProp, function (child, index) {
                    return cloneElement(child, { multiple: true });
                });

                // add the current selection count to the label
                if (localValue && this.state.allItems) {
                    var countString = '(' + this.props.value.length + '/' + Object.keys(this.state.allItems).length + ')';
                    label = labelProp + ' ' + countString;
                }
            }

            return React.createElement(
                FormControl,
                Object.assign({
                    className: classNames(_defineProperty({}, classes.formControlWidth, !fullWidth))
                }, FormControlProps),
                React.createElement(
                    Fragment,
                    null,
                    React.createElement(
                        InputLabel,
                        {
                            id: selectLabelId,
                            classes: {
                                root: classes.inputLabelRoot,
                                shrink: classes.inputLabelShrink,
                                disabled: classNames(classes.disabled, classes.zIndex)
                            },
                            disabled: disabled,
                            htmlFor: cleanedId,
                            className: classNames(_defineProperty({}, classes.inputLabelSmall, size === 'small'))
                        },
                        required ? label + ' *' : label
                    ),
                    React.createElement(
                        Select,
                        Object.assign({
                            className: classNames((_classNames3 = {}, _defineProperty(_classNames3, classes.small, size === 'small'), _defineProperty(_classNames3, classes.medium, size === 'medium'), _defineProperty(_classNames3, classes.selectIcon, !disabled), _classNames3)),
                            classes: {
                                root: classes.selectRoot,
                                select: classNames((_classNames4 = {}, _defineProperty(_classNames4, classes.select, !this.props.native), _defineProperty(_classNames4, classes.nativeSelect, this.props.native), _defineProperty(_classNames4, classes.error, this.props.native && error), _classNames4)),
                                selectMenu: classNames(classes.selectMenu, _defineProperty({}, classes.error, error)),
                                icon: classes.icon,
                                disabled: classes.disabled
                            },
                            onChange: this.handleChange.bind(this),
                            disabled: disabled,
                            disableUnderline: true,
                            IconComponent: ChevronDown,
                            inputProps: newInputProps,
                            labelId: selectLabelId,
                            id: selectValueId,
                            MenuProps: {
                                MenuListProps: {
                                    disablePadding: true,
                                    className: menuListClassName
                                },
                                classes: {
                                    paper: classes.paper
                                }
                            },
                            multiple: multiple,
                            renderValue: multiple ? this.renderMultipleValues.bind(this) : this.renderSingleValue.bind(this),
                            value: localValue
                        }, rest, {
                            onOpen: this.onDropDownOpen,
                            onClose: this.onDropDownClose
                        }),
                        showSelectAll && React.createElement(
                            DropdownItem,
                            {
                                label: edsContext.formatMessage('component.Dropdown.selectAll'),
                                selectAll: true,
                                multiple: true,
                                value: this.selectAllValue
                            },
                            React.createElement(Checkbox, {
                                indeterminate: this.state.selectionState === INDETERMINATE,
                                checked: this.state.selectionState !== NONE
                            })
                        ),
                        children
                    ),
                    helperText && React.createElement(
                        FormHelperText,
                        Object.assign({
                            className: size === 'medium' && !this.props.native ? classes.ffErrorTextAdjuster : null,
                            error: error,
                            id: cleanedId + '_HelperText'
                        }, FormHelperTextProps),
                        helperText
                    )
                )
            );
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            // Just making sure to unlock the body scroll
            IOSBodyScroll.unlock();
        }
    }]);

    return Dropdown;
}(React.Component);

/**
 * The following should be used to enable Tooltip hover once bugs have been tested
 *
    <Tooltip
        disableFocusListener
        enterDelay={1000}
        enterTouchDelay={1000}
        leaveTouchDelay={500}
        id={`${id}_Tooltip`}
        title={multiple ? value.join(', ') : value}
    >
        <Select></Select>
    </Tooltip>
 */

Dropdown.muiName = 'Dropdown';

Dropdown.propTypes = {
    /**
     * If `true`, the width of the popover will automatically be set according to the items inside the
     * menu, otherwise it will be at least the width of the select input.
     */
    autoWidth: PropTypes.bool,
    /**
     * The elements to populate the select with.
     *
     * - If `native` it true, these must be `option` elements
     * - if `native` is false, these must be `DropdownItem` elements
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * If `true`, the dropdown will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the selected item is displayed even if its value is empty.
     */
    displayEmpty: PropTypes.bool,
    /**
     * If `true`, the dropdown will display in an error state
     */
    error: PropTypes.bool,
    /**
     * Properties applied to the [`FormControl`](#/components/FormControl) element.
     */
    FormControlProps: PropTypes.object,
    /**
     * Properties applied to the [`FormHelperText`](#/components/FormControl) element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
     * If `true`, the dropdown will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,
    /**
     * Text to be displayed by the FormHelperText component
     */
    helperText: PropTypes.string,
    /**
     * The icon that displays the arrow.
     */
    IconComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * Id assigned to the input element and referenced by label element
     */
    id: PropTypes.string,
    /**
     * An `Input` element; does not have to be a material-ui specific `Input`.
     */
    input: PropTypes.element,
    /**
     * Attributes applied to the `input` element. When `native` is `true`,
     * the attributes are applied on the `select` element.
     */
    inputProps: PropTypes.object,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Label that is attached to the `Dropdown`
     */
    label: PropTypes.string.isRequired,
    /**
     * Properties applied to the [`Menu`](https://material-ui.com/api/menu/) element.
     */
    MenuProps: PropTypes.object,
    /**
     * If true, `value` must be an array and the menu will support multiple selections.
     * This feature will only work if `native` is `false`.
     */
    multiple: PropTypes.bool,
    /**
     * If `true`, the component will be using a native `select` element.
     * Be sure to use the `option` element to create dropdown items when
     * `native` is set to `true`, instead of our `DropdownItem` component.
     */
    native: PropTypes.bool,
    /**
     * Callback function fired when a menu item is selected.
     *
     * Signature:
     * ```
     * function(event: object, child?: object) => void
     * ```
     * _event_: The event source of the callback. You can pull out the new value by accessing `event.target.value`.
     *
     * _child_: The react element that was selected
     */
    onChange: PropTypes.func,
    /**
     * Callback fired when the component requests to be closed.
     * Use in controlled mode (see open).
     *
     * Signature:
     * ```
     * function(event: object) => void
     * ```
     * _event_: The event source of the callback
     */
    onClose: PropTypes.func,
    /**
     * Callback fired when the component requests to be opened.
     * Use in controlled mode (see open).
     *
     * Signature:
     * ```
     * function(event: object) => void
     * ```
     *
     * _event_: The event source of the callback
     */
    onOpen: PropTypes.func,
    /**
     * Control `select` open state. You can only use it when the `native` property is `false` (default).
     *
     * If you use `onOpen` or `onClose` callbacks, you must always provide the `open` prop.
     */
    open: PropTypes.bool,
    /**
     * Render the selected value.
     *
     * Signature:
     * ```
     * function(value: any) => ReactElement
     * ```
     *
     * _value_: The `value` provided to the component.
     */
    renderValue: PropTypes.func,
    /**
     * Whether this dropdown is required.
     */
    required: PropTypes.bool,
    /**
     * Properties applied to the clickable div element.
     */
    SelectDisplayProps: PropTypes.object,
    /**
     * Whether to provide a "select all" option for multi-select dropdowns
     */
    showSelectAll: PropTypes.bool,
    /**
     * The input value.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))]),
    /**
     * The size of the Dropdown. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: PropTypes.oneOf(['small', 'medium'])
};

Dropdown.defaultProps = {
    autoWidth: false,
    displayEmpty: false,
    fullWidth: false,
    multiple: false,
    required: false,
    showSelectAll: false,
    size: 'medium',
    value: ''
};
export default withEDSContext(withStyles(styles)(Dropdown));