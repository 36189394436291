/* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
 * File: SignIn.tsx
 * Type: Container component */

// #region Imports
import URLSearchParams from '@ungap/url-search-params';
import React from 'react';

// Core components
import Button, { ButtonGroup } from '@hedtech/powercampus-design-system/react/core/Button';
import Card, { CardContent } from '@hedtech/powercampus-design-system/react/core/Card';
import Grid from '@hedtech/powercampus-design-system/react/core/Grid';
import Modal from '@hedtech/powercampus-design-system/react/core/Modal';
import Paragraph from '@hedtech/powercampus-design-system/react/core/Paragraph';
import PasswordConfirmation from '@hedtech/powercampus-design-system/react/components/PasswordConfirmation';
import Text from '@hedtech/powercampus-design-system/react/core/Text';
import TextField from '@hedtech/powercampus-design-system/react/core/TextField';

// Types
import { IAccountValidations } from '@hedtech/powercampus-design-system/types/PasswordConfirmation/IAccountValidations';
import { IAlert } from '@hedtech/powercampus-design-system/types/IAlert';
import { IJsonResult } from '@hedtech/powercampus-design-system/types/IJsonResult';
import { ILogData } from '@hedtech/powercampus-design-system/types/ILogData';
import { ResultType } from '@hedtech/powercampus-design-system/types/ResultType';
import { AuthStatus } from '../../Types/Enum/AuthStatus';
import { ChangePasswordResponse } from '../../Types/Enum/ChangePasswordResponse';
import { IAuthResponse } from '../../Types/Generic/IAuthResponse';
// import { IResultSignUp } from '@hedtech/powercampus-design-system/types/PasswordConfirmation/IResultSignUp';
import { ISignInResources } from '../../Types/Resources/Generic/ISignInResources';

// Helpers
import Constants from '@hedtech/powercampus-design-system/helpers/Constants';
import Format from '@hedtech/powercampus-design-system/helpers/Format';
import LogData from '@hedtech/powercampus-design-system/helpers/LogData';
import Tokens from '@hedtech/powercampus-design-system/react/core/styles/Tokens';
// import PasswordValidation from '@hedtech/powercampus-design-system/helpers/PasswordValidation';
import { createStyles, withStyles, WithStyles } from '@hedtech/powercampus-design-system/react/core/styles/withStyles';

// Requests
import RequestsLayout from '@hedtech/powercampus-design-system/requests/LayoutRequests';
import Requests from '../../Requests/Generic/SignIn';

// State Management
import LayoutActions from '@hedtech/powercampus-design-system/flux/actions/LayoutActions';
import LayoutStore from '@hedtech/powercampus-design-system/flux/stores/LayoutStore';
import PasswordConfirmationActions from '@hedtech/powercampus-design-system/flux/actions/PasswordConfirmationActions';
import PasswordValidation from '@hedtech/powercampus-design-system/helpers/PasswordValidation';
import PasswordConfirmationStore from '@hedtech/powercampus-design-system/flux/stores/PasswordConfirmationStore';
// #endregion Imports

// #region Types
export interface ISignInProps {
    open?: boolean;
    userName?: string;
    onAfterSignIn?: () => void;
    onClose?: () => void;
    onGoSignUp?: () => void;
}

interface ISignInState {
    accountValidations?: IAccountValidations;
    accountLocked: boolean;
    authMode?: number;
    openChangePassword: boolean;
    errorAttemps: boolean;
    hasStore: boolean;
    invalidPassword: boolean;
    isLoading: boolean;
    isLoadingNext: boolean;
    isLoadingSignIn: boolean;
    isLockOut: boolean;
    password: string;
    passwordResetURL: string;
    remainingAttempts?: number;
    remainingLockedOutTime?: number;
    resources?: ISignInResources;
    showForgotPassword: boolean;
    userExists: boolean;
    username: string;

    // Change Password
    errorCurrentPassword: boolean;
    newPassword?: string;
}

const styles = createStyles({
    loginCard: {
        animation: 'slidein 1s',
        marginTop: Tokens.spacing80,
        maxWidth: '800px'
    },
    loginText: {
        marginTop: `${Tokens.spacing40}!important`
    },
    spacingButtons: {
        paddingTop: Tokens.spacing40
    },
    spacingInstructions: {
        paddingTop: Tokens.spacing40
    },
    spacingWelcome: {
        marginBottom: Tokens.spacing40
    }
});

type PropsWithStyles = ISignInProps & WithStyles<typeof styles>;
// #endregion Types

// #region Component
class SignIn extends React.Component<PropsWithStyles, ISignInState> {
    private idModule: string;
    private idPage: string;

    public readonly state: Readonly<ISignInState>;

    public constructor(props) {
        super(props);

        // #region Initialize Variables and State
        this.idModule = 'Generic';
        this.idPage = 'SignIn';
        this.state = this.getInitialState(this.props.userName);
        // #endregion Initialize Variables and State

        // #region Bind State Management Listeners
        PasswordConfirmationStore.addPasswordValidationListener(this.onChangePasswordValidation);
        // #endregion State Management Listeners
    }

    private getInitialState(userName?: string): ISignInState {
        let isLoading: boolean = true;
        let passwordResetURL: string = '';
        let resources: ISignInResources | undefined;
        let showForgotPassword: boolean = false;

        if (this.state) {
            isLoading = this.state.isLoading;
            passwordResetURL = this.state.passwordResetURL;
            resources = this.state.resources;
            showForgotPassword = this.state.showForgotPassword;
        }
        return {
            accountLocked: false,
            accountValidations: undefined,
            authMode: undefined,
            openChangePassword: false,
            errorAttemps: false,
            errorCurrentPassword: false,
            hasStore: false,
            invalidPassword: false,
            isLoading: isLoading,
            isLoadingNext: false,
            isLoadingSignIn: false,
            isLockOut: false,
            password: '',
            passwordResetURL: passwordResetURL,
            resources: resources,
            showForgotPassword: showForgotPassword,
            userExists: false,
            username: userName || ''
        };
    }

    // #region Events

    // #region Change Password
    private onChangePasswordButton = (): void => {
        try {
            try {
                const {
                    accountValidations,
                    errorCurrentPassword,
                    newPassword,
                    password,
                    username
                } = this.state;

                let isValid: boolean = true;
                if (accountValidations) {
                    let validations: IAccountValidations = PasswordValidation.validatePassword(accountValidations, newPassword,
                        PasswordConfirmationStore.getPasswordPolicy());
                    validations = PasswordValidation.validateConfirmPassword(accountValidations, accountValidations.confirmPassword, newPassword);
                    PasswordConfirmationActions.setPasswordValidation(newPassword, validations);

                    if (validations.hasErrors || errorCurrentPassword) {
                        isValid = false;
                    }
                }

                if (isValid && newPassword) {
                    LayoutActions.showPageLoader();
                    Requests.postChangePassword(username, password, newPassword, this.resolveChangePassword, this.logError);
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.onChangePasswordButton.name, e));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onChangePasswordButton.name, e));
        }
    };

    private onCloseChangePassword = (): void => {
        try {
            LayoutStore.setIsAuthenticated(false);
            LayoutStore.setMenuOptions(undefined);
            this.setState({
                authMode: undefined,
                hasStore: false,
                newPassword: '',
                password: '',
                openChangePassword: false,
                userExists: false,
                username: ''
            });
            PasswordConfirmationActions.setPasswordValidation();
            this.hideLoaderSignIn();
            LayoutActions.hidePageLoader();
        }
        catch (e) {
            this.logError(LogData.fromException(this.onCloseChangePassword.name, e));
        }
    };

    private onChangePasswordValidation = (): void => {
        try {
            const {
                password
            } = this.state;

            this.setState({
                accountValidations: PasswordConfirmationStore.getPasswordValidation(),
                errorCurrentPassword: PasswordConfirmationStore.getPassword() === password,
                newPassword: PasswordConfirmationStore.getPassword()
            });
        }
        catch (e) {
            this.logError(LogData.fromException(this.onChangePassword.name, e));
        }
    };
    // #endregion Change Password

    private onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            password: event.target.value
        });
    };

    private onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            invalidPassword: false,
            username: event.target.value
        });
    };

    private onCloseModal = (): void => {
        try {
            const {
                onClose
            } = this.props;
            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (onClose && !isLoadingNext && !isLoadingSignIn) {
                this.setState(this.getInitialState());
                onClose();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onCloseModal.name, e));
        }
    };

    private onGoSignUpModal = (): void => {
        try {
            const {
                onGoSignUp
            } = this.props;

            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (onGoSignUp && !isLoadingNext && !isLoadingSignIn) {
                this.setState(this.getInitialState());
                onGoSignUp();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onGoSignUpModal.name, e));
        }
    };

    private onNext = (): void => {
        try {
            const {
                isLoadingNext,
                username
            } = this.state;

            if (!isLoadingNext) {
                this.showLoaderNext();
                Requests.getAuthenticationMode(username, this.resolveGetAuthenticationMode, this.logError);
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onNext.name, e));
        }
    };

    private onSignIn = (): void => {
        try {
            const {
                authMode,
                isLoadingSignIn,
                password,
                username
            } = this.state;

            if (authMode && !isLoadingSignIn) {
                this.showLoaderSignIn();
                if (authMode === 4) {
                    window.location.assign(`${Constants.webUrl}/Sso/SAML`);
                }
                else {
                    Requests.postAuthenticateUser(username, password, this.resolvePostAuthenticateUser, this.logError);
                }
            }
            else {
                this.showError();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onSignIn.name, e));
        }
    };

    private onClickForgotPassword = (): void => {
        try {
            const {
                isLoadingNext,
                isLoadingSignIn,
                passwordResetURL
            } = this.state;

            if (passwordResetURL && !isLoadingNext && !isLoadingSignIn) {
                window.location.assign(passwordResetURL);
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onClickForgotPassword.name, e));
        }
    };

    private onUseAnotherAccount = (): void => {
        try {
            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (!isLoadingNext && !isLoadingSignIn) {
                this.setState({
                    authMode: undefined,
                    password: '',
                    username: ''
                });
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onUseAnotherAccount.name, e));
        }
    };
    // #endregion Events

    // #region Loader Functions
    private hideAllLoaders = (): void => {
        this.setState({
            isLoading: false,
            isLoadingNext: false,
            isLoadingSignIn: false
        });
    };

    private hideLoaderNext = (): void => {
        this.setState({
            isLoadingNext: false
        });
    };

    private hideLoaderSignIn = (): void => {
        this.setState({
            isLoadingSignIn: false
        });
    };

    private showLoaderNext = (): void => {
        this.setState({
            isLoadingNext: true
        });
    };

    private showLoaderSignIn = (): void => {
        this.setState({
            isLoadingSignIn: true
        });
    };
    // #endregion Loader Functions

    // #region Error Functions
    private logError(logData: ILogData): void {
        this.hideAllLoaders();
        LayoutActions.setLogData(logData);
    }

    private redirectError(code: number): void {
        this.hideAllLoaders();
        LayoutActions.setRedirectCode(code);
    }

    private showError(message?: string): void {
        this.hideAllLoaders();
        LayoutActions.setAlert({ message: message, messageType: ResultType.error } as IAlert);
    }
    // #endregion Error Functions

    // #region Resolvers
    private resolveGetResources = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                this.setState({
                    isLoading: false,
                    resources: result.data
                });
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveGetResources.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveGetResources.name, e));
        }
    };

    private resolveGetShowForgotPassword = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                this.setState({
                    passwordResetURL: result.data.passwordResetURL,
                    showForgotPassword: result.data.enablePasswordReset
                });
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveGetShowForgotPassword.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveGetShowForgotPassword.name, e));
        }
    };

    private resolveGetAuthenticationMode = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                const {
                    resources
                } = this.state;
                if (resources) {
                    this.setState({
                        authMode: result.data.mode,
                        hasStore: result.data.hasStore,
                        userExists: result.data.userExists
                    });

                    let message: string | undefined;

                    if (!result.data.userExists) {
                        message = resources.lblUserExists;
                    }
                    else if (!result.data.hasStore) {
                        message = resources.lblHasStore;
                    }
                    const alert: IAlert = {
                        message: message,
                        messageType: ResultType.error
                    };

                    if (message) {
                        LayoutActions.setAlert(alert);
                    }

                    if (result.data.mode === 3) {
                        window.location.assign(`${Constants.webUrl}/ADFS/Authentication`);
                    }
                    if (result.data.mode === 4) {
                        window.location.assign(`${Constants.webUrl}/SSO/SAML`);
                    }
                }
                this.hideLoaderNext();
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveGetAuthenticationMode.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveGetAuthenticationMode.name, e));
        }
    };

    private resolvePostAuthenticateUser = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                const authResponse: IAuthResponse = result.data;
                if (authResponse && authResponse.success) {
                    LayoutStore.setIsAuthenticated(true);
                    LayoutStore.setMenuOptions(undefined);

                    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
                    const returnUrl: string | null = urlParams.get('ReturnUrl');

                    const {
                        onAfterSignIn
                    } = this.props;

                    if (authResponse.status === AuthStatus.Success &&
                        authResponse.changePasswordAtNextLogon) {
                        this.setState({
                            openChangePassword: true
                        });
                    }
                    else {
                        if (onAfterSignIn) {
                            onAfterSignIn();
                        }
                        else {
                            if (returnUrl && !returnUrl.includes('LogOut')) {
                                window.location.href = `${returnUrl}`;
                            }
                            else {
                                window.location.href = `${Constants.webUrl}`;
                            }
                        }
                    }
                }
                else {
                    const {
                        resources
                    } = this.state;

                    if (resources) {
                        let message: string = '';
                        if (authResponse.status === AuthStatus.None) {
                            message = resources.lblNone;
                        }
                        else if (authResponse.status === AuthStatus.Success) {
                            message = resources.lblSuccess;
                        }
                        else if (authResponse.status === AuthStatus.InvalidCredentials) {
                            message = resources.lblInvalidCredentials;
                        }
                        else if (authResponse.status === AuthStatus.InvalidPassword) {
                            message = resources.lblInvalidPassword;
                            if (authResponse.authResponseDetail.attempt) {
                                this.setState({
                                    remainingAttempts: authResponse.authResponseDetail.attempt.remainingAttempts,
                                    remainingLockedOutTime: authResponse.authResponseDetail.attempt.remainingLockedOutTime,
                                });
                            }

                            this.setState({
                                invalidPassword: true
                            });
                        }
                        else if (authResponse.status === AuthStatus.NoIdentity) {
                            message = resources.lblNoIdentity;
                        }
                        else if (authResponse.status === AuthStatus.NoStoreAssigned) {
                            message = resources.lblUnknown;
                        }
                        else if (authResponse.status === AuthStatus.IsLocked) {
                            if (authResponse.authResponseDetail.attempt) {
                                this.setState({
                                    remainingAttempts: authResponse.authResponseDetail.attempt.remainingAttempts,
                                    remainingLockedOutTime: authResponse.authResponseDetail.attempt.remainingLockedOutTime,
                                });
                            }
                            this.setState({
                                accountLocked: true,
                                invalidPassword: false
                            });
                        }

                        if (authResponse.authResponseDetail.attempt &&
                            (authResponse.authResponseDetail.attempt.remainingAttempts ||
                                authResponse.authResponseDetail.attempt.remainingLockedOutTime)) {
                            this.setState({
                                errorAttemps: true
                            });
                        }

                        if (authResponse.status === AuthStatus.Success || authResponse.status === AuthStatus.InvalidCredentials
                            || authResponse.status === AuthStatus.NoIdentity || authResponse.status === AuthStatus.InvalidConfiguration
                            || authResponse.status === AuthStatus.NoStoreAssigned || authResponse.status === AuthStatus.Unknown
                            || (authResponse.status === AuthStatus.InvalidPassword &&
                                authResponse.authResponseDetail.attempt && authResponse.authResponseDetail.attempt.remainingAttempts === 0)) {
                            const alert: IAlert = {
                                message: message,
                                messageType: ResultType.error
                            };

                            LayoutActions.setAlert(alert);
                        }
                        this.hideLoaderSignIn();
                    }
                }
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolvePostAuthenticateUser.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolvePostAuthenticateUser.name, e));
        }
    };

    // #region Change Password
    private resolveChangePassword = (json: string): void => {
        try {
            const {
                accountValidations,
                newPassword,
                resources
            } = this.state;
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                if (resources) {
                    const response: ChangePasswordResponse = result.data;
                    if (response.updatedSuccessfully) {
                        PasswordConfirmationActions.setPasswordValidation();
                        this.setState({
                            newPassword: '',
                            password: '',
                            openChangePassword: false,
                            username: ''
                        });
                        this.hideLoaderSignIn();
                        const {
                            onAfterSignIn
                        } = this.props;
                        if (onAfterSignIn) {
                            onAfterSignIn();
                        }
                        else {
                            const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
                            const returnUrl: string | null = urlParams.get('ReturnUrl');

                            if (returnUrl && !returnUrl.includes('LogOut')) {
                                window.location.href = `${returnUrl}`;
                            }
                            else {
                                window.location.href = `${Constants.webUrl}`;
                            }
                        }
                    }
                    else if (response.errors.length > 0) {
                        switch (response.errors[0]) {
                            case 9:
                                if (accountValidations) {
                                    const validations: IAccountValidations = PasswordValidation.validatePassword(accountValidations, newPassword,
                                        PasswordConfirmationStore.getPasswordPolicy());
                                    validations.isNotPreviousPassword = true;
                                    PasswordConfirmationActions.setPasswordValidation(newPassword, validations);
                                }
                                break;
                            default:
                                this.showError(resources.lblGenericError);
                                break;
                        }
                    }
                }
                LayoutActions.hidePageLoader();
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveChangePassword.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveChangePassword.name, e));
        }
    };
    // #endregion Events
    // #endregion Resolvers

    // #region Lifecycle
    public componentDidMount(): void {
        try {
            RequestsLayout.getResources(this.idModule, this.idPage,
                this.resolveGetResources,
                this.logError);
            Requests.getShowForgotPassword(this.resolveGetShowForgotPassword, this.logError);

            const {
                username
            } = this.state;
            if (username) {
                this.onNext();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.componentDidMount.name, e));
        }
    }

    public componentWillUnmount(): void {
        PasswordConfirmationStore.removePasswordValidationListener(this.onChangePasswordValidation);
    }
    // #endregion Lifecycle

    public render(): JSX.Element {
        const {
            classes,
            open,
            onClose,
            onGoSignUp
        } = this.props;

        const {
            accountLocked,
            authMode,
            openChangePassword,
            errorAttemps,
            errorCurrentPassword,
            invalidPassword,
            isLoading,
            isLoadingNext,
            isLoadingSignIn,
            password,
            remainingAttempts,
            remainingLockedOutTime,
            resources,
            showForgotPassword,
            username
        } = this.state;

        let contentPage: JSX.Element | undefined;
        if (resources && !isLoading) {
            let forgotPassword: JSX.Element | undefined;
            if (showForgotPassword) {
                forgotPassword = (
                    <Button
                        TextProps={{
                            weight: 'strong'
                        }}
                        id="btnForgotPassword"
                        align="right"
                        textVariantStyling="inline"
                        variant="text"
                        onClick={this.onClickForgotPassword}
                    >
                        {resources.btnForgot}
                    </Button>
                );
            }
            const stepNumber: number = !authMode ? 1 : (authMode && (authMode === 1 || authMode === 2) ? 2 : 0);

            let title: JSX.Element | undefined;
            let loginContent: JSX.Element | JSX.Element[] | undefined;
            let button: JSX.Element | undefined;

            switch (stepNumber) {
                case 0:
                    title = (
                        <Text align="center" size="h2">
                            {resources.lblLogInTitle}
                        </Text>
                    );
                    break;
                case 1:
                    title = (
                        <>
                            <Text align="center" size="h2">
                                {resources.lblLogInTitle}
                            </Text>
                            {onGoSignUp && (
                                <Paragraph
                                    align="center"
                                    className={classes.spacingInstructions}
                                    id="prgSignUp"
                                    text={resources.lblInstructions}
                                    events={[this.onGoSignUpModal]}
                                />
                            )}
                        </>
                    );
                    button = (
                        <Button
                            id="btnNext"
                            loading={isLoadingNext}
                            onClick={this.onNext}
                        >
                            {resources.btnNext}
                        </Button>
                    );
                    loginContent = (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    {!onClose && (
                                        <>
                                            {title}
                                        </>
                                    )}
                                    <TextField
                                        autoComplete="username"
                                        className={classes.loginText}
                                        disabled={authMode || isLoadingNext}
                                        id="txtUsername"
                                        label={resources.lblUserName}
                                        value={username || ''}
                                        onChange={this.onChangeUsername}
                                        onEnterPress={this.onNext}
                                    />
                                </Grid>
                            </Grid>
                            {!onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    <Grid item>
                                        {button}
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    );
                    break;
                case 2:
                    title = (
                        <>
                            <Text
                                align="center"
                                className={classes.spacingWelcome}
                                size="h1"
                            >
                                {resources.lblWelcome}
                            </Text>
                            <Text align="center" size="h2">
                                {username}
                            </Text>
                            <Grid container justify="center">
                                <Grid item>
                                    <Button
                                        TextProps={{
                                            weight: 'strong'
                                        }}
                                        disabled={isLoadingSignIn}
                                        id="btnAnotherAccount"
                                        textVariantStyling="inline"
                                        variant="text"
                                        onClick={this.onUseAnotherAccount}
                                    >
                                        {resources.lblUseAnotherAccount}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    );
                    button = (
                        <Button
                            id="btnSignIn"
                            loading={isLoadingSignIn}
                            onClick={this.onSignIn}
                        >
                            {resources.btnSingIn}
                        </Button>
                    );
                    loginContent = (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    {!onClose && (
                                        <>
                                            {title}
                                        </>
                                    )}
                                    <TextField
                                        autoComplete="new-password"
                                        className={classes.loginText}
                                        disabled={isLoadingSignIn}
                                        error={invalidPassword || accountLocked || errorAttemps}
                                        helperText={invalidPassword && errorAttemps ?
                                            Format.toString(resources.formatInvalidAttempts, [remainingAttempts]) :
                                            (invalidPassword ? resources.lblInvalidPassword :
                                                accountLocked && remainingLockedOutTime ?
                                                    Format.toString(resources.formatLockedAccount, [remainingLockedOutTime]) :
                                                    accountLocked ? resources.lblLoocked : ''
                                            )}
                                        id="txtPassword"
                                        label={resources.lblPassword}
                                        passwordToggle
                                        type="password"
                                        value={password || ''}
                                        onChange={this.onChangePassword}
                                        onEnterPress={this.onSignIn}
                                    />
                                </Grid>
                            </Grid>
                            {!onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    {forgotPassword && (
                                        <Grid item>
                                            {forgotPassword}
                                        </Grid>
                                    )}
                                    <Grid item>
                                        {button}
                                    </Grid>
                                </Grid>
                            )}
                            {onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    {forgotPassword && (
                                        <Grid item>
                                            {forgotPassword}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    );
                    break;
            }

            if (onClose) {
                contentPage = (
                    <Modal
                        disableBackdropClick={isLoadingNext || isLoadingSignIn}
                        disableEscapeKeyDown={isLoadingNext || isLoadingSignIn}
                        disableHeaderTypography
                        id="signInModal"
                        footer={button}
                        header={title}
                        maxWidth="md"
                        open={open}
                        onClose={this.onCloseModal}
                    >
                        {loginContent}
                    </Modal>
                );
            }
            else {
                let changePasswordModal: JSX.Element | undefined;
                if (openChangePassword) {
                    changePasswordModal = (
                        <Modal
                            disableBackdropClick
                            footer={(
                                <ButtonGroup id="btgChangePassword">
                                    <Button
                                        id="btnCancel"
                                        color="secondary"
                                        onClick={this.onCloseChangePassword}
                                    >
                                        {resources.btnCancel}
                                    </Button>
                                    <Button
                                        id="btnChangePassword"
                                        onClick={this.onChangePasswordButton}
                                    >
                                        {resources.btnChangePassword}
                                    </Button>
                                </ButtonGroup>
                            )}
                            id="changePasswordModal"
                            header={resources.lblChangePassword}
                            maxWidth="md"
                            open={openChangePassword}
                            onClose={this.onCloseChangePassword}
                        >
                            <br />
                            <Grid container>
                                <Grid item xs={12}>
                                    <Text>
                                        {resources.lblChangePasswordInstructions}
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordConfirmation
                                        data={{ userName: username }}
                                        route="/Password/PolicyByUserName"
                                    />
                                </Grid>
                            </Grid>
                            {errorCurrentPassword && (
                                <Grid item xs={12}>
                                    <Text color="error" size="small">
                                        {resources.lblErrorCurrentPasswords}
                                    </Text>
                                </Grid>
                            )}
                        </Modal>
                    );
                }

                contentPage = (
                    <Card className={classes.loginCard}>
                        <CardContent>
                            {loginContent}
                            {changePasswordModal}
                        </CardContent>
                    </Card>
                );
            }
        }

        return (
            <>
                {contentPage}
            </>
        );
    }
}
// #endregion Component

// Export: Component
export default withStyles(styles)(SignIn);