function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiListItemIcon } from '@material-ui/core/ListItemIcon';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        root: {}
    };
};

/**
 * A simple wrapper component to apply icon styles to ListItems.
 */
var ListItemIcon = function ListItemIcon(props) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = Object.assign({
        // See Material UI documentation for available CSS classes can be passed through to override
        root: classes.root
    }, classes);

    return React.createElement(MuiListItemIcon, Object.assign({}, rest, {
        classes: classOverrides }));
};

ListItemIcon.muiName = 'ListItemIcon';

ListItemIcon.propTypes = {
    /**
     * The content of the component, normally an icon
     */
    children: PropTypes.element.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object
};

export default withStyles(styles)(ListItemIcon);