function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from './../../List';
import ListItem from './../../List/ListItem';
import ListItemText from './../../List/ListItemText';
import { withEDSContext } from '../../EDSContext/EDSContext';
import ListItemIcon from './../../List/ListItemIcon';
import Icon from '@eui/ds-icons/lib/Icon';
import { default as withStyles } from '../../styles';
import { SEARCH_WIDTH_WITH_CATEGORY_MENU } from './constants';
import { borderRadiusMedium, widthInputFields, borderWidthThin, colorTextNeutral500, spacingXLarge } from '../../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            height: 'auto',
            '&$listWidth': {
                width: '' + widthInputFields
            },
            '&$listWidthWithCategory': {
                width: SEARCH_WIDTH_WITH_CATEGORY_MENU
            },
            '&$listRootFullWidth': {
                width: '100%'
            },
            borderRadius: borderRadiusMedium,
            boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)',
            marginTop: '.25rem',
            position: 'absolute',
            zIndex: theme.zIndex.dropdown
        },
        listItemHover: {
            '&:hover': {
                backgroundColor: 'initial !important',
                cursor: 'unset'
            },
            color: colorTextNeutral500
        },
        listRootFullWidth: {},
        listWidth: {},
        listIcon: {
            minWidth: spacingXLarge
        },
        listWidthWithCategory: {}
    };
};

var SearchStatus = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        loading = props.loading,
        noData = props.noData,
        showCategoryMenu = props.showCategoryMenu,
        edsContext = props.edsContext;

    return React.createElement(
        List,
        {
            'aria-busy': loading,
            'aria-label': edsContext.formatMessage('component.Search.noresult'),
            className: classNames(classes.listWidth, (_classNames = {}, _defineProperty(_classNames, classes.listRootFullWidth, props.fullWidth), _defineProperty(_classNames, classes.listWidthWithCategory, showCategoryMenu), _classNames)),
            classes: {
                root: classes.root
            }
        },
        React.createElement(
            ListItem,
            { classes: {
                    root: classes.listItemHover
                } },
            noData && React.createElement(ListItemText, { primary: edsContext.formatMessage('component.Search.noresult') }),
            loading && React.createElement(
                React.Fragment,
                null,
                React.createElement(
                    ListItemIcon,
                    { classes: {
                            root: classes.listIcon
                        } },
                    React.createElement(Icon, {
                        name: 'spinner',
                        large: true,
                        spin: true
                    })
                ),
                React.createElement(ListItemText, { primary: edsContext.formatMessage('component.Search.loading') })
            )
        )
    );
});

SearchStatus.propTypes = {
    // To display loader.
    loading: PropTypes.bool,

    // To display no result found message.
    noData: PropTypes.bool,

    //the List will take up the full width of its container if value is true.
    fullWidth: PropTypes.bool
};

export default withEDSContext(withStyles(styles)(SearchStatus));