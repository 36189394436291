function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import MenuItem from '@material-ui/core/MenuItem';
import { borderWidthThin, fontSizeDefault, lineHeightHeader3, sizingXxLarge } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        menuRoot: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[300]
        },
        menuItemRoot: {
            fontSize: fontSizeDefault,
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300],
            paddingTop: '.75rem',
            paddingBottom: '.75rem',
            '&:focus, &:hover': {
                backgroundColor: theme.palette.grey[250],
                color: theme.palette.grey[600]
            },
            '&:last-child': {
                borderBottom: 'none'
            },
            '&$menuItemSelected': {
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey[100],
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.hover,
                    color: theme.palette.grey[100]
                },
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.hover,
                    color: theme.palette.grey[100]
                }
            },
            minHeight: sizingXxLarge,
            lineHeight: lineHeightHeader3
        },
        menuItemSelected: {
            backgroundColor: theme.palette.ctaColor.base,
            color: theme.palette.grey[100],
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:focus': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            }
        }
    };
};

var SelectionMenuItem = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        children = props.children,
        disabled = props.disabled,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'disabled']);

    return React.createElement(
        MenuItem,
        Object.assign({
            classes: {
                root: classes.menuItemRoot,
                selected: classes.menuItemSelected
            },
            disabled: disabled,
            'aria-disabled': disabled ? true : false,
            ref: ref
        }, rest),
        children
    );
});

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
SelectionMenuItem.displayName = 'SelectionMenuItem';

SelectionMenuItem.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The text of the item.
     */
    children: PropTypes.node.isRequired,
    /**
     * If `true`, the item will be disabled.
     */
    disabled: PropTypes.bool
};

export default withStyles(styles, { name: 'SelectionMenuItem' })(SelectionMenuItem);