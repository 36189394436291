function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiListItemSecondaryAction } from '@material-ui/core/ListItemSecondaryAction';

var styles = function styles(theme) {
    return {
        root: {}
    };
};

/**
 * A simple wrapper component to apply secondary action styles to ListItems.
 */
var ListItemSecondaryAction = function ListItemSecondaryAction(props) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = Object.assign({
        // See Material UI documentation for available CSS classes can be passed through to override
        root: classes.root
    }, classes);

    return React.createElement(MuiListItemSecondaryAction, Object.assign({}, rest, {
        classes: classOverrides
    }));
};

ListItemSecondaryAction.muiName = 'ListItemSecondaryAction';

ListItemSecondaryAction.propTypes = {
    /**
     * The content of the component, normally an `IconButton` or selection control.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object
};

// If default props are needed, they must be set here
// ListItemSecondaryAction.defaultProps = { };

export default withStyles(styles)(ListItemSecondaryAction);