var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { createContext, useEffect } from 'react';
import { default as MuiCard } from '@material-ui/core/Card';
import { useSpacing } from './../globalSpacing';
import { default as withStyles } from '../styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { borderRadiusMedium, sizingSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            borderRadius: borderRadiusMedium,
            overflow: 'inherit',

            // added this to ensure that the contents of a card don't exceed the width of the card itself;
            // you can reproduce this behavior by putting a <pre> inside of a <CardContent> and filling it
            // up with stuff; it's go beyond the bounds of its width
            //
            // see https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
            // for more details
            gridTemplateColumns: 'minmax(0, 1fr)'

        },
        accent: {
            borderTopWidth: sizingSmall,
            borderTopStyle: 'solid',
            paddingTop: 0
        },
        accentPrimary: {
            borderTopColor: theme.palette.primary.main
        },
        accentSecondary: {
            borderTopColor: theme.palette.secondary.main
        }
    };
};

var CardContext = createContext();

/**
 * Use `Card` to display a group of similar content on a page.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  SimpleCard
 *  SubtitleHeaderActionsCard
 *  VisuallyAccentedCard
 *  ComplexCard
 *  TitleAndActionButtonAlignment
 *  LegacySpacingCard
 *  CustomSpacingCard
 *  CustomSpacingWithSpacingOptionsAPI
 */
var Card = React.forwardRef(function (props, ref) {
    var _classNames;

    var accent = props.accent,
        classes = props.classes,
        padding = props.padding,
        responsive = props.responsive,
        spacingOptionsLocal = props.spacingOptions,
        legacySpacingMode = props.legacySpacingMode,
        rest = _objectWithoutProperties(props, ['accent', 'classes', 'padding', 'responsive', 'spacingOptions', 'legacySpacingMode']);

    var _useSpacing = useSpacing(spacingOptionsLocal),
        _useSpacing2 = _slicedToArray(_useSpacing, 3),
        spacingStyles = _useSpacing2[0],
        options = _useSpacing2[1],
        setSpacingOptions = _useSpacing2[2];

    var spacingOptions = legacySpacingMode ? {} : options;
    var spacingStylesLocal = legacySpacingMode ? {} : spacingStyles;
    var classOverrides = classNames(classes.root, spacingStylesLocal, (_classNames = {}, _defineProperty(_classNames, classes.accent, ['primary', 'secondary'].indexOf(accent) !== -1), _defineProperty(_classNames, classes.accentPrimary, accent === 'primary'), _defineProperty(_classNames, classes.accentSecondary, accent === 'secondary'), _classNames));

    useEffect(function () {
        setSpacingOptions(spacingOptionsLocal);
    }, [spacingOptionsLocal]); // eslint-disable-line

    return React.createElement(
        CardContext.Provider,
        {
            value: {
                padding: padding,
                responsive: responsive,
                spacingOptions: spacingOptions
            }
        },
        React.createElement(MuiCard, Object.assign({}, rest, {
            classes: {
                root: classOverrides
            },
            ref: ref
        }))
    );
});

Card.muiName = 'Card';
Card.displayName = 'Card';

Card.propTypes = {
    /**
     * Customize the color of accent border that is added to the card.
     *
     * The options are `primary` and `secondary`. The `Card` will render with an
     * accented top border color that corresponds to the theme's primary and secondary
     * color (i.e. `primary` will render a `Card` with a `primary` accent border).
     */
    accent: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * If `true`, the card will use raised visual styling
     */
    raised: PropTypes.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     */
    elevation: PropTypes.number,
    /**
     * If `true`, uses the old (padding-based) spacing infrastructure.\
     * If `false`, uses the new (CSS grid-based) spacing infrastructure.
     */
    legacySpacingMode: PropTypes.bool,
    /**
     * Customize the spacing for Card's subcomponents. Standard, dense, and none
     * apply consistent padding across all viewports, per subcomponent.
     *
     * **NOTE:** Only applicable when `legacySpacingMode` is `true`.
     */
    padding: PropTypes.oneOf(['standard', 'dense', 'none']),
    /**
     * If `true`, `responsive` combines with `padding` to provide a responsive
     * spacing solution, if padding is set to either `standard` or `dense`.
     *
     * **NOTE:** Only applicable when `legacySpacingMode` is `true`.
     */
    responsive: PropTypes.bool,
    /**
     * Provides several attributes to control `Card` spacing.
     *
     * Available options are:
     *
     * * `spacing` (string): **'standard'** | 'none' | \<object\>
     *      * Controls the padding of the `Card` and grid gap between child elements
     *      * `standard`: The default spacing
     *      * `none`: Removes all spacing styles, allowing for complete style customization through the `classes` prop
     *      * `object`: Allows for more fine-grained spacing customization. See [container customization documentation](#/utilities/spacing#using-customizations-for-fine-grained-container-spacing-control)
     *        for more details.
     * * `responsive` (bool): **true** | false
     *      * By default, `Card` spacing adjusts responsively to its viewport. Set to `false` to disable responsiveness.
     * * `outerSpacing` (bool): **true** | false
     *      * If set to `false`, removes the card's outer padding (but retains the space between its subcomponents)
     */
    spacingOptions: PropTypes.shape({
        spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        responsive: PropTypes.bool,
        outerSpacing: PropTypes.bool
    })
};

Card.defaultProps = {
    elevation: 5,
    padding: 'standard',
    responsive: true,
    legacySpacingMode: false
};

export { CardContext };
export default withStyles(styles)(Card);