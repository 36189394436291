import { LogType } from '../types/LogType';
const LogData = {
    fromException(functionName, e) {
        return {
            functionName: functionName,
            message: `Code: ${e.code}; Name: ${e.name}; Message: ${e.message}; Stack: ${e.stack}`,
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    fromResponse(functionName, response) {
        return {
            functionName: functionName,
            message: `Status: ${response.status}; StatusText: ${response.statusText}; Url: ${response.url}`,
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    fromComponentException(functionName, e, info) {
        return {
            functionName: functionName,
            message: `Code: ${e.code}; Name: ${e.name}; Message: ${e.message}; Stack: ${e.stack}; ComponentStack: ${info.componentStack}`,
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    fromMessage(functionName, message) {
        return {
            functionName: functionName,
            message: message,
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    badJsonResult(functionName) {
        return {
            functionName: functionName,
            message: 'JsonResult has bad structure',
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    layoutNoReady(functionName) {
        return {
            functionName: functionName,
            message: 'Layout was not ready.',
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    noPermissions(functionName) {
        return {
            functionName: functionName,
            message: 'Permissions object is null.',
            timestamp: new Date(),
            type: LogType.GenericError
        };
    },
    noResources(functionName) {
        return {
            functionName: functionName,
            message: 'Resources object is null.',
            timestamp: new Date(),
            type: LogType.GenericError
        };
    }
};
export default LogData;
