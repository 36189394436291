import React from 'react';
import PropTypes from 'prop-types';
import ErrorTemplateBody from './ErrorTemplateBody';

var MaintenancePage = React.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.maintenance');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.maintenance.message');

    return React.createElement(ErrorTemplateBody, Object.assign({
        largeIllustrationName: props.narrow ? '503Narrow.png' : '503.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage
    }, props, {
        ref: ref
    }));
});

MaintenancePage.propTypes = {
    edsContext: PropTypes.object,
    narrow: PropTypes.bool
};

export default MaintenancePage;