var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import { Manager, Target, Popper } from 'react-popper';
import debounce from 'lodash/debounce';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withEDSContext } from '../EDSContext/EDSContext';
import IconButton from '../IconButton/IconButton';
import MoreVertical from '@eui/ds-icons/lib/MoreVertical';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { borderRadiusXSmall, borderRadiusSmall, borderWidthThin, borderWidthThick, spacingSmall, lineHeightParagraphMedium } from '../styles/tokens';

export var styles = function styles(theme) {
    return {
        root: {
            display: 'inline-block',
            '& .paper': {
                boxShadow: 'none'
            }
        },
        iconButton: {
            borderRadius: borderRadiusSmall,
            fontSize: '1.2rem',
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.focus
            },
            '&.color': {
                color: theme.palette.grey[500]
            },
            '&.color:hover': {
                background: 'none',
                color: theme.palette.ctaColor.base
            },
            '&.color:active': {
                background: 'none',
                color: theme.palette.ctaColor.base
            },
            '&.actionMenuOpen': {
                color: theme.palette.ctaColor.base
            },
            '&.actionMenuOpen:after': {
                position: 'absolute',
                width: '0.625rem',
                height: '0.625rem',
                top: 'auto',
                bottom: '-0.3rem',
                left: '1.05rem',
                content: '""',
                background: 'linear-gradient(-45deg, white 0%, white 54%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%)',
                borderRight: borderWidthThin + ' solid ' + theme.palette.grey[400],
                borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[400],
                transform: 'rotate(-135deg)',
                borderBottomRightRadius: borderRadiusXSmall,
                zIndex: theme.zIndex.actionMenu + 1
            },
            '&.actionMenuOpen.topPlacement:after': {
                bottom: 'auto',
                top: '-0.3rem',
                transform: 'rotate(45deg)'
            }
        },
        popperClose: {
            pointerEvents: 'none'
        },
        popperOpen: {
            zIndex: theme.zIndex.actionMenu
        },
        menuList: {
            maxWidth: '18.750rem',
            borderRadius: '' + borderRadiusXSmall,
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: 'none'
        },
        menuListItem: {
            // Since menu list item is pulling from MUI, have to override to match recommended list
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: lineHeightParagraphMedium,
            textOverflow: 'ellipsis',
            '&.padding': {
                padding: spacingSmall + ' 1.25rem',
                display: 'block'
            },
            '&:hover': {
                color: '' + theme.palette.grey[600],
                backgroundColor: theme.palette.grey[250]
            },
            '&:focus': {
                color: '' + theme.palette.grey[100],
                backgroundColor: theme.palette.action.hover
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.base
            }
        }
    };
};

/**
 * Use `ActionMenu` to give users access to additional actions related to a field.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  BasicActionMenuExamples
 *  PlacementActionMenuExamples
 */

var ActionMenu = function (_React$Component) {
    _inherits(ActionMenu, _React$Component);

    function ActionMenu(props) {
        _classCallCheck(this, ActionMenu);

        var _this = _possibleConstructorReturn(this, (ActionMenu.__proto__ || Object.getPrototypeOf(ActionMenu)).call(this, props));

        _this.handleToggle = function () {
            _this.setState({ open: !_this.state.open }, function () {
                if (_this.state.open) {
                    _this.registerEvents();
                } else {
                    _this.removeEvents();
                }

                // Queue it for execution from event queue as there is some timing issue.
                // TODO: Revisit this code later
                setTimeout(function () {
                    return _this.setInverted();
                });
            });
        };

        _this.handleClose = function (event, item) {
            if (_this.target.contains(event.target)) {
                return;
            }

            if (item) {
                item.callback(item);
            }

            _this.removeEvents();
            _this.setState({ open: false });
        };

        _this.popperRef = React.createRef();
        _this.findMenuPlacement = debounce(_this.findMenuPlacement.bind(_this), 0);
        _this.placements = ['top-start', 'top-end'];

        _this.state = {
            open: props.initialOpenOverride, // action menu is closed by default, unless an override is passed in
            invertedMenu: false
        };
        return _this;
    }

    _createClass(ActionMenu, [{
        key: 'registerEvents',
        value: function registerEvents() {
            window.addEventListener('resize', this.findMenuPlacement);
            window.addEventListener('scroll', this.findMenuPlacement, true);
        }
    }, {
        key: 'removeEvents',
        value: function removeEvents() {
            window.removeEventListener('resize', this.findMenuPlacement);
            window.removeEventListener('scroll', this.findMenuPlacement, true);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            // Making sure to remove listeners
            this.removeEvents();
        }
    }, {
        key: 'findMenuPlacement',
        value: function findMenuPlacement() {
            if (this.state.open) {
                this.setInverted();
            }
        }
    }, {
        key: 'setInverted',
        value: function setInverted() {
            var placement = this.popperRef.current.state.data.placement;

            this.setState({
                invertedMenu: this.placements.indexOf(placement) !== -1
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this,
                _classNames2;

            var _props = this.props,
                classes = _props.classes,
                actions = _props.actions,
                placement = _props.placement,
                menuId = _props.menuId,
                edsContext = _props.edsContext,
                icon = _props.icon;
            var _state = this.state,
                open = _state.open,
                invertedMenu = _state.invertedMenu;


            var popperPlacement = placement ? placement : edsContext.dir === 'rtl' ? 'bottom-start' : 'bottom-end';

            var menuItems = actions.map(function (item, index) {
                return React.createElement(
                    MenuItem,
                    {
                        onClick: function onClick(e) {
                            return _this2.handleClose(e, item);
                        },
                        key: uuid(),
                        tabIndex: index === 0 ? 0 : -1,
                        className: classes.menuListItem + ' padding' // Not sure if this is the way to increase the Specificity of css
                    },
                    item.label
                );
            });

            return React.createElement(
                'div',
                { className: classes.root },
                React.createElement(
                    Manager,
                    null,
                    React.createElement(
                        Target,
                        { className: 'menuTarget' },
                        React.createElement(
                            'div',
                            {
                                ref: function ref(node) {
                                    _this2.target = node;
                                },
                                className: 'menuTargetInner'
                            },
                            React.createElement(
                                Tooltip,
                                { title: edsContext.formatMessage('component.ActionMenu.more'), enterDelay: 1000, enterNextDelay: 1000 },
                                React.createElement(
                                    IconButton,
                                    {
                                        disableRipple: true,
                                        'aria-label': edsContext.formatMessage('component.ActionMenu.more'),
                                        'aria-expanded': open,
                                        'aria-haspopup': 'true',
                                        'aria-controls': menuId,
                                        onClick: this.handleToggle,
                                        color: 'gray',
                                        className: classNames(_defineProperty({}, classes.iconButton + ' color', true), { 'actionMenuOpen': open }, { 'topPlacement': invertedMenu }),
                                        tabIndex: 0
                                    },
                                    icon
                                )
                            )
                        )
                    ),
                    React.createElement(
                        Popper,
                        {
                            ref: this.popperRef,
                            placement: popperPlacement,
                            eventsEnabled: open,
                            className: classNames('eds-actionmenu-popper', (_classNames2 = {}, _defineProperty(_classNames2, classes.popperClose, !open), _defineProperty(_classNames2, classes.popperOpen, open), _classNames2))
                        },
                        React.createElement(
                            ClickAwayListener,
                            { onClickAway: this.handleClose },
                            React.createElement(
                                Grow,
                                { 'in': open, className: 'eds-action-menu', style: { transformOrigin: '0 0 0' } },
                                React.createElement(
                                    Paper,
                                    { className: classes.root + ' paper' },
                                    React.createElement(
                                        MenuList,
                                        { id: menuId, role: 'menu', className: classes.menuList },
                                        menuItems
                                    )
                                )
                            )
                        )
                    )
                )
            );
        }
    }]);

    return ActionMenu;
}(React.Component);

ActionMenu.propTypes = {
    /**
     * Classes used to extend original style object.
     */
    classes: PropTypes.object,

    /**
     * Actions and label for the actions to be taken from the `ActionMenu`.
     */
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,

    /**
     * @ignore
     */
    edsContext: PropTypes.object,

    /**
     * Icon used in the icon button.
     *
     * Defaults to 'more-vertical' icon.
     */
    icon: PropTypes.node,

    /**
     * Menu HTML ID for uniqueness.
     */
    menuId: PropTypes.string,

    /**
     * Placement is used to place the menu at start or end of the container or screen.
     *
     * Defaults to 'bottom-end' for LTR or 'bottom-start' for RTL.
     */
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start']),

    /**
     * @ignore
     *
     * Externally set the initial open state of the action menu. For testing purposes only.
     */
    initialOpenOverride: PropTypes.bool

};

ActionMenu.defaultProps = {
    initialOpenOverride: false,
    icon: React.createElement(MoreVertical, null)
};

export default withEDSContext(withStyles(styles)(ActionMenu));