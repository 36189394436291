import React from 'react';
import PropTypes from 'prop-types';
import Typography from './../../Typography';
import { cdn } from './../../EDSApplication';
import { withStyles } from './../../styles';

import ErrorPageAction from './ErrorPageAction';

import { spacingSmall, spacingMedium } from './../../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {},
        errorTemplate: {},
        noHeader: {},
        illustration: {
            width: '100%',
            objectFit: 'contain'
        },
        errorDetails: {
            textAlign: 'center',
            padding: spacingSmall,
            '& h2': {
                marginBottom: spacingMedium
            },
            '& a': {
                marginTop: spacingSmall,
                display: 'inline-block'
            }
        }
    };
};

var ErrorTemplateBody = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        largeIllustrationName = props.largeIllustrationName,
        errorTitle = props.errorTitle,
        errorMessage = props.errorMessage,
        message = props.message,
        imageSrc = props.imageSrc,
        hasAction = props.hasAction,
        action = props.action,
        text = props.text;

    var illustrationUrl = cdn.getLargeIllustrationsUrl(largeIllustrationName);

    return React.createElement(
        'div',
        { ref: ref },
        React.createElement('img', { className: classes.illustration,
            src: imageSrc || illustrationUrl,
            alt: ''
        }),
        React.createElement(
            'div',
            { className: classes.errorDetails },
            React.createElement(
                Typography,
                { variant: 'h2' },
                errorTitle
            ),
            React.createElement(
                Typography,
                null,
                message || errorMessage
            ),
            hasAction && React.createElement(ErrorPageAction, { action: action, text: text })
        )
    );
});

ErrorTemplateBody.propTypes = {
    largeIllustrationName: PropTypes.string,
    classes: PropTypes.object,
    errorTitle: PropTypes.string,
    errorMessage: PropTypes.string,
    message: PropTypes.string,
    imageSrc: PropTypes.string,
    text: PropTypes.string,
    hasAction: PropTypes.bool,
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default withStyles(styles)(ErrorTemplateBody);