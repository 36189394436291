var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import EdsDialog, { DialogActions as EdsDialogActions, DialogContent as EdsDialogContent, DialogTitle as EdsDialogTitle } from '@hedtech/react-design-system/core/es/Dialog';
import withMobileDialog from '@hedtech/react-design-system/core/es/withMobileDialog';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    modalContent: {
        padding: Tokens.spacing40
    }
});
const Modal = (props) => {
    const { children, classes, disableHeaderTypography, disableSpacing, footer, header, id, noContentPadding } = props, rest = __rest(props, ["children", "classes", "disableHeaderTypography", "disableSpacing", "footer", "header", "id", "noContentPadding"]);
    let modalHeader;
    if (header) {
        modalHeader = (React.createElement(EdsDialogTitle, { disableTypography: disableHeaderTypography, id: `title${id}` }, header));
    }
    let modalBody;
    if (children) {
        modalBody = (React.createElement(EdsDialogContent, { classes: { root: noContentPadding ? undefined : classes.modalContent } }, children));
    }
    let modalFooter;
    if (footer) {
        modalFooter = (React.createElement(EdsDialogActions, { disableSpacing: disableSpacing }, footer));
    }
    return (React.createElement(EdsDialog, Object.assign({ "aria-labelledby": `title${id}`, id: id }, rest),
        modalHeader,
        modalBody,
        modalFooter));
};
export default withMobileDialog()(withStyles(styles)(Modal));
