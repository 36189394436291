import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup as MuiFormGroup } from '@material-ui/core';
import { default as withStyles } from '../styles';

export var styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    row: {
        flexDirection: 'row'
    }
};

/**
 * Wraps controls such as Checkbox and Switch, with compact and changeable layout.
 */
var FormGroup = React.forwardRef(function (props, ref) {
    return React.createElement(MuiFormGroup, Object.assign({}, props, { ref: ref }));
});

FormGroup.muiName = 'FormGroup';
FormGroup.displayName = 'FormGroup';

FormGroup.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Display group of elements in a compact row.
     */
    row: PropTypes.bool
};

FormGroup.defaultProps = {
    row: false
};

export default withStyles(styles)(FormGroup);