import React from 'react';

var SvgError = function SvgError(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("circle", { fill: "currentColor", cx: "30", cy: "30", r: "30" }),
            React.createElement("circle", { fill: "#FFFFFF", opacity: "0.88", cx: "30", cy: "30", r: "30" }),
            React.createElement("path", {
                d: "M30,3.87096774 C44.3493145,3.87096774 56.1290323,15.4929435 56.1290323,30 C56.1290323,44.4303629 44.4423387,56.1290323 30,56.1290323 C15.5753226,56.1290323 3.87096774,44.4481452 3.87096774,30 C3.87096774,15.5802823 15.5566935,3.87096774 30,3.87096774 M30,0 C13.432621,0 0,13.4374597 0,30 C0,46.5722177 13.432621,60 30,60 C46.567379,60 60,46.5722177 60,30 C60,13.4374597 46.567379,0 30,0 Z M28.6100806,14.516129 L31.3897984,14.516129 C32.2151613,14.516129 32.8745565,15.2034677 32.8402016,16.0282258 L31.9934274,36.3508065 C31.9610081,37.1283871 31.3213306,37.7419355 30.5430242,37.7419355 L29.4568548,37.7419355 C28.6786694,37.7419355 28.038871,37.1282661 28.0064516,36.3508065 L27.1596774,16.0282258 C27.1254435,15.2034677 27.7847177,14.516129 28.6100806,14.516129 Z M30,40.1612903 C28.1293548,40.1612903 26.6129032,41.6777419 26.6129032,43.5483871 C26.6129032,45.4190323 28.1293548,46.9354839 30,46.9354839 C31.8706452,46.9354839 33.3870968,45.4190323 33.3870968,43.5483871 C33.3870968,41.6777419 31.8706452,40.1612903 30,40.1612903 Z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgError;