function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiChip } from '@material-ui/core/Chip';
import CloseIcon from '@eui/ds-icons/lib/Close';

import { borderWidthThick, borderWidthThin, colorBrandSecondary, fontSizeDefault, borderRadiusCircle, sizingSmall, spacingSmall, spacingXSmall, spacingXSmall2, spacingXxSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[100],
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            color: theme.palette.grey[500],
            fontSize: fontSizeDefault
        },
        activatedRoot: {
            backgroundColor: theme.palette.ctaColor.tint,
            border: borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
            color: theme.palette.ctaColor.base,
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.tint
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.tint
            }
        },
        label: {
            paddingLeft: spacingSmall,
            paddingRight: spacingSmall,
            paddingBottom: '0.125rem' // Push label up by 2px to perfectly center
        },
        labelWithIcon: {
            paddingRight: spacingXSmall2
        },
        clickable: {
            WebkitTapHighlightColor: 'transparent', // Remove grey highlight
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            },
            '&:focus': {
                backgroundColor: theme.palette.grey[100],
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBrandSecondary
            },
            '&:active': {
                backgroundColor: theme.palette.grey[300],
                boxShadow: 'none'
            },
            '&:active:focus': {
                boxShadow: 'none',
                backgroundColor: theme.palette.grey[300]
            },
            '&$activatedRoot': {
                backgroundColor: theme.palette.ctaColor.tint,
                border: borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
                color: theme.palette.ctaColor.base,
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.tint
                },
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.tint,
                    boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBrandSecondary
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: theme.palette.ctaColor.tint
                }
            }
        },
        deletable: {
            '&:focus': {
                backgroundColor: theme.palette.grey[100],
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBrandSecondary
            },
            '&$activatedRoot': {
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.tint,
                    boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBrandSecondary
                }
            }
        },
        deleteIcon: {
            boxSizing: 'initial', // required to fix stylistic differences between storybook and doc site
            margin: '0 0.3125rem 0 -' + spacingXSmall,
            height: sizingSmall,
            width: sizingSmall,
            color: theme.palette.grey[500],
            fill: theme.palette.grey[500],
            borderRadius: borderRadiusCircle,
            padding: spacingXxSmall,
            '&:hover': {
                backgroundColor: theme.palette.grey[300]
            },
            '&:active': {
                backgroundColor: theme.palette.grey[400]
            }
        }
    };
};

/**
 * Use `Chip` to represent a selected item or filter.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  ChipExamples
 *  ChipActions
 *  ChipGroup
 *  ListActivatedChips
 *  SelectedContentWithChips
 */
var Chip = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        activated = props.activated,
        rest = _objectWithoutProperties(props, ['classes', 'activated']);

    return React.createElement(MuiChip, Object.assign({
        classes: {
            root: cn(classes.root, _defineProperty({}, classes.activatedRoot, activated)),
            label: cn(classes.label, _defineProperty({}, classes.labelWithIcon, props.onDelete)),
            clickable: classes.clickable,
            deletable: classes.deletable,
            deleteIcon: classes.deleteIcon
        },
        deleteIcon: React.createElement(CloseIcon, null)
    }, rest, {
        ref: ref
    }));
});

Chip.muiName = 'Chip';

Chip.propTypes = {
    /**
     * Set the chip to active, using the theme CTA color.
     */
    activated: PropTypes.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If true, the chip will appear clickable, and will raise when pressed,
     * even if the onClick property is not defined. This can be used, for example,
     * along with the component property to indicate an anchor Chip is clickable.
     */
    clickable: PropTypes.bool,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * The content of the `Chip` label.
     */
    label: PropTypes.node,
    /**
     * Callback to fire when the `Chip` is clicked on or `space` or `enter` keys are pressed when the Chip has focus.
     */
    onClick: PropTypes.func,
    /**
     * Callback function fired when the delete icon is clicked or `backspace` key is pressed when the `Chip` has focus.
     * If set, the delete icon will be shown.
     */
    onDelete: PropTypes.func,
    /**
     * @ignore
     */
    onKeyDown: PropTypes.func,
    /**
     * @ignore
     */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Chip.displayName = 'Chip';

export default withStyles(styles)(Chip);