var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

// Default imports
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// Internal components and functions
import Button from '../Button';
import DatePicker from '../DatePicker';
import Divider from '../Divider';
import Dropdown from '../Dropdown';
import DropdownItem from '../Dropdown/DropdownItem';
import FormControl from '../FormControl';
import Grid from '../Grid';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import { CalendarContext } from './Calendar';
import { withEDSContext } from '../EDSContext/EDSContext';
import withStyles from '../styles';
import { sizingSmall, widthFluid } from '../styles/tokens';

// DS Icons imports
import Calendar from '@eui/ds-icons/lib/Calendar';
import ChevronLeft from '@eui/ds-icons/lib/ChevronLeft';
import ChevronRight from '@eui/ds-icons/lib/ChevronRight';

// Utilities
import Popper from '@material-ui/core/Popper';
import moment from 'moment';
import classNames from 'classnames';

// create constants for navigation used in react-big-calendar
export var navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

var styles = function styles(theme) {
    return {
        alignLabel: {
            alignSelf: 'center'
        },
        iconRoot: {
            height: sizingSmall,
            width: sizingSmall
        },
        iconButtonMargin: {
            margin: '0 0.125rem'
        },
        toolbar: {
            minWidth: '6.25rem'
        },
        datePickerPosition: {
            display: 'flex'
        },
        gridMarginOverride: {
            margin: 0,
            width: widthFluid
        },
        popperIndex: {
            zIndex: 5
        },
        todayButton: {
            flex: 1
        }
    };
};

var CalendarToolbar = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        localizer = props.localizer,
        label = props.label,
        edsContext = props.edsContext,
        onNavigate = props.onNavigate,
        onView = props.onView,
        views = props.views;

    // Initialize states using useState hook

    var _useState = useState(null),
        _useState2 = _slicedToArray(_useState, 2),
        anchorEl = _useState2[0],
        setAnchorEl = _useState2[1];

    var _useState3 = useState(false),
        _useState4 = _slicedToArray(_useState3, 2),
        focused = _useState4[0],
        setFocused = _useState4[1];

    var _useState5 = useState(false),
        _useState6 = _slicedToArray(_useState5, 2),
        open = _useState6[0],
        setOpen = _useState6[1];

    var messages = localizer.messages;

    var _useContext = useContext(CalendarContext),
        date = _useContext.date,
        setDate = _useContext.setDate,
        showViewNames = _useContext.showViewNames,
        selectedView = _useContext.selectedView,
        setSelectedView = _useContext.setSelectedView,
        width = _useContext.width;

    var handleClick = function handleClick(event) {
        var currentTarget = event.currentTarget;

        setAnchorEl(currentTarget);
        setOpen(true);
    };

    var handleClickAway = function handleClickAway() {
        setOpen(false);
    };

    var handleDateChange = function handleDateChange(selectedDate) {
        setOpen(false);
        setDate(selectedDate.toDate());
    };

    var handleFocusChange = function handleFocusChange() {
        setFocused(true);
    };

    var handleNavigation = function handleNavigation(action) {
        onNavigate(action);
    };

    var handleViewChange = function handleViewChange(event) {
        var value = event.target.value;

        onView(value);
        setSelectedView(value);
    };

    var sentenceCase = function sentenceCase(message) {
        return message.toLowerCase().split(' ').map(function (word, index) {
            return word.replace(word[0], index > 0 ? word[0].toLowerCase() : word[0].toUpperCase());
        }).join(' ');
    };

    var dropdownControl = views.length >= 1 && React.createElement(
        Grid,
        { className: 'rbc-btn-group', item: true },
        React.createElement(
            FormControl,
            { className: classes.toolbar },
            React.createElement(
                Dropdown,
                {
                    label: '',
                    onChange: handleViewChange,
                    value: selectedView,
                    size: 'small'
                },
                views.map(function (name) {
                    return React.createElement(DropdownItem, {
                        key: name,
                        label: sentenceCase(messages[name]),
                        value: name
                    });
                })
            )
        )
    );

    var datePickerControl = React.createElement(
        Grid,
        { item: true },
        React.createElement(
            Grid,
            {
                classes: {
                    'spacing-xs-2': classes.gridMarginOverride,
                    'spacing-xs-3': classes.gridMarginOverride
                },
                container: true,
                direction: 'row',
                justify: 'flex-end',
                spacing: 0
            },
            React.createElement(
                Grid,
                { item: true },
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.CalendarToolBar.openDatePicker'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            'aria-label': edsContext.formatMessage('component.CalendarToolBar.openDatePicker'),
                            color: 'gray',
                            onClick: handleClick,
                            'aria-haspopup': 'true'
                        },
                        React.createElement(Calendar, null)
                    )
                ),
                React.createElement(
                    Popper,
                    {
                        anchorEl: anchorEl,
                        className: classes.popperIndex,
                        disablePortal: true,
                        open: open,
                        placement: 'bottom-end'
                    },
                    React.createElement(DatePicker, {
                        date: moment(date),
                        focused: focused,
                        id: 'CalendarToolbar__StandaloneDatePicker',
                        onDateChange: handleDateChange,
                        onFocusChange: handleFocusChange,
                        onOutsideClick: handleClickAway,
                        variant: 'standalone'
                    })
                )
            )
        )
    );

    var labelNavigation = React.createElement(
        Grid,
        { dir: edsContext.dir, item: true },
        React.createElement(
            Grid,
            {
                classes: {
                    'spacing-xs-2': classes.gridMarginOverride,
                    'spacing-xs-3': classes.gridMarginOverride
                },
                container: true,
                direction: 'row',
                justify: 'flex-start',
                spacing: 0,
                wrap: 'nowrap'
            },
            React.createElement(
                Grid,
                { item: true },
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Calendar.previous'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            color: 'gray',
                            className: classes.iconButtonMargin,
                            onClick: function onClick() {
                                return handleNavigation(navigate.PREVIOUS);
                            },
                            'aria-label': edsContext.formatMessage('component.Calendar.previous')
                        },
                        edsContext.dir === 'rtl' ? React.createElement(ChevronRight, { className: classes.iconRoot }) : React.createElement(ChevronLeft, { className: classes.iconRoot })
                    )
                )
            ),
            React.createElement(
                Grid,
                { className: classes.alignLabel, item: true },
                React.createElement(
                    Typography,
                    { variant: 'body1', component: 'div', className: 'hedtech-calendar-date-title' },
                    label
                )
            ),
            React.createElement(
                Grid,
                { item: true },
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Calendar.next'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            color: 'gray',
                            className: classes.iconButtonMargin,
                            onClick: function onClick() {
                                return handleNavigation(navigate.NEXT);
                            },
                            'aria-label': edsContext.formatMessage('component.Calendar.next')
                        },
                        edsContext.dir === 'rtl' ? React.createElement(ChevronLeft, { className: classes.iconRoot }) : React.createElement(ChevronRight, { className: classes.iconRoot })
                    )
                )
            )
        )
    );

    var todayButton = React.createElement(
        Grid,
        {
            className: classNames(classes.alignLabel, classes.todayButton),
            item: true
        },
        React.createElement(
            Button,
            {
                onClick: function onClick() {
                    return handleNavigation(navigate.TODAY);
                },
                variant: 'text'
            },
            messages.today
        )
    );

    return React.createElement(
        Grid,
        {
            classes: {
                'spacing-xs-2': classes.gridMarginOverride,
                'spacing-xs-3': classes.gridMarginOverride
            },
            className: 'calendar-toolbar',
            container: true,
            direction: 'column',
            spacing: 0,
            wrap: 'nowrap',
            ref: ref
        },
        React.createElement(
            Grid,
            { item: true },
            React.createElement(
                Grid,
                {
                    dir: edsContext.dir,
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    direction: 'row',
                    justify: showViewNames ? 'space-between' : 'flex-end',
                    spacing: 2
                },
                todayButton,
                showViewNames && width !== 'xs' && dropdownControl,
                datePickerControl
            )
        ),
        width === 'xs' && React.createElement(
            Grid,
            { item: true },
            React.createElement(
                Grid,
                {
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    justify: 'center',
                    spacing: 2
                },
                showViewNames && width === 'xs' && dropdownControl
            )
        ),
        React.createElement(
            Grid,
            { item: true },
            React.createElement(Divider, {
                className: classes.divider,
                style: { margin: '0 1rem' },
                variant: 'middle'
            })
        ),
        React.createElement(
            Grid,
            { item: true },
            React.createElement(
                Grid,
                {
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    justify: 'center',
                    spacing: 2
                },
                labelNavigation
            )
        )
    );
});

CalendarToolbar.propTypes = {
    /**
     * JSS classes object.
     */
    classes: PropTypes.object,
    /**
     * The active view to display.
     */
    view: PropTypes.string.isRequired,
    /**
     * An array of views that is sent by react-big-calendar.
     */
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Callback function for updating the current Date based on the selection
     * from DatePicker
     */
    onDateChange: PropTypes.func,
    /**
     * The node to render the date range label.
     */
    label: PropTypes.node.isRequired,
    /**
     * Internal localizer function used by react-big-calendar.
     */
    localizer: PropTypes.object,
    /**
     * @ignore
     */
    onNavigate: PropTypes.func.isRequired,
    /**
     * @ignore
     */
    onView: PropTypes.func.isRequired
};

CalendarToolbar.displayName = 'CalendarToolbar';

export default withEDSContext(withStyles(styles)(CalendarToolbar));