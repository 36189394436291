var _responsiveSpacingNon;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { spacingClasses } from './globalSpacing.js';

import { spacing40, spacing50, layout10, layout20, spacingInsetTall30, spacingInsetTall40, spacingInsetTall50 } from '../styles/tokens';

var commonMobileStyles = {
    display: 'grid',
    padding: spacing40,
    gridGap: layout10
};

var responsiveSpacingNoneStyles = (_responsiveSpacingNon = {}, _defineProperty(_responsiveSpacingNon, '&.' + spacingClasses.SPACING_ROW_NONE_CLASS_NAME, {
    gridRowGap: 0,
    paddingTop: 0,
    paddingBottom: 0
}), _defineProperty(_responsiveSpacingNon, '&.' + spacingClasses.SPACING_COLUMN_NONE_CLASS_NAME, {
    gridColumnGap: 0,
    paddingLeft: 0,
    paddingRight: 0
}), _responsiveSpacingNon);

var useStyles = makeStyles(function (theme) {
    var _theme$breakpoints$up, _ref, _Object$assign2, _ref2, _global;

    return {
        '@global': (_global = {}, _defineProperty(_global, '.' + spacingClasses.RESET_SPACING_CLASS_NAME, (_ref = {
            padding: '0',
            margin: '0'
        }, _defineProperty(_ref, '&.' + spacingClasses.RESET_SPACING_RIGHT_LEFT_STANDARD_CLASS_NAME, {
            marginLeft: '-' + spacing40,
            marginRight: '-' + spacing40
        }), _defineProperty(_ref, '&.' + spacingClasses.RESET_SPACING_ALL_STANDARD_CLASS_NAME, {
            margin: '-' + spacing40
        }), _defineProperty(_ref, theme.breakpoints.up('lg'), (_theme$breakpoints$up = {}, _defineProperty(_theme$breakpoints$up, '&.' + spacingClasses.RESET_SPACING_RIGHT_LEFT_STANDARD_CLASS_NAME, {
            marginLeft: '-' + spacing50,
            marginRight: '-' + spacing50
        }), _defineProperty(_theme$breakpoints$up, '&.' + spacingClasses.RESET_SPACING_ALL_STANDARD_CLASS_NAME, {
            margin: '-' + spacing50
        }), _theme$breakpoints$up)), _ref)), _defineProperty(_global, 'html body .' + spacingClasses.OUTER_SPACING_NONE_CLASS_NAME, {
            padding: '0 !important'
        }), _defineProperty(_global, '.' + spacingClasses.SPACING_NONE_CLASS_NAME, {
            display: 'grid',
            padding: 0,
            margin: 0,
            gridGap: 0
        }), _defineProperty(_global, 'html .' + spacingClasses.SPACING_STANDARD_CLASS_NAME, Object.assign({}, commonMobileStyles)), _defineProperty(_global, '.' + spacingClasses.SPACING_RESPONSIVE_CLASS_NAME, (_ref2 = {}, _defineProperty(_ref2, '&.' + spacingClasses.CONTAINER_CLASS_NAME, Object.assign(_defineProperty({}, theme.breakpoints.up('lg'), _defineProperty({}, '&.' + spacingClasses.SPACING_STANDARD_CLASS_NAME, {
            padding: spacing50,
            gridGap: layout20
        })), responsiveSpacingNoneStyles)), _defineProperty(_ref2, '&.' + spacingClasses.TEMPLATE_CLASS_NAME, Object.assign((_Object$assign2 = {
            padding: spacingInsetTall30,
            gridGap: layout20
        }, _defineProperty(_Object$assign2, theme.breakpoints.between('sm', 'md'), _defineProperty({}, '&.' + spacingClasses.SPACING_STANDARD_CLASS_NAME, {
            padding: spacingInsetTall40,
            gridGap: layout20
        })), _defineProperty(_Object$assign2, theme.breakpoints.up('lg'), _defineProperty({}, '.' + spacingClasses.SPACING_STANDARD_CLASS_NAME, {
            padding: spacingInsetTall50,
            gridGap: layout20
        })), _Object$assign2), responsiveSpacingNoneStyles)), _ref2)), _defineProperty(_global, '.' + spacingClasses.LAYOUT_CLASS_NAME, Object.assign(_defineProperty({}, '&.' + spacingClasses.SPACING_STANDARD_CLASS_NAME, {
            padding: 0,
            gridGap: layout20
        }), responsiveSpacingNoneStyles)), _defineProperty(_global, '.' + spacingClasses.SPACING_NON_RESPONSIVE_CLASS_NAME, _defineProperty({}, '&.' + spacingClasses.SPACING_STANDARD_CLASS_NAME, {
            padding: spacing50,
            gridGap: layout20
        })), _global)
    };
}, { index: 2 });

function CssGlobalSpacing(props) {
    var _props$children = props.children,
        children = _props$children === undefined ? null : _props$children;

    useStyles();
    return React.createElement(
        React.Fragment,
        null,
        children
    );
}

CssGlobalSpacing.propTypes = {
    /**
     * You can wrap a node.
     */
    children: PropTypes.node
};

export default CssGlobalSpacing;