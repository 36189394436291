import React from 'react';
import Grid from '../../core/Grid';
import Icon from '../../core/Icon';
import IconButton from '../../core/IconButton';
import Tooltip from '../../core/Tooltip';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import Popper from '../../core/Popper';
import Format from '../../../helpers/Format';
import LogData from '../../../helpers/LogData';
import PasswordValidation from '../../../helpers/PasswordValidation';
import Resolver from '../../../helpers/Resolver';
import Tokens from '../../core/styles/Tokens';
import { createStyles, withStyles } from '../../core/styles/withStyles';
import RequestsLayout from '../../../requests/LayoutRequests';
import Requests from '../../../requests/PasswordConfirmationRequests';
import LayoutActions from '../../../flux/actions/LayoutActions';
import LayoutStore from '../../../flux/stores/LayoutStore';
import Actions from '../../../flux/actions/PasswordConfirmationActions';
import Store from '../../../flux/stores/PasswordConfirmationStore';
const styles = () => createStyles({
    passwordMargin: {
        height: `${Tokens.sizingXLarge}${Tokens.important}`,
        width: `${Tokens.sizingXLarge}${Tokens.important}`
    }
});
class PasswordConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeConfirmPassword = (event) => {
            try {
                const { accountValidations, password } = this.state;
                const value = event.target.value;
                const validations = PasswordValidation.validateConfirmPassword(accountValidations, value, password);
                Actions.setPasswordValidation(password, validations);
            }
            catch (e) {
                this.logError(LogData.fromException(this.onChangeConfirmPassword.name, e));
            }
        };
        this.onChangePassword = (event) => {
            try {
                const { accountValidations, passwordPolicy } = this.state;
                const value = event.target.value;
                const validations = PasswordValidation.validatePassword(accountValidations, value, passwordPolicy);
                Actions.setPasswordValidation(value, validations);
            }
            catch (e) {
                this.logError(LogData.fromException(this.onChangePassword.name, e));
            }
        };
        this.onCloseInfo = () => {
            try {
                this.setState({
                    anchorInfo: null
                });
            }
            catch (e) {
                this.logError(LogData.fromException(this.onCloseInfo.name, e));
            }
        };
        this.onOpenInfo = (event) => {
            try {
                this.setState({
                    anchorInfo: event.currentTarget
                });
            }
            catch (e) {
                this.logError(LogData.fromException(this.onOpenInfo.name, e));
            }
        };
        this.onSetPasswordValidation = () => {
            this.getPasswordValidation();
        };
        this.getPasswordValidation = () => {
            try {
                this.setState({
                    accountValidations: Store.getPasswordValidation(),
                    password: Store.getPassword()
                });
            }
            catch (e) {
                this.logError(LogData.fromException(this.getPasswordValidation.name, e));
            }
        };
        this.overwriteResources = (resources) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            const { resourcesToOverwrite } = this.props;
            if (resources && resourcesToOverwrite) {
                resources.formatPasswordMinLength = (_a = resourcesToOverwrite.formatPasswordMinLength) !== null && _a !== void 0 ? _a : resources.formatPasswordMinLength;
                resources.formatYourMinimumChar = (_b = resourcesToOverwrite.formatYourMinimumChar) !== null && _b !== void 0 ? _b : resources.formatYourMinimumChar;
                resources.lblConfirmPassword = (_c = resourcesToOverwrite.lblConfirmPassword) !== null && _c !== void 0 ? _c : resources.lblConfirmPassword;
                resources.lblConfirmPasswordRequired = (_d = resourcesToOverwrite.lblConfirmPasswordRequired) !== null && _d !== void 0 ? _d : resources.lblConfirmPasswordRequired;
                resources.lblInformation = (_e = resourcesToOverwrite.lblInformation) !== null && _e !== void 0 ? _e : resources.lblInformation;
                resources.lblPassword = (_f = resourcesToOverwrite.lblPassword) !== null && _f !== void 0 ? _f : resources.lblPassword;
                resources.lblPasswordDigits = (_g = resourcesToOverwrite.lblPasswordDigits) !== null && _g !== void 0 ? _g : resources.lblPasswordDigits;
                resources.lblPasswordLowerCase = (_h = resourcesToOverwrite.lblPasswordLowerCase) !== null && _h !== void 0 ? _h : resources.lblPasswordLowerCase;
                resources.lblPasswordMust = (_j = resourcesToOverwrite.lblPasswordMust) !== null && _j !== void 0 ? _j : resources.lblPasswordMust;
                resources.lblPasswordRequired = (_k = resourcesToOverwrite.lblPasswordRequired) !== null && _k !== void 0 ? _k : resources.lblPasswordRequired;
                resources.lblPasswordSymbols = (_l = resourcesToOverwrite.lblPasswordSymbols) !== null && _l !== void 0 ? _l : resources.lblPasswordSymbols;
                resources.lblPasswordUpperCase = (_m = resourcesToOverwrite.lblPasswordUpperCase) !== null && _m !== void 0 ? _m : resources.lblPasswordUpperCase;
                resources.lblPasswordsNotMatch = (_o = resourcesToOverwrite.lblPasswordsNotMatch) !== null && _o !== void 0 ? _o : resources.lblPasswordsNotMatch;
                resources.lblYourPasswordMust = (_p = resourcesToOverwrite.lblYourPasswordMust) !== null && _p !== void 0 ? _p : resources.lblYourPasswordMust;
                resources.lblYourPwdLowerCase = (_q = resourcesToOverwrite.lblYourPwdLowerCase) !== null && _q !== void 0 ? _q : resources.lblYourPwdLowerCase;
                resources.lblYourPwdNumberChar = (_r = resourcesToOverwrite.lblYourPwdNumberChar) !== null && _r !== void 0 ? _r : resources.lblYourPwdNumberChar;
                resources.lblYourPwdSpecialChar = (_s = resourcesToOverwrite.lblYourPwdSpecialChar) !== null && _s !== void 0 ? _s : resources.lblYourPwdSpecialChar;
                resources.lblYourPwdUpperCase = (_t = resourcesToOverwrite.lblYourPwdUpperCase) !== null && _t !== void 0 ? _t : resources.lblYourPwdUpperCase;
            }
            return resources;
        };
        this.hideAllLoaders = () => {
            this.setState({
                isLoading: false
            });
        };
        this.resolveGetPasswordPolicy = (json) => {
            try {
                const { accountValidations, password } = this.state;
                const result = Resolver(json, this.resolveGetPasswordPolicy.name, this.hideAllLoaders);
                if (result === null || result === void 0 ? void 0 : result.status) {
                    const passwordPolicy = result.data;
                    if (passwordPolicy) {
                        accountValidations.hasPasswordPolicy = true;
                    }
                    this.setState({
                        accountValidations: accountValidations,
                        passwordPolicy: passwordPolicy
                    }, this.hideAllLoaders);
                    Store.setPasswordPolicy(passwordPolicy);
                    Actions.setPasswordValidation(password, accountValidations);
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.resolveGetPasswordPolicy.name, e));
            }
        };
        this.resolveGetResources = (json) => {
            try {
                const result = Resolver(json, this.resolveGetResources.name, this.hideAllLoaders);
                if (result === null || result === void 0 ? void 0 : result.status) {
                    this.setState({
                        resources: this.overwriteResources(result.data)
                    });
                    LayoutStore.setResourcesByKey(`${this.idModule}.${this.idPage}`, result.data);
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.resolveGetResources.name, e));
            }
        };
        this.idModule = 'Generic';
        this.idPage = 'PasswordConfirmation';
        this.state = this.getInitialState();
        Store.addPasswordValidationListener(this.onSetPasswordValidation);
    }
    getInitialState() {
        let isLoading = true;
        let resources;
        if (this.state) {
            isLoading = this.state.isLoading;
            resources = this.overwriteResources(this.state.resources);
        }
        else {
            resources = this.overwriteResources(LayoutStore.getResourcesByKey(`${this.idModule}.${this.idPage}`));
        }
        return {
            accountValidations: Store.getPasswordValidation(),
            anchorInfo: null,
            isLoading: isLoading,
            password: Store.getPassword(),
            passwordPolicy: Store.getPasswordPolicy(),
            resources: resources
        };
    }
    logError(logData) {
        this.hideAllLoaders();
        LayoutActions.setLogData(logData);
    }
    componentDidMount() {
        try {
            const { data, route } = this.props;
            const { passwordPolicy, resources } = this.state;
            if (!resources) {
                RequestsLayout.getResources(this.idModule, this.idPage, this.resolveGetResources);
            }
            if (data && route && !passwordPolicy) {
                Requests.getPasswordPolicy(route, data, this.resolveGetPasswordPolicy);
            }
            else {
                this.hideAllLoaders();
            }
            Actions.setPasswordValidation(this.state.password, this.state.accountValidations);
        }
        catch (e) {
            this.logError(LogData.fromException(this.componentDidMount.name, e));
        }
    }
    componentWillUnmount() {
        Store.removePasswordValidationListener(this.onSetPasswordValidation);
    }
    render() {
        const { bottomElements, classes, disabled, id, topElements, onEnterPress } = this.props;
        const { accountValidations, anchorInfo, isLoading, password, passwordPolicy, resources } = this.state;
        let contentPage;
        if (!isLoading && resources) {
            let errorPassword = false;
            if (accountValidations.passwordModified) {
                if (passwordPolicy) {
                    errorPassword = Boolean(accountValidations.isPwdLowerCase
                        || accountValidations.isPwdUpperCase
                        || accountValidations.isPwdSpecialChar
                        || accountValidations.isPwdNumberChar
                        || accountValidations.isMinimumChar
                        || accountValidations.isNotPreviousPassword
                        || accountValidations.isCurrentPwdNewPwd);
                }
                else {
                    errorPassword = !Boolean(password);
                }
            }
            let errorConfirmPassword = false;
            let errorTextConfirmPassword;
            if (accountValidations.confirmPasswordModified) {
                errorConfirmPassword = !Boolean(accountValidations.confirmPassword)
                    || accountValidations.confirmPassword !== password;
                errorTextConfirmPassword = !Boolean(accountValidations.confirmPassword) ?
                    resources.lblConfirmPasswordRequired
                    : (accountValidations.confirmPassword !== password ?
                        resources.lblPasswordsNotMatch : undefined);
            }
            contentPage = (React.createElement(Grid, { container: true, spacing: 2 },
                topElements ? (React.createElement(React.Fragment, null, topElements.map((element, iElement) => (React.createElement(Grid, { item: true, xs: 12, key: `${id}TopElement${iElement}` },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 11 }, element),
                        passwordPolicy && (React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement("div", { className: classes.passwordMargin }))))))))) : undefined,
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 11 },
                            React.createElement(TextField, { autoComplete: "new-password", disabled: disabled, error: errorPassword, id: `txt${id}Password`, helperText: (React.createElement(React.Fragment, null, errorPassword && (React.createElement(React.Fragment, null, passwordPolicy ?
                                    (React.createElement(Grid, { container: true },
                                        React.createElement(Grid, { item: true },
                                            !accountValidations.isCurrentPwdNewPwd && (React.createElement(Text, { size: "inherit" }, resources.lblPasswordMust)),
                                            accountValidations.isPwdLowerCase && (React.createElement(Text, { size: "inherit" }, resources.lblPasswordLowerCase)),
                                            accountValidations.isPwdUpperCase && (React.createElement(Text, { size: "inherit" }, resources.lblPasswordUpperCase)),
                                            accountValidations.isPwdSpecialChar && (React.createElement(Text, { size: "inherit" }, resources.lblPasswordSymbols)),
                                            accountValidations.isPwdNumberChar && (React.createElement(Text, { size: "inherit" }, resources.lblPasswordDigits)),
                                            accountValidations.isMinimumChar && (React.createElement(Text, { size: "inherit" }, Format.toString(resources.formatPasswordMinLength, [passwordPolicy === null || passwordPolicy === void 0 ? void 0 : passwordPolicy.minimumLength]))),
                                            accountValidations.isNotPreviousPassword && (React.createElement(Text, { size: "inherit" }, Format.toString(resources.formatNotPreviousPassword, [passwordPolicy === null || passwordPolicy === void 0 ? void 0 : passwordPolicy.previousPwdNumber]))),
                                            accountValidations.isCurrentPwdNewPwd && (React.createElement(Text, { size: "inherit" }, resources.lblCurrentPwdNewPwdError))))) : resources.lblPasswordRequired)))), label: resources.lblPassword, maxCharacters: 30, passwordToggle: true, required: true, type: "password", value: password, onChange: this.onChangePassword, onEnterPress: onEnterPress })),
                        passwordPolicy && (React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(Tooltip, { id: `tlt${id}PassPolicyInformation`, title: resources.lblInformation, placement: "top" },
                                React.createElement(IconButton, { "aria-label": resources.lblInformation, color: "gray", id: `btn${id}PassPolicyInformation`, onClick: this.onOpenInfo },
                                    React.createElement(Icon, { name: "info", type: "info" }))),
                            React.createElement(Popper, { arrow: true, id: `pop${id}PassPolicyInformation`, open: Boolean(anchorInfo), placement: "bottom-start", anchorEl: anchorInfo, onClickAway: this.onCloseInfo },
                                React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, resources.lblYourPasswordMust))),
                                passwordPolicy.isUpperCaseRequired && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, resources.lblYourPwdUpperCase)))),
                                passwordPolicy.isLowerCaseRequired && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, resources.lblYourPwdLowerCase)))),
                                passwordPolicy.isNumbersRequired && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, resources.lblYourPwdNumberChar)))),
                                passwordPolicy.isSpecialCharacterRequired && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, resources.lblYourPwdSpecialChar)))),
                                passwordPolicy.minimumLength && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, Format.toString(resources.formatYourMinimumChar, [passwordPolicy.minimumLength]))))),
                                passwordPolicy.compareAgainstPreviousPwd && (React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Text, null, Format.toString(resources.formatYourNotPreviousPassword, [passwordPolicy.previousPwdNumber])))))))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 11 },
                            React.createElement(TextField, { autoComplete: "new-password", disabled: disabled, error: errorConfirmPassword, id: `txt${id}ConfirmPassword`, helperText: errorTextConfirmPassword, label: resources.lblConfirmPassword, maxCharacters: 30, passwordToggle: true, required: true, type: "password", value: accountValidations.confirmPassword, onChange: this.onChangeConfirmPassword, onEnterPress: onEnterPress })),
                        passwordPolicy && (React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement("div", { className: classes.passwordMargin }))))),
                bottomElements ? (React.createElement(React.Fragment, null, bottomElements.map((element, iElement) => (React.createElement(Grid, { item: true, xs: 12, key: `${id}BottomElement${iElement}` },
                    React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid, { item: true, xs: 11 }, element),
                        passwordPolicy && (React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement("div", { className: classes.passwordMargin }))))))))) : undefined));
        }
        return (React.createElement(React.Fragment, null, contentPage));
    }
}
export default withStyles(styles)(PasswordConfirmation);
