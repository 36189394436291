function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronRight from '@eui/ds-icons/lib/ChevronRight';
import ChevronLeft from '@eui/ds-icons/lib/ChevronLeft';
import withStyles from '../../styles/withStyles';
import IconButton from '../../IconButton';
import { spacingSmall, spacingXSmall } from '../../styles/tokens';
import { withEDSContext } from '../../EDSContext/EDSContext';
import Tooltip from '../../Tooltip/Tooltip';

export var styles = {
    /* Styles applied to the root element. */
    root: {
        color: 'inherit',
        flexBasis: 'auto',
        alignSelf: 'center',
        margin: '0 0.125rem'
    },
    icon: {
        height: spacingSmall,
        width: spacingSmall
    },
    scrollLeftIcon: {
        marginRight: spacingXSmall
    },
    scrollRightIcon: {
        marginLeft: spacingXSmall
    },
    hideScrollButtons: {
        display: 'none'
    }
};

var TabScrollButton = React.forwardRef(function (props, ref) {
    var _classNames2;

    var classes = props.classes,
        classNameProp = props.className,
        direction = props.direction,
        edsContext = props.edsContext,
        onClick = props.onClick,
        visible = props.visible,
        hideScrollButtons = props.hideScrollButtons,
        other = _objectWithoutProperties(props, ['classes', 'className', 'direction', 'edsContext', 'onClick', 'visible', 'hideScrollButtons']);

    var className = classNames('tab-scroll-button', classes.root, _defineProperty({}, classes.hideScrollButtons, hideScrollButtons), classNameProp, (_classNames2 = {}, _defineProperty(_classNames2, classes.scrollLeftIcon, direction === 'left'), _defineProperty(_classNames2, classes.scrollRightIcon, direction === 'right'), _classNames2));

    return React.createElement(
        Tooltip,
        { title: edsContext.formatMessage('component.Tabs.scrollButtons'), enterDelay: 1000, enterNextDelay: 1000 },
        React.createElement(
            IconButton,
            Object.assign({
                'aria-label': edsContext.formatMessage('component.Tabs.scrollButtons'),
                className: className,
                onClick: onClick,
                tabIndex: -1,
                color: 'gray',
                disabled: !visible
            }, other, {
                ref: ref
            }),
            direction === 'left' ? React.createElement(ChevronLeft, { className: classes.icon }) : React.createElement(ChevronRight, { className: classes.icon })
        )
    );
});

TabScrollButton.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The direction of icon to render.
     */
    direction: PropTypes.oneOf(['left', 'right']),
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Callback to execute for button press.
     */
    onClick: PropTypes.func,
    /**
     * Should the button be present or just consume space.
     */
    visible: PropTypes.bool,
    /**
     * For auto mode, buttons will be hidden if tabs don't need scrolling
     */
    hideScrollButtons: PropTypes.bool
};

TabScrollButton.defaultProps = {
    visible: true
};

TabScrollButton.muiName = 'MuiPrivateTabScrollButton';

export default withEDSContext(withStyles(styles)(TabScrollButton));