function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '../styles';
import { withEDSContext } from '../EDSContext/EDSContext';
import { borderRadiusMedium, spacing30 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            width: 'auto',
            backgroundColor: theme.palette.grey[100],
            padding: spacing30,
            boxShadow: '0px 0px 0.438rem 0.125rem rgba(0,0,0,0.12)',
            borderBottomLeftRadius: borderRadiusMedium,
            borderBottomRightRadius: borderRadiusMedium,
            flexWrap: 'wrap'
        }
    };
};

/**
 * A wrapper component to handle the footer of the WeekView component.
 * @done false
 * @updated false
 * @versionAdded v0.2.1
 * @lab true
 */
var CalendarLegend = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        className = props.className,
        children = props.children,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'children', 'edsContext']);

    return React.createElement(
        'div',
        Object.assign({ dir: edsContext.dir, className: classNames(classes.root, className) }, rest, { ref: ref }),
        children
    );
});

CalendarLegend.propTypes = {
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * Custom class name to the outer CalendarLegend component.
     */
    className: PropTypes.string,
    /**
     * The children to render within the CalendarLegend.
     * Common children are the CalendarLegendItem, Button, and IconButton.
     */
    children: PropTypes.any
};

CalendarLegend.displayName = 'CalendarLegend';

export default withEDSContext(withStyles(styles)(CalendarLegend));