import React from 'react';
import Button from '../Button';
import Text from '../Text';
const Paragraph = (props) => {
    const { align, className, color, display, events, gutterBottom, id, size, text, weight } = props;
    const paragraph = [];
    const openBracket = text.split('[[');
    let countLinks = 0;
    let closeBracket;
    if (openBracket && openBracket.length > 0) {
        openBracket.forEach((openElement, iOpenElement) => {
            closeBracket = openElement.split(']]');
            if (closeBracket) {
                if (closeBracket.length > 1) {
                    paragraph.push(React.createElement(Button, { key: `btn-${id}_${countLinks}`, align: "left", id: `btn-${id}_${countLinks}`, noTextCover: true, textVariantStyling: "inline", variant: "text", onClick: events ? events[countLinks] : undefined }, closeBracket[0]));
                    countLinks++;
                    for (let jSubElement = 1; jSubElement < closeBracket.length; jSubElement++) {
                        if (closeBracket[jSubElement] !== '') {
                            paragraph.push(React.createElement("span", { key: `${id}_text_${iOpenElement}_${jSubElement}`, id: `${id}_text_${iOpenElement}_${jSubElement}` }, closeBracket[jSubElement]));
                        }
                    }
                }
                else {
                    if (closeBracket[0] !== '') {
                        paragraph.push(React.createElement("span", { key: `${id}_text`, id: `${id}_text` }, closeBracket[0]));
                    }
                }
            }
        });
    }
    else {
        paragraph.push(React.createElement("span", { key: `${id}_text`, id: `${id}_text` }, text));
    }
    return (React.createElement(Text, { align: align, className: className, color: color, display: display, id: id, gutterBottom: gutterBottom, size: size, weight: weight }, paragraph));
};
export default Paragraph;
