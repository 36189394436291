function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiCircularProgress } from '@material-ui/core/CircularProgress';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  root: {}
};

/**
 * Use `CircularProgress` to indicate work is being done "behind the scenes" when a user initiates a call to action (loading, working, etc).
 * @done false
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  CircularProgressIndeterminate
 *  CircularProgressDeterminate
 *  CircularProgressStatic
 */
var CircularProgress = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: classes.root
  };

  return React.createElement(MuiCircularProgress, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

CircularProgress.muiName = 'CircularProgress';
CircularProgress.displayName = 'CircularProgress';

CircularProgress.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  /**
   * If true, the shrink animation is disabled. This only works if variant is indeterminate.
   */
  disableShrink: PropTypes.bool,
  /**
  * The size of the circle.
  * If using a number, the pixel unit is assumed.
  * If using a string, you need to provide the CSS unit, e.g '3rem'.
  */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * @ignore
   */
  style: PropTypes.object,
  /**
   * The thickness of the circle.
   */
  thickness: PropTypes.number,
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number,
  /**
   * The variant to use.
   * Use indeterminate when there is no progress value.
   */
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static'])
};

CircularProgress.defaultProps = {
  color: 'primary',
  disableShrink: false,
  size: 40,
  thickness: 3.6,
  value: 0,
  variant: 'indeterminate'
};

export default withStyles(styles)(CircularProgress);