export const heightReset = '0rem';
export const important = '!important';
export const nonBreakingSpace = '&nbsp;';
export const spacing0 = '0rem';
export const spacing05 = '0.0625rem';
export const spacing34 = '0.625rem';
export const spacing35 = '0.75rem';
export const spacing55 = '1.75rem';
export const spacing85 = '4rem';
export const spacingReset = '0';
export const widthReset = '0rem';
