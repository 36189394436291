import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import { default as withStyles } from '../styles';
import { fontSizeLarge } from '../styles/tokens';

export var styles = function styles(theme) {
  return {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      // For correct alignment with the text.
      verticalAlign: 'middle',
      // Remove gray highlight
      WebkitTapHighlightColor: 'transparent',
      marginLeft: -2 * theme.spacing(1),
      marginRight: theme.spacing(2), // used for row presentation of radio/checkbox
      '&$disabled': {
        cursor: 'default'
      }
    },
    disabled: {},
    label: {
      fontSize: fontSizeLarge,
      '&$disabled': {
        color: theme.palette.text.disabled
      }
    }
  };
};

/**
 * A drop-in replacement for the <Radio>, <Switch> and <Checkbox> Component.
 * Use this component if you want to display an extra label. You will be able to use the
 * state to change the label text from within the context of the FormControl.
 */
var FormControlLabel = React.forwardRef(function (props, ref) {
  return React.createElement(MuiFormControlLabel, Object.assign({}, props, { ref: ref }));
});

FormControlLabel.muiName = 'FormControlLabel';
FormControlLabel.displayName = 'FormControlLabel';

FormControlLabel.propTypes = {
  /**
   * If `true`, the component appears selected.
   */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Useful to extend the style applied to components.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * A control element. For instance, it can be be a `Radio`, a `Switch` or a `Checkbox`.
   */
  control: PropTypes.element.isRequired,
  /**
   * If `true`, the control will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef: PropTypes.func,
  /**
   * The text to be used in an enclosing label element.
   */
  label: PropTypes.node,
  /*
   * @ignore
   */
  name: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.checked`.
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange: PropTypes.func,
  /**
   * The value of the component.
   */
  value: PropTypes.string
};

FormControlLabel.contextTypes = {
  muiFormControl: PropTypes.object
};

export default withStyles(styles)(FormControlLabel);