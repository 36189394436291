var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { Component } from 'react';
import { Tabs as MuiTabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '../styles';

import TabScrollButton from './internal/TabScrollButton';

var styles = function styles(theme) {
    return _defineProperty({
        root: {
            backgroundColor: 'transparent'
        },
        cardLevelFlexContainer: {
            boxShadow: 'inset 0 -1px 0 0 ' + theme.palette.grey[300]
        },
        indicator: {
            height: '0.125rem',
            backgroundColor: theme.palette.ctaColor.base
        }
    }, theme.breakpoints.down('sm'), {
        scrollButtonsDesktop: {
            display: 'inherit !important' // Forcing to display on mobile
        }
    });
};

/**
 * Use `Tabs` to group similar content into different sections on the same page.
 * @done true
 * @updated false
 * @versionAdded v0.0.2
 * @examples
 *  TabsWithContent
 *  ScrollableButtonTab
 *  CardLevelTabs
 *  CardLevelTabsScrollableButtons
 */

var Tabs = function (_Component) {
    _inherits(Tabs, _Component);

    function Tabs(props) {
        _classCallCheck(this, Tabs);

        // create a ref to store the Tabs DOM element
        var _this = _possibleConstructorReturn(this, (Tabs.__proto__ || Object.getPrototypeOf(Tabs)).call(this, props));

        _this.state = {
            hideScrollButtons: false
        };
        _this.tabsContainer = React.createRef();
        _this.updateScrollButtonsOnLoad = false;
        return _this;
    }

    _createClass(Tabs, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            // Options for the observer (which mutations to observe)
            var config = { attributes: true, childList: true, subtree: true };

            // Callback function to execute when mutations are observed
            var callback = function callback(mutationsList, observer) {
                if (mutationsList && mutationsList.length > 0) {
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = mutationsList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var mutation = _step.value;

                            if (mutation.attributeName === 'disabled' && mutation.target.nodeName === 'BUTTON') {
                                _this2.showOrHideScrollButtons();
                            }
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                }
            };

            // Create an observer instance linked to the callback function
            this.observer = new MutationObserver(callback);

            // Start observing the target node for configured mutations
            this.observer.observe(this.tabsContainer.current, config);

            // Call this here so that it triggers `componentDidUpdate()`
            this.showOrHideScrollButtons();
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            // This need to be called only once when the component is loaded.
            // With `componentDidMount()` it did not work and maybe DOM state
            // was not updated which is strange. After the first update MutationObserver
            // will take care of updating the state of scroll buttons.
            if (!this.updateScrollButtonsOnLoad) {
                this.showOrHideScrollButtons();
                this.updateScrollButtonsOnLoad = true;
            }
        }
    }, {
        key: 'showOrHideScrollButtons',
        value: function showOrHideScrollButtons() {
            var buttons = this.tabsContainer.current.querySelectorAll('.tab-scroll-button:disabled');
            this.setState(function (state) {
                return { hideScrollButtons: buttons.length === 2 };
            });
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this.observer.disconnect();
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var _props = this.props,
                classes = _props.classes,
                TabIndicatorProps = _props.TabIndicatorProps,
                childrenProp = _props.children,
                scrollButtons = _props.scrollButtons,
                scrollButtonsOnMobile = _props.scrollButtonsOnMobile,
                variant = _props.variant,
                widthVariant = _props.widthVariant,
                rest = _objectWithoutProperties(_props, ['classes', 'TabIndicatorProps', 'children', 'scrollButtons', 'scrollButtonsOnMobile', 'variant', 'widthVariant']);

            var _TabIndicatorProps = void 0,
                children = childrenProp;

            if (variant === 'card') {
                _TabIndicatorProps = Object.assign({
                    style: { display: 'none' }
                }, TabIndicatorProps);

                children = React.Children.map(this.props.children, function (child) {
                    return React.cloneElement(child, {
                        variant: _this3.props.variant
                    });
                });
            } else {
                _TabIndicatorProps = Object.assign({}, TabIndicatorProps);
            }

            var scrollButtonsProp = scrollButtons;
            if (scrollButtons) {
                scrollButtonsProp = 'auto';
            } else {
                scrollButtonsProp = 'off';
            }

            return React.createElement(
                'div',
                { ref: this.tabsContainer },
                React.createElement(
                    MuiTabs,
                    Object.assign({
                        classes: {
                            root: classes.root,
                            scroller: variant === 'card' && classes.cardLevelFlexContainer,
                            indicator: classes.indicator,
                            scrollButtonsDesktop: scrollButtonsOnMobile ? classes.scrollButtonsDesktop : ''
                        },
                        ScrollButtonComponent: function ScrollButtonComponent(props) {
                            return React.createElement(TabScrollButton, Object.assign({
                                hideScrollButtons: _this3.state.hideScrollButtons
                            }, props));
                        },
                        TabIndicatorProps: _TabIndicatorProps,
                        scrollButtons: scrollButtonsProp,
                        variant: widthVariant
                    }, rest),
                    children
                )
            );
        }
    }]);

    return Tabs;
}(Component);

Tabs.muiName = 'Tabs';

Tabs.propTypes = {
    /**
     * Callback fired when the component mounts.
     * This is useful when you want to trigger an action programmatically.
     * It currently only supports `updateIndicator()` action.
     *
     * @param {object} actions This object contains all possible actions
     * that can be triggered programmatically.
     */
    action: PropTypes.func,
    /**
     * If `true`, the tabs will be centered.
     * This property is intended for large views.
     */
    centered: PropTypes.bool,
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * Send a custom class name.
     */
    className: PropTypes.string,
    /**
     * @ignore
     * Determines the color of the indicator.
     */
    indicatorColor: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['secondary', 'primary'])]),
    /**
     * Callback fired when the value changes.
     *
     * @param {object} event The event source of the callback
     * @param {number} value We default to the index of the child
     */
    onChange: PropTypes.func,
    /**
     * Render the scroll buttons. If set to `true`, scroll buttons will be rendered if the tabs
     * don't fit in the current viewport.
     *
     * On mobile viewports the Tabs container has horizontal scrolling. To suppress scroll buttons
     * on mobile viewports, use the `scrollButtonsOnMobile` prop.
     */
    scrollButtons: PropTypes.bool,
    /**
     * Whether to show scroll buttons in the mobile viewport (when `scrollButtons` is `true`).
     */
    scrollButtonsOnMobile: PropTypes.bool,
    /**
     * @ignore
     * The component used to render the scroll buttons.
     */
    ScrollButtonComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * @ignore
     * Properties applied to the private `TabIndicator` element.
     */
    TabIndicatorProps: PropTypes.object,
    /**
     * The value of the currently selected `Tab`.
     * If you don't want any selected `Tab`, you can set this property to `false`.
     */
    value: PropTypes.any,
    /**
     * Set the variant to `page` to render a page level Tab.
     * Set the variant to `card` to render a card level Tab.
     */
    variant: PropTypes.oneOf(['page', 'card']),

    /**
     * Determines additional display behavior of the tabs:
     *   - scrollable will invoke scrolling properties and allow for horizontally scrolling (or swiping) of the tab bar.
     *   - fullWidth will make the tabs grow to use all the available space, which should be used for small views, like on mobile.
     *   - standard will render the default state.
     */
    widthVariant: PropTypes.oneOf(['scrollable', 'standard', 'fullWidth'])
};

Tabs.defaultProps = {
    centered: false,
    widthVariant: 'scrollable',
    scrollButtons: false,
    scrollButtonsOnMobile: true,
    variant: 'page'
};

export default withStyles(styles, { index: 0 })(Tabs);