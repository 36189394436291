function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiButton } from '@material-ui/core/Button';
import { borderRadiusSmall, borderWidthThin, fontFamilyCondensed, fontWeightBold, fontSizeLarge, lineHeightDefault, sizingXLarge, sizingXxLarge, spacing20, spacing40, boxShadowFocus } from '../styles/tokens';

// text styling variants
export var TEXT_STYLING_STANDALONE = 'standalone';
export var TEXT_STYLING_INLINE = 'inline';

/**
 * TODO: Need to implement the css for outlined buttons now that they are available.
 */
export var styles = function styles(theme) {
    return {
        // Shared Button styles
        root: {
            // fontFamily is being updated from theme object for fontFamilies: { button: ... }
            boxShadow: 'none',
            color: theme.palette.text.white,
            borderRadius: borderRadiusSmall,
            minHeight: sizingXLarge, // replace with token
            outline: 'none',
            padding: spacing20 + ' ' + spacing40,
            '&$disabled': {
                color: theme.palette.text.white
            },
            '& svg': {
                flex: '1 0 auto'
            }
        },
        // Sizes
        sizeDefault: {},
        sizeLarge: {
            fontSize: theme.typography.h3.fontSize,
            minHeight: sizingXxLarge,
            padding: spacing20 + ' ' + spacing40
        },
        // Shared Disabled Button styles
        disabled: {
            backgroundColor: theme.palette.disabled.background,
            color: theme.palette.grey[100]
        },
        // Primary Button
        contained: {
            backgroundColor: theme.palette.ctaColor.base,
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                borderColor: theme.palette.ctaColor.hover,
                boxShadow: 'none'
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active
            },
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: theme.palette.disabled.background,
                color: theme.palette.text.white
            },
            '&:focus': {
                outline: 'none',
                boxShadow: boxShadowFocus
            },
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                boxShadow: boxShadowFocus
            }
        },
        text: {},
        // text Button
        textPrimary: {
            backgroundColor: 'transparent',
            color: theme.palette.action.base, // cta blue 600
            borderRadius: borderRadiusSmall,
            minWidth: 'inherit',
            minHeight: 'inherit',
            height: 'auto',
            padding: '0 ' + spacing20 + ' 0 ' + spacing20,
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                color: theme.palette.action.disabled,
                backgroundColor: 'transparent'
            },
            '&:focus:active': {
                color: theme.palette.action.active, // cta blue 800
                boxShadow: 'none'
            },
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.action.hover, // cta blue 700
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: boxShadowFocus
            },
            '&:focus:hover': {
                boxShadow: 'none'
            }
        },
        textStandalone: {
            fontFamily: fontFamilyCondensed,
            fontSize: fontSizeLarge,
            fontWeight: fontWeightBold,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625em'
        },
        textInline: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
            wordSpacing: 'inherit',
            letterSpacing: 'inherit',
            verticalAlign: 'inherit',
            textTransform: 'inherit',
            padding: 0
        },
        textPrimaryDark: {
            color: theme.palette.grey[100],
            '&:hover': {
                color: theme.palette.grey[200]
            },
            '&:focus:active': {
                color: theme.palette.grey[300]
            }
        },
        // Secondary Button
        containedSecondary: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.ctaColor.base,
            border: borderWidthThin + ' solid',
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                borderColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                color: theme.palette.grey[100]
            },
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: theme.palette.disabled.background,
                color: theme.palette.text.white
            },
            '&:focus': {
                outline: 'none',
                boxShadow: boxShadowFocus
            },
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                boxShadow: boxShadowFocus,
                color: theme.palette.text.white
            }
        }
        // Outlined => Inverted Buttons
        // outlined: {
        //     backgroundColor: "transparent",
        //     color: "#FFFFFF",
        //     border: "1px solid #FFFFFF",
        //     "&:hover": {
        //         backgroundColor: "#FFFFFF",
        //         color: "transparent" // TODO: verify with UXD about color fo rtext on hover, pressed, focused
        //     },
        //     "&:active": {
        //         boxShadow: "none",
        //         backgroundColor: "#0A4982",
        //         borderColor: "#0A4982",
        //         color: "#FFFFFF"
        //     },
        //     "&$disabled": {
        //         color: "#FFFFFF", // FIXME: not showing up properly, being overridden
        //         boxShadow: "none",
        //         backgroundColor: "#B2B3B7",
        //         border: "none"
        //     },
        //     "&:focus": {
        //         boxShadow: "0 0 0 2px #FFFFFF"
        //     },
        //     "&:focus:hover": {
        //         backgroundColor: "#FFFFFF",
        //         borderColor: "#FFFFFF",
        //         boxShadow: "0 0 0 2px #FFFFFF",
        //         color: "transparent"
        //     }
        // },
    };
};

/**
 * Use `Button` to show a call to action.
 * @done true
 * @updated false
 * @versionAdded v0.0.5
 * @examples
 *  SimpleExample
 *  SizesExample
 *  FluidExample
 *  TextWithIconExample
 *  ButtonGroupExample
 *  TextButtonExample
 *  TextOverflowExample
 */
var Button = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        fluid = props.fluid,
        textVariantStyling = props.textVariantStyling,
        variant = props.variant,
        withDarkBackground = props.withDarkBackground,
        size = props.size,
        rest = _objectWithoutProperties(props, ['classes', 'fluid', 'textVariantStyling', 'variant', 'withDarkBackground', 'size']);

    // Extra our custom classes so they aren't passed to MUI


    var sizeDefault = classes.sizeDefault,
        textStandalone = classes.textStandalone,
        textInline = classes.textInline,
        textPrimaryDark = classes.textPrimaryDark,
        restClasses = _objectWithoutProperties(classes, ['sizeDefault', 'textStandalone', 'textInline', 'textPrimaryDark']);

    var textClass = classes.textPrimary + ' ' + classes.text + '\n        ' + (withDarkBackground ? textPrimaryDark : '') + '\n        ' + (textVariantStyling === TEXT_STYLING_INLINE ? textInline : textStandalone) + '\n    ';

    var propOverrides = {
        disableRipple: true, // disable the click ripple by default
        disableFocusRipple: true, // disable the default MUI focus ripple effect
        size: size
    };

    if (variant === 'text') {
        // Need to override these props to prevent styling issues
        propOverrides.color = 'primary';
        propOverrides.fullWidth = false;
    };

    switch (size) {
        case 'small' || 'medium':
            console.warn('Size="' + size + '" has been removed. Size is set to "default".');
            propOverrides.size = 'medium'; // needs to be set to MUI size, "medium"
            break;
        case 'default':
            propOverrides.size = 'medium'; // needs to be set to MUI size, "medium"
            break;
        default:
            break;
    }

    var classOverrides = Object.assign({}, restClasses, { // pass down the rest of MUI supported classes
        root: cn(classes.root, _defineProperty({}, sizeDefault, propOverrides.size === 'medium')),
        contained: classes.contained,
        text: textClass,
        containedSecondary: classes.containedSecondary,
        sizeLarge: classes.sizeLarge,
        disabled: classes.disabled
    });

    return React.createElement(
        MuiButton,
        Object.assign({
            variant: variant,
            classes: classOverrides,
            fullWidth: fluid,
            size: propOverrides.size,
            ref: ref
        }, rest, propOverrides),
        props.children
    );
});

Button.propTypes = {
    /**
     * Class name to assign the component.
     */
    className: PropTypes.string,
    /**
     * Classes used to extend original style object.
     */
    classes: PropTypes.object,
    /**
     * The content of the button.
     */
    children: PropTypes.node.isRequired,
    /**
     * The color of the component.
     */
    color: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * If `true`, the button will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the button will take up the full width of its container.
     */
    fluid: PropTypes.bool,
    /**
     * The URL to link to when the button is clicked.
     * If defined, an `a` element will be used as the root node.
     */
    href: PropTypes.string,
    /**
     * The size of the button.
     */
    size: PropTypes.oneOf(['default', 'large']),
    /**
     * The font configuration of the `text` variant
     *
     * * `standalone` - Use standard text-button styling
     * * `inline` - Inherits styling from context. Used for text buttons placed inline with other text.
     */
    textVariantStyling: PropTypes.oneOf(['standalone', 'inline']),
    /**
     * The HTML button type.
     */
    type: PropTypes.string,
    /**
     * The type of button.
     */
    variant: PropTypes.oneOf(['contained', 'text']),
    /**
     * With `variant="text"`, use this if you want to render a button on Snackbar or any dark background.
     */
    withDarkBackground: PropTypes.bool
    // We should use "flat" for our "text" buttons, and "outlined" for our "inverted", and "fab" for our floating action button.
};

Button.defaultProps = {
    variant: 'contained',
    color: 'primary',
    size: 'default',
    textVariantStyling: 'standalone',
    fluid: false,
    withDarkBackground: false
};

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
Button.displayName = 'Button';

/**
 * We order Button styles at index: 0 to avoid improper ordering
 * of Dropdown styles.
 */
export default withStyles(styles, { name: 'Button', index: 0 })(Button);