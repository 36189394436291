function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';

import Divider from '../Divider/Divider';
import ListItem from '../List/ListItem';
import ListItemText from '../List/ListItemText';
import classNames from 'classnames';

import { colorTextNeutral500 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        item: {
            outline: 'none'
        },
        divider: {
            margin: '0 !important'
        },
        mark: {
            color: colorTextNeutral500,
            backgroundColor: 'initial'
        },
        disabled: {
            cursor: 'unset'
        },
        itemHover: {
            backgroundColor: theme.palette.grey['200']
        }
    };
};

var SearchItem = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        childrenProp = props.children,
        disabled = props.disabled,
        searchString = props.searchString,
        divider = props.divider,
        onClick = props.onClick,
        isActive = props.isActive,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'disabled', 'searchString', 'divider', 'onClick', 'isActive']);

    var children = childrenProp;
    var label = children;
    var itemRef = React.createRef();

    // disabling warning for missing dpendency of itemRef
    // refer to the following link for more details on why this is the best option for this case
    // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    useEffect(function () {
        if (isActive) {
            itemRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    // Refer following link for more details about escaping regex characters
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    var escapeRegExp = function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    };

    if (children && searchString) {
        var regex = new RegExp(escapeRegExp(searchString), 'gi');
        children = children.replace(regex, function (val) {
            return '<mark class=' + classes.mark + '>' + val + '</mark>';
        });
    }

    return React.createElement(
        React.Fragment,
        null,
        React.createElement(
            ListItem,
            Object.assign({
                classes: {
                    root: disabled ? classes.disabled : isActive ? classes.itemHover : ''
                },
                className: classNames(classes.item, props.className),
                disabled: disabled,
                'aria-disabled': disabled ? true : false,
                'aria-label': label,
                ref: itemRef,
                onClick: disabled ? null : onClick
            }, rest, {
                tabIndex: 0,
                role: 'button'
            }),
            React.createElement(
                ListItemText,
                null,
                React.createElement('span', { dangerouslySetInnerHTML: { __html: children } })
            )
        ),
        divider && React.createElement(Divider, { classes: { root: classes.divider }, component: 'li' })
    );
});

SearchItem.displayName = 'SearchItem';

SearchItem.propTypes = {
    /**
     * The text of the item.
     */
    children: PropTypes.node.isRequired,
    /**
     * If `true`, the item will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, a 1px light border is added to the bottom of the list item.
     */
    divider: PropTypes.bool,
    /**
     * Value of this prop would be returned when user clicks on list item.
     */
    option: PropTypes.object
};

export default withStyles(styles, { name: 'SearchItem' })(SearchItem);