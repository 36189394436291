const PasswordValidation = {
    validateConfirmPassword(accountValidations, confirmPassword, password) {
        accountValidations.confirmPassword = confirmPassword || '';
        accountValidations.confirmPasswordModified = true;
        if (accountValidations.confirmPassword !== password) {
            accountValidations.hasErrors = true;
        }
        return accountValidations;
    },
    validatePassword(accountValidations, password, passwordPolicy) {
        if (!passwordPolicy) {
            accountValidations.isPwdLowerCase = false;
            accountValidations.isPwdUpperCase = false;
            accountValidations.isPwdSpecialChar = false;
            accountValidations.isPwdNumberChar = false;
            accountValidations.isMinimumChar = false;
            accountValidations.isNotPreviousPassword = false;
            accountValidations.isCurrentPwdNewPwd = false;
            accountValidations.hasErrors = false;
        }
        else {
            if (!password) {
                accountValidations.isPwdLowerCase = passwordPolicy.isLowerCaseRequired;
                accountValidations.isPwdUpperCase = passwordPolicy.isUpperCaseRequired;
                accountValidations.isPwdSpecialChar = passwordPolicy.isSpecialCharacterRequired;
                accountValidations.isPwdNumberChar = passwordPolicy.isNumbersRequired;
                accountValidations.isMinimumChar = passwordPolicy.minimumLength >= 1;
                accountValidations.isNotPreviousPassword = false;
                accountValidations.isCurrentPwdNewPwd = false;
            }
            else {
                accountValidations.isPwdLowerCase = passwordPolicy.isLowerCaseRequired && !Boolean(password.match(/[a-z]/));
                accountValidations.isPwdUpperCase = passwordPolicy.isUpperCaseRequired && !Boolean(password.match(/[A-Z]/));
                accountValidations.isPwdSpecialChar = passwordPolicy.isSpecialCharacterRequired
                    && !Boolean(password.match(/[\s~`!@#$%^&*()_\-+={}\[\]|\\:;<,>.?/"]/));
                accountValidations.isPwdNumberChar = passwordPolicy.isNumbersRequired && !Boolean(password.match(/[0-9]/));
                accountValidations.isMinimumChar = !(password.length > 0 && password.length >= passwordPolicy.minimumLength);
                accountValidations.isNotPreviousPassword = false;
                accountValidations.isCurrentPwdNewPwd = false;
            }
            accountValidations.hasErrors = accountValidations.isPwdLowerCase || accountValidations.isPwdUpperCase
                || accountValidations.isPwdSpecialChar || accountValidations.isPwdNumberChar
                || accountValidations.isMinimumChar
                || !Boolean(password);
        }
        accountValidations.passwordModified = true;
        return accountValidations;
    }
};
export default PasswordValidation;
