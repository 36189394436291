function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '../styles';
import Typography from '../Typography';
import { withEDSContext } from '../EDSContext/EDSContext';

import { spacing20, spacing30, sizingXSmall, borderRadiusCircle } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            display: 'inline-flex',
            margin: spacing30
        },
        indicator: {
            width: sizingXSmall,
            height: sizingXSmall,
            borderRadius: borderRadiusCircle,
            marginRight: spacing20,
            alignSelf: 'center'
        }
    };
};

/**
 * A UI element that renders an indicator color and label for the CalendarLegend.
 * @done true
 * @updated false
 * @versionAdded v0.2.1
 * @lab true
 */
var CalendarLegendItem = React.forwardRef(function (props, ref) {
    var color = props.color,
        classes = props.classes,
        edsContext = props.edsContext,
        label = props.label,
        className = props.className,
        theme = props.theme,
        rest = _objectWithoutProperties(props, ['color', 'classes', 'edsContext', 'label', 'className', 'theme']);
    // default to the cta color if no color it sent for the indicator


    var indicatorColor = color === null || color === undefined ? theme.palette.tertiary.fountain[600] : color;

    return React.createElement(
        'div',
        Object.assign({ className: classNames(classes.root, className) }, rest, { ref: ref, dir: edsContext.dir }),
        React.createElement('div', { className: classes.indicator, style: { backgroundColor: indicatorColor } }),
        React.createElement(
            Typography,
            { variant: 'body2' },
            label
        )
    );
});

CalendarLegendItem.propTypes = {
    /**
     * The color to match the calendar items in this category.
     */
    color: PropTypes.string,
    /**
     * The label to render next to the indicator.
     */
    label: PropTypes.string.isRequired,
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * Class name to send to parent `div` of the CalendarLegendItem.
     */
    className: PropTypes.string,
    /**
     * @ignore
     * Material-UI theme object.
     */
    theme: PropTypes.object
};

CalendarLegendItem.displayName = 'CalendarLegendItem';

export default withEDSContext(withStyles(styles, { withTheme: true })(CalendarLegendItem));