function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { sizingXSmall, sizingXxSmall, sizingLarge, sizingXxLarge, spacingXxSmall, spacingXSmall, spacing30, spacing40, spacing80, fontSizeSmall, fontSizeLarge, fontFamilyDefault, borderRadiusXSmall, borderRadiusSmall, borderRadiusMedium, borderRadiusCircle, borderWidthThin, borderWidthThick, colorTextAlertError } from '../../styles/tokens';
//import scrollbarSize from 'dom-helpers/scrollbarSize';

var ReactBigCalendarStyles = function ReactBigCalendarStyles(theme) {
    var _global;

    return {
        '@global': (_global = {
            '.rbc-container': {
                borderRadius: borderRadiusMedium,
                boxShadow: '\n                0px 0.188rem 0.313rem -0.063rem rgba(0, 0, 0, 0.2),\n                0px 0.313rem ' + spacingXSmall + ' 0px rgba(0, 0, 0, 0.14),\n                0px 0.063rem 0.875rem 0.063rem rgba(0, 0, 0, 0.12)\n            '
            },
            '.rbc-btn': {
                color: 'inherit',
                font: 'inherit',
                margin: 0
            },
            'button.rbc-btn': {
                overflow: 'visible',
                textTransform: 'none',
                WebkitAppearance: 'button',
                cursor: 'pointer'
            },
            'button[disabled].rbc-btn': {
                backgroundColor: theme.palette.grey[400],
                cursor: 'not-allowed'
            },
            'button.rbc-input::-moz-focus-inner': {
                border: 0,
                padding: 0
            },
            '.rbc-calendar': {
                boxSizing: 'border-box',
                height: '100%',
                display: 'flex',
                WebkitFlexDirection: 'column',
                MsFlexDirection: 'column',
                flexDirection: 'column',
                WebkitAlignItems: 'stretch',
                MsFlexAlign: 'stretch',
                alignItems: 'stretch',
                fontFamily: fontFamilyDefault
            }
        }, _defineProperty(_global, '.rbc-calendar *,\n            .rbc-calendar *:before,\n            .rbc-calendar *:after', {
            boxSizing: 'inherit'
        }), _defineProperty(_global, '.rbc-abs-full,\n           .rbc-row-bg', {
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }), _defineProperty(_global, '.rbc-ellipsis,\n            .rbc-event-label,\n            .rbc-row-segment .rbc-event-content,\n            .rbc-show-more', {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }), _defineProperty(_global, '.rbc-off-range', {
            color: '#999999'
        }), _defineProperty(_global, '.rbc-off-range-bg', {
            background: '#e5e5e5'
        }), _defineProperty(_global, '.rbc-header', {
            overflow: 'hidden',
            WebkitFlex: '1 0 0%',
            MsFlex: '1 0 0%',
            flex: '1 0 0%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            padding: '0 ' + spacingXxSmall,
            textAlign: 'center',
            verticalAlign: 'middle',
            fontWeight: 'bold',
            fontSize: fontSizeSmall,
            minHeight: 0,
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-header + .rbc-header', {
            borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-header > a,\n            .rbc-header > a:active,\n            .rbc-header > a:visited', {
            color: theme.palette.grey[500],
            textDecoration: 'none'
        }), _defineProperty(_global, '.rbc-row-content', {
            position: 'relative',
            MozUserSelect: 'none',
            MsUserSelect: 'none',
            userSelect: 'none',
            WebkitUserSelect: 'none',
            zIndex: 4
        }), _defineProperty(_global, '.rbc-highlight-today .rbc-today', {
            backgroundColor: theme.palette.ctaColor.tint
        }), _defineProperty(_global, '.rbc-toolbar', {
            display: 'flex',
            WebkitFlexWrap: 'wrap',
            MsFlexWrap: 'wrap',
            flexWrap: 'wrap',
            WebkitJustifyContent: 'flex-end',
            MsFlexPack: 'center',
            justifyContent: 'flex-end',
            WebkitAlignItems: 'center',
            MsFlexAlign: 'center',
            alignItems: 'center',
            fontSize: fontSizeLarge,
            marginRight: spacingXSmall
        }), _defineProperty(_global, '.rbc-btn-group', {
            display: 'inline-block',
            whiteSpace: 'nowrap'
        }), _defineProperty(_global, '.rbc-btn-group > button:first-child:not(:last-child)', {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }), _defineProperty(_global, '.rbc-btn-group > button:last-child:not(:first-child)', {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        }), _defineProperty(_global, '.rbc-btn-group > button:not(:first-child):not(:last-child)', {
            borderRadius: 0
        }), _defineProperty(_global, '.rbc-btn-group button + button', {
            marginLeft: '-0.063rem'
        }), _defineProperty(_global, '.rbc-btn-group+.rbc-btn-group,\n            .rbc-btn-group+button', {
            marginLeft: '0.625rem'
        }), _defineProperty(_global, '.rbc-event', {
            border: 'none',
            boxShadow: '0 0.125rem ' + sizingXxSmall + ' 0.063rem rgba(0, 0, 0, 0.3)',
            margin: 0,
            padding: '0.125rem ' + spacingXxSmall,
            backgroundColor: theme.palette.common.white,
            borderLeft: borderRadiusSmall + ' solid ' + theme.palette.tertiary.fountain[600],
            borderRadius: borderRadiusXSmall,
            color: '#151618',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'left',
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeSmall
        }), _defineProperty(_global, '.rbc-slot-selecting .rbc-event', {
            cursor: 'inherit',
            pointerEvents: 'none'
        }), _defineProperty(_global, '.rbc-event.rbc-selected', {
            backgroundColor: theme.palette.common.white
        }), _defineProperty(_global, '.rbc-event:focus', {
            outline: sizingXxSmall + ' auto ' + theme.palette.tertiary.fountain[600]
        }), _defineProperty(_global, '.rbc-event-label', {
            fontSize: fontSizeSmall
        }), _defineProperty(_global, '.rbc-event-overlaps', {
            boxShadow: '-0.063rem 0.063rem ' + sizingXxSmall + ' 0px rgba(51, 51, 51, 0.5)'
        }), _defineProperty(_global, '.rbc-event-continues-prior', {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        }), _defineProperty(_global, '.rbc-event-continues-after', {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }), _defineProperty(_global, '.rbc-event-continues-earlier', {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        }), _defineProperty(_global, '.rbc-event-continues-later', {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }), _defineProperty(_global, '.rbc-row', {
            display: 'flex',
            WebkitFlexDirection: 'row',
            MsFlexDirection: 'row',
            flexDirection: 'row'
        }), _defineProperty(_global, '.rbc-row-segment', {
            padding: '0 0.063rem 0.063rem 0.063rem'
        }), _defineProperty(_global, '.rbc-date-cell.rbc-now', {
            fontWeight: 'bold'
        }), _defineProperty(_global, '.rbc-date-cell>a,\n            .rbc - date - cell > a: active,\n            .rbc - date - cell > a: visited', {
            color: 'inherit',
            textDecoration: 'none'
        }), _defineProperty(_global, '.rbc-row-bg', {
            display: 'flex',
            WebkitFlexDirection: 'row',
            MsFlexDirection: 'row',
            flexDirection: 'row',
            WebkitFlex: '1 0 0',
            MsFlex: '1 0 0px',
            flex: '1 0 0',
            overflow: 'hidden'
        }), _defineProperty(_global, '.rbc-day-bg', {
            WebkitFlex: '1 0 0%',
            MsFlex: '1 0 0%',
            flex: '1 0 0%'
        }), _defineProperty(_global, '.rbc-day-bg + .rbc-day-bg', {
            borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-column', {
            display: 'flex',
            WebkitFlexDirection: 'column',
            MsFlexDirection: 'column',
            flexDirection: 'column',
            minHeight: '100%'
        }), _defineProperty(_global, '.rbc-time-column .rbc-timeslot-group', {
            WebkitFlex: '1',
            MsFlex: '1',
            flex: '1'
        }), _defineProperty(_global, '.rbc-time-column .rbc-timeslot-group:nth-child(odd)', {
            backgroundColor: theme.palette.grey[200]
        }), _defineProperty(_global, '.rbc-time-column .rbc-timeslot-group:nth-child(odd).rbc-today', {
            backgroundColor: theme.palette.grey[200]
        }), _defineProperty(_global, '.rbc-timeslot-group', {
            minHeight: sizingLarge,
            display: 'flex',
            WebkitFlexFlow: 'column nowrap',
            MsFlexFlow: 'column nowrap',
            flexFlow: 'column nowrap'
        }), _defineProperty(_global, '.rbc-time-gutter,\n            .rbc-header-gutter', {
            WebkitFlex: 'none',
            MsFlex: 'none',
            flex: 'none'
        }), _defineProperty(_global, '.rbc-label', {
            padding: '0 0.313rem',
            fontSize: fontSizeSmall,
            color: theme.palette.grey[500]
        }), _defineProperty(_global, '.rbc-day-slot', {
            position: 'relative'
        }), _defineProperty(_global, '.rbc-day-slot .rbc-events-container', {
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            marginRight: '0.625rem',
            top: 0
        }), _defineProperty(_global, '.rbc-day-slot .rbc-event', {
            display: 'flex',
            maxHeight: '100%',
            minHeight: '1.250rem',
            WebkitFlexFlow: 'column wrap',
            MsFlexFlow: 'column wrap',
            flexFlow: 'column wrap',
            WebkitAlignItems: 'flex-start',
            MsFlexAlign: 'start',
            alignItems: 'flex-start',
            overflow: 'hidden',
            position: 'absolute'
        }), _defineProperty(_global, '.rbc - day - slot.rbc - event - label', {
            display: 'none',
            WebkitFlex: 'none',
            MsFlex: 'none',
            flex: 'none',
            paddingRight: '0.313rem',
            width: 'auto'
        }), _defineProperty(_global, '.rbc-day-slot .rbc-event-content', {
            width: '100%',
            WebkitFlex: '1 1 0',
            MsFlex: '1 1 0px',
            flex: '1 1 0',
            wordWrap: 'break-word',
            lineHeight: '1.5',
            height: '100%',
            minHeight: 0,
            padding: spacingXxSmall + ' 0.125rem'
        }), _defineProperty(_global, '.rbc-highlight-today .rbc-time-column.rbc-today .rbc-timeslot-group', {
            backgroundColor: theme.palette.ctaColor.tint
        }), _defineProperty(_global, '.rbc-time-view-resources .rbc-time-gutter,\n            .rbc-time-view-resources .rbc-time-header-gutter', {
            width: '62.2167px',
            position: 'sticky',
            left: 0,
            backgroundColor: theme.palette.grey[100],
            borderRight: borderWidthThin + ' solid ' + theme.palette.grey[300],
            zIndex: 10,
            marginRight: '-0.063rem'
        }), _defineProperty(_global, '.rbc-time-view-resources .rbc-time-header', {
            overflow: 'hidden'
        }), _defineProperty(_global, '.rbc-time-view-resources .rbc-time-header-content', {
            minWidth: 'auto',
            WebkitFlex: '1 0 0',
            MsFlex: '1 0 0px',
            flex: '1 0 0',
            WebkitFlexBasis: '0px',
            MsFlexPreferredSize: '0px',
            flexBasis: '0px'
        }), _defineProperty(_global, '.rbc-time-view-resources .rbc-time-header-cell-single-day', {
            display: 'none'
        }), _defineProperty(_global, '.rbc-time-view-resources .rbc-header,\n            .rbc-time-view-resources .rbc-day-bg', {
            WebkitFlex: '1 1 0',
            MsFlex: '1 1 0px',
            flex: '1 1 0',
            WebkitFlexBasis: '0 px',
            MsFlexPreferredSize: '0 px',
            flexBasis: '0 px'
        }), _defineProperty(_global, '.rbc-time-header-content + .rbc-time-header-content', {
            marginLeft: '-1px'
        }), _defineProperty(_global, '.rbc-time-slot', {
            WebkitFlex: '1 0 0',
            MsFlex: '1 0 0px',
            flex: '1 0 0',
            display: 'flex',
            alignItems: 'center'
        }), _defineProperty(_global, '.rbc-time-slot.rbc-now', {
            fontWeight: 'bold'
        }), _defineProperty(_global, '.rbc-day-header', {
            textAlign: 'center'
        }), _defineProperty(_global, '.rbc-slot-selecting', {
            cursor: 'move'
        }), _defineProperty(_global, '.rbc-time-view', {
            display: 'flex',
            WebkitFlexDirection: 'column',
            MsFlexDirection: 'column',
            flexDirection: 'column',
            WebkitFlex: '1',
            MsFlex: '1',
            flex: '1',
            width: '100%',
            borderTop: borderWidthThin + ' solid ' + theme.palette.grey[300],
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300],
            minHeight: 0
        }), _defineProperty(_global, '.rbc-time-view .rbc-time-gutter', {
            whiteSpace: 'nowrap'
        }), _defineProperty(_global, '.rbc-time-view .rbc-allday-cell', {
            boxSizing: 'content-box',
            width: '100%',
            height: '100%',
            position: 'relative'
        }), _defineProperty(_global, '.rbc-time-view .rbc-allday-cell + .rbc-allday-cell', {
            borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-view .rbc-allday-events', {
            position: 'relative',
            zIndex: 4
        }), _defineProperty(_global, '.rbc-time-view .rbc-row', {
            boxSizing: 'border-box',
            minHeight: '1.250rem'
        }), _defineProperty(_global, '.rbc-time-header', {
            display: 'flex',
            WebkitFlex: '0 0 auto',
            MsFlex: '0 0 auto',
            flex: '0 0 auto',
            WebkitFlexDirection: 'row',
            MsFlexDirection: 'row',
            flexDirection: 'row'
        }), _defineProperty(_global, '.rbc-time-header.rbc-overflowing', {
            outline: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-header > .rbc-row:first-child', {
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-header > .rbc-row.rbc-row-resource', {
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-header-cell-single-day', {
            display: 'none'
        }), _defineProperty(_global, '.rbc-time-header-content', {
            WebkitFlex: '1',
            MsFlex: '1',
            flex: '1',
            display: 'flex',
            minWidth: 0,
            WebkitFlexDirection: 'column',
            MsFlexDirection: 'column',
            flexDirection: 'column',
            borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-content', {
            display: 'flex',
            WebkitFlex: '1 0 0%',
            MsFlex: '1 0 0%',
            flex: '1 0 0%',
            WebkitAlignItems: 'flex-start',
            MsFlexAlign: 'start',
            alignItems: 'flex-start',
            width: '100%',
            borderTop: borderWidthThick + ' solid ' + theme.palette.grey[300],
            overflowY: 'auto',
            position: 'relative'
        }), _defineProperty(_global, '.rbc-time-content > .rbc-time-gutter', {
            WebkitFlex: 'none',
            MsFlex: 'none',
            flex: 'none'
        }), _defineProperty(_global, '.rbc-time-content > * + * > *', {
            borderLeft: borderWidthThin + ' solid ' + theme.palette.grey[300]
        }), _defineProperty(_global, '.rbc-time-content > .rbc-day-slot', {
            width: '100%',
            MozUserSelect: 'none',
            MsUserSelect: 'none',
            userSelect: 'none',
            WebkitUserSelect: 'none'
        }), _defineProperty(_global, '.rbc-current-time-indicator', {
            position: 'absolute',
            zIndex: '3',
            left: '0',
            right: '0',
            height: '.125rem',
            backgroundColor: colorTextAlertError,
            pointerEvents: 'none'
        }), _defineProperty(_global, '.rbc-current-time-indicator:before', {
            content: '""',
            display: 'block',
            width: sizingXSmall,
            height: sizingXSmall,
            transform: 'translate(-0.28125rem, -0.1875rem)',
            borderRadius: borderRadiusCircle,
            border: borderWidthThin + ' solid white',
            backgroundColor: colorTextAlertError
        }), _defineProperty(_global, '.rbc-compact', {
            '& .rbc-time-header-content': {
                borderLeft: 0
            },
            '& .rbc-allday-cell .rbc-row': {
                minHeight: 0
            },
            '& .rbc-allday-cell .rbc-row-segment': {
                flexBasis: 'inherit',
                maxWidth: 'inherit',
                width: '100%',
                marginRight: spacing30
            },
            '& .rbc-allday-cell .rbc-event-allday': {
                height: spacing80
            },
            '& .rbc-time-content': {
                paddingTop: '.625rem'
            },
            '& .rbc-time-content > * + * > *': {
                borderLeft: '0px'
            },
            '& .rbc-timeslot-group': {
                height: sizingXxLarge
            },
            '& .rbc-day-slot > .rbc-timeslot-group': {
                borderTop: borderWidthThin + ' solid ' + theme.palette.grey[250]
            },
            '& .rbc-time-slot': {
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                width: '5.5rem',
                paddingRight: spacing40,
                transform: 'translateY(-.625rem)'
            },
            '& .rbc-time-header-gutter': {
                padding: '0'
            },
            '& .rbc-time-gutter > .rbc-timeslot-group': {
                backgroundColor: 'white'
            },
            '& .rbc-label': {
                padding: '0px'
            }
        }), _defineProperty(_global, '.rbc-gutterHeader', {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            width: '5.5rem',
            height: sizingXxLarge,
            paddingRight: spacing40
        }), _global)
    };
};

export default ReactBigCalendarStyles;