import Constants from './Constants';
const Redirect = {
    refreshPage: () => {
        window.location.assign(window.location.href);
    },
    toError: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error`);
    },
    toError400: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error400`);
    },
    toError401: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error401`);
    },
    toError403: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error403`);
    },
    toError404: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error404`);
    },
    toError500: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error500`);
    },
    toError503: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error503`);
    },
    toHome: () => {
        window.location.assign(`${Constants.webUrl}/Home`);
    },
    toLogin: () => {
        window.location.assign(`${Constants.webUrl}/Home/LogIn`);
    },
    toLogout: () => {
        window.location.assign(`${Constants.webUrl}/Home/LogOut`);
    }
};
export default Redirect;
