/* CTA taupe base color */
export var colorCtaTaupeBase = "#72665B";
/* CTA blue tint color */
export var colorCtaBlueTint = "#F2F8FC";
/* CTA iris active color */
export var colorCtaIrisActive = "#3A3B87";
/* CTA green hover color */
export var colorCtaGreenHover = "#046D44";
/* CTA plum active color */
export var colorCtaPlumActive = "#502F5E";
/* CTA green base color */
export var colorCtaGreenBase = "#00834F";
/* Brand Neutral 250 color */
export var colorBrandNeutral250 = "#E9E9E9";
/* CTA taupe tint color */
export var colorCtaTaupeTint = "#F8F7F7";
/* CTA taupe hover color */
export var colorCtaTaupeHover = "#5F564E";
/* CTA blue active color */
export var colorCtaBlueActive = "#0A4982";
/* Brand Neutral 100 color */
export var colorBrandNeutral100 = "#FFFFFF";
/* Brand Neutral 200 color */
export var colorBrandNeutral200 = "#F8F8F8";
/* Brand CTA hover color */
export var colorBrandCtaHover = "#065AA5";
/* Brand Neutral 300 color */
export var colorBrandNeutral300 = "#D9D9D9";
/* Brand secondary base color */
export var colorBrandSecondary = "#51ABFF";
/* CTA iris base color */
export var colorCtaIrisBase = "#5353D1";
/* Brand Neutral 400 color */
export var colorBrandNeutral400 = "#B2B3B7";
/* CTA green tint color */
export var colorCtaGreenTint = "#F2F9F6";
/* CTA plum base color */
export var colorCtaPlumBase = "#783F8C";
/* Brand Neutral 500 color */
export var colorBrandNeutral500 = "#5B5E65";
/* Brand CTA active color */
export var colorBrandCtaActive = "#0A4982";
/* Brand Neutral 600 color */
export var colorBrandNeutral600 = "#151618";
/* CTA blue hover color */
export var colorCtaBlueHover = "#065AA5";
/* CTA taupe active color */
export var colorCtaTaupeActive = "#4D4640";
/* CTA blue base color */
export var colorCtaBlueBase = "#026BC8";
/* CTA iris tint color */
export var colorCtaIrisTint = "#F6F6FD";
/* CTA plum hover color */
export var colorCtaPlumHover = "#643775";
/* CTA plum tint color */
export var colorCtaPlumTint = "#F8F5F9";
/* CTA green active color */
export var colorCtaGreenActive = "#085739";
/* CTA iris hover color */
export var colorCtaIrisHover = "#4747AC";
/* Brand CTA base color */
export var colorBrandCtaBase = "#026BC8";
/* Brand primary base color */
export var colorBrandPrimary = "#5353D1";
export var fountain400 = "#90C9FF";
export var iris300 = "#B0B0EA";
export var fountain500 = "#70BAFF";
export var iris400 = "#9191E2";
export var kiwi100 = "#F4FAEF";
export var iris500 = "#7272D9";
export var kiwi200 = "#E1F2D3";
export var fountain600 = "#51ABFF";
export var kiwi300 = "#CEEAB7";
export var colorBackgroundHeaderBarItemSelected = "#21226E";
export var iris600 = "#5353D1";
export var kiwi400 = "#BBE29B";
export var tangerine100 = "#FFF4EB";
export var kiwi500 = "#A8D97E";
export var tangerine200 = "#FFDFC8";
/* Fill color to be used behind white warning icon. */
export var colorFillAlertWarning = "#EFC728";
export var kiwi600 = "#95D162";
export var tangerine300 = "#FFCAA4";
export var tangerine400 = "#FFB581";
/* Global divider color. */
export var colorBackgroundDivider = "#B2B3B7";
export var tangerine500 = "#FFA15D";
export var tangerine600 = "#FF8C3A";
export var colorFillLogoAlternativeText = "#454647";
/* Fill color to be used behind white success icon. */
export var colorFillAlertSuccess = "#00AF69";
export var meadow100 = "#E8F9F5";
export var saffron100 = "#FDF9EA";
export var saffron200 = "#FBEFC3";
export var meadow200 = "#BFEDE2";
/* Default background color that should be applied to all pages other than login and dashboard pages. */
export var colorBackgroundDefault = "#F8F8F8";
export var colorFillLogoPreferred = "#FFFFFF";
export var saffron300 = "#F8E59C";
export var meadow300 = "#96E2CF";
/* Background color for warning alerts and notifications. */
export var colorBackgroundAlertWarning = "#F5F2E7";
export var saffron400 = "#F5DB75";
/* Fill color to be used behind white neutral/information icon. */
export var colorFillAlertNeutral = "#51ABFF";
export var meadow400 = "#6CD6BC";
/* Fill color to be used behind white error icon. */
export var colorFillAlertError = "#D42828";
export var saffron500 = "#F2D14F";
export var meadow500 = "#43CBA9";
export var saffron600 = "#EFC728";
export var meadow600 = "#1ABF96";
export var colorBackgroundHeaderBarItemHover = "#2E2E93";
export var purple100 = "#F8EEF6";
export var purple200 = "#ECCCE6";
/* Background color for default alerts and notifications. */
export var colorBackgroundAlertDefault = "#F8F8F8";
export var purple300 = "#DFAAD6";
/* Background color for success alerts and notifications. */
export var colorBackgroundAlertSuccess = "#EAF7F2";
export var purple400 = "#D389C6";
export var purple500 = "#C667B6";
export var purple600 = "#BA46A6";
export var colorFillLogoAlternativePowerButton = "#5353D1";
/* Background color for neutral alerts and notifications. */
export var colorBackgroundAlertNeutral = "#EFF7FF";
export var fountain100 = "#EFF7FF";
/* Background color for error alerts and notifications. */
export var colorBackgroundAlertError = "#FFEEED";
export var fountain200 = "#CEE7FF";
export var iris100 = "#EEEEFA";
export var fountain300 = "#AFD8FF";
export var iris200 = "#CFCFF2";
/* Border width tokens should only be used within the border property. */
export var borderWidthThin = "0.063rem";
/* Border width tokens should only be used within the border property. */
export var borderWidthThick = "0.125rem";
/* Border width tokens should only be used within the border property. */
export var borderWidthThicker = "0.1875rem";
/* Border width tokens should only be used within the border property. */
export var borderWidthThickest = "0.25rem";
/* Global focus color for better accessibility. */
export var colorBorderGlobalFocus = "#51ABFF";
/* Global error state border color. */
export var colorGlobalBorderError = "#D42828";
/* Global default state border color. */
export var colorGlobalBorderDefault = "#B2B3B7";
/* Global disabled state border color. */
export var colorGlobalBorderDisabled = "#B2B3B7";
/* Border color for success alerts and notifications. */
export var colorBorderAlertSuccess = "#00AF69";
/* Border color for success alerts and notifications. */
export var colorBorderAlertWarning = "#EFC728";
/* Border color for success alerts and notifications. */
export var colorBorderAlertNeutral = "#51ABFF";
/* Border color for success alerts and notifications. */
export var colorBorderAlertError = "#D42828";
/* 0 second */
export var durationNone = "0s";
/* .1 second */
export var durationFast = ".1s";
/* .15 seconds */
export var durationFastAlt = ".15s";
/* .3 seconds */
export var durationMedium = ".3s";
/* .5 seconds */
export var durationSlow = ".5s";
export var fontFamilyDefault = "'Noto Sans', Helvetica, Arial, sans-serif";
export var fontFamilyHeader = "'Roboto', Helvetica, Arial, sans-serif";
export var fontFamilyCondensed = "'Roboto Condensed', 'Impact', 'Franklin Gothic Bold', sans-serif";
export var fontFamilyMonospace = "'Courier New', Courier, monospace";
/* Large body text. */
export var fontSizeLarge = "1rem";
/* h5 small font size. */
export var fontSizeHeader5Small = "0.75rem";
/* h4 small font size. */
export var fontSizeHeader4Small = "0.875rem";
/* h3 small font size. */
export var fontSizeHeader3Small = "1rem";
/* Default body text. */
export var fontSizeDefault = "0.875rem";
/* h2 small font size. */
export var fontSizeHeader2Small = "1.375rem";
/* h1 small font size. */
export var fontSizeHeader1Small = "1.75rem";
/* h1 font size. */
export var fontSizeHeader1 = "2rem";
/* h2 font size. */
export var fontSizeHeader2 = "1.5rem";
/* h3 font size. */
export var fontSizeHeader3 = "1.125rem";
/* h4 font size. */
export var fontSizeHeader4 = "1rem";
/* Small body text. */
export var fontSizeSmall = "0.75rem";
/* h5 font size. */
export var fontSizeHeader5 = "0.875rem";
/* h6 font size. */
export var fontSizeHeader6 = "0.75rem";
/* Light font weight. */
export var fontWeightLight = 300;
/* Normal or regular font weight. */
export var fontWeightNormal = 400;
/* Use fontWeightBold instead. */
export var fontWeightStrong = 600;
/* Bold font weight. */
export var fontWeightBold = 700;
/* Minimum accessible line height value. */
export var lineHeightDefault = 1.5;
export var lineHeightParagraphLarge = "1.375";
export var lineHeightParagraphMedium = "1.43";
export var lineHeightHeader1 = "1.17";
export var lineHeightHeader2 = "1.23";
export var lineHeightHeader3 = "1.33";
export var lineHeightHeader4 = "1.33";
export var lineHeightParagraphSmall = "1.5";
export var lineHeightReset = "1";
export var lineHeightHeader2Small = "1.25";
/* Screens smaller than 480px. */
export var mqXSmall = "only screen and (min-width: 0px) and (max-width: 479px)";
/* Screens smaller than 768px. */
export var mqSmallDown = "only screen and (min-width: 0px) and (max-width: 767px)";
/* Screens 480px or larger. */
export var mqSmall = "only screen and (min-width: 480px)";
/* Screens smaller than 992px. */
export var mqMediumDown = "only screen and (min-width: 0px) and (max-width: 991px)";
/* Screens 768px or larger. */
export var mqMedium = "only screen and (min-width: 768px)";
/* Screens 992px or larger. */
export var mqLarge = "only screen and (min-width: 992px)";
/* 20% of transparency of an element */
export var opacity2 = "0.2";
/* 30% of transparency of an element */
export var opacity3 = "0.3";
/* 40% of transparency of an element */
export var opacity4 = "0.4";
/* 70% of transparency of an element */
export var opacity7 = "0.7";
/* 80% of transparency of an element */
export var opacity8 = "0.8";
/* Generic reset border radius for global use. */
export var borderRadiusReset = "0";
/* Circle border radius for global use. */
export var borderRadiusCircle = "50%";
/* Generic extra small border radius value. */
export var borderRadiusXSmall = "0.125rem";
/* Generic small border radius value. */
export var borderRadiusSmall = "0.25rem";
/* Generic medium border radius value. */
export var borderRadiusMedium = "0.375rem";
/* Generic large border radius value. */
export var borderRadiusLarge = "1rem";
/* Generic extra large border radius value. */
export var borderRadiusXLarge = "6.25rem";
export var boxShadowReset = "none";
/* Focus state border, inset. */
export var boxShadowFocusInset = "inset 0 0 0 0.125rem #51ABFF";
/* Focus state border, default outset. */
export var boxShadowFocus = "0 0 0 0.125rem #51ABFF";
/* Error state border, outset. */
export var boxShadowError = "0 0 0 0.063rem #D42828";
export var sizingXLarge = "2.5rem";
/* Height used to span the entire height of its children. */
export var heightFluid = "100%";
/* Width used to span the entirety of its parent container. */
export var widthFluid = "100%";
export var sizingSmall = "1rem";
export var sizingXxSmall = "0.25rem";
/* Height of small input fields. */
export var heightInputFieldsSmall = "2.375rem";
export var sizingXSmall = "0.5rem";
export var sizingMedium = "1.5rem";
export var sizingXxLarge1 = "4rem";
/* Height of input fields. */
export var heightInputFields = "3.375rem";
export var sizingLarge = "2rem";
/* Width of input fields that are not full width. */
export var widthInputFields = "20.9375rem";
export var sizingXxLarge = "3rem";
/* Height of the HeaderBar component. */
export var heightHeaderBar = "4rem";
/* Use '12px or 0.75rem' */
export var spacingXSmall2 = "0.75rem";
/*  */
export var spacing30 = "0.5rem";
/*  */
export var spacing20 = "0.25rem";
/*  */
export var spacing10 = "0.125rem";
/* Use 'spacing-20' */
export var spacingXxSmall = "0.25rem";
/* Use 'spacing-40' */
export var spacingSmall = "1rem";
/* Use 'spacing-90' */
export var spacingXxxLarge = "4.5rem";
/* Use 'spacing-70' */
export var spacingXLarge = "2.5rem";
/* Use 'spacing-30' */
export var spacingXSmall = "0.5rem";
/* Use 'spacing-60' */
export var spacingLarge = "2rem";
/* Use 'spacing-50' */
export var spacingMedium = "1.5rem";
/* Use 'spacing-80' */
export var spacingXxLarge = "3rem";
/* Use '64px or 4rem' */
export var spacingXxLarge1 = "4rem";
/*  */
export var spacing90 = "4.5rem";
/*  */
export var spacing80 = "3rem";
/*  */
export var spacing70 = "2.5rem";
/* Padding around TextField, DatePicker, and TimePicker component inputs. */
export var paddingTextField = "1.375rem 1rem .625rem 1rem";
/*  */
export var spacing60 = "2rem";
/*  */
export var spacing50 = "1.5rem";
/* Use '10px or 0.625rem' */
export var spacingXSmall1 = "0.625rem";
/*  */
export var spacing40 = "1rem";
/*  */
export var spacingInsetShort20 = "0.5rem 1rem";
/*  */
export var spacingInsetTall60 = "4.5rem 3rem";
/*  */
export var spacingInsetShort10 = "0.25rem 0.5rem";
/*  */
export var spacingInsetTall50 = "3rem 2rem";
/*  */
export var spacingInsetTall40 = "2.25rem 1.5rem";
/*  */
export var spacingInsetTall30 = "1.5rem 1rem";
/*  */
export var spacingInsetTall20 = "0.75rem 0.5rem";
/*  */
export var spacingInsetTall10 = "0.375rem 0.25rem";
/*  */
export var spacingInset70 = "4rem";
/*  */
export var spacingInset60 = "3rem";
/*  */
export var spacingInset50 = "2rem";
/*  */
export var spacingInset40 = "1.5rem";
/*  */
export var spacingInset30 = "1rem";
/*  */
export var spacingInset20 = "0.5rem";
/*  */
export var spacingInset10 = "0.25rem";
/*  */
export var spacingInsetShort60 = "2rem 3rem";
/*  */
export var spacingInsetShort50 = "1.25rem 2.5rem";
/*  */
export var spacingInsetShort40 = "1rem 2rem";
/*  */
export var spacingInsetShort30 = "0.75rem 1.5rem";
/*  */
export var layout10 = "1rem";
/*  */
export var layout20 = "1.5rem";
/*  */
export var layout30 = "2rem";
/*  */
export var layout40 = "3rem";
/*  */
export var layout50 = "4rem";
/*  */
export var layout60 = "6rem";
/*  */
export var layout70 = "7.5rem";
export var colorTextSecondary = "#5B5E65";
export var colorTextDisabled = "#5B5E65";
/* Text color for warning and draft notifications. */
export var colorTextAlertWarning = "#8A6A00";
export var colorTextPrimary = "#151618";
/* Text color for default notifications. */
export var colorTextAlertDefault = "#5B5E65";
export var colorTextNeutral250 = "#E9E9E9";
/* Text color for success notifications. */
export var colorTextAlertSuccess = "#00804D";
export var colorTextError = "#D42828";
export var colorTextNeutral100 = "#FFFFFF";
/* Text color for hint, neutral and pending notifications. */
export var colorTextAlertNeutral = "#2874BB";
export var colorTextNeutral200 = "#F8F8F8";
/* Text color for error and failure notifications. */
export var colorTextAlertError = "#D42828";
export var colorTextNeutral300 = "#D9D9D9";
export var colorTextNeutral400 = "#B2B3B7";
export var colorTextNeutral500 = "#5B5E65";
export var colorTextNeutral600 = "#151618";
export var zIndexDropdown = 1450;
export var zIndexMegaMenu = 1500;
export var zIndexDefault = 1;
export var zIndexDrawer = 1200;
export var zIndexAppBar = 1100;
export var zIndexTooltip = 1500;
export var zIndexInlineAlert = 1425;
export var zIndexActionMenu = 1450;
export var zIndexHeaderBar = 1500;
export var zIndexBackdrop = 1500;
export var zIndexSmall = 2;
export var zIndexDatePicker = 1050;
export var zIndexModal = 1500;
export var zIndexPageAlert = 1475;
export var zIndexMobileStepper = 1000;
export var zIndexSnackbar = 1600;
export var zIndexTextFieldLabel = 1;
export var zIndexHeaderBarDropdown = 1450;