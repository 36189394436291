import React from 'react';

var SvgEmptySet = function SvgEmptySet(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                d: "M59.3345029,3.19385965 L56.6888889,0.548245614 C55.9578947,-0.182748538 54.7730994,-0.182748538 54.0421053,0.548245614 L45.6105263,8.97982456 C41.2362573,5.70497076 35.825731,3.74239766 29.9415205,3.74239766 C15.4725146,3.74239766 3.74269006,15.4722222 3.74269006,29.9412281 C3.74269006,35.824269 5.70526316,41.2359649 8.98011696,45.6090643 L0.548538012,54.0418129 C-0.18245614,54.772807 -0.18245614,55.9576023 0.548538012,56.6885965 L3.19532164,59.3353801 C3.92631579,60.0663743 5.11111111,60.0663743 5.84210526,59.3353801 L14.2736842,50.9038012 C18.6467836,54.1774854 24.0584795,56.1400585 29.9415205,56.1400585 C44.4105263,56.1400585 56.1403509,44.4102339 56.1403509,29.9412281 C56.1403509,24.0581871 54.1777778,18.6464912 50.902924,14.2733918 L59.3345029,5.84181287 C60.0654971,5.10964912 60.0654971,3.9248538 59.3345029,3.19385965 Z M13.0994152,29.9412281 C13.0994152,20.6546784 20.6549708,13.0991228 29.9415205,13.0991228 C33.2292398,13.0991228 36.2760234,14.0827485 38.871345,15.7178363 L15.7181287,38.8710526 C14.0830409,36.275731 13.0994152,33.2289474 13.0994152,29.9412281 Z M46.7836257,29.9412281 C46.7836257,39.2277778 39.2280702,46.7833333 29.9415205,46.7833333 C26.6538012,46.7833333 23.6070175,45.7997076 21.0116959,44.1646199 L44.1637427,21.0114035 C45.8,23.6067251 46.7836257,26.6535088 46.7836257,29.9412281 Z",
                fill: "currentColor"
            }),
            React.createElement("path", {
                d: "M59.3345029,3.19385965 L56.6888889,0.548245614 C55.9578947,-0.182748538 54.7730994,-0.182748538 54.0421053,0.548245614 L45.6105263,8.97982456 C41.2362573,5.70497076 35.825731,3.74239766 29.9415205,3.74239766 C15.4725146,3.74239766 3.74269006,15.4722222 3.74269006,29.9412281 C3.74269006,35.824269 5.70526316,41.2359649 8.98011696,45.6090643 L0.548538012,54.0418129 C-0.18245614,54.772807 -0.18245614,55.9576023 0.548538012,56.6885965 L3.19532164,59.3353801 C3.92631579,60.0663743 5.11111111,60.0663743 5.84210526,59.3353801 L14.2736842,50.9038012 C18.6467836,54.1774854 24.0584795,56.1400585 29.9415205,56.1400585 C44.4105263,56.1400585 56.1403509,44.4102339 56.1403509,29.9412281 C56.1403509,24.0581871 54.1777778,18.6464912 50.902924,14.2733918 L59.3345029,5.84181287 C60.0654971,5.10964912 60.0654971,3.9248538 59.3345029,3.19385965 Z M13.0994152,29.9412281 C13.0994152,20.6546784 20.6549708,13.0991228 29.9415205,13.0991228 C33.2292398,13.0991228 36.2760234,14.0827485 38.871345,15.7178363 L15.7181287,38.8710526 C14.0830409,36.275731 13.0994152,33.2289474 13.0994152,29.9412281 Z M46.7836257,29.9412281 C46.7836257,39.2277778 39.2280702,46.7833333 29.9415205,46.7833333 C26.6538012,46.7833333 23.6070175,45.7997076 21.0116959,44.1646199 L44.1637427,21.0114035 C45.8,23.6067251 46.7836257,26.6535088 46.7836257,29.9412281 Z",
                fill: "#FFFFFF",
                opacity: "0.88"
            }),
            React.createElement("path", {
                d: "M59.6865954,1.82816581 L58.1718636,0.313399853 C57.7540065,-0.104466618 57.0749888,-0.104466618 56.6571317,0.313399853 L46.5964194,10.3743387 C42.1151703,6.58273622 36.3294571,4.28580995 30,4.28580995 C15.7982112,4.28580995 4.28571344,15.7985669 4.28571344,30.0006697 C4.28571344,36.3302752 6.58258799,42.1161187 10.3741051,46.5961294 L0.313392796,56.6570682 C-0.104464265,57.0749347 -0.104464265,57.7539677 0.313392796,58.1718342 L1.82812464,59.6866001 C2.2459817,60.1044666 2.92499942,60.1044666 3.34285649,59.6866001 L13.4035688,49.6256613 C17.8848179,53.4186031 23.6705311,55.7155294 30,55.7155294 C44.201777,55.7155294 55.7142748,44.2027724 55.7142748,30.0006697 C55.7142748,23.6710641 53.4174002,17.8852207 49.6258831,13.4052099 L59.6865954,3.34427108 C60.1044682,2.92528792 60.1044682,2.24714897 59.6865954,1.82816581 Z M8.57142689,30.0006697 C8.57142689,18.1852273 18.1848179,8.5716199 29.9999941,8.5716199 C35.1348145,8.5716199 39.7901707,10.4627335 43.4852593,13.4855689 L13.4852652,43.4862385 C10.4611587,39.7910668 8.57142689,35.1356057 8.57142689,30.0006697 Z M51.4285613,30.0006697 C51.4285613,41.816112 41.8151703,51.4297194 29.9999941,51.4297194 C24.8651737,51.4297194 20.2098175,49.5386058 16.5147289,46.5157704 L46.5147289,16.5151008 C49.5388295,20.2102726 51.4285613,24.8657336 51.4285613,30.0006697 Z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgEmptySet;