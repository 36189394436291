import { LogType } from '../types/LogType';
import LogData from '../helpers/LogData';
import Constants from './Constants';
import Redirect from './Redirect';
import LayoutActions from '../flux/actions/LayoutActions';
import LayoutStore from '../flux/stores/LayoutStore';
const onRejectRequest = (info) => {
    console.warn('The request was rejected');
    console.debug(info);
};
const onCatchRequest = (ex, functionName) => {
    if (ex.code !== 20) {
        const logData = LogData.fromException(functionName, ex);
        logData.type = LogType.NoConnection;
        LayoutActions.setLogData(logData);
        Redirect.toError500();
    }
};
const onResolveRequest = (response, functionName, resolver) => {
    if (!(response && response.ok)) {
        LayoutActions.setLogData(LogData.fromResponse(functionName, response));
    }
    else if (response.redirected) {
        if (response.url.indexOf('LogIn?ReturnUrl') >= 0) {
            window.location.assign(window.location.href);
        }
        else if (response.url.indexOf('Error400') >= 0) {
            Redirect.toError400();
        }
        else if (response.url.indexOf('Error401') >= 0) {
            Redirect.toError401();
        }
        else if (response.url.indexOf('Error403') >= 0) {
            Redirect.toError403();
        }
        else if (response.url.indexOf('Error404') >= 0) {
            Redirect.toError404();
        }
        else if (response.url.indexOf('Error500') >= 0) {
            Redirect.toError500();
        }
        else if (response.url.indexOf('Error503') >= 0) {
            Redirect.toError503();
        }
    }
    else {
        LayoutActions.resetTimeout();
        if (resolver) {
            response.json()
                .then(resolver)
                .catch(ex => {
                LayoutActions.setLogData(LogData.fromResponse(functionName, ex));
            });
        }
    }
};
const Request = {
    get(functionName, url, resolver, resolveError, signal) {
        try {
            fetch(`${Constants.webUrl}${url}`, {
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: Constants.headersRequestsJson,
                method: 'get',
                signal: signal ? signal : LayoutStore.getSignal()
            })
                .then(response => onResolveRequest(response, functionName, resolver), onRejectRequest)
                .catch(ex => onCatchRequest(ex, functionName));
            if (resolveError) {
                console.warn('resolveError parameter was deprecated.');
            }
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(functionName, e));
        }
    },
    post(functionName, url, data, resolver, resolveError, signal) {
        try {
            fetch(`${Constants.webUrl}${url}`, {
                body: JSON.stringify(data),
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: Constants.headersRequestsJson,
                method: 'post',
                signal: signal ? signal : LayoutStore.getSignal()
            })
                .then(response => onResolveRequest(response, functionName, resolver), onRejectRequest)
                .catch(ex => onCatchRequest(ex, functionName));
            if (resolveError) {
                console.warn('resolveError parameter was deprecated.');
            }
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(functionName, e));
        }
    }
};
export default Request;
