var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import EdsIcon from '@eui/ds-icons/lib/es6/Icon';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    default: {
        color: Tokens.colorBrandNeutral400
    },
    defaultBg: {
        background: Tokens.colorBrandNeutral400
    },
    error: {
        color: Tokens.colorTextAlertError
    },
    errorBg: {
        background: Tokens.colorFillAlertError
    },
    iconBackground: {
        alignItems: 'center',
        borderRadius: Tokens.borderRadiusCircle,
        color: Tokens.colorBrandNeutral100,
        display: 'inline-flex',
        height: '1.8125rem',
        justifyContent: 'center',
        verticalAlign: 'middle',
        width: '1.8125rem'
    },
    info: {
        color: Tokens.colorTextAlertNeutral
    },
    infoBg: {
        background: Tokens.colorFillAlertNeutral
    },
    marginBottom2Icon: {
        marginBottom: Tokens.spacing35
    },
    marginBottomIcon: {
        marginBottom: Tokens.spacing30
    },
    marginLeft2Icon: {
        marginLeft: Tokens.spacing35
    },
    marginLeftIcon: {
        marginLeft: Tokens.spacing30
    },
    marginRight2Icon: {
        marginRight: Tokens.spacing35
    },
    marginRightIcon: {
        marginRight: Tokens.spacing30
    },
    marginTop2Icon: {
        marginTop: Tokens.spacing35
    },
    marginTopIcon: {
        marginTop: Tokens.spacing30
    },
    middle: {
        display: 'inline-flex',
        verticalAlign: 'middle'
    },
    neutral: {
        color: Tokens.colorBrandNeutral100
    },
    neutralBg: {
        background: Tokens.colorBrandNeutral100
    },
    success: {
        color: Tokens.colorTextAlertSuccess
    },
    successBg: {
        background: Tokens.colorFillAlertSuccess
    },
    warning: {
        color: Tokens.colorTextAlertWarning
    },
    warningBg: {
        background: Tokens.colorFillAlertWarning
    }
});
const Icon = (props, ref) => {
    const { className, classes, iconWithBackground, marginBottom, marginBottom2, marginLeft, marginLeft2, marginRight, marginRight2, marginTop, marginTop2, type, verticalAlign } = props, rest = __rest(props, ["className", "classes", "iconWithBackground", "marginBottom", "marginBottom2", "marginLeft", "marginLeft2", "marginRight", "marginRight2", "marginTop", "marginTop2", "type", "verticalAlign"]);
    let classIcon;
    let classIconDiv;
    if (type) {
        switch (type) {
            case 'error':
                classIcon = iconWithBackground ? classes.errorBg : classes.error;
                break;
            case 'info':
                classIcon = iconWithBackground ? classes.infoBg : classes.info;
                break;
            case 'success':
                classIcon = iconWithBackground ? classes.successBg : classes.success;
                break;
            case 'warning':
                classIcon = iconWithBackground ? classes.warningBg : classes.warning;
                break;
            case 'neutral':
                classIcon = iconWithBackground ? classes.neutralBg : classes.neutral;
                break;
            case 'default':
                classIcon = iconWithBackground ? classes.defaultBg : classes.default;
                break;
        }
    }
    if (marginBottom) {
        classIcon = classnames(classIcon, classes.marginBottomIcon);
    }
    else if (marginBottom2) {
        classIcon = classnames(classIcon, classes.marginBottom2Icon);
    }
    if (marginLeft) {
        classIcon = classnames(classIcon, classes.marginLeftIcon);
    }
    else if (marginLeft2) {
        classIcon = classnames(classIcon, classes.marginLeft2Icon);
    }
    if (marginRight) {
        classIcon = classnames(classIcon, classes.marginRightIcon);
    }
    else if (marginRight2) {
        classIcon = classnames(classIcon, classes.marginRight2Icon);
    }
    if (marginTop) {
        classIcon = classnames(classIcon, classes.marginTopIcon);
    }
    else if (marginTop2) {
        classIcon = classnames(classIcon, classes.marginTop2Icon);
    }
    if (className) {
        classIcon = classnames(classIcon, className);
    }
    if (iconWithBackground || verticalAlign) {
        if (verticalAlign === 'middle') {
            classIconDiv = classnames(classIcon, classes.middle);
        }
        else if (iconWithBackground) {
            classIconDiv = classnames(classIcon, classes.iconBackground);
        }
        else {
            classIconDiv = classIcon;
        }
        return (React.createElement("div", { className: classIconDiv },
            React.createElement(EdsIcon, Object.assign({ ref: ref }, rest))));
    }
    return (React.createElement(EdsIcon, Object.assign({ className: classIcon, ref: ref }, rest)));
};
const IconFRC = forwardRef(Icon);
IconFRC.displayName = 'IconFRC';
export default withStyles(styles, { name: 'IconWS' })(IconFRC);
