function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiAvatarGroup } from '@material-ui/lab/AvatarGroup';
// Necessary design tokens are imported here
import { colorBrandNeutral500 } from '../styles/tokens';

var styles = {
    root: {},
    colorDefault: {
        backgroundColor: colorBrandNeutral500
    }
};

var AvatarGroup = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        children = props.children,
        rest = _objectWithoutProperties(props, ['classes', 'children']);

    var classOverrides = {
        root: classes.root,
        avatar: classes.colorDefault
    };

    return React.createElement(
        MuiAvatarGroup,
        Object.assign({}, rest, {
            classes: classOverrides,
            ref: ref
        }),
        children
    );
});

AvatarGroup.muiName = 'AvatarGroup';
AvatarGroup.displayName = 'AvatarGroup';

AvatarGroup.propTypes = {
    /**
     * The avatars to stack.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Max avatars to show before +x.
     * * Value should be greater than or equal to two.
    */
    max: PropTypes.number,
    /**
     * Spacing between avatars.
     * * number:
     *  * Positive number would decrease space between Avatars
     *  * Negative number would increase space between Avatars
     */
    spacing: PropTypes.oneOf(['medium', 'small', 'number'])
};

AvatarGroup.defaultProps = {
    spacing: 'medium',
    max: '5'
};

export default withStyles(styles)(AvatarGroup);