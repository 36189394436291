import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import { default as withStyles } from '../styles';

import { fontSizeSmall, spacingXSmall } from '../styles/tokens';

export var styles = function styles(theme) {
  return {
    root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: fontSizeSmall,
      color: theme.palette.text.secondary,
      '&$error': {
        color: theme.palette.status.error.text
      },
      '&$disabled': {
        color: theme.palette.text.disabled
      },
      marginTop: spacingXSmall,
      textTransform: 'none',
      letterSpacing: 'unset'
    },
    error: {},
    disabled: {},
    marginDense: {
      marginTop: theme.spacing(0.5)
    }
  };
};

/**
 *  A wrapper for standardized states for text near inputs in a form, disabled/error.
 */
var FormHelperText = React.forwardRef(function (props, ref) {
  return React.createElement(MuiFormHelperText, Object.assign({}, props, { ref: ref }));
});

FormHelperText.muiName = 'FormHelperText';
FormHelperText.displayName = 'FormHelperText';

FormHelperText.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * If `true`, the helper text should be displayed in a disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, helper text should be displayed in an error state.
   */
  error: PropTypes.bool,
  /**
   * If `true`, the helper text should use filled classes key.
   */
  filled: PropTypes.bool,
  /**
   * If `true`, the helper text should use focused classes key.
   */
  focused: PropTypes.bool,
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin: PropTypes.oneOf(['dense']),
  /**
   * If `true`, the helper text should use required classes key.
   */
  required: PropTypes.bool
};

FormHelperText.defaultProps = {
  component: 'p'
};

FormHelperText.contextTypes = {
  muiFormControl: PropTypes.object
};

export default withStyles(styles)(FormHelperText);