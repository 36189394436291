var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Manager, Target, Popper } from 'react-popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { default as withStyles } from '../styles';
import { HeaderBarContext } from './HeaderBar';
import Typography from '../Typography';

import { borderWidthThick, borderWidthThin, colorBrandNeutral100, colorBrandNeutral300, colorBrandPrimary, colorCtaBlueBase, colorTextNeutral500, spacingSmall, spacingXSmall, spacingMedium, fontSizeSmall, fontSizeDefault } from '../styles/tokens';

var colorBackgroundDropdownThemed = colorBrandNeutral100;
var colorBackgroundDropdownHoverThemed = colorBrandNeutral300;

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            cursor: 'pointer',
            "& [class*='focusVisible']": {
                backgroundColor: colorCtaBlueBase
            },
            "& .utility [class*='focusVisible']": {
                backgroundColor: 'transparent',
                color: colorCtaBlueBase
            }
        },
        dropdownLabel: {
            padding: spacingMedium + ' 0'
        },
        dropdownUtilityLabel: {
            paddingLeft: spacingSmall,
            paddingRight: spacingSmall,
            fontSize: theme.typography.body2.fontSize,
            display: 'flex'
        },
        dropdownMenu: {
            width: '12.5rem',
            backgroundColor: colorBrandPrimary,
            '&.themed': {
                backgroundColor: colorBackgroundDropdownThemed
            },

            '&.themed .headerbar-dropdown-menu-item': {
                color: colorTextNeutral500
            },
            '&.themed :hover': {
                backgroundColor: colorBackgroundDropdownHoverThemed
            },
            '&.themed :focus': {
                backgroundColor: colorBackgroundDropdownHoverThemed
            }
        },
        dropdownMenuUtility: {
            width: '12.5rem',
            fontSize: fontSizeDefault,
            paddingTop: 0
        },
        dropdownMenuItem: {
            color: theme.palette.grey[100],
            whiteSpace: 'normal',
            height: 'auto',
            minHeight: 'auto',
            fontSize: fontSizeSmall,
            padding: spacingXSmall + ' ' + spacingSmall,
            '&:hover': {
                // TODO: We will need logic to figure this out
                // NOTE: This is a placeholder
                backgroundColor: theme.palette.primary.dark
            },
            '&:focus': {
                backgroundColor: theme.palette.action.base
            }
        },
        dropdownMenuItemUtility: {
            color: theme.palette.grey[600],
            whiteSpace: 'normal',
            height: 'auto',
            fontSize: fontSizeDefault,
            '&:not(:last-child)': {
                borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[400]
            },
            padding: spacingXSmall + ' 0.1rem',
            margin: '0 ' + spacingSmall,
            '&:hover': {
                backgroundColor: 'transparent',
                color: colorCtaBlueBase
            },
            '& a': {
                color: theme.palette.grey[600] + ' !important',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: colorCtaBlueBase + ' !important'
                }
            }
        },
        labeledContent: {
            display: 'flex',
            alignItems: 'center',
            '& div': {
                paddingLeft: spacingXSmall,
                paddingTop: spacingXSmall
            }
        },
        popperOpen: {
            top: '1.35rem !important', // Popper adds inline style so had to use `!important`
            zIndex: theme.zIndex.headerBarDropdown,
            '&.utility': {
                top: '1.2rem !important' // Popper adds inline style so had to use `!important`
            },
            '&> div': {
                borderRadius: 0
            }
        },
        popperClose: {
            top: '1.35rem !important', // Popper adds inline style so had to use `!important`
            pointerEvents: 'none',
            '&.utility': {
                top: '1.2rem !important' // Popper adds inline style so had to use `!important`
            }
        },
        dropdownMenuHeader: {
            borderBottom: borderWidthThick + ' solid ' + theme.palette.grey[400],
            margin: '0 ' + spacingSmall,
            padding: spacingSmall + ' 0'
        }
    };
};

var HeaderBarDropdownConsumer = function (_React$Component) {
    _inherits(HeaderBarDropdownConsumer, _React$Component);

    function HeaderBarDropdownConsumer() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, HeaderBarDropdownConsumer);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = HeaderBarDropdownConsumer.__proto__ || Object.getPrototypeOf(HeaderBarDropdownConsumer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            open: false
        }, _this.handleOpen = function (event, context) {
            if (!_this.props.disabled) {
                _this.setState({ open: true }, function () {
                    setTimeout(function () {
                        return context.setOpen(true);
                    });
                });
            }
        }, _this.handleClose = function (event, context, item) {

            // TODO: Use react's equivalent to check node name.
            if (event.type === 'click' && event.target.nodeName === 'LI') {
                var activeLink = document.querySelector('.activeLink');

                if (activeLink) {
                    activeLink.classList.remove('activeLink');
                }
            }

            if (item) {
                // look for child anchors, click them if they're there
                var anchor = event.target && event.target.querySelector('a');

                if (anchor) {
                    anchor.click();
                } else {
                    _this.props.onMenuItemClick(event, item);

                    // let our parent know that an item was selected
                    if (_this.props.itemSelected) {
                        _this.props.itemSelected();
                    }
                }
            }

            _this.setState({ open: false });

            setTimeout(function () {
                return context.setOpen(false);
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(HeaderBarDropdownConsumer, [{
        key: 'render',


        /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                classes = _props.classes,
                menuItems = _props.menuItems,
                dropdownMenuLabel = _props.dropdownMenuLabel,
                secondaryDropdownMenuLabel = _props.secondaryDropdownMenuLabel;
            var open = this.state.open;


            var dropdownMenuLabelLocal = dropdownMenuLabel;

            if (secondaryDropdownMenuLabel) {
                dropdownMenuLabelLocal = React.createElement(
                    'div',
                    { className: this.props.classes.labeledContent },
                    dropdownMenuLabel,
                    React.createElement(
                        'div',
                        { 'data-key': 'content-label' },
                        secondaryDropdownMenuLabel
                    )
                );
            }
            return React.createElement(
                HeaderBarContext.Consumer,
                null,
                function (context) {
                    var _classNames;

                    return React.createElement(
                        'div',
                        { className: classes.root,
                            onMouseOver: function onMouseOver(e) {
                                return _this2.handleOpen(e, context);
                            },
                            onFocus: function onFocus(e) {
                                return _this2.handleOpen(e, context);
                            },
                            onMouseLeave: function onMouseLeave(e) {
                                return _this2.handleClose(e, context);
                            },
                            onBlur: function onBlur(e) {
                                return _this2.handleClose(e, context);
                            } },
                        React.createElement(
                            Manager,
                            null,
                            React.createElement(
                                Target,
                                null,
                                React.createElement(
                                    'div',
                                    { onClick: function onClick(e) {
                                            return _this2.handleOpen(e, context);
                                        },
                                        ref: function ref(node) {
                                            _this2.target = node;
                                        }
                                    },
                                    React.createElement(
                                        'a',
                                        { tabIndex: 0, className: _this2.props.isUtility ? classes.dropdownUtilityLabel : classes.dropdownLabel,
                                            onMouseDown: function onMouseDown(e) {
                                                return e.stopPropagation();
                                            },
                                            onMouseUp: function onMouseUp(e) {
                                                return e.stopPropagation();
                                            }
                                        },
                                        dropdownMenuLabelLocal
                                    )
                                )
                            ),
                            React.createElement(
                                Popper,
                                {
                                    placement: _this2.props.isUtility ? 'bottom-end' : 'bottom',
                                    eventsEnabled: open,
                                    className: classNames((_classNames = {}, _defineProperty(_classNames, classes.popperClose, !open), _defineProperty(_classNames, classes.popperOpen, open), _defineProperty(_classNames, 'utility', _this2.props.isUtility), _classNames))
                                },
                                React.createElement(
                                    ClickAwayListener,
                                    { onClickAway: function onClickAway(e) {
                                            return _this2.handleClose(e, context);
                                        } },
                                    React.createElement(
                                        Grow,
                                        { 'in': open, style: { transformOrigin: '0 0 0' } },
                                        React.createElement(
                                            Paper,
                                            null,
                                            _this2.props.menuHeader && React.createElement(
                                                'div',
                                                { className: classes.dropdownMenuHeader },
                                                React.createElement(
                                                    Typography,
                                                    null,
                                                    _this2.props.menuHeader
                                                )
                                            ),
                                            React.createElement(
                                                MenuList,
                                                { role: 'menu', className: classNames(_this2.props.isUtility ? classes.dropdownMenuUtility : classes.dropdownMenu, {
                                                        'themed': _this2.props.context.isThemed()
                                                    }) },
                                                menuItems.filter(function (item) {
                                                    return !item.hidden;
                                                }).map(function (item, index) {
                                                    return React.createElement(
                                                        MenuItem,
                                                        {
                                                            key: item.value,
                                                            'data-first': index === 0,
                                                            tabIndex: index === 0 ? 0 : -1,
                                                            className: classNames('headerbar-dropdown-menu-item', _this2.props.isUtility ? classes.dropdownMenuItemUtility : classes.dropdownMenuItem, { 'utility': _this2.props.isUtility }),
                                                            role: 'menuitem',
                                                            disabled: item.disabled,
                                                            onClick: function onClick(e) {
                                                                return _this2.handleClose(e, context, item);
                                                            },
                                                            onKeyUp: function onKeyUp(e) {
                                                                if (e.key === 'Enter') {
                                                                    _this2.handleClose(e, context, item);
                                                                }
                                                            }
                                                        },
                                                        item.label
                                                    );
                                                })
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    );
                }
            );
        }
    }]);

    return HeaderBarDropdownConsumer;
}(React.Component);

// see below for prop descriptions


HeaderBarDropdownConsumer.propTypes = {
    classes: PropTypes.object.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    dropdownMenuLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onMenuItemClick: PropTypes.func,
    isUtility: PropTypes.bool,
    secondaryDropdownMenuLabel: PropTypes.string,
    itemSelected: PropTypes.func,
    disabled: PropTypes.bool,
    menuHeader: PropTypes.string,
    context: PropTypes.object.isRequired // Provided through wrapper

};

HeaderBarDropdownConsumer.defaultProps = {
    disabled: false,
    isUtility: false
};

var HeaderBarDropdown = function HeaderBarDropdown(props) {
    return React.createElement(
        HeaderBarContext.Consumer,
        null,
        function (context) {
            return React.createElement(HeaderBarDropdownConsumer, Object.assign({}, props, { context: context }));
        }
    );
};

HeaderBarDropdown.propTypes = {
    /** Useful to extend the style applied to components. */
    classes: PropTypes.object,

    /** List of menu items with label and callback. */
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,

    /** A label (or icon) to be displayed for the dropdown */
    dropdownMenuLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

    /**
     * An additional label that augments the main label, and appers alongside it.
     */
    secondaryDropdownMenuLabel: PropTypes.string,

    /**
     * A callback when an item from dropdown is clicked.
     * This will send and `event` and selected `item` back to callback.
     */
    onMenuItemClick: PropTypes.func,

    /**
     * Whether this should be rendered as a utility menu. For internal use only.
     *
     * @ignore
     */
    isUtility: PropTypes.bool,

    /**
     * When provided, this will be called whenever an item is selected.
     *
     * This is used internally, to manage HeaderBar selection state.
     *
     * @ignore
     */
    itemSelected: PropTypes.func,

    /** Whether this item is disabled */
    disabled: PropTypes.bool,

    /**
     * The header for this dropdwn  (optional)
     */
    menuHeader: PropTypes.string

};

HeaderBarDropdown.defaultProps = {
    disabled: false,
    isUtility: false
};

export default withStyles(styles)(HeaderBarDropdown);