function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiCardMedia } from '@material-ui/core/CardMedia';
import { CardContext } from './Card';
import { getSpacingStyles, spacingType } from './../globalSpacing';
import isEmpty from 'lodash/isEmpty';

var styles = {
    root: {}
};

/**
 * CardMedia component for displaying media on a Card component
 */
var CardMedia = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var spacingOptions = useContext(CardContext).spacingOptions;

    // Card media needs to span across the card width
    // so using `negateSpacingRightLeft` props to set that.
    var classesOverrides = !isEmpty(spacingOptions) ? getSpacingStyles({
        spacing: spacingOptions.spacing,
        negateSpacingRightLeft: true
    }, spacingType.COMPONENT_RESET) : {};

    var classOverrides = {
        root: classNames(classes.root, classesOverrides)
    };

    return React.createElement(MuiCardMedia, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

CardMedia.muiName = 'CardMedia';
CardMedia.displayName = 'CardMedia';

CardMedia.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Component for rendering image.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Image to be displayed as a background image.
     * Either `image` or `src` prop must be specified.
     * Note that caller must specify height otherwise the image will not be visible.
     */
    image: PropTypes.string,
    /**
     * An alias for `image` property.
     * Available only with media components.
     * Media components: `video`, `audio`, `picture`, `iframe`, `img`.
     */
    src: PropTypes.string
};

// If default props are needed, they must be set here
CardMedia.defaultProps = {
    component: 'div'
};

export default withStyles(styles)(CardMedia);