/* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
 * File: SignIn.ts */

// Types
import { ILogData } from '@hedtech/powercampus-design-system/types/ILogData';

// Helpers
import Request from '@hedtech/powercampus-design-system/helpers/Request';

// Functions for requests
const SignInRequests = {
    getAuthenticationMode(username: string, resolver: (json: string) => void, resolveError: (logData: ILogData) => void): void {
        Request.post(this.getAuthenticationMode.name, '/SignIn/GetAuthenticationMode', { username }, resolver, resolveError);
    },
    getShowForgotPassword(resolver: (json: string) => void, resolveError: (logData: ILogData) => void): void {
        Request.get(this.getShowForgotPassword.name, '/SignIn/ShowForgotPassword', resolver, resolveError);
    },
    postAuthenticateUser(username: string, password: string, resolver: (json: string) => void, resolveError: (logData: ILogData) => void): void {
        Request.post(this.postAuthenticateUser.name, '/SignIn/Authenticate', { username, password }, resolver, resolveError);
    },
    postChangePassword(username: string,
        currentPassword: string,
        newPassword: string,
        resolver: (json: string) => void, resolveError: (logData: ILogData) => void): void {
        Request.post(this.postChangePassword.name, '/Password/ChangePassword', { username, currentPassword, newPassword }, resolver, resolveError);
    }
};

// Export object with the requests
export default SignInRequests;