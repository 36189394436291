import React from 'react';
import DSErrorBoundary from '../DSErrorBoundary';
const withLayout = (WrappedComponent) => {
    class LayoutWrap extends React.Component {
        render() {
            return (React.createElement(DSErrorBoundary, null,
                React.createElement(WrappedComponent, Object.assign({}, this.props))));
        }
    }
    return LayoutWrap;
};
export default withLayout;
