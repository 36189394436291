var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl as MuiFormControl } from '@material-ui/core';
import { default as withStyles } from '../styles';
import { widthFluid } from '../styles/tokens';

export var styles = function styles(theme) {
    return {
        root: {
            display: 'block',
            position: 'relative',
            // Reset fieldset default style
            minWidth: 0,
            padding: 0,
            margin: 0,
            border: 0
        },
        marginNormal: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        marginDense: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.5)
        },
        fullWidth: {
            width: widthFluid
        }
    };
};

/**
 * Provides context such as filled/focused/error/required for form inputs.
 * Relying on the context provides high flexibility and ensures that state always stays consistent
 * across children.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  ASimpleInput
 *  BulkFormGroupOrientation
 *  ContactFormDemo
 */

var FormControl = function (_React$Component) {
    _inherits(FormControl, _React$Component);

    function FormControl() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, FormControl);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FormControl.__proto__ || Object.getPrototypeOf(FormControl)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            adornedStart: false,
            filled: false,
            focused: false
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(FormControl, [{
        key: 'getChildContext',
        value: function getChildContext() {
            var _props = this.props,
                disabled = _props.disabled,
                error = _props.error,
                required = _props.required,
                margin = _props.margin;
            var _state = this.state,
                adornedStart = _state.adornedStart,
                filled = _state.filled,
                focused = _state.focused;


            return {
                muiFormControl: {
                    adornedStart: adornedStart,
                    disabled: disabled,
                    error: error,
                    filled: filled,
                    focused: focused,
                    margin: margin,
                    onBlur: this.handleBlur,
                    onEmpty: this.handleClean,
                    onFilled: this.handleDirty,
                    onFocus: this.handleFocus,
                    required: required
                }
            };
        }
    }, {
        key: 'render',
        value: function render() {
            var props = this.props;

            var classes = props.classes,
                other = _objectWithoutProperties(props, ['classes']);

            return React.createElement(MuiFormControl, Object.assign({
                classes: {
                    root: classes.root,
                    marginNormal: classes.marginNormal,
                    marginDense: classes.marginDense,
                    fullWidth: classes.fullWidth
                }
            }, other));
        }
    }]);

    return FormControl;
}(React.Component);

FormControl.muiName = 'FormControl';

FormControl.propTypes = {
    /**
     * The contents of the form control.
     */
    children: PropTypes.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * If `true`, the label, input and helper text should be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the label should be displayed in an error state.
     */
    error: PropTypes.bool,
    /**
     * If `true`, the component will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,
    /**
     * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
     */
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    /**
     * @ignore
     */
    onBlur: PropTypes.func,
    /**
     * @ignore
     */
    onFocus: PropTypes.func,
    /**
     * If `true`, the label will indicate that the input is required.
     */
    required: PropTypes.bool
};

FormControl.defaultProps = {
    component: 'div',
    disabled: false,
    error: false,
    fullWidth: false,
    margin: 'none',
    required: false
};

FormControl.childContextTypes = {
    muiFormControl: PropTypes.object
};

export default withStyles(styles)(FormControl);