function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiDialogContentText } from '@material-ui/core/DialogContentText';
import { spacingSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.body2.lineHeight,
            marginBottom: spacingSmall
        }, theme.breakpoints.up('sm'), {
            marginBottom: spacingSmall
        })
    };
};

/**
 * DialogContentText is the text of the main content within a Dialog.
 */
var DialogContentText = React.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root
    };

    return React.createElement(
        MuiDialogContentText,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogContentText.muiName = 'DialogContentText';
DialogContentText.displayName = 'DialogContentText';

DialogContentText.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string
};

export default withStyles(styles)(DialogContentText);