function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiCardActions } from '@material-ui/core/CardActions';
import getLegacySpacingModeCss from './internal/getLegacySpacingModeCss';
import { CardContext } from './Card';
import { spacingSmall, spacingXSmall, spacingXSmall2 } from '../styles/tokens';
import classNames from 'classnames';

var styles = function styles(theme) {
    return {
        root: {},
        standard: {
            padding: spacingSmall
        },
        dense: {
            padding: spacingXSmall2 + ' ' + spacingXSmall
        },
        none: {
            padding: 0
        },
        responsive: _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: spacingXSmall + ' 0'
        })
    };
};

/**
 * CardActions component for displaying actions on a Card component
 */
var CardActions = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classesOverrides = getLegacySpacingModeCss(useContext(CardContext), classes);

    var classOverrides = {
        root: classNames(classes.root, classesOverrides)
    };

    return React.createElement(MuiCardActions, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

CardActions.muiName = 'CardActions';
CardActions.displayName = 'CardActions';

CardActions.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the card actions do not have additional margin.
     */
    disableSpacing: PropTypes.bool
};

// If default props are needed, they must be set here
CardActions.defaultProps = {
    disableSpacing: false
};

export default withStyles(styles)(CardActions);