export var LayoutStatus;
(function (LayoutStatus) {
    LayoutStatus["ErrorGetCountCart"] = "ErrorGetCountCart";
    LayoutStatus["FetchGetCountCart"] = "FetchGetCountCart";
    LayoutStatus["SuccessGetCountCart"] = "SuccessGetCountCart";
    LayoutStatus["SuccessHidePageLoader"] = "SuccessHidePageLoader";
    LayoutStatus["SuccessSetAlert"] = "SuccessSetAlert";
    LayoutStatus["SuccessSetCountCart"] = "SuccessSetCountCart";
    LayoutStatus["SuccessSetLayoutReady"] = "SuccessSetLayoutReady";
    LayoutStatus["SuccessSetLogData"] = "SuccessSetLogData";
    LayoutStatus["SuccessSetRedirectCode"] = "SuccessSetRedirectCode";
    LayoutStatus["SuccessSetReloadTheme"] = "SuccessSetReloadTheme";
    LayoutStatus["SuccessShowPageLoader"] = "SuccessShowPageLoader";
    LayoutStatus["SuccessResetTimeout"] = "SuccessResetTimeout";
    LayoutStatus["SuccessSetTimeout"] = "SuccessSetTimeout";
})(LayoutStatus || (LayoutStatus = {}));
