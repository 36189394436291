import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '../styles';
import withTheme from '@material-ui/core/styles/withTheme';
import { borderWidthThickest } from '../styles/tokens';
import { withEDSContext } from '../EDSContext/EDSContext';

var EventWrapper = React.forwardRef(function (props, ref) {
    var event = props.event,
        theme = props.theme,
        children = props.children;
    // Check if a 'color' property is attched to the event,
    // if there is, and the color it non-empty then set the color to the value
    // else set to the current theme ctaColor base shade.

    var eventColor = event.hasOwnProperty('color') ? event.color.length > 0 && event.color : theme.palette.tertiary.fountain[600];

    var styles = {
        root: {
            '& > .rbc-event': {
                borderLeft: borderWidthThickest + ' solid ' + eventColor
            }
        }
    };

    /* eslint-disable */
    var div = function div(_ref) {
        var classes = _ref.classes,
            children = _ref.children,
            edsContext = _ref.edsContext;
        return React.createElement(
            'div',
            { dir: edsContext.dir, className: classes.root },
            children
        );
    };
    /* eslint-enable */

    var StyledEventWrapper = withEDSContext(withStyles(styles)(div));

    return React.createElement(
        StyledEventWrapper,
        { ref: ref },
        children
    );
});

EventWrapper.propTypes = {
    /**
     * Event data sent to the WeekViewSchedule.
     */
    event: PropTypes.object.isRequired,
    /**
     * @ignore
     * Material-UI provided theme.
     */
    theme: PropTypes.object,
    /**
     * @ignore
     * The children to render from react-big-calendar.
     */
    children: PropTypes.any
};

EventWrapper.displayName = 'EventWrapper';

export default withTheme(EventWrapper);