import LayoutActions from '../flux/actions/LayoutActions';
import LogData from '../helpers/LogData';
import { ResultType } from '../types/ResultType';
import Format from './Format';
const Resolver = (json, functionName, onCatch) => {
    let result;
    try {
        result = Format.toJson(json);
        if (result && !result.status) {
            if (result.code) {
                if (onCatch) {
                    onCatch();
                }
                LayoutActions.setRedirectCode(result.code);
            }
            else if (result.log) {
                if (onCatch) {
                    onCatch();
                }
                LayoutActions.setAlert({ messageType: ResultType.error });
            }
            else {
                if (onCatch) {
                    onCatch();
                }
                LayoutActions.setLogData(LogData.badJsonResult(functionName));
            }
        }
    }
    catch (e) {
        if (onCatch) {
            onCatch();
        }
        LayoutActions.setLogData(LogData.fromException(functionName, e));
    }
    return result;
};
export default Resolver;
