var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '../makeStyles';
import { useSpacing } from './../globalSpacing';

import { spacing60 } from '../styles/tokens';

var spanAllColumns = 'span 12';

var useStyles = makeStyles(function (theme) {
    var _sidebar;

    return {
        root: {
            gridTemplateColumns: 'repeat(12, minmax(0, 1fr))'
        },
        content: _defineProperty({
            gridColumn: spanAllColumns
        }, theme.breakpoints.up('lg'), {
            gridColumn: 'span 8',
            marginRight: spacing60
        }),
        hiddenSidebar: {
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            visibility: 'hidden',
            gridColumn: '9 / span 4'
        },
        sidebar: (_sidebar = {
            gridColumn: spanAllColumns
        }, _defineProperty(_sidebar, theme.breakpoints.up('sm'), {
            gridColumn: 'span 6'
        }), _defineProperty(_sidebar, theme.breakpoints.up('lg'), {
            position: 'fixed',
            width: function width(rect) {
                return rect && rect.width + 'px';
            },
            left: function left(rect) {
                return rect && rect.left + 'px';
            }
        }), _sidebar),
        sidebarFullWidthSm: _defineProperty({}, theme.breakpoints.only('sm'), {
            gridColumn: spanAllColumns
        }),
        sidebarFullWidthMd: _defineProperty({}, theme.breakpoints.between('sm', 'md'), {
            gridColumn: spanAllColumns
        })
    };
});

/**
 * A stationary column that remains visible at all times
 * while the rest of the page changes as a user scrolls, interacts, or progresses.
 * @done true
 * @updated true
 * @versionAdded v4.4.0
 * @template true
 * @examples
 *  FixedSidebarLayoutExample
 */
var FixedSidebarLayout = React.forwardRef(function (props, ref) {
    var _useState = useState(),
        _useState2 = _slicedToArray(_useState, 2),
        rect = _useState2[0],
        setRect = _useState2[1];

    var classes = useStyles(rect);
    var sidebarRef = useRef(null);
    var hiddenSidebarRef = useRef(null);

    var children = props.children,
        sidebar = props.sidebar,
        spacingOptions = props.spacingOptions,
        sidebarTop = props.sidebarTop,
        sidebarFullWidth = props.sidebarFullWidth;

    var _useSpacing = useSpacing(Object.assign({ outerSpacing: false }, spacingOptions)),
        _useSpacing2 = _slicedToArray(_useSpacing, 3),
        spacingStyles = _useSpacing2[0],
        setSpacingOptions = _useSpacing2[2];

    useEffect(function () {
        var handleResize = function handleResize() {
            setRect(hiddenSidebarRef.current.getBoundingClientRect());
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return function () {
            return window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(function () {
        setSpacingOptions(spacingOptions);

        // Breakout of execution loop
        setTimeout(function () {
            setRect(hiddenSidebarRef.current.getBoundingClientRect());
        });
    }, [spacingOptions]); // eslint-disable-line

    return React.createElement(
        'div',
        { ref: ref, className: cn(classes.root, props.classes && props.classes.root, spacingStyles) },
        React.createElement(
            'section',
            { className: cn(classes.content, spacingStyles, props.classes && props.classes.content) },
            children
        ),
        React.createElement(
            'section',
            { ref: sidebarRef, style: { top: sidebarTop }, className: cn(classes.sidebar, props.classes && props.classes.sidebar, _defineProperty({}, classes.sidebarFullWidthSm, sidebarFullWidth === 'sm'), _defineProperty({}, classes.sidebarFullWidthMd, sidebarFullWidth === 'md')) },
            sidebar
        ),
        React.createElement('section', { className: classes.hiddenSidebar, ref: hiddenSidebarRef })
    );
});

FixedSidebarLayout.muiName = 'FixedSidebarLayout';

FixedSidebarLayout.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The content of the main section of the layout
     */
    children: PropTypes.node.isRequired,
    /**
     * The contents of the sidebar
     */
    sidebar: PropTypes.node.isRequired,
    /**
     * @ignore
     *
     * Ignoring this prop as per UX request as they don't see any use case.
     * By default `top` is automatically calculated.
     * Use this prop to override or adjust the `top` as per your need.
     */
    sidebarTop: PropTypes.string,
    /**
     * Use this prop to span the sidebar to span full width (12 columns) on small (sm) or medium (md) devices or screens.
     *
     * **NOTE:** On extra small (xs) devices, sidebar will always be full width.
     */
    sidebarFullWidth: PropTypes.oneOf(['sm', 'md']),
    /**
     * To control `FixedSidebarLayout` spacing.
     *
     * Available options are:
     *
     * * `spacing` (string): **'standard'** | 'none' | \<object\>
     *      * Controls the padding of the `Dialog` and grid gap between child elements
     *      * `standard`: The default spacing
     *      * `none`: Removes all spacing styles, allowing for complete style customization through the `classes` prop
     *      * `object`: Allows for more fine-grained spacing customization. See [container customization documentation](#/utilities/spacing#using-customizations-for-fine-grained-container-spacing-control)
     *        for more details.
     */
    spacingOptions: PropTypes.shape({
        spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
};

FixedSidebarLayout.defaultProps = {
    sidebarFullWidth: 'sm',
    spacingOptions: {
        outerSpacing: false
    }
};

export default FixedSidebarLayout;