export var LayoutEventStatus;
(function (LayoutEventStatus) {
    LayoutEventStatus["ChangeAlert"] = "ChangeAlert";
    LayoutEventStatus["ChangeCountCart"] = "ChangeCountCart";
    LayoutEventStatus["ChangeLayoutReady"] = "ChangeLayoutReady";
    LayoutEventStatus["ChangeLoading"] = "ChangeLoading";
    LayoutEventStatus["ChangeLogData"] = "ChangeLogData";
    LayoutEventStatus["ChangeRedirectCode"] = "ChangeRedirectCode";
    LayoutEventStatus["ChangeReloadTheme"] = "ChangeReloadTheme";
    LayoutEventStatus["ChangeTimeout"] = "ChangeTimeout";
    LayoutEventStatus["Error"] = "LayoutError";
})(LayoutEventStatus || (LayoutEventStatus = {}));
