function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiListItem } from '@material-ui/core/ListItem';
import { spacing30, spacing40 } from '../styles/tokens';
import { ListContext } from './List';
import classNames from 'classnames';
import { spacingVariant } from '../globalSpacing';

var FOCUS_VISIBLE_CLASSNAME = 'eds-list-item-focus-visible';

var styles = function styles(theme) {
    return {
        root: {
            paddingTop: spacing30,
            paddingBottom: spacing30
        },
        listButton: _defineProperty({
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.grey['250']
            }
        }, '&.' + FOCUS_VISIBLE_CLASSNAME, {
            backgroundColor: '' + theme.palette.grey['250']
        }),
        gutters: {
            // Reset MUI default padding
            paddingLeft: spacing40,
            paddingRight: spacing40
        },
        none: {
            padding: 0
        }
    };
};

/**
 * An item in a list.
 */
var ListItem = React.forwardRef(function (props, ref) {
    var _classNames;

    var _props$classes = props.classes,
        root = _props$classes.root,
        restOfClasses = _objectWithoutProperties(_props$classes, ['root']),
        rest = _objectWithoutProperties(props, ['classes']);

    var _useContext = useContext(ListContext),
        spacing = _useContext.spacingOptions.spacing;

    var classOverrides = {
        root: classNames(root, (_classNames = {}, _defineProperty(_classNames, restOfClasses.none, spacing === spacingVariant.NONE), _defineProperty(_classNames, restOfClasses.listButton, props.button === true), _classNames)),
        gutters: restOfClasses.gutters
    };

    return React.createElement(MuiListItem, Object.assign({}, rest, {
        classes: classOverrides,
        focusVisibleClassName: FOCUS_VISIBLE_CLASSNAME,
        ref: ref
    }));
});

ListItem.muiName = 'ListItem';
ListItem.displayName = 'ListItem';

ListItem.propTypes = {
    /**
     * If `true`, the list item will be a button (using `ButtonBase`).
     */
    button: PropTypes.bool,
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * By default, it's a `li` when `button` is `false` and a `div` when `button` is `true`.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * The container component used when a `ListItemSecondaryAction` is rendered.
     */
    ContainerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * Properties applied to the container element when the component
     * is used to display a `ListItemSecondaryAction`.
     */
    ContainerProps: PropTypes.object,
    /**
     * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
     */
    dense: PropTypes.bool,
    /**
     * If `true`, the list item will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the left and right padding is removed.
     */
    disableGutters: PropTypes.bool,
    /**
     * If `true`, a 1px light border is added to the bottom of the list item.
     */
    divider: PropTypes.bool
};

ListItem.defaultProps = {
    button: false,
    ContainerComponent: 'li',
    dense: false,
    disabled: false,
    disableGutters: false,
    divider: false
};

export default withStyles(styles)(ListItem);