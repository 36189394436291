import React from 'react';
import uuid from 'uuid/v4';
var SvgNoSearchResults = function SvgNoSearchResults(props) {

    var id1 = uuid();
    var id2 = uuid();

    return React.createElement(
        'svg',
        Object.assign({ width: 153, height: 181, viewBox: '0 0 153 181' }, props),
        React.createElement(
            'defs',
            null,
            React.createElement('circle', { id: id1, cx: 36.348, cy: 36, r: 36 })
        ),
        React.createElement(
            'g',
            { fill: 'none', fillRule: 'evenodd' },
            React.createElement('path', {
                d: 'M48.716 5.603h82.642v11.723a5.4 5.4 0 0 0 5.4 5.4h11.051v99.971a2.7 2.7 0 0 1-2.7 2.7H49.616a2.7 2.7 0 0 1-2.7-2.7V7.403a1.8 1.8 0 0 1 1.8-1.8zM135.898.5h.601v.125c.167.073.324.178.46.315l15.515 15.533c.157.158.272.341.345.537h.093V126.3a4.2 4.2 0 0 1-4.2 4.2H45.7a4.2 4.2 0 0 1-4.2-4.2V4.7A4.2 4.2 0 0 1 45.7.5h90.198zm12.872 16.51L136.5 4.724V15.81a1.2 1.2 0 0 0 1.2 1.2h11.07zM133.5 3.5H45.7a1.2 1.2 0 0 0-1.2 1.2v121.6a1.2 1.2 0 0 0 1.2 1.2h103.012a1.2 1.2 0 0 0 1.2-1.2V20.01H137.7a4.2 4.2 0 0 1-4.2-4.2V3.5z',
                fill: 'currentColor'
            }),
            React.createElement('path', {
                d: 'M48.716 5.603h82.642v11.723a5.4 5.4 0 0 0 5.4 5.4h11.051v99.971a2.7 2.7 0 0 1-2.7 2.7H49.616a2.7 2.7 0 0 1-2.7-2.7V7.403a1.8 1.8 0 0 1 1.8-1.8z',
                fillOpacity: 0.92,
                fill: '#FFF'
            }),
            React.createElement('path', {
                fill: '#FFF',
                d: 'M61.33 29.922h72.967v7.206H61.33zM61.33 44.333h72.967v7.206H61.33zM61.33 58.745h72.967v7.206H61.33zM61.33 73.156h72.967v7.206H61.33zM61.33 87.567h72.967v7.206H61.33z'
            }),
            React.createElement(
                'g',
                { transform: 'translate(6.652 84)' },
                React.createElement(
                    'mask',
                    { id: id2, fill: '#fff' },
                    React.createElement('use', { xlinkHref: '#' + id1 })
                ),
                React.createElement('use', { fill: '#FFF', xlinkHref: '#' + id1 }),
                React.createElement('path', {
                    d: 'M32-223h183.475v26.021c0 6.628 5.372 12 12 12H252V37a6 6 0 0 1-6 6H34a6 6 0 0 1-6-6v-256a4 4 0 0 1 4-4zm-6-5a3 3 0 0 0-3 3V45a3 3 0 0 0 3 3h229a3 3 0 0 0 3-3v-236.34h-27.487a9 9 0 0 1-9-9V-228H26zm0-6h201.513v33.66a3 3 0 0 0 3 3H264V45a9 9 0 0 1-9 9H26a9 9 0 0 1-9-9v-270a9 9 0 0 1 9-9z',
                    fill: 'currentColor',
                    mask: 'url(#' + id2 + ')'
                }),
                React.createElement('path', {
                    d: 'M32-223h183.475v26.021c0 6.628 5.372 12 12 12H252V37a6 6 0 0 1-6 6H34a6 6 0 0 1-6-6v-256a4 4 0 0 1 4-4z',
                    fillOpacity: 0.92,
                    fill: '#FFF',
                    mask: 'url(#' + id2 + ')'
                })
            ),
            React.createElement(
                'g',
                { fill: 'currentColor' },
                React.createElement('path', { d: 'M80.358 139.898l8.267 4.773c7 .192 12.163 1.249 15.49 3.17l34.858 20.125c.4 1.641-.257 3.945-1.97 6.913-1.713 2.967-3.39 4.704-5.03 5.211l-34.857-20.125c-2.996-1.73-6.493-5.673-10.49-11.83l-8.268-4.773c-12.176 19.001-37.269 25.248-57.016 13.847C1.015 145.473-5.95 119.481 5.786 99.153c11.736-20.327 37.729-27.292 58.056-15.556 19.747 11.4 26.884 36.255 16.516 56.3zm-7.022-1.745c9.803-16.98 3.986-38.69-12.994-48.494-16.98-9.803-38.69-3.985-48.494 12.994-9.803 16.98-3.985 38.69 12.994 48.494 16.98 9.803 38.691 3.985 48.494-12.994zm-12.452-40.9l-.62.786a28.742 28.742 0 0 0-4.83-3.08 28.442 28.442 0 0 0-5.63-2.136c-7.447-1.952-15.393-.778-21.966 3.19a28.367 28.367 0 0 0-9.928 10.14l-.866-.5a29.367 29.367 0 0 1 10.277-10.496c6.803-4.107 15.028-5.322 22.737-3.301a29.442 29.442 0 0 1 7.284 3 29.742 29.742 0 0 1 3.542 2.398z' })
            )
        )
    );
};

export default SvgNoSearchResults;