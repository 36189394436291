function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { StepLabel as MuiStepLabel } from '@material-ui/core';
import Warning from '@eui/ds-icons/lib/Warning';
import { sizingMedium, borderWidthThin } from '../styles/tokens';
import Typography from '../Typography';
import Tooltip from '../Tooltip';
import { withEDSContext } from '../EDSContext/EDSContext';
import { STEP_LABEL_ERROR_CLASSNAME, STEP_LABEL_HAS_ERROR_CLASSNAME, STEP_LABEL_CONTAINER_CLASSNAME, STEP_LABEL_CLASSNAME } from './internal/constants.js';

import StepProgressContext from './internal/StepProgressContext';

import { fontWeightNormal, fontWeightStrong, fontSizeSmall, fontSizeHeader2 } from '../styles/tokens';

import ChevronDownIcon from '@eui/ds-icons/lib/ChevronDown';
import ChevronUpIcon from '@eui/ds-icons/lib/ChevronUp';

var styles = function styles(theme) {
    return {
        labelRoot: {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.grey[500],
            flexGrow: 4
        },
        root: {
            width: '100%'
        },
        labelContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        labelError: {
            color: theme.palette.status.error.fill,
            flexGrow: 1
        },
        labelErrorActive: {
            fontWeight: fontWeightStrong
        },
        stepIconRoot: {
            color: theme.palette.grey[100],
            borderRadius: '100%',
            border: borderWidthThin + ' solid ' + theme.palette.grey[500],
            '& > text': {
                fontSize: fontSizeSmall,
                fontWeight: fontWeightStrong,
                textAnchor: 'middle'
            },
            '&$stepIconActive': {
                border: 'none',
                color: theme.palette.ctaColor.base,
                '&> text': {
                    color: theme.palette.grey[100],
                    fill: theme.palette.grey[100]
                }
            }
        },
        stepIconText: {
            fill: theme.palette.grey[500]
        },
        stepIconActive: {
            color: theme.palette.ctaColor.base
        },
        stepIconEnable: {
            color: theme.palette.grey[500]
        },
        stepIconCustomRoot: {
            border: 'none',
            width: sizingMedium,
            height: sizingMedium,
            verticalAlign: 'middle'
        },
        stepIconError: {
            color: theme.palette.status.error.fill,
            fill: theme.palette.status.error.fill
        },
        stepIconCompleted: {
            color: theme.palette.ctaColor.base,
            fill: theme.palette.ctaColor.base
        },
        stepIconDisabled: {
            '& span > svg': {
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                '&> text': {
                    fill: theme.palette.grey[400]
                }
            },
            '& div > svg': {
                fill: theme.palette.grey[400]
            }
        },
        stepLabelDisabled: {
            color: theme.palette.grey[400],
            fontWeight: fontWeightNormal
        },
        stepLabelActiveOrCompleted: {
            color: theme.palette.grey[600],
            fontWeight: fontWeightStrong
        },
        expansionIcon: {
            paddingLeft: '8px',
            display: 'flex',
            alignItems: 'center',
            fill: theme.palette.grey[500]
        },
        expansionIconOpen: {
            fill: theme.palette.grey[400]
        },
        expansionIconContainer: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: fontSizeHeader2
            }
        }
    };
};

/**
 * A component that displays progress through numbered steps.
 */
var StepLabel = React.forwardRef(function (props, ref) {
    var _classNames2;

    var className = props.className,
        classes = props.classes,
        children = props.children,
        error = props.error,
        completed = props.completed,
        optional = props.optional,
        disabled = props.disabled,
        StepIconProps = props.StepIconProps,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['className', 'classes', 'children', 'error', 'completed', 'optional', 'disabled', 'StepIconProps', 'edsContext']);

    var _React$useContext = React.useContext(StepProgressContext),
        expansion = _React$useContext.expansion;

    var defaultOverrides = {
        StepIconProps: Object.assign({
            classes: {
                root: classes.stepIconRoot,
                text: classes.stepIconText,
                active: classes.stepIconActive,
                completed: classes.stepIconActive,
                error: classes.stepIconError
            }
        }, StepIconProps)
    };

    var expansionIcon = function expansionIcon() {
        return React.createElement(
            'span',
            { className: classes.expansionIcon },

            // eslint-disable-next-line react/prop-types
            props.active ? React.createElement(ChevronUpIcon, { className: classes.expansionIconOpen }) : React.createElement(
                Tooltip,
                { title: edsContext.formatMessage('component.StepProgress.open'), enterDelay: 1000, enterNextDelay: 1000 },
                React.createElement(ChevronDownIcon, null)
            )
        );
    };

    defaultOverrides.optional = React.createElement(
        'div',
        null,
        React.createElement(
            Typography,
            {
                variant: 'body3',
                color: 'textSecondary',
                align: props.alternativeLabel ? 'center' : 'left',
                classes: {
                    body3: !error && disabled && classes.stepLabelDisabled
                },
                className: expansion ? classes.expansionIconContainer : null
            },
            optional,
            !expansion ? null : expansionIcon()
        )
    );

    if (error) {
        // Assign new error icon
        defaultOverrides.StepIconProps.icon = React.createElement(Warning, { className: classNames(classes.stepIconError, classes.stepIconCustomRoot) });

        // if error is a string make the optional prop print the error message
        if (error.length > 0) {
            /* These marker class names will be used by its parent component StepProgress */
            defaultOverrides.optional = React.createElement(
                'div',
                { className: classes.expansionIconContainer },
                React.createElement(
                    Typography,
                    {
                        variant: 'body3',
                        className: classNames(classes.labelError, STEP_LABEL_ERROR_CLASSNAME),
                        align: props.alternativeLabel ? 'center' : 'left'
                    },
                    error
                ),
                !expansion ? null : expansionIcon()
            );
        }
    }

    var classOverrides = {
        disabled: !error && disabled && classes.stepIconDisabled
    };

    if (expansion) {
        classOverrides = Object.assign(classOverrides, {
            labelContainer: classes.labelContainer,
            root: classes.root
        });
    }

    return React.createElement(
        MuiStepLabel,
        Object.assign({}, defaultOverrides, {
            classes: classOverrides,
            disabled: disabled,
            completed: completed,
            className: classNames(className, STEP_LABEL_CONTAINER_CLASSNAME)
        }, rest, {
            ref: ref
        }),
        React.createElement(
            'span',
            { className: classNames(_defineProperty({}, classes.stepLabelActiveOrCompleted, (props.active || completed) && !error), classes.labelRoot, '' + STEP_LABEL_CLASSNAME, (_classNames2 = {}, _defineProperty(_classNames2, classes.labelError, error), _defineProperty(_classNames2, classes.labelErrorActive, error && props.active), _defineProperty(_classNames2, STEP_LABEL_HAS_ERROR_CLASSNAME, error), _defineProperty(_classNames2, classes.stepLabelDisabled, disabled && !error), _classNames2)) },
            children
        )
    );
});

StepLabel.muiName = 'StepLabel';
StepLabel.displayName = 'StepLabel';

StepLabel.propTypes = {
    /**
     * @ignore
     * Sets the step as active. Is passed to child components.
     */
    active: PropTypes.bool,
    /**
     * @ignore
     * Set internally by Stepper when it's supplied with the alternativeLabel property.
     */
    alternativeLabel: PropTypes.bool,
    /**
     * In most cases will simply be a string containing a title for the label.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepLabelButton` is a child of `StepLabel`. Is passed to child components.
     */
    disabled: PropTypes.bool,
    /**
     * Used to mark the step in an error state and provide an error message.
     */
    error: PropTypes.any,
    /**
     * Override the default icon.
     */
    icon: PropTypes.node,
    /**
     * The optional text to display.
     */
    optional: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

StepLabel.defaultProps = {
    error: false
};

export default withEDSContext(withStyles(styles)(StepLabel));