var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

import React, { useState, useEffect, useRef } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Popper from '../../Popper';
import makeStyles from '../../makeStyles';

import { spacing30 } from './../../styles/tokens';

var useStyles = makeStyles(function (theme) {
    return {
        paperRoot: {
            boxShadow: '\n            0 0.1875rem 0.3125rem -0.0625rem rgba(0,0,0,0.2),\n            0 0.3125rem ' + spacing30 + ' 0 rgba(0,0,0,0.14),\n            0 0.0625rem 0.875rem 0 rgba(0,0,0,0.12)\n        '
        }
    };
});

var CAL_EVENT_CLASS = 'calendar-event';

var CalendarEvent = React.forwardRef(function (props, ref) {
    var classes = useStyles();
    var eventRef = useRef(null);

    var _useState = useState(null),
        _useState2 = _slicedToArray(_useState, 2),
        anchorEl = _useState2[0],
        setAnchorEl = _useState2[1];

    var _useState3 = useState(false),
        _useState4 = _slicedToArray(_useState3, 2),
        localOpen = _useState4[0],
        setLocalOpen = _useState4[1];

    var open = props.open,
        target = props.target,
        popperContent = props.popperContent,
        event = props.event;

    var title = React.createElement(
        'span',
        null,
        props.event.title
    );

    if (!popperContent) {
        return title;
    }

    useEffect(function () {
        target && setLocalOpen(target.querySelector('.' + CAL_EVENT_CLASS).dataset.id === '' + event.event.id);
    }, [target]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(function () {
        popperContent && setAnchorEl(eventRef.current);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return React.createElement(
        'div',
        { className: CAL_EVENT_CLASS + ' ' + CAL_EVENT_CLASS + '-' + event.event.id,
            'data-id': event.event.id,
            ref: eventRef
        },
        title,
        React.createElement(
            ClickAwayListener,
            { onClickAway: function onClickAway() {
                    return setLocalOpen(false);
                } },
            React.createElement(
                Popper,
                {
                    className: CAL_EVENT_CLASS + '-popper',
                    classes: { paperRoot: classes.paperRoot },
                    arrow: true,
                    open: localOpen && open,
                    anchorEl: anchorEl,
                    placement: 'top',
                    modifiers: {
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: eventRef.current && eventRef.current.closest('.rbc-time-content')
                        }
                    }
                },
                typeof popperContent === 'function' ? popperContent(event.event) : popperContent
            )
        )
    );
});

export default CalendarEvent;