var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, globalizeLocalizer, Views as views } from 'react-big-calendar';
import { default as withStyles } from '../styles';
import { default as withWidth } from '../withWidth';
import { withEDSContext } from '../EDSContext/EDSContext';
import classNames from 'classnames';

import moment from 'moment';
// Our custom components
import CalendarToolbar from './CalendarToolbar';
import EventWrapper from './EventWrapper';
import ReactBigCalendarStyles from './internal/ReactBigCalendarStyles';
import CalendarEvent from './internal/CalendarEvent';

// NOTE: application complains if we do not do this. Simply exporting the 'Calendar'
// does not send the required properties from the 'Calendar' component.
// Export required functions so they can be used by consumers when
// initializing the component.
var DEFAULT_LOCALIZER = momentLocalizer(moment);

var CalendarContext = createContext();
var Provider = CalendarContext.Provider,
    CalendarConsumer = CalendarContext.Consumer;


var dateFormat = PropTypes.any;
var dateRangeFormat = PropTypes.func;

var CalendarProvider = function CalendarProvider(props) {
  var compact = props.compact,
      defaultDate = props.defaultDate,
      showViewNames = props.showViewNames,
      view = props.view,
      width = props.width;

  var _useState = useState(defaultDate),
      _useState2 = _slicedToArray(_useState, 2),
      date = _useState2[0],
      setDate = _useState2[1];

  var _useState3 = useState(view),
      _useState4 = _slicedToArray(_useState3, 2),
      selectedView = _useState4[0],
      setSelectedView = _useState4[1];

  return React.createElement(
    Provider,
    {
      value: {
        compact: compact,
        date: date,
        setDate: setDate,
        selectedView: selectedView,
        setSelectedView: setSelectedView,
        showViewNames: showViewNames,
        width: width
      }
    },
    props.children
  );
};

CalendarProvider.propTypes = {
  compact: PropTypes.bool,
  children: PropTypes.object.isRequired,
  defaultDate: PropTypes.instanceOf(Date),
  showViewNames: PropTypes.bool,
  view: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

/**
 * A component for displaying events within a week or day view.
 * @done true
 * @updated false
 * @versionAdded v0.2.1
 * @examples
 *  CalendarSingleDay
 *  CalendarExample
 *  CalendarI18n
 *  ClickEvents
 *  CalendarWithPopperAsNode
 *  CalendarWithPopperAsRenderFunction
 *  CustomComponentExample
 */
var Calendar = React.forwardRef(function (props, ref) {
  var _useState5 = useState({ open: false, target: undefined }),
      _useState6 = _slicedToArray(_useState5, 2),
      popper = _useState6[0],
      setPopper = _useState6[1];

  var classes = props.classes,
      compact = props.compact,
      dateProp = props.date,
      defaultDate = props.defaultDate,
      defaultView = props.defaultView,
      edsContext = props.edsContext,
      localeProp = props.locale,
      culture = props.culture,
      formats = props.formats,
      legend = props.legend,
      messages = props.messages,
      onNavigate = props.onNavigate,
      showViewNames = props.showViewNames,
      showOnlyDay = props.showOnlyDay,
      width = props.width,
      onSelectEvent = props.onSelectEvent,
      popperContent = props.popperContent,
      rest = _objectWithoutProperties(props, ['classes', 'compact', 'date', 'defaultDate', 'defaultView', 'edsContext', 'locale', 'culture', 'formats', 'legend', 'messages', 'onNavigate', 'showViewNames', 'showOnlyDay', 'width', 'onSelectEvent', 'popperContent']);

  // Custom messages to send to component


  var overrideMessages = Object.assign({
    agenda: edsContext.formatMessage('component.Calendar.agenda'),
    allDay: edsContext.formatMessage('component.Calendar.allDay'),
    date: edsContext.formatMessage('component.Calendar.date'),
    day: edsContext.formatMessage('component.Calendar.day'),
    event: edsContext.formatMessage('component.Calendar.event'),
    month: edsContext.formatMessage('component.Calendar.month'),
    next: edsContext.formatMessage('component.Calendar.next'),
    noEventsInRange: edsContext.formatMessage('component.Calendar.noEventsInRange'),
    previous: edsContext.formatMessage('component.Calendar.previous'),
    time: edsContext.formatMessage('component.Calendar.time'),
    today: edsContext.formatMessage('component.Calendar.goToToday'),
    tomorrow: edsContext.formatMessage('component.Calendar.tomorrow'),
    week: edsContext.formatMessage('component.Calendar.week'),
    work_week: edsContext.formatMessage('component.Calendar.workWeek'),
    yesterday: edsContext.formatMessage('component.Calendar.yesterday')
  }, messages);

  // Custom date format
  // See more
  // http://intljusticemission.github.io/react-big-calendar/examples/index.html#prop-formats
  var overrideFormats = Object.assign({
    // A day of the week format for Week and Day headings
    dayFormat: function dayFormat(date, calendarCulture, localizer) {
      switch (width) {
        case 'sm':
          return localizer.format(date, 'ddd D', calendarCulture);
        case 'xs':
          return localizer.format(date, 'ddd', calendarCulture);
        default:
          return localizer.format(date, 'dddd D', calendarCulture);
      }
    },

    // Toolbar header format for the Day view
    dayHeaderFormat: function dayHeaderFormat(date, calendarCulture, localizer) {
      return localizer.format(date, showOnlyDay ? 'dddd' : 'dddd, MMM D, Y', calendarCulture);
    },

    // Toolbar header format for the Week views
    dayRangeHeaderFormat: function dayRangeHeaderFormat(_ref, calendarCulture, localizer) {
      var start = _ref.start,
          end = _ref.end;

      var startYear = start.getFullYear();
      var endYear = end.getFullYear();
      var startFormat = localizer.format(start, startYear === endYear ? 'MMM D' : 'MMM D, Y', calendarCulture);
      var endFormat = localizer.format(end, 'MMM D, Y', calendarCulture);

      return startFormat + ' \u2013 ' + endFormat;
    }

  }, formats);

  //function necessary for adjusting timeGutter format based on locale
  function localeUses24HourTime(locale) {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric'
    }).formatToParts(new Date(2020, 0, 1, 13)).find(function (part) {
      return part.type === 'hour';
    }).value.length === 2;
  }

  if (compact) {
    Object.assign(overrideFormats, {
      timeGutterFormat: function timeGutterFormat(date, localCulture, localizer) {
        if (localeUses24HourTime(locale)) {
          return localizer.format(date, 'H', localCulture);
        } else {
          return localizer.format(date, 'h A', localCulture);
        }
      }
    });
  }

  var handleNavigation = function handleNavigation(callback) {
    return function (date) {
      setPopper({ open: false });
      callback(date);
    };
  };

  // figure out which locale to use -- in order: the locale prop, deprecated culture prop, or the EDSApplication-level locale
  var locale = localeProp || culture || edsContext.locale;

  var compactGutterHeader = function compactGutterHeader() {
    return React.createElement(
      'div',
      { className: 'rbc-gutterHeader' },
      edsContext.formatMessage('component.Calendar.allDay')
    );
  };

  var onSelectEventClick = function onSelectEventClick(eventData, e) {
    e.preventDefault();

    popperContent && setPopper({
      open: true,
      target: e.currentTarget
    });

    onSelectEvent && onSelectEvent(eventData, e);
  };

  return React.createElement(
    CalendarProvider,
    {
      compact: compact,
      date: defaultDate,
      showViewNames: showViewNames,
      view: defaultView,
      width: width
    },
    React.createElement(
      CalendarConsumer,
      null,
      function (context) {
        return React.createElement(
          'div',
          {
            className: classNames('rbc-container', {
              'rbc-highlight-today': context.selectedView !== 'day',
              'rbc-compact': compact
            })
          },
          React.createElement(BigCalendar, Object.assign({
            className: classes.root,
            defaultDate: defaultDate,
            defaultView: defaultView,
            date: dateProp || context.date,
            messages: overrideMessages,
            onNavigate: onNavigate || handleNavigation(context.setDate),
            formats: overrideFormats,
            culture: locale,
            rtl: edsContext.dir === 'rtl',
            components: {
              // eslint-disable-next-line react/display-name
              event: function event(_event) {
                return React.createElement(CalendarEvent, {
                  popperContent: popperContent,
                  open: popper.open,
                  target: popper.target,
                  event: _event
                });
              },
              toolbar: CalendarToolbar,
              eventWrapper: EventWrapper,
              timeGutterHeader: compact ? compactGutterHeader : undefined
            },
            onSelectEvent: onSelectEventClick
          }, rest, {
            ref: ref
          })),
          legend
        );
      }
    )
  );
});

Calendar.propTypes = {
  /**
   * Classes used to extend original style object.
   */
  classes: PropTypes.object,

  /**
   * @ignore
   * **BETA** Applies compact variant styles to calendar.
   */
  compact: PropTypes.bool,

  /**
   * Object to set the date/time library handler. Common uses are `const localizer = momentLocalizer(moment);` or `const localizer = globalizeLocalizer(globalize);`
   */
  localizer: PropTypes.object,

  /**
   * Props passed to main calendar `<div>`.
   */
  elementProps: PropTypes.object,

  /**
   * The current date value of the calendar. Determines the visible view range.
   * If `date` is omitted then the result of `getNow` is used; otherwise the
   * current date is used.
   *
   * `@controllable onNavigate`
   */
  date: PropTypes.instanceOf(Date),

  /**
   * The initial date set for the Calendar.
   */
  defaultDate: PropTypes.instanceOf(Date),

  /**
   * The current view of the calendar.
   *
   * `@default 'month'`
   * `@controllable onView`
   */
  view: PropTypes.string,

  /**
   * The initial view set for the Calendar.
   *
   * `@type Calendar.Views ('month'|'week'|'work_week'|'day'|'agenda')`
   * `@default 'month'`
   */
  defaultView: PropTypes.string,

  /**
   * An array of event objects to display on the calendar. Events objects
   * can be any shape, as long as the Calendar knows how to retrieve the
   * following details of the event:
   *
   *  - start time
   *  - end time
   *  - title
   *  - whether its an "all day" event or not
   *  - any resource the event may be related to
   *
   * Each of these properties can be customized or generated dynamically by
   * setting the various "accessor" props. Without any configuration the default
   * event should look like:
   *
   * ```js
   * Event {
   *   title: string,
   *   start: Date,
   *   end: Date,
   *   allDay?: boolean
   *   resource?: any,
   * }
   * ```
   */
  events: PropTypes.arrayOf(PropTypes.object),

  /**
   * Accessor for the event title, used to display event information. Should
   * resolve to a `renderable` value.
   *
   * ```js
   * string | (event: Object) => string
   * ```
   */
  titleAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Accessor for the event tooltip. Should
   * resolve to a `renderable` value. Removes the tooltip if null.
   *
   * ```js
   * string | (event: Object) => string
   * ```
   */
  tooltipAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Determines whether the event should be considered an "all day" event and ignore time.
   * Must resolve to a `boolean` value.
   *
   * ```js
   * string | (event: Object) => boolean
   * ```
   */
  allDayAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * The start date/time of the event. Must resolve to a JavaScript `Date` object.
   *
   * ```js
   * string | (event: Object) => Date
   * ```
   */
  startAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * The end date/time of the event. Must resolve to a JavaScript `Date` object.
   *
   * ```js
   * string | (event: Object) => Date
   * ```
   */
  endAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Returns the id of the `resource` that the event is a member of. This
   * id should match at least one resource in the `resources` array.
   *
   * ```js
   * string | (event: Object) => Date
   * ```
   */
  resourceAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * An array of resource objects that map events to a specific resource.
   * Resource objects, like events, can be any shape or have any properties,
   * but should be uniquly identifiable via the `resourceIdAccessor`, as
   * well as a "title" or name as provided by the `resourceTitleAccessor` prop.
   */
  resources: PropTypes.arrayOf(PropTypes.object),

  /**
   * Provides a unique identifier for each resource in the `resources` array
   *
   * ```js
   * string | (resource: Object) => any
   * ```
   */
  resourceIdAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Provides a human readable name for the resource object, used in headers.
   *
   * ```js
   * string | (resource: Object) => any
   * ```
   */
  resourceTitleAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Determines the current date/time which is highlighted in the views.
   *
   * The value affects which day is shaded and which time is shown as
   * the current time. It also affects the date used by the Today button in
   * the toolbar.
   *
   * Providing a value here can be useful when you are implementing time zones
   * using the `startAccessor` and `endAccessor` properties.
   *
   * @type {func}
   * @default () => new Date()
   */
  getNow: PropTypes.func,

  /**
   * Callback fired when the `date` value changes.
   * This is used in custom `Calendar` components.
   * @controllable date
   */
  onNavigate: PropTypes.func,

  /**
   * Callback fired when the `view` value changes.
   * This is used in custom `Calendar` components.
   * @controllable view
   */
  onView: PropTypes.func,

  /**
   * Callback fired when date header, or the truncated events links are clicked
   *
   */
  onDrillDown: PropTypes.func,

  /**
   *
   * ```js
   * (dates: Date[] | { start: Date; end: Date }, view?: 'month'|'week'|'work_week'|'day'|'agenda') => void
   * ```
   *
   * Callback fired when the visible date range changes. Returns an Array of dates
   * or an object with start and end dates for BUILTIN views. Optionally new `view`
   * will be returned when callback called after view change.
   *
   * Custom views may return something different.
   */
  onRangeChange: PropTypes.func,

  /**
   * A callback fired when a date selection is made. Only fires when `selectable` is `true`.
   *
   * ```js
   * (
   *   slotInfo: {
   *     start: Date,
   *     end: Date,
   *     slots: Array<Date>,
   *     action: "select" | "click" | "doubleClick",
   *     bounds: ?{ // For "select" action
   *       x: number,
   *       y: number,
   *       top: number,
   *       right: number,
   *       left: number,
   *       bottom: number,
   *     },
   *     box: ?{ // For "click" or "doubleClick" actions
   *       clientX: number,
   *       clientY: number,
   *       x: number,
   *       y: number,
   *     },
   *   }
   * ) => any
   * ```
   */
  onSelectSlot: PropTypes.func,

  /**
   * Callback fired when a calendar event is selected.
   *
   * ```js
   * (event: Object, e: SyntheticEvent) => any
   * ```
   *
   * @controllable selected
   */
  onSelectEvent: PropTypes.func,

  /**
   * Callback fired when a calendar event is clicked twice.
   *
   * ```js
   * (event: Object, e: SyntheticEvent) => void
   * ```
   */
  onDoubleClickEvent: PropTypes.func,

  /**
   * Callback fired when dragging a selection in the Time views.
   *
   * Returning `false` from the handler will prevent a selection.
   *
   * ```js
   * (range: { start: Date, end: Date }) => ?boolean
   * ```
   */
  onSelecting: PropTypes.func,

  /**
   * The selected event, if any.
   */
  selected: PropTypes.object,

  /**
   * An array of built-in view names to allow the calendar to display.
   * accepts either an array of builtin view names,
   *
   * ```jsx
   * views={['month', 'day', 'agenda']}
   * ```
   * or an object hash of the view name and the component (or boolean for builtin).
   *
   * ```jsx
   * views={{
   *   month: true,
   *   week: false,
   *   myweek: WorkWeekViewComponent,
   * }}
   * ```
   *
   * Custom views can be any React component, that implements the following
   * interface:
   *
   * ```js
   * interface View {
   *   static title(date: Date, { formats: DateFormat[], culture: string?, ...props }): string
   *   static navigate(date: Date, action: 'PREV' | 'NEXT' | 'DATE'): Date
   * }
   * ```
   *
   * `@type Calendar.Views ('month'|'week'|'work_week'|'day'|'agenda')`
   * `@View ['month', 'week', 'day', 'agenda']`
   */
  views: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

  /**
   * The string name of the destination view for drill-down actions, such
   * as clicking a date header, or the truncated events links. If
   * `getDrilldownView` is also specified it will be used instead.
   *
   * Set to `null` to disable drill-down actions.
   *
   * ```js
   * <WeekViewSchedule
   *   drilldownView="agenda"
   * />
   * ```
   */
  drilldownView: PropTypes.string,

  /**
   * Functionally equivalent to `drilldownView`, but accepts a function
   * that can return a view name. It's useful for customizing the drill-down
   * actions depending on the target date and triggering view.
   *
   * Return `null` to disable drill-down actions.
   *
   * ```js
   * <WeekViewSchedule
   *   getDrilldownView={(targetDate, currentViewName, configuredViewNames) =>
   *     if (currentViewName === 'month' && (configuredViewNames.indexOf('week') !== -1))
   *       return 'week'
   *
   *     return null;
   *   }}
   * />
   * ```
   */
  getDrilldownView: PropTypes.func,

  /**
   * Determines the end date from date prop in the agenda view
   * date prop + length (in number of days) = end date
   */
  length: PropTypes.number,

  /**
   * Determines whether the toolbar is displayed
   */
  toolbar: PropTypes.bool,

  /**
   * Show truncated events in an overlay when you click the "+_x_ more" link.
   */
  popup: PropTypes.bool,

  /**
   * Distance in pixels, from the edges of the viewport, the "show more" overlay should be positioned.
   *
   * ```jsx
   * <WeekViewSchedule popupOffset={30}/>
   * <WeekViewSchedule popupOffset={{x: 30, y: 20}}/>
   * ```
   */
  popupOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })]),

  /**
   * Allows mouse selection of ranges of dates/times.
   *
   * The 'ignoreEvents' option prevents selection code from running when a
   * drag begins over an event. Useful when you want custom event click or drag
   * logic.
   */
  selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),

  /**
   * Specifies the number of miliseconds the user must press and hold on the screen for a touch
   * to be considered a "long press." Long presses are used for time slot selection on touch
   * devices.
   *
   * `@type {number}`
   * `@default 250`
   */
  longPressThreshold: PropTypes.number,

  /**
   * Determines the selectable time increments in week and day views
   */
  step: PropTypes.number,

  /**
   * The number of slots per "section" in the time grid views. Adjust with `step`
   * to change the default of 1 hour long groups, with 30 minute slots.
   */
  timeslots: PropTypes.number,

  /**
   * Optionally provide a function that returns an object of className or style props
   * to be applied to the the event node.
   *
   * ```js
   * (
   * 	event: Object,
   * 	start: Date,
   * 	end: Date,
   * 	isSelected: boolean
   * ) => { className?: string, style?: Object }
   * ```
   */
  eventPropGetter: PropTypes.func,

  /**
   * Optionally provide a function that returns an object of className or style props
   * to be applied to the the time-slot node. Caution! Styles that change layout or
   * position may break the calendar in unexpected ways.
   *
   * ```js
   * (date: Date, resourceId: (number|string)) => { className?: string, style?: Object }
   * ```
   */
  slotPropGetter: PropTypes.func,

  /**
   * Optionally provide a function that returns an object of className or style props
   * to be applied to the the day background. Caution! Styles that change layout or
   * position may break the calendar in unexpected ways.
   *
   * ```js
   * (date: Date) => { className?: string, style?: Object }
   * ```
   */
  dayPropGetter: PropTypes.func,

  /**
   * Support to show multi-day events with specific start and end times in the
   * main time grid (rather than in the all day header).
   *
   * **Note: This may cause calendars with several events to look very busy in
   * the week and day views.**
   */
  showMultiDayTimes: PropTypes.bool,

  /**
   * Constrains the minimum _time_ of the Day and Week views.
   * This prop expects a `Date` to be sent in.
   */
  min: PropTypes.instanceOf(Date),

  /**
   * Constrains the maximum _time_ of the Day and Week views.
   * This prop expects a `Date` to be sent in.
   */
  max: PropTypes.instanceOf(Date),

  /**
   * Determines how far down the scroll pane is initially scrolled down.
   */
  scrollToTime: PropTypes.instanceOf(Date),

  /**
   * **DEPRECATED** Use the global locale specified via [`EDSApplication`](#/components/EDSApplication) instead.
   *
   * Specify a specific culture code for the Calendar.
   *
   *
   * **Note: it's generally better to handle this globally via your i18n library.**
   */
  culture: PropTypes.string,

  /**
   *
   * **DEPRECATED** Use the global locale specified via [`EDSApplication`](#/components/EDSApplication) instead.
   *
   * Locale to use for date/calendar formatting. Defaults to the locale specified in [`EDSApplication`](#/components/EDSApplication).
   */
  locale: PropTypes.string,

  /**
   * @ignore
   */
  edsContext: PropTypes.object,

  /**
   * Localizer specific formats, tell the Calendar how to format and display dates.
   *
   * `format` types are dependent on the configured localizer; both Moment and Globalize
   * accept strings of tokens according to their own specification, such as: `'DD mm yyyy'`.
   *
   * ```jsx
   * let formats = {
   *   dateFormat: 'dd',
   *
   *   dayFormat: (date, , localizer) =>
   *     localizer.format(date, 'DDD', culture),
   *
   *   dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
   *     localizer.format(start, { date: 'short' }, culture) + ' — ' +
   *     localizer.format(end, { date: 'short' }, culture)
   * }
   *
   * <Calendar formats={formats} />
   * ```
   *
   * All localizers accept a function of
   * the form `(date: Date, culture: ?string, localizer: Localizer) -> string`
   */
  formats: PropTypes.shape({
    /**
     * Format for the day of the month heading in the Month view.
     * e.g. "01", "02", "03", etc
     */
    dateFormat: dateFormat,

    /**
     * A day of the week format for Week and Day headings,
     * e.g. "Wed 01/04"
     *
     */
    dayFormat: dateFormat,

    /**
     * Week day name format for the Month week day headings,
     * e.g: "Sun", "Mon", "Tue", etc
     *
     */
    weekdayFormat: dateFormat,

    /**
     * The timestamp cell formats in Week and Time views, e.g. "4:00 AM"
     */
    timeGutterFormat: dateFormat,

    /**
     * Toolbar header format for the Month view, e.g "2015 April"
     *
     */
    monthHeaderFormat: dateFormat,

    /**
     * Toolbar header format for the Week views, e.g. "Mar 29 - Apr 04"
     */
    dayRangeHeaderFormat: dateRangeFormat,

    /**
     * Toolbar header format for the Day view, e.g. "Wednesday Apr 01"
     */
    dayHeaderFormat: dateFormat,

    /**
     * Toolbar header format for the Agenda view, e.g. "4/1/2015 — 5/1/2015"
     */
    agendaHeaderFormat: dateRangeFormat,

    /**
     * A time range format for selecting time slots, e.g "8:00am — 2:00pm"
     */
    selectRangeFormat: dateRangeFormat,

    agendaDateFormat: dateFormat,
    agendaTimeFormat: dateFormat,
    agendaTimeRangeFormat: dateRangeFormat,

    /**
     * Time range displayed on events.
     */
    eventTimeRangeFormat: dateRangeFormat,

    /**
     * An optional event time range for events that continue onto another day
     */
    eventTimeRangeStartFormat: dateFormat,

    /**
     * An optional event time range for events that continue from another day
     */
    eventTimeRangeEndFormat: dateFormat
  }),

  /**
   * Customize how different sections of the calendar render by providing custom Components.
   * In particular the `Event` component can be specified for the entire calendar, or you can
   * provide an individual component for each view type.
   *
   * ```jsx
   * let components = {
   *   event: MyEvent, // used by each view (Month, Day, Week)
   *   eventWrapper: MyEventWrapper,
   *   eventContainerWrapper: MyEventContainerWrapper,
   *   dayWrapper: MyDayWrapper,
   *   dateCellWrapper: MyDateCellWrapper,
   *   timeSlotWrapper: MyTimeSlotWrapper,
   *   timeGutterHeader: MyTimeGutterWrapper,
   *   toolbar: MyToolbar,
   *   agenda: {
   *   	 event: MyAgendaEvent // with the agenda view use a different component to render events
   *     time: MyAgendaTime,
   *     date: MyAgendaDate,
   *   },
   *   day: {
   *     header: MyDayHeader,
   *     event: MyDayEvent,
   *   },
   *   week: {
   *     header: MyWeekHeader,
   *     event: MyWeekEvent,
   *   },
   *   month: {
   *     header: MyMonthHeader,
   *     dateHeader: MyMonthDateHeader,
   *     event: MyMonthEvent,
   *   }
   * }
   * <Calendar components={components} />
   * ```
   */
  components: PropTypes.shape({
    event: PropTypes.elementType,
    eventWrapper: PropTypes.elementType,
    eventContainerWrapper: PropTypes.elementType,
    dayWrapper: PropTypes.elementType,
    dateCellWrapper: PropTypes.elementType,
    timeSlotWrapper: PropTypes.elementType,
    timeGutterHeader: PropTypes.elementType,

    toolbar: PropTypes.elementType,

    agenda: PropTypes.shape({
      date: PropTypes.elementType,
      time: PropTypes.elementType,
      event: PropTypes.elementType
    }),

    day: PropTypes.shape({
      header: PropTypes.elementType,
      event: PropTypes.elementType
    }),
    week: PropTypes.shape({
      header: PropTypes.elementType,
      event: PropTypes.elementType
    }),
    month: PropTypes.shape({
      header: PropTypes.elementType,
      dateHeader: PropTypes.elementType,
      event: PropTypes.elementType
    })
  }),
  /**
   * Use this to add legends to calendar. You can use CalendarLegend component render legends.
   */
  legend: PropTypes.node,

  /**
   * **DEPRECATED** Use [`EDSApplication`](#/components/EDSApplication) `labelOverrides` property instead.
   *
   * String messages used throughout the component, override to provide localizations
   */
  messages: PropTypes.shape({
    allDay: PropTypes.node,
    previous: PropTypes.node,
    next: PropTypes.node,
    today: PropTypes.node,
    month: PropTypes.node,
    week: PropTypes.node,
    work_week: PropTypes.node,
    day: PropTypes.node,
    agenda: PropTypes.node,
    date: PropTypes.node,
    time: PropTypes.node,
    event: PropTypes.node,
    noEventsInRange: PropTypes.node,
    showMore: PropTypes.func
  }),

  /**
   * If true, displays the dropdown with view names in the Toolbar
   */
  showViewNames: PropTypes.bool,

  /**
   * If true, displays the current day of the week when view is set to `day`
   */
  showOnlyDay: PropTypes.bool,

  /**
   * @ignore
   * A string representation of the current viewport
   */
  width: PropTypes.string.isRequired,
  /**
   * Content to be rendered on Popper for each event click
   *
   * You can pass a render function or a node.
   *
   * * If you pass this prop as a node, then you will have to set the selected event through `onSelectEvent()` callback.
   * * If you pass this prop as a render function, then the selected `event` will be passed as an argument to the render function.
   */
  popperContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

Calendar.defaultProps = {
  elementProps: {},
  popup: false,
  toolbar: true,

  // view: views.WEEK, // NOTE: breaks component if uncommented
  views: [views.WEEK, views.WORK_WEEK],
  step: 30,
  length: 30,

  drilldownView: views.DAY,

  titleAccessor: 'title',
  tooltipAccessor: 'title',
  allDayAccessor: 'allDay',
  startAccessor: 'start',
  endAccessor: 'end',
  resourceAccessor: 'resourceId',

  resourceIdAccessor: 'id',
  resourceTitleAccessor: 'title',

  longPressThreshold: 250,
  getNow: function getNow() {
    return new Date();
  },
  showViewNames: true,
  showOnlyDay: false,

  localizer: DEFAULT_LOCALIZER
};

Calendar.displayName = 'Calendar';

export { CalendarConsumer, CalendarContext, momentLocalizer, globalizeLocalizer, views };

export default withWidth()(withEDSContext(withStyles(ReactBigCalendarStyles)(Calendar)));