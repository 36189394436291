function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import classnames from 'classnames';

export var spacingClasses = {
    CONTAINER_CLASS_NAME: 'hedtech-container-spacing',
    LAYOUT_CLASS_NAME: 'hedtech-layout-spacing',
    TEMPLATE_CLASS_NAME: 'hedtech-template-spacing',

    SPACING_RESPONSIVE_CLASS_NAME: 'hedtech-spacing-responsive',
    SPACING_NON_RESPONSIVE_CLASS_NAME: 'hedtech-spacing-non-responsive',
    SPACING_STANDARD_CLASS_NAME: 'hedtech-spacing-standard',
    SPACING_ROW_NONE_CLASS_NAME: 'hedtech-spacing-row-none',
    SPACING_COLUMN_NONE_CLASS_NAME: 'hedtech-spacing-column-none',
    SPACING_NONE_CLASS_NAME: 'hedtech-spacing-none',
    OUTER_SPACING_NONE_CLASS_NAME: 'hedtech-outer-spacing-none',

    RESET_SPACING_CLASS_NAME: 'hedtech-reset-spacing',
    RESET_SPACING_RIGHT_LEFT_STANDARD_CLASS_NAME: 'hedtech-reset-spacing-right-left-standard',
    RESET_SPACING_ALL_STANDARD_CLASS_NAME: 'hedtech-reset-spacing-all-standard'
};

export var spacingVariant = {
    STANDARD: 'standard',
    NONE: 'none'
};

export var spacingType = {
    CONTAINER: 'container',
    LAYOUT: 'layout',
    TEMPLATE: 'template',
    COMPONENT_RESET: 'componentReset'
};

var spacingOptions = {
    spacing: spacingVariant.STANDARD,
    spacingRow: spacingVariant.STANDARD,
    spacingColumn: spacingVariant.STANDARD,
    responsive: true,
    outerSpacing: true
};

var negateComponentSpacingOptions = {
    negateSpacingRightLeft: false, // negative spacing left and right
    negateSpacingAll: false // adds negative margins on all dimensions
};

var getSpacingCss = function getSpacingCss(options, type) {
    var _classnames;

    var optionsLocal = options || spacingOptions;
    var spacing = optionsLocal.spacing,
        spacingRow = optionsLocal.spacingRow,
        spacingColumn = optionsLocal.spacingColumn,
        responsive = optionsLocal.responsive,
        outerSpacing = optionsLocal.outerSpacing;


    return classnames((_classnames = {}, _defineProperty(_classnames, spacingClasses.SPACING_RESPONSIVE_CLASS_NAME, responsive), _defineProperty(_classnames, spacingClasses.CONTAINER_CLASS_NAME, type === spacingType.CONTAINER), _defineProperty(_classnames, spacingClasses.LAYOUT_CLASS_NAME, type === spacingType.LAYOUT), _defineProperty(_classnames, spacingClasses.TEMPLATE_CLASS_NAME, type === spacingType.TEMPLATE), _defineProperty(_classnames, spacingClasses.SPACING_NON_RESPONSIVE_CLASS_NAME, !responsive), _defineProperty(_classnames, spacingClasses.SPACING_STANDARD_CLASS_NAME, spacing === spacingVariant.STANDARD), _defineProperty(_classnames, spacingClasses.SPACING_NONE_CLASS_NAME, spacing === spacingVariant.NONE), _defineProperty(_classnames, spacingClasses.SPACING_ROW_NONE_CLASS_NAME, spacingRow === spacingVariant.NONE), _defineProperty(_classnames, spacingClasses.SPACING_COLUMN_NONE_CLASS_NAME, spacingColumn === spacingVariant.NONE), _defineProperty(_classnames, spacingClasses.OUTER_SPACING_NONE_CLASS_NAME, !outerSpacing), _classnames));
};

var getResetComponentSpacingCss = function getResetComponentSpacingCss(options) {
    var _classnames2;

    var optionsLocal = options || negateComponentSpacingOptions;
    var spacing = optionsLocal.spacing,
        negateSpacingRightLeft = optionsLocal.negateSpacingRightLeft,
        negateSpacingAll = optionsLocal.negateSpacingAll;


    return classnames((_classnames2 = {}, _defineProperty(_classnames2, spacingClasses.RESET_SPACING_CLASS_NAME, true), _defineProperty(_classnames2, spacingClasses.RESET_SPACING_RIGHT_LEFT_STANDARD_CLASS_NAME, negateSpacingRightLeft && spacing === spacingVariant.STANDARD), _defineProperty(_classnames2, spacingClasses.RESET_SPACING_ALL_STANDARD_CLASS_NAME, negateSpacingAll && spacing === spacingVariant.STANDARD), _classnames2));
};

var globalSpacing = {
    spacingOptions: spacingOptions,
    getSpacingCss: getSpacingCss,
    getResetComponentSpacingCss: getResetComponentSpacingCss
};

export default globalSpacing;