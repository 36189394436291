var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import globalSpacing, { spacingType } from './globalSpacing';
import useCustomSpacing from './internal/useCustomSpacing';

/**
 * Use this hook in React functional components and when there is a need to update the spacing on-the-fly.
 * For example:
 *
 *   1. The spacing turns to none on tablet landscape mode.
 *   2. Rows spacings need to remain standard on mobile, etc.
 *   3. And any other use cases where there's need to change the default spacing dynamically.
 *
 * Use the `initialSpacingOptions` param to override spacing defaults. It accepts an object with the
 * following attributes:
 *
 *   * spacing - (standard | none | object) The spacing to apply to the grid as a whole. The default *standard* option applies our standard set
 *                                          of spacing styles. The *none* option removes **all** standards spacing, within and without the
 *                                          container. The *object* option allows custom values to be applied. Use *none* or *object* when you want to define your own spacing.
 *   * spacingRow - (standard | none)       Enable/disable grid row spacing
 *   * spacingColumn - (standard | none)    Enable/disable grid column spacing
 *   * responsive - (true | false)          Enable/disable responsive spacing updates.
 *   * outerSpacing - (true | false)        If `false`, disables all of grid container's *exterior* spacing
 *
 * Use the `type` param to specify the kind of spacing classes to produce. Options include:
 *
 *   * container - For container components
 *   * layout - For grid-based layouts
 *   * template - For page templates
 *   * componentReset -  To remove all spacing for a container or layout or component
 *
 *
 * @param {object} initialSpacingOptions Use this if you want to override default spacing
 * @param {string} type                  The type of spacing to return
 */
var useSpacing = function useSpacing() {
    var initialSpacingOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : spacingType.CONTAINER;

    var getSpacingCss = void 0;

    switch (type) {
        case spacingType.COMPONENT_RESET:
            getSpacingCss = globalSpacing.getResetComponentSpacingCss;
            break;
        default:
            getSpacingCss = globalSpacing.getSpacingCss;
    }

    var _useState = useState(Object.assign(Object.assign({}, globalSpacing.spacingOptions), initialSpacingOptions)),
        _useState2 = _slicedToArray(_useState, 2),
        spacingOptions = _useState2[0],
        setOptions = _useState2[1];

    var _useState3 = useState(getSpacingCss(spacingOptions, type)),
        _useState4 = _slicedToArray(_useState3, 2),
        defaultSpacingSyles = _useState4[0],
        setSpacingCss = _useState4[1];

    var _useCustomSpacing = useCustomSpacing(spacingOptions),
        _useCustomSpacing2 = _slicedToArray(_useCustomSpacing, 2),
        customSpacingStyles = _useCustomSpacing2[0],
        setSpacingCustomCss = _useCustomSpacing2[1];

    var spacingStyles = customSpacingStyles || defaultSpacingSyles;

    var setSpacingOptions = function setSpacingOptions(options) {
        setOptions(Object.assign(Object.assign({}, spacingOptions), options));
        setSpacingCss(getSpacingCss(Object.assign(Object.assign({}, spacingOptions), options), type));
        setSpacingCustomCss(options);
    };

    return [spacingStyles, spacingOptions, setSpacingOptions];
};

/**
 * Spacing utility methods can be used in places where you can't use React hooks,
 * like React class components or if you know that the default
 * spacing is not going to change for the entire lifecycle of the application.
 *
 * Use this method to get default spacing options.
 *
 * @param {object} initialSpacingOptions Use this if you want to override default spacing
 */
var getSpacingOptions = function getSpacingOptions() {
    var initialSpacingOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return Object.assign(Object.assign({}, globalSpacing.spacingOptions), initialSpacingOptions);
};

/**
 * Spacing utility methods can be used in places where you can't use React hooks,
 * like React class components or if you know that the default
 * spacing is not going to change for the entire lifecycle of the application.
 *
 * Use this method to get defaut spacing styles.
 *
 * @param {object} initialSpacingOptions Use this if you want to override default spacing
 * @param {string} type
 *  `spacingType.CONTAINER` (`container`) for container components
 *  `spacingType.LAYOUT` (`layout`) for layout components
 *  `spacingType.TEMPLATE` (`template`) for page template components
 *  `spacingType.COMPONENT_RESET` (`componentReset`) to remove the spacing for a container or layout or component
 */
var getSpacingStyles = function getSpacingStyles() {
    var initialSpacingOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : spacingType.CONTAINER;

    var spacingOptions = Object.assign(Object.assign({}, globalSpacing.spacingOptions), initialSpacingOptions);

    var spacingStyles = '';
    switch (type) {
        case spacingType.COMPONENT_RESET:
            spacingStyles = globalSpacing.getResetComponentSpacingCss(spacingOptions);
            break;
        default:
            spacingStyles = globalSpacing.getSpacingCss(spacingOptions, type);
    }

    return spacingStyles;
};

/**
 * Use this HOC with React class components.
 *
 * Use the `initialSpacingOptions` param to override spacing defaults. It accepts an object with the
 * following attributes:
 *
 *   * spacing - (standard | none | object) The spacing to apply to the grid as a whole. The default *standard* option applies our standard set
 *                                          of spacing styles. The *none* option removes **all** standards spacing, within and without the
 *                                          container. The *object* option allows custom values to be applied. Use *none* or *object* when you want to define your own spacing.
 *   * spacingRow - (standard | none)       Enable/disable grid row spacing
 *   * spacingColumn - (standard | none)    Enable/disable grid column spacing
 *   * responsive - (true | false)          Enable/disable responsive spacing updates.
 *   * outerSpacing - (true | false)        If `false`, disables all of grid container's *exterior* spacing
 *
 * Use the `type` param to specify the kind of spacing classes to produce. Options include:
 *
 *   * container - For container components
 *   * layout - For grid-based layouts
 *   * template - For page templates
 *   * componentReset -  To remove all spacing for a container or layout or component
 *
 *
 * @param {object} initialSpacingOptions Use this if you want to override default spacing
 * @param {string} type                  The type of spacing to return
 * @param {object} WrappedComponent      The component which will receive spacing props.
 */
var withSpacing = function withSpacing() {
    var initialSpacingOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var type = arguments[1];
    return function (WrappedComponent) {
        var Wrap = React.forwardRef(function (props, ref) {
            var spacingOptionsLocal = getSpacingOptions(initialSpacingOptions);

            var _useSpacing = useSpacing(spacingOptionsLocal, type),
                _useSpacing2 = _slicedToArray(_useSpacing, 3),
                spacingStyles = _useSpacing2[0],
                spacingOptions = _useSpacing2[1],
                setSpacingOptions = _useSpacing2[2];

            if (WrappedComponent === undefined) {
                throw new Error('\n                You are calling withSpacing(options)(Component) with an undefined component.\n                You may have forgotten to import it.              \n            '.trim());
            }

            return React.createElement(WrappedComponent, Object.assign({
                spacingStyles: spacingStyles,
                spacingOptions: spacingOptions,
                setSpacingOptions: setSpacingOptions,
                ref: ref
            }, props));
        });

        return hoistNonReactStatics(Wrap, WrappedComponent);
    };
};

export { getSpacingStyles, getSpacingOptions, withSpacing };
export default useSpacing;