var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

import React from 'react';
import TextLink from './../../TextLink';

var ErrorPageAction = function ErrorPageAction(_ref) {
    var action = _ref.action,
        text = _ref.text;

    var actionLink = React.createElement(
        TextLink,
        null,
        text
    );

    if (action && typeof action === 'string') {
        actionLink = React.createElement(
            TextLink,
            { href: action },
            text
        );
    } else if (action && (typeof action === 'undefined' ? 'undefined' : _typeof(action)) === 'object') {
        actionLink = action;
    }

    return actionLink;
};

export default ErrorPageAction;