function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../';
import { default as withStyles } from '../styles';
import { default as MuiDialogTitle } from '@material-ui/core/DialogTitle';

var styles = function styles(theme) {
    return {
        root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h2.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.h2.lineHeight,
            margin: 0,
            padding: 0
        }
    };
};

/**
 * DialogTitle is the title within a Dialog.
 */
var DialogTitle = React.forwardRef(function (props, ref) {
    var children = props.children,
        disableTypography = props.disableTypography,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'disableTypography', 'classes']);

    var classes = {
        root: classesProp.root
    };

    // use our own typography element (if the user hasn't disabled typography for titles),
    // so that we can control the variant
    var titleContents = disableTypography ? children : React.createElement(
        Typography,
        { variant: 'h2' },
        children
    );

    return React.createElement(
        MuiDialogTitle,
        Object.assign({ disableTypography: true, classes: classes }, rest, { ref: ref }),
        titleContents
    );
});

DialogTitle.muiName = 'DialogTitle';
DialogTitle.displayName = 'DialogTitle';

DialogTitle.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the children won't be wrapped by a typography component.
     * For instance, this can be useful to render an h4 instead of the default h2.
     */
    disableTypography: PropTypes.bool
};

DialogTitle.defaultProps = {
    disableTypography: false
};

export default withStyles(styles)(DialogTitle);