import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiBackdrop } from '@material-ui/core/Backdrop';
import { withStyles } from '../styles';

var styles = function styles(theme) {
    return {
        root: {
            zIndex: theme.zIndex.backdrop
        }
    };
};

/**
 * Places a tinted, full-screen backdrop over the application. Useful
 * as a backdrop for UI elements that sit on top of the main content.
 *
 * @done false
 * @updated true
 * @versionAdded v4.4.0
 * @examples
 *  BackdropExample
 */
var Backdrop = function Backdrop(props) {
    var children = props.children,
        classes = props.classes,
        invisible = props.invisible,
        onClick = props.onClick,
        open = props.open,
        className = props.className,
        transitionDuration = props.transitionDuration;


    var classOverrides = {
        root: classes.root
    };

    return React.createElement(
        MuiBackdrop,
        {
            invisible: invisible,
            open: open,
            className: className,
            transitionDuration: transitionDuration,
            onClick: onClick,
            classes: classOverrides
        },
        children
    );
};

Backdrop.muiName = 'Backdrop';

Backdrop.propTypes = {

    /**
     * The content of the component.
     */
    children: PropTypes.node,

    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
     * If `true`, the backdrop is invisible.
     *
     * It can be used when rendering a popover or a custom select component.
     */
    invisible: PropTypes.bool,

    /**
     * Event handler that fires when the user clicks on an active backdrop.
     */
    onClick: PropTypes.func,

    /**
     * If `true`, the backdrop is open.
     */
    open: PropTypes.bool.isRequired,

    /**
     * The duration for the transition, in milliseconds.
     *
     * You may specify a single timeout for all transitions, or individually with an object.
     *
     * Object format:
     *
     * ```
     * {
     *     appear: number,
     *     enter: number,
     *     exit: number
     * }
     * ```
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
        appear: PropTypes.number,
        enter: PropTypes.number,
        exit: PropTypes.number
    })])

};

Backdrop.defaultProps = {
    invisible: false
};

export default withStyles(styles)(Backdrop);