function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiList } from '@material-ui/core/List';
import { widthFluid } from '../styles/tokens';
import { getSpacingOptions } from './../globalSpacing';
import { spacingVariant } from '../globalSpacing';

var styles = function styles(theme) {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      width: widthFluid
    }
  };
};

var ListContext = createContext();

/**
 * Use `List` to display a continuous, vertical index of text and/or images.
 * @done true
 * @updated true
 * @versionAdded v0.0.15
 * @examples
 *  PrimaryTextExample
 *  SecondaryTextExample
 *  ControlsExample
 *  ListItemWithButtonExample
 *  IconsExample
 *  ComplexListExample
 *  MultilineExample
 *  NestedOptionsExample
 */
var List = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      spacingOptionsLocal = props.spacingOptions,
      rest = _objectWithoutProperties(props, ['classes', 'spacingOptions']);

  var spacingOptions = getSpacingOptions(spacingOptionsLocal);

  var classOverrides = Object.assign({
    // Style overrides
    root: classes.root
  }, classes);

  return React.createElement(
    ListContext.Provider,
    { value: { spacingOptions: spacingOptions } },
    React.createElement(MuiList, Object.assign({}, rest, { classes: classOverrides, ref: ref }))
  );
});

List.muiName = 'List';
List.displayName = 'List';

List.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used for
   * the list and list items. The property is available to descendant components as the
   * `dense` context.
   */
  dense: PropTypes.bool,
  /**
   * If `true`, vertical padding will be removed from the list.
   */
  disablePadding: PropTypes.bool,
  /**
   * The content of the subheader, normally `ListSubheader`.
   */
  subheader: PropTypes.node,
  /**
   * Override global spacing options.
   * Available options are:
   * * `spacing: 'standard' | 'none'`
   *      * Controls the padding around the `ListItems`.
   */
  spacingOptions: PropTypes.shape({
    spacing: PropTypes.string
  })
};

List.defaultProps = {
  dense: false,
  disablePadding: true,
  spacingOptions: { spacing: spacingVariant.STANDARD }
};

export { ListContext };
export default withStyles(styles)(List);