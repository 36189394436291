var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import React from 'react';
import EdsCircularProgress from '@hedtech/react-design-system/core/es/CircularProgress';
import Fade from '../Fade';
import Text from '../Text';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = (theme) => createStyles({
    '@global': {
        '.custom-page-loader': {
            color: theme.palette.primary.main
        },
        '.default-page-loader': {
            color: Tokens.colorBrandNeutral200
        },
        '.page-loader': {
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            left: 0,
            margin: 'auto',
            position: 'fixed',
            right: 0,
            top: 0,
            transition: 'visibility 0.2s',
            visibility: 'visible',
            WebkitTransition: 'visibility 0.2s',
            zIndex: 2001
        },
        '.page-loader-back': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            WebkitTapHighlightColor: 'transparent',
            zIndex: 2000
        },
        '.page-loader-back-hidden': {
            visibility: 'hidden'
        },
        '.text-loader': {
            marginTop: Tokens.spacing30
        }
    }
});
const PageLoader = (props) => {
    const { id, isDefault, open, text } = props, rest = __rest(props, ["id", "isDefault", "open", "text"]);
    let progress;
    let classLoaderBack = 'page-loader-back';
    if (open) {
        let classLoader;
        let classTextLoader = 'text-loader';
        if (isDefault) {
            classLoader = 'default-page-loader';
            classTextLoader = 'default-page-loader';
        }
        else {
            classTextLoader = 'custom-page-loader';
        }
        progress = (React.createElement("div", { className: "page-loader" },
            React.createElement(EdsCircularProgress, Object.assign({ className: classLoader, id: id, size: 50 }, rest)),
            text && (React.createElement(Text, { id: "txtLoadingPage", className: classTextLoader }, text))));
    }
    else {
        classLoaderBack = classnames(classLoaderBack, 'page-loader-back-hidden');
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Fade, { appear: true, in: open, timeout: { enter: 100, exit: 100 } },
            React.createElement("div", { className: classLoaderBack, "aria-busy": "true" })),
        progress));
};
export default withStyles(styles)(PageLoader);
