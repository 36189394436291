import AppDispatcher from '../AppDispatcher';
import { PasswordConfirmationStatus } from '../../types/PasswordConfirmation/PasswordConfirmationStatus';
const PasswordConfirmationActions = {
    setPasswordValidation(password, accountValidations) {
        AppDispatcher.dispatch({
            payload: {
                password,
                accountValidations
            },
            type: PasswordConfirmationStatus.SuccessSetPasswordValidation
        });
    }
};
export default PasswordConfirmationActions;
