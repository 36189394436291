"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var isRTL = function isRTL(props) {
  return false;
};
var reverseTransform = "scale(-1, 1)";

var SvgWarning = function SvgWarning(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return _react2.default.createElement(
    "svg",
    Object.assign({
      viewBox: "0 0 512 512",
      width: "1em",
      height: "1em",
      transform: isRTL(props) ? reverseTransform : null,
      ref: svgRef
    }, props),
    _react2.default.createElement("path", { d: "M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z" })
  );
};

var ForwardRef = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(SvgWarning, Object.assign({ svgRef: ref }, props));
});
exports.default = ForwardRef;