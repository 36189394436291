var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import classnames from 'classnames';
import makeStyles from './../../makeStyles';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

var useStyles = makeStyles(function (theme) {
    var _responsiveSpacingCss;

    return {
        commomCss: function commomCss(props) {
            var spacing = props.spacing;


            return Object.assign({
                display: 'grid'
            }, spacing && spacing.style ? spacing.style : {});
        },
        responsiveSpacingCss: (_responsiveSpacingCss = {}, _defineProperty(_responsiveSpacingCss, theme.breakpoints.up('xs'), {
            padding: function padding(props) {
                return get(props, 'spacing.xs.padding');
            },
            gridGap: function gridGap(props) {
                return get(props, 'spacing.xs.gutter');
            }
        }), _defineProperty(_responsiveSpacingCss, theme.breakpoints.up('sm'), {
            padding: function padding(props) {
                return get(props, 'spacing.sm.padding');
            },
            gridGap: function gridGap(props) {
                return get(props, 'spacing.sm.gutter');
            }
        }), _defineProperty(_responsiveSpacingCss, theme.breakpoints.up('md'), {
            padding: function padding(props) {
                return get(props, 'spacing.md.padding');
            },
            gridGap: function gridGap(props) {
                return get(props, 'spacing.md.gutter');
            }
        }), _defineProperty(_responsiveSpacingCss, theme.breakpoints.up('lg'), {
            padding: function padding(props) {
                return get(props, 'spacing.lg.padding');
            },
            gridGap: function gridGap(props) {
                return get(props, 'spacing.lg.gutter');
            }
        }), _responsiveSpacingCss),
        responsiveCommonCss: function responsiveCommonCss(props) {
            var _ref;

            return _ref = {}, _defineProperty(_ref, theme.breakpoints.up('xs'), Object.assign({}, get(props, 'spacing.xs.style'))), _defineProperty(_ref, theme.breakpoints.up('sm'), Object.assign({}, get(props, 'spacing.sm.style'))), _defineProperty(_ref, theme.breakpoints.up('md'), Object.assign({}, get(props, 'spacing.md.style'))), _defineProperty(_ref, theme.breakpoints.up('lg'), Object.assign({}, get(props, 'spacing.lg.style'))), _ref;
        }
    };
}, { index: 2 });

var useCustomSpacing = function useCustomSpacing(props) {
    var _React$useState = React.useState(props),
        _React$useState2 = _slicedToArray(_React$useState, 2),
        spacingOptions = _React$useState2[0],
        setSpacingOptions = _React$useState2[1];

    var _ref2 = spacingOptions || { spacing: {} },
        spacing = _ref2.spacing;

    var classes = useStyles({ spacing: spacing });

    if (!isPlainObject(spacing) || isEmpty(spacing)) {
        return [undefined, setSpacingOptions];
    }

    return [classnames(classes.commomCss, classes.responsiveSpacingCss, classes.responsiveCommonCss), setSpacingOptions];
};

export default useCustomSpacing;