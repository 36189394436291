function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { StepContent as MuiStepContent, Collapse } from '@material-ui/core';
import StepProgressContext from './internal/StepProgressContext';
import cn from 'classnames';

var styles = {
    root: {},
    expansion: {
        borderLeft: 'none',
        marginTop: '0px'
    }
};

/**
 * A component that displays progress through numbered steps.
 */
var StepContent = React.forwardRef(function (props, ref) {
    var _cn;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var _React$useContext = React.useContext(StepProgressContext),
        expansion = _React$useContext.expansion;

    var classOverrides = {
        root: cn((_cn = {}, _defineProperty(_cn, classes.expansion, expansion), _defineProperty(_cn, classes.root, true), _cn))
    };

    return React.createElement(MuiStepContent, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

StepContent.muiName = 'StepContent';
StepContent.displayName = 'StepContent';

StepContent.propTypes = {
    /**
     * Step content.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Collapse component.
     */
    TransitionComponent: PropTypes.elementType,
    /**
     * Adjust the duration of the content expand transition.
     * Passed as a property to the transition component.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }), PropTypes.oneOf(['auto'])]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: PropTypes.object
};

StepContent.defaultProps = {
    TransitionComponent: Collapse,
    transitionDuration: 'auto'
};

export default withStyles(styles)(StepContent);