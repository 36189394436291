var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { default as ReactTimePicker } from 'react-time-picker';
import { isTime } from 'react-time-picker/dist/shared/propTypes';
import { withEDSContext } from '../EDSContext/EDSContext';
import withStyles from '../styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '../FormControl/FormControl';
import FormHelperText from '../FormControl/FormHelperText';
import { borderRadiusMedium, borderWidthThin, borderWidthThick, fontFamilyDefault, fontSizeDefault, heightInputFieldsSmall, spacingMedium, spacingSmall, spacingXSmall, sizingMedium, sizingSmall, paddingTextField, zIndexTextFieldLabel, colorTextError, colorGlobalBorderError, heightInputFields, boxShadowFocus, boxShadowError, widthFluid } from '@hedtech/react-design-system/core/styles/tokens';
import uuid from 'uuid/v4';

var styles = function styles(theme) {
    return {
        inputBox: {
            // Box around the whole TimePicker
            '& .react-time-picker__wrapper': {
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                borderRadius: borderRadiusMedium,
                height: heightInputFields,
                minWidth: '11.75rem',
                padding: 0,
                fontFamily: fontFamilyDefault
            },
            // All of the inputs and select inside of the TimePicker
            '& .react-time-picker__inputGroup': {
                padding: paddingTextField
            },
            // The inputs; hour, minute (and second)
            '& .react-time-picker__inputGroup__input': {
                outline: 'none',
                '&:focus': {
                    backgroundColor: theme.palette.grey[200]
                },
                // When any of the inputs or the select have an error state, this class is applied
                '&:invalid': {
                    background: theme.palette.grey[100],
                    boxShadow: 'none'
                }
            },
            // The am/pm selector
            '& .react-time-picker__inputGroup__amPm': {
                height: sizingMedium,
                '-o-appearance': 'none',
                '-moz-appearance': 'none',
                '-webkit-appearance': 'none'
            },
            '&:focus': {
                backgroundColor: theme.palette.grey[200]
            },
            '& .react-time-picker--disabled': {
                backgroundColor: theme.palette.grey[200],
                borderRadius: borderRadiusMedium
            }
        },
        rtlInputs: {
            '& .react-time-picker__inputGroup__hour': {
                left: spacingMedium,
                minWidth: sizingSmall
            },
            '& .react-time-picker__inputGroup__minute': {
                left: '-' + spacingMedium
            }
        },
        inputBoxFocused: {
            // Class that's applied whenever any of the underlying inputs or selects are focused
            '& .react-time-picker--open': {
                boxShadow: boxShadowFocus,
                borderRadius: borderRadiusMedium
            }
        },
        inputBoxError: {
            // Class that's applied whenever the error prop is true
            '& .react-time-picker--open': {
                boxShadow: boxShadowError,
                borderRadius: borderRadiusMedium,
                '-webkit-box-shadow': boxShadowError,
                '-moz-box-shadow:': boxShadowError
            },
            '& .react-time-picker__wrapper': {
                border: borderWidthThick + ' solid ' + colorGlobalBorderError,
                borderRadius: borderRadiusMedium
            }
        },
        inputBoxEnabled: {
            // Setting background and text color in regular state
            '& .react-time-picker__wrapper': {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.grey[600],
                '& .react-time-picker__inputGroup': {
                    // : separator
                    color: theme.palette.grey[600]
                },
                '& .react-time-picker__inputGroup__input': {
                    // Hours, minutes, seconds text
                    color: theme.palette.grey[600],
                    '&::placeholder': {
                        opacity: 1 // Firefox fix so the placeholder text isn't gray
                    },
                    // Browsers handle placeholder text color differently :-|
                    '&::-webkit-input-placeholder': {
                        color: theme.palette.grey[600] // Chrome/Opera/Safari
                    },
                    '&::-moz-placeholder': {
                        color: theme.palette.grey[600] // Firefox 19+
                    },
                    '&:-moz-placeholder': {
                        color: theme.palette.grey[600] // Firefox 18-
                    },
                    '&::-ms-input-placeholder': {
                        color: theme.palette.grey[600] // Edge
                    },
                    '&:-ms-input-placeholder': {
                        color: theme.palette.grey[600] // IE 10+
                    }
                },
                '& .react-time-picker__inputGroup__amPm': {
                    // AM PM text
                    color: theme.palette.grey[600]
                }
            }
        },
        inputBoxDisabled: {
            // Setting background and text color in disabled state
            '& .react-time-picker__wrapper': {
                backgroundColor: '#F7F7F7',
                color: theme.palette.grey[400],
                '& .react-time-picker__inputGroup': {
                    // : separator
                    color: theme.palette.grey[500]
                },
                '& .react-time-picker__inputGroup__input': {
                    // Hours, minutes, seconds text
                    color: theme.palette.grey[500],
                    '&::placeholder': {
                        opacity: 1 // Firefox fix
                    }
                },
                '& .react-time-picker__inputGroup__amPm': {
                    // AM PM text
                    color: theme.palette.grey[500]
                }
            }
        },
        inputLabel: {
            zIndex: zIndexTextFieldLabel,
            top: '0.375rem',
            left: spacingSmall,
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeDefault
        },
        helperTextError: {
            color: colorTextError
        },
        fullWidth: {
            width: widthFluid,
            '& .react-time-picker': {
                flex: '0 0 100%'
            },
            '& .react-time-picker__wrapper': {
                width: widthFluid
            }
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        small: {
            '& .react-time-picker__wrapper': {
                height: heightInputFieldsSmall
            },
            '& .react-time-picker__inputGroup': {
                padding: spacingXSmall
            },
            '& .react-time-picker__inputGroup__amPm, & .react-time-picker__inputGroup__leadingZero': {
                fontSize: fontSizeDefault,
                height: '100%'
            },
            '& $inputBox, & $inputBox:focus': {
                padding: 'none',
                fontSize: fontSizeDefault
            },
            '& $inputLabel[data-shrink=true]': {
                display: 'none'
            }
        }
    };
};

/**
 * A component that allows for the selection of time.
 * @done true
 * @updated false
 * @versionAdded v2.4.0
 * @examples
 *  TimePickerExample
 *  TimePickerFormSubmissionError
 *  TimePickerHooks
 *  TimePickerRequired
 *  TimePickerIntl
 *  TimePickerFullWidth
 *  TimePickerDisabled
 */

var TimePicker = function (_React$Component) {
    _inherits(TimePicker, _React$Component);

    function TimePicker(props) {
        _classCallCheck(this, TimePicker);

        var _this = _possibleConstructorReturn(this, (TimePicker.__proto__ || Object.getPrototypeOf(TimePicker)).call(this, props));

        _this.handleBlur = function (event) {
            var invalid = void 0;

            var nativeTimePicker = _this.timePicker.current.children[0].children[0].children[0].children;

            if (_this.checkValuesAreInvalidOrEmpty(nativeTimePicker)) {
                invalid = true;
            } else {
                invalid = false;
            }

            if (_this.props.required && invalid) {
                _this.setState({ requiredError: true });
            } else {
                _this.setState({ requiredError: false });
            }
            if (_this.props.isInvalid) {
                try {
                    _this.props.isInvalid(invalid);
                } catch (error) {
                    return false;
                }
            }
        };

        _this.checkValuesAreInvalidOrEmpty = function (x) {
            var emptyInputsCounter = 0; // All are empty or all are full are considered valid times (non-required)
            // Count up how many of the inputs/select are empty
            if (x.hour24 && !x.hour24.value || x.hour12 && !x.hour12.value) {
                emptyInputsCounter = emptyInputsCounter + 1;
            }
            if (x.minute && !x.minute.value) {
                emptyInputsCounter = emptyInputsCounter + 1;
            }
            if (x.amPm && !x.amPm.value) {
                emptyInputsCounter = emptyInputsCounter + 1;
            }

            // Total number of inputs & select will be 2 for a 24 hour formated time or 3 for a 12 hour formatted time
            var totalPiecesOfTime = x.amPm ? 3 : 2;

            /**
             * The time is invalid if any part of the time (hour, minutes, AM/PM) has an invalid value in it
             * or if any part of the time is empty. For required, if all of the parts of the time are empty it
             * is considered invalid, but that's handled on its own through the checkValidity function when
             * required is passed into the inputs/select (react-time-picker is doing this for us).
             * This empty checking (emptyInputsCounter > 0 && emptyInputsCounter < totalPiecesOfTime) is for the non-required state.
             */
            if (x.hour24 && !x.hour24.checkValidity() || x.hour12 && !x.hour12.checkValidity() || x.minute && !x.minute.checkValidity() || x.amPm && !x.amPm.checkValidity() || emptyInputsCounter > 0 && emptyInputsCounter < totalPiecesOfTime) {
                return true;
            } else {
                return false;
            }
        };

        _this.state = {
            requiredError: false
        };
        _this.timePicker = React.createRef();
        return _this;
    }

    _createClass(TimePicker, [{
        key: 'render',
        value: function render() {
            var _cn, _cn2;

            var _props = this.props,
                classes = _props.classes,
                classNameProp = _props.className,
                disabled = _props.disabled,
                label = _props.label,
                helperText = _props.helperText,
                fullWidth = _props.fullWidth,
                error = _props.error,
                required = _props.required,
                edsContext = _props.edsContext,
                localeProp = _props.locale,
                FormControlProps = _props.FormControlProps,
                InputLabelPropsProp = _props.InputLabelProps,
                FormHelperTextProps = _props.FormHelperTextProps,
                size = _props.size,
                value = _props.value,
                hourAriaLabel = _props.hourAriaLabel,
                minuteAriaLabel = _props.minuteAriaLabel,
                amPmAriaLabel = _props.amPmAriaLabel,
                rest = _objectWithoutProperties(_props, ['classes', 'className', 'disabled', 'label', 'helperText', 'fullWidth', 'error', 'required', 'edsContext', 'locale', 'FormControlProps', 'InputLabelProps', 'FormHelperTextProps', 'size', 'value', 'hourAriaLabel', 'minuteAriaLabel', 'amPmAriaLabel']);

            var locale = localeProp || edsContext && edsContext.locale.replace('_', '-');
            var inputBoxError = error || this.state.requiredError;
            var InputLabelProps = Object.assign({}, InputLabelPropsProp);
            var labelledByObj = {};
            var rootClassName = cn(classes.root, (_cn = {}, _defineProperty(_cn, classes.small, size === 'small'), _defineProperty(_cn, classes.constrainedWidth, !this.props.fullWidth), _cn), classNameProp);

            InputLabelProps.id = InputLabelProps.id || uuid();

            if (label) {
                labelledByObj['aria-labelledby'] = InputLabelProps.id;
            }

            return React.createElement(
                'div',
                { className: rootClassName },
                React.createElement(
                    FormControl,
                    Object.assign({
                        className: cn('eds-timepicker', classes.inputBox, (_cn2 = {}, _defineProperty(_cn2, classes.rtlInputs, edsContext.dir === 'rtl'), _defineProperty(_cn2, classes.fullWidth, fullWidth), _defineProperty(_cn2, classes.inputBoxEnabled, !disabled), _defineProperty(_cn2, classes.inputBoxDisabled, disabled), _defineProperty(_cn2, classes.inputBoxFocused, !error && !this.state.requiredError), _defineProperty(_cn2, classes.inputBoxError, inputBoxError), _defineProperty(_cn2, 'eds-timepicker-has-error', inputBoxError), _cn2))
                    }, FormControlProps, {
                        onBlur: this.handleBlur,
                        error: error
                    }, labelledByObj),
                    label && React.createElement(
                        InputLabel,
                        Object.assign({
                            className: classes.inputLabel
                        }, InputLabelProps, {
                            required: required,
                            shrink: true
                        }),
                        label
                    ),
                    React.createElement(
                        'div',
                        { ref: this.timePicker, className: classes.inputContainer },
                        React.createElement(ReactTimePicker, Object.assign({}, rest, {
                            disableClock: true,
                            clearIcon: null,
                            disabled: disabled,
                            required: required,
                            value: value,
                            locale: locale,
                            hourAriaLabel: hourAriaLabel ? hourAriaLabel : edsContext.formatMessage('component.TimePicker.hour'),
                            minuteAriaLabel: minuteAriaLabel ? minuteAriaLabel : edsContext.formatMessage('component.TimePicker.minute'),
                            amPmAriaLabel: amPmAriaLabel ? amPmAriaLabel : edsContext.formatMessage('component.TimePicker.amPm')
                        }))
                    ),
                    helperText && React.createElement(
                        FormHelperText,
                        Object.assign({
                            error: error || this.state.requiredError
                        }, FormHelperTextProps),
                        helperText
                    )
                )
            );
        }
    }]);

    return TimePicker;
}(React.Component);

TimePicker.propTypes = {
    /**
     * ARIA message for AM/PM select, defaults to a [translated resource string](#/components/EDSApplication)
     */
    amPmAriaLabel: PropTypes.string,
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Defines whether the TimePicker should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Defines whether the TimePicker should show an error state.
     */
    error: PropTypes.bool,
    /**
    * Defines input format based on [Unicode Technical Standard #35](https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table). Supported values are: H, HH, h, hh, m, mm, s, ss, a.
    */
    format: PropTypes.string,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Properties applied to the [`FormControl`](#/components/FormControl) element.
     */
    FormControlProps: PropTypes.object,
    /**
     * Properties applied to the [`FormHelperText`](#/components/FormControl) element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
    * Render the input field the full width of the parent container.
    */
    fullWidth: PropTypes.bool,
    /**
     * The helper text content.
     */
    helperText: PropTypes.node,
    /**
     * ARIA message for hour input, defaults to a [translated resource string](#/components/EDSApplication)
     */
    hourAriaLabel: PropTypes.string,
    /**
     * Properties applied to the [`InputLabel`](https://material-ui.com/api/input-label/) element.
     */
    InputLabelProps: PropTypes.object,
    /**
     * Function called to tell the consumer if any of the fields within the TimePicker are invalid. To be used for easier form validation.
     * Updates when onBlur is triggered. Accepted parameter is a boolean.
     */
    isInvalid: PropTypes.func,
    /**
     * The label content.
     */
    label: PropTypes.node,
    /**
     * **DEPRECATED** Use the global locale specified via [`EDSApplication`](#/components/EDSApplication) instead.
     *
     * Defines which locale is used by the TimePicker. Can be any [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag).
     *
     * Defaults to the locale specified in [`EDSApplication`](#/components/EDSApplication).
     */
    locale: PropTypes.string,
    /**
     * @ignore
     * Defines how detailed the TimePicker is.
     */
    maxDetail: PropTypes.oneOf(['hour', 'minute', 'second']),
    /**
     * @ignore
     * Defines maximum time that the user can select.
     */
    maxTime: isTime,
    /**
     * ARIA message for minute input, defaults to a [translated resource string](#/components/EDSApplication)
     */
    minuteAriaLabel: PropTypes.string,
    /**
     * @ignore
     * Defines minimum date that the user can select.
     */
    minTime: isTime,
    /**
     * Defines input name.
     */
    name: PropTypes.string,
    /**
     * Function called when the user picks a valid time. Accepted parameter is a time (see `value` property for supported types).
     */
    onChange: PropTypes.func,
    /**
    * The size of the `TimePicker`. Small size is recommended to be used within the `TableEditableCell` component
    */
    size: PropTypes.oneOf(['small', 'medium']),
    /**
     * Defines whether input is required.
     */
    required: PropTypes.bool,
    /**
    * Defines the value of the input. Can be a string or a Date object.
    */
    value: PropTypes.oneOfType([isTime, PropTypes.instanceOf(Date)])
};

TimePicker.displayName = 'TimePicker';

TimePicker.defaultProps = {
    maxDetail: 'minute',
    name: 'time',
    disabled: false,
    required: false,
    size: 'medium'
};

export default withEDSContext(withStyles(styles)(TimePicker));