var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React from 'react';
import { CardContent as EdsCardContent } from '@hedtech/react-design-system/core/es/Card';
import { Tokens } from '../styles';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    cardFluid: {
        width: Tokens.widthFluid
    }
});
const CardContent = (props) => {
    const { children, classes, className } = props, rest = __rest(props, ["children", "classes", "className"]);
    return (React.createElement(EdsCardContent, Object.assign({ classes: omit(classes, ['cardFluid']), className: classnames(classes.cardFluid, className) }, rest), children));
};
export default withStyles(styles)(CardContent);
