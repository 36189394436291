function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTooltip } from '@material-ui/core/Tooltip';
import { withEDSContext } from '../EDSContext/EDSContext';

import { spacingSmall, spacingXxSmall } from '../styles/tokens';

var styles = function styles(theme) {
  return {
    tooltip: _defineProperty({
      paddingLeft: spacingSmall,
      paddingRight: spacingSmall,
      fontSize: theme.typography.body2.fontSize,
      maxWidth: '18.750rem',
      lineHeight: theme.typography.caption.lineHeight,
      textAlign: 'center',
      backgroundColor: theme.palette.grey[500]
    }, theme.breakpoints.up('sm'), {
      fontSize: theme.typography.caption.fontSize,
      padding: spacingXxSmall
    })
  };
};

/**
 * Use `Tooltip` to display a small amount of informative text.
 * @done true
 * @updated false
 * @versionAdded v0.0.9
 * @examples
 *  TooltipExample
 *  TooltipPositionedExample
 *  TooltipDisabledButton
 */
var Tooltip = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      edsContext = props.edsContext,
      PopperProps = props.PopperProps,
      rest = _objectWithoutProperties(props, ['classes', 'edsContext', 'PopperProps']);

  var classOverrides = {
    tooltip: classes.tooltip
  };

  var popperPropOverrides = Object.assign({ dir: edsContext.dir }, PopperProps);

  return React.createElement(MuiTooltip, Object.assign({
    PopperProps: popperPropOverrides,
    classes: classOverrides,
    ref: ref
  }, rest));
});

Tooltip.muiName = 'Tooltip';
Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /**
   * Tooltip reference element.
   */
  children: PropTypes.element.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Do not respond to focus events.
   */
  disableFocusListener: PropTypes.bool,
  /**
   * Do not respond to hover events.
   */
  disableHoverListener: PropTypes.bool,
  /**
   * Do not respond to long press touch events.
   */
  disableTouchListener: PropTypes.bool,
  /**
   * The number of milliseconds to wait before showing the tooltip.
   * This property won't impact the enter touch delay (`enterTouchDelay`).
   */
  enterDelay: PropTypes.number,
  /**
   * The number of milliseconds a user must touch the element before showing the tooltip.
   */
  enterTouchDelay: PropTypes.number,
  /**
   * The relationship between the tooltip and the wrapper component is not clear from the DOM.
   * By providing this property, we can use aria-describedby to solve the accessibility issue.
   */
  id: PropTypes.string,
  /**
   * The number of milliseconds to wait before hiding the tooltip.
   * This property won't impact the leave touch delay (`leaveTouchDelay`).
   */
  leaveDelay: PropTypes.number,
  /**
   * The number of milliseconds after the user stops touching an element before hiding the tooltip.
   */
  leaveTouchDelay: PropTypes.number,
  /**
   * Callback fired when the tooltip requests to be closed.
   *
   * @param {object} event The event source of the callback
   */
  onClose: PropTypes.func,
  /**
   * Callback fired when the tooltip requests to be open.
   *
   * @param {object} event The event source of the callback
   */
  onOpen: PropTypes.func,
  /**
   * If `true`, the tooltip is shown.
   */
  open: PropTypes.bool,
  /**
   * Tooltip placement.
   *
   * When using values besides "top" or "bottom", be sure to evaluate the Tooltip direction when your app is RTL.
   */
  placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
  /**
   * Properties applied to the `Popper` element.
   */
  PopperProps: PropTypes.object,
  /**
   * @ignore
   */
  theme: PropTypes.object,
  /**
   * Tooltip title. Zero-length titles string are never displayed.
   */
  title: PropTypes.node.isRequired
};

// If default props are needed, they must be set here
Tooltip.defaultProps = {
  enterDelay: 100,
  enterTouchDelay: 700,
  leaveDelay: 0,
  leaveTouchDelay: 1500,
  disableFocusListener: false,
  disableHoverListener: false,
  disableTouchListener: false,
  placement: 'bottom'
};

export default withEDSContext(withStyles(styles)(Tooltip));