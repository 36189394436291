import classnames from 'classnames';
import React from 'react';
import EdsPageHeader from '@hedtech/react-design-system/core/es/PageHeader';
import Grid from '../Grid';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    h1: {},
    pageHeaderWhite: {
        color: Tokens.colorBrandNeutral100
    }
});
const PageHeader = (props) => {
    const { classes, id, text, white } = props;
    const classOverrides = {
        h1: classes.h1
    };
    if (white) {
        classOverrides.h1 = classnames(classes.h1, classes.pageHeaderWhite);
    }
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true },
            React.createElement(EdsPageHeader, { classes: classOverrides, id: id, text: text }))));
};
export default withStyles(styles)(PageHeader);
