function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';

import { default as MuiCardContent } from '@material-ui/core/CardContent';
import { CardContext } from './Card';
import getLegacySpacingModeCss from './internal/getLegacySpacingModeCss';
import { default as withStyles } from '../styles';
import { useSpacing } from './../globalSpacing';

import { spacingLarge, spacingMedium, spacingSmall, spacingXSmall, spacingXSmall2, borderRadiusMedium, spacing40, spacing50 } from '../styles/tokens';

var borderRadiusStyles = {
    borderBottomLeftRadius: borderRadiusMedium,
    borderBottomRightRadius: borderRadiusMedium
};

var styles = function styles(theme) {
    var _responsive;

    return {
        root: {
            '&:last-child': {
                padding: 0
            }
        },
        standard: {
            padding: spacingSmall + ' ' + spacingLarge,
            '&:last-child': {
                padding: spacingSmall + ' ' + spacingLarge
            }
        },
        dense: {
            padding: spacingXSmall2 + ' ' + spacingMedium,
            '&:last-child': {
                padding: spacingXSmall2 + ' ' + spacingMedium
            }
        },
        none: {
            padding: 0,
            '&:last-child': {
                padding: 0
            }
        },
        responsive: (_responsive = {}, _defineProperty(_responsive, theme.breakpoints.only('xs'), {
            padding: spacingXSmall + ' ' + spacingSmall
        }), _defineProperty(_responsive, '&:last-child', _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: spacingXSmall + ' ' + spacingSmall
        })), _responsive),
        negateSpacing: Object.assign({}, borderRadiusStyles, {
            '& > *:last-child': Object.assign({}, borderRadiusStyles)
        })
    };
};

/**
 * CardContent component for displaying content on a Card component
 */
var CardContent = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        spacingOptions = props.spacingOptions,
        rest = _objectWithoutProperties(props, ['classes', 'spacingOptions']);

    var negateSpacingAll = get(spacingOptions, 'negateSpacingAll');
    var negateSpacingStyles = useSpacing({ spacing: {
            style: {
                margin: '0 -' + spacing40 + ' -' + spacing40,
                display: 'block'
            },
            lg: {
                style: {
                    margin: '0 -' + spacing50 + ' -' + spacing50
                }
            }
        } });

    var classesOverrides = negateSpacingAll ? negateSpacingStyles : getLegacySpacingModeCss(useContext(CardContext), classes);

    var classOverrides = {
        root: cn(classes.root, classesOverrides, _defineProperty({}, classes.negateSpacing, negateSpacingAll))
    };

    return React.createElement(MuiCardContent, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

CardContent.muiName = 'CardContent';
CardContent.displayName = 'CardContent';

CardContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Adds negative margins on all dimensions
     *
     * `negateSpacingAll` (bool): **true** | false
     *
     *  * If set to `true`, adds negative margins on all dimensions.
     *  * Helpful when you want to remove the outer padding for card content
     */
    spacingOptions: PropTypes.shape({
        negateSpacingAll: PropTypes.bool
    })
};

// If default props are needed, they must be set here
CardContent.defaultProps = {
    component: 'div'
};

export default withStyles(styles)(CardContent);