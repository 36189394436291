function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanel } from '@material-ui/core/ExpansionPanel';
import ExpansionPanelContext from './internal/ExpansionPanelContext';
import { getSpacingOptions, spacingVariant } from './../globalSpacing';

import { spacing40, spacing50 } from '../styles/tokens';

var styles = function styles(theme) {
    var _separated;

    return {
        root: {

            '&$expanded$nogap': {
                marginTop: 0,
                marginBottom: 0,
                '&:before': {
                    opacity: 1
                }
            },

            '&$expanded$nogap + &': {
                '&:before': {
                    display: 'block'
                }
            },

            '&$expanded&$separated': _defineProperty({
                margin: spacing40 + ' 0'
            }, theme.breakpoints.up('md'), {
                margin: spacing50 + ' 0'
            }),

            backgroundColor: theme.palette.grey[100]
        },

        card: {
            boxShadow: '\n            0 2px 1px -1px rgba(0, 0, 0, 0.12),\n            0 1px 1px 0 rgba(0, 0, 0, 0.14),\n            0 1px 3px 0 rgba(0, 0, 0, 0.21)\n        '
        },

        default: {
            boxShadow: '\n            0 19px 20px 0 rgba(0, 0, 0, 0.03),\n            0 3px 8px 0 rgba(0, 0, 0, 0.06)\n        '
        },

        disabled: {},
        expanded: {},
        separated: (_separated = {

            margin: spacing40 + ' 0'
        }, _defineProperty(_separated, theme.breakpoints.up('md'), {
            margin: spacing50 + ' 0'
        }), _defineProperty(_separated, '& + &', {
            '&:before': {
                opacity: 0
            }
        }), _separated),
        nogap: {},
        none: {}
    };
};

/**
 * Use `ExpansionPanel` to organize content into expandable and collapsible sections.
 *
 * @done true
 * @updated false
 * @versionAdded v0.0.13
 * @examples
 *  ExpansionPanelSimple
 *  ExpansionPanelCard
 *  ExpansionPanelControlledAccordion
 *  ExpansionPanelLeftIcon
 *  ExpansionPanelSecondary
 */
var ExpansionPanel = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        panelSpacingVariant = props.panelSpacingVariant,
        variant = props.variant,
        spacingOptions = props.spacingOptions,
        CollapseProps = props.CollapseProps,
        rest = _objectWithoutProperties(props, ['classes', 'panelSpacingVariant', 'variant', 'spacingOptions', 'CollapseProps']);

    var spacingOptionsLocal = getSpacingOptions(spacingOptions);

    var classOverrides = {};
    classOverrides.root = classNames(classes.root, _defineProperty({}, classes.nogap, panelSpacingVariant !== 'separated'), _defineProperty({}, classes.separated, panelSpacingVariant === 'separated'), _defineProperty({}, classes.card, variant === 'card'), _defineProperty({}, classes.default, variant === 'default'), _defineProperty({}, classes.none, spacingOptionsLocal.spacing === spacingVariant.NONE));

    classOverrides.expanded = classes.expanded;

    return React.createElement(
        ExpansionPanelContext.Provider,
        { value: {
                disabled: props.disabled,
                spacingOptions: spacingOptionsLocal
            } },
        React.createElement(MuiExpansionPanel, Object.assign({
            TransitionProps: CollapseProps
        }, rest, {
            classes: classOverrides,
            ref: ref
        }))
    );
});

ExpansionPanel.muiName = 'ExpansionPanel';
ExpansionPanel.displayName = 'ExpansionPanel';

ExpansionPanel.propTypes = {
    /**
     * The content of the expansion panel.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Properties applied to the `Collapse` element.
     */
    CollapseProps: PropTypes.object,
    /**
     * If `true`, expands the panel by default.
     */
    defaultExpanded: PropTypes.bool,
    /**
     * If `true`, the panel will be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, expands the panel, otherwise collapse it.
     * Setting this prop enables control over the panel.
     */
    expanded: PropTypes.bool,

    /**
     * Determines the spacing between panels in a group of expansion panels.
     *
     *   * none - No separation between panels (the default)
     *   * separated - Each panel is separated from its siblings
     */
    panelSpacingVariant: PropTypes.oneOf(['none', 'separated']),

    /**
     * Callback fired when the expand/collapse state is changed.
     *
     * @param {object} event The event source of the callback
     * @param {boolean} expanded The `expanded` state of the panel
     */
    onChange: PropTypes.func,
    /**
     * Styles the ExpansionPanel for the context it is on, either 'default' or 'card'.
     */
    variant: PropTypes.oneOf(['default', 'card']),
    /**
     * Override gloabal spacing options.
     * Available options are:
     * * `spacing: 'standard' | 'none'`
     *      * Controls the padding around the `ExpansionPanel` as a whole, and between the elements of the expansion panel
     * * `responsive: true | false`
     *      * Adjusts spacing automatically on desktop, tablet and mobile viewports
     * * `outerSpacing: true | false`
     *      * Removes the padding around the `ExpansionPanel`
     */
    spacingOptions: PropTypes.shape({
        spacing: PropTypes.string,
        responsive: PropTypes.bool,
        outerSpacing: PropTypes.bool
    })
};

// If default props are needed, they must be set here
ExpansionPanel.defaultProps = {
    variant: 'default',
    panelSpacingVariant: 'none',
    defaultExpanded: false,
    disabled: false
};

export default withStyles(styles)(ExpansionPanel);